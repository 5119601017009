import yes from "../Assets/PricingViews/Pricing/yes.svg";
import no from "../Assets/PricingViews/Pricing/no.svg";
import basicpatients from "../Assets/PricingViews/Pricing/basicpatients.svg";
import propatients from "../Assets/PricingViews/Pricing/propatients.svg";
import enterpatients from "../Assets/PricingViews/Pricing/enterpatients.svg";

export const items = [
  // {
  // id:1,
  // features: "",
  // basichead: "Basic",
  // prohead: "Pro",
  // enterhead: "Enterprise",
  // style: "px-2  ",
  // },

  // {
  // id:2,
  // features: "",
  // basichead: basicprice,
  // prohead: proprice,
  // enterhead: enterprice,
  // style: "px-2 w-[60%]",
  // },

  {
    id: 3,
    features: "3 User Level Access",
    basichead: yes,
    prohead: yes,
    enterhead: yes,
    style: "px-2",
  },

  {
    id: 4,
    features: "Chat Support",
    basichead: yes,
    prohead: yes,
    enterhead: yes,
    style: "px-2",
  },

  {
    id: 5,
    features: "Number of Patients",
    basichead: basicpatients,
    prohead: propatients,
    enterhead: enterpatients,
    style: "px-2",
  },

  {
    id: 6,
    features: "Exercise Library - Add/View Access",
    basichead: yes,
    prohead: yes,
    enterhead: yes,
    style: "px-2",
  },

  {
    id: 7,
    features: "Plan Library - View/Assign Access",
    basichead: yes,
    prohead: yes,
    enterhead: yes,
    style: "px-2",
  },

  {
    id: 8,
    features: "Plan Create/Update Rights",
    basichead: yes,
    prohead: yes,
    enterhead: yes,
    style: "px-2",
  },

  {
    id: 9,
    features: "Patient Exercise and Wearables Monitoring",
    basichead: yes,
    prohead: yes,
    enterhead: yes,
    style: "px-2",
  },

  {
    id: 10,
    features: "Telemedicine Platform",
    basichead: no,
    prohead: yes,
    enterhead: yes,
    style: "bg-dimWhite rounded-lg px-2 font-bold",
  },

  {
    id: 11,
    features: "Unlimited Consultation",
    basichead: no,
    prohead: no,
    enterhead: yes,
    style: "bg-dimWhite rounded-lg px-2 font-bold",
  },

  {
    id: 12,
    features: "Remote AI enabled ROM Monitoring",
    basichead: no,
    prohead: no,
    enterhead: yes,
    style: "bg-dimWhite rounded-lg px-2 font-bold",
  },

  {
    id: 13,
    features: "Dedicated Server",
    basichead: no,
    prohead: no,
    enterhead: yes,
    style: "bg-dimWhite rounded-lg px-2 font-bold",
  },

  {
    id: 14,
    features: "User Medical Records Storage",
    basichead: no,
    prohead: no,
    enterhead: yes,
    style: "bg-dimWhite rounded-lg px-2 font-bold",
  },

  {
    id: 15,
    features: "Advanced Patient Vitals Monitoring Dashboard",
    basichead: no,
    prohead: no,
    enterhead: yes,
    style: "bg-dimWhite rounded-lg px-2 font-bold",
  },

  // {
  // id:16,
  // features: "",
  // basichead: "Choose Plan",
  // prohead: "Choose Plan",
  // enterhead: "Choose Plan",
  // style: "",
  // },
];

export const proitems = [
  {
    id: 3,
    features: "3 User Level Access",
    style: "",
  },

  {
    id: 4,
    features: "Chat Support",
    style: "",
  },

  {
    id: 5,
    features: "100 Patients",
    style: "font-bold text-xl",
  },

  {
    id: 6,
    features: "₹ 1,000 per Extra Patient",
    style: "font-bold text-xl",
  },

  {
    id: 7,
    features: "Exercise Library - Add/View Access",
    style: "",
  },

  {
    id: 8,
    features: "Plan Library - View/Assign Access",
    style: "",
  },

  {
    id: 9,
    features: "Plan Create/Update Rights",
  },

  {
    id: 10,
    features: "Patient Exercise and Wearables Monitoring",
  },

  {
    id: 11,
    features: "Telemedicine Platform",
  },
];

export const enteritems = [
  {
    id: 3,
    features: "3 User Level Access",
    style: "",
  },

  {
    id: 4,
    features: "Chat Support",
    style: "",
  },

  {
    id: 5,
    features: "200 Patients",
    style: "font-bold text-xl",
  },

  {
    id: 6,
    features: "₹ 900 per Extra Patient",
    style: "font-bold text-xl",
  },

  {
    id: 7,
    features: "Exercise Library - Add/View Access",
    style: "",
  },

  {
    id: 8,
    features: "Plan Library - View/Assign Access",
    style: "",
  },

  {
    id: 9,
    features: "Plan Create/Update Rights",
  },

  {
    id: 10,
    features: "Patient Exercise and Wearables Monitoring",
  },

  {
    id: 11,
    features: "Telemedicine Platform",
  },

  {
    id: 12,
    features: "Unlimited Consultation",
    style: "",
  },

  {
    id: 13,
    features: "Remote AI enabled ROM Monitoring",
    style: "",
  },

  {
    id: 14,
    features: "Dedicated Server",
    style: "",
  },

  {
    id: 15,
    features: "User Medical Records Storage",
    style: "",
  },

  {
    id: 16,
    features: "Advanced Patient Vitals Monitoring Dashboard",
    style: "",
  },
];

export const basicitems = [
  {
    id: 3,
    features: "3 User Level Access",
    style: "",
  },

  {
    id: 4,
    features: "Chat Support",
    style: "",
  },

  {
    id: 5,
    features: "10 Patients",
    style: "font-bold text-xl",
  },

  {
    id: 6,
    features: "₹ 1,150 per Extra Patient",
    style: "font-bold text-xl",
  },

  {
    id: 7,
    features: "Exercise Library - Add/View Access",
    style: "",
  },

  {
    id: 8,
    features: "Plan Library - View/Assign Access",
    style: "",
  },

  {
    id: 9,
    features: "Plan Create/Update Rights",
  },

  {
    id: 9,
    features: "Patient Exercise and Wearables Monitoring",
  },
];

export const freemiumitems = [
  {
    id: 3,
    features: "3 User Level Access",
    style: "",
  },

  {
    id: 4,
    features: "Chat Support",
    style: "",
  },

  {
    id: 5,
    features: "2 Patients",
    style: "font-bold text-xl",
  },

  {
    id: 6,
    features: "Exercise Library - Add/View Access",
    style: "",
  },

  {
    id: 7,
    features: "Plan Library - View/Assign Access",
    style: "",
  },

  {
    id: 8,
    features: "Plan Create/Update Rights",
  },

  {
    id: 9,
    features: "Patient Exercise and Wearables Monitoring",
  },
];
