import React from 'react'

import careplans from "../Assets/HomeViews/Numbers/careplans.svg"
import users from "../Assets/HomeViews/Numbers/users.svg"
import countries from "../Assets/HomeViews/Numbers/countries.svg"

function Numbers() {
    const items = [
        {
            image: careplans,
            heading: "300+",
            text: "Curated Care Plans"
        },
        
        {
            image: countries,
            heading: "20+",
            text: "Countries Around The World"
        },

        {
            image: users,
            heading: "3000+",
            text: "trusted Users"
        },
    ]
    return (
        <div className='flex  items-center gap-16 justify-center flex-wrap'>
            <ul className='flex flex-col sm:flex-row  '>
                
            {items.map((item, index) => (
                <li key={index}>
                <div className='flex gap-4  p-4  '>
                    
                    <div className='p-2 text-casashade2 '>
                        <img className='w-full' src={item.image} alt={item.image} />
                    </div>
                    <div className='text-whyus'>
                        <h3 className='font-poppins text-[18px] font-bold'>
                            {item.heading}
                        </h3>
                        <p className='capitalize font-poppins text-casashade2 text-[14px] font-semibold '>
                            {item.text}
                        </p>
                    </div>
                    
                </div>
                </li>
                
            ))}
            
            </ul>

        </div>
        
    )
}

export default Numbers