import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const RTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Get the Best Rehabilitation Therapy
      </h1>
      <div className="mx-5">
        <p>
          Are you looking for a rehabilitation centre for yourself or someone
          close to you? Then, you are at the right place. Connect with Casamed
          to learn more.
        </p>
        <p>
          We understand how frustrating it can be when you cannot perform the
          daily activities because of some medical conditions. To overcome the
          ailment induced pain or challenges, there are mainly 3 types of
          rehabilitation; physical therapy,{" "}
          <Link to="/occupational-therapy-treatment">occupational therapy</Link>{" "}
          and speech therapy. We have certified therapists who can assist
          patients in overcoming any illness or injury associated physical,
          sensory or mental loss.
        </p>
        <p>
          Our therapists at first try to understand the problem and then perform
          therapy according to the need of the disorder. Proper and adequate
          rehabilitation therapy reverses the disability of the patient and
          helps them to perform their daily activities with ease.
        </p>
        <p>
          Casamed also provides online sessions for patients with time or
          location constraints. We continuously guide and assist our patients
          with therapy to help them attain pain relief. During their journey of
          rehabilitation, the patient usually coordinates with the team of
          doctors, psychologists, speech, physical and occupational therapists,
          nurses, and vocational counsellors. There is physical therapy where we
          guide our patients to restore the use of bone, muscles or nervous
          system through heat treatment, exercise or any other technique.
          Exercise is by far considered one of the best techniques because this
          helps the patient to overcome their disability.
        </p>
        <p>
          In occupational therapy, our therapists encourage you to restore old
          skills or learn new skills, adjust to disabilities through adaptive
          equipment, orthotics, and modification of the patient&#39;s home
          environment. At our centre, we also have a speech therapist who can
          guide you to restore speech that is lost because of some illness or
          accident.
        </p>
        <p>
          We promote a non-pharmacological, non-invasive and natural treatment.
          For better results, our therapist will also guide you regarding the
          changes in lifestyle that will boost your condition. The duration of
          the treatment depends on the condition of the patients. Generally, it
          varies from six months to a few years, but the crucial part is to
          complete your treatment for long-term effect. Hence, to overcome your
          disability feel free to reach us and plan your session with our
          therapist. To learn about the pricing and other necessary details
          connect with our professionals.
        </p>
        <h2>Why Choose Casamed For Rehabilitation Therapy</h2>
        <ul>
          <li>
            <strong>Helps to overcome your disability</strong>
          </li>
        </ul>
        <p>
          Our therapists are highly trained, and will curate a custom therapy
          plan for you after going through your case history. They will help you
          in a way through which you can easily move your body, learn new skills
          or restore the old ones, reduce daily active life years (DALY) lost
          and increase your quality of life
        </p>
        <ul>
          <li>
            <strong>Assist you with manual therapy treatment</strong>
          </li>
        </ul>
        <p>
          Our therapist uses heat or cold treatment which helps to ease the
          muscles pain and strengthen movements. Apart from that, they use the
          innovative cutting edge devices to assist patients in joint mobility,
          walking etc. Specialised aids for pain alleviation and rehabilitation
          are also recommended.
        </p>
        <ul>
          <li>
            <strong>
              Educate you about root cause of the ailment and how to prevent it
              from returning
            </strong>
          </li>
        </ul>
        <p>
          During the therapy, our therapist at Casamed also educates the
          patient, caregivers and the family members about the underlying cause
          of the disorder and how it can be overcome. They also guide with “What
          To Do” and “What Not to Do” to ensure complete recovery and avoid any
          relapse.
        </p>
        <p>
          For more details about our services, contact us or track our services
          through our website. Get quality and effective care through our home
          service.
        </p>
      </div>
    </div>
  );
};

const RehabilitationTherapy = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>Rehabilitation Therapy @ CASAMED | Get Consultation now!</title>
        <meta
          name="description"
          content="Connect with CASAMED to get rehabilitation therapy. We have certified therapists, who can assist patients in overcoming any physical, sensory or mental loss."
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="rehabilitation therapy" />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/rehabilitation-therapy"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Rehabilitation Therapy @ CASAMED | Get Consultation now!"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/rehabilitation-therapy"
        />
        <meta
          property="og:description"
          content="Connect with CASAMED to get rehabilitation therapy. We have certified therapists, who can assist patients in overcoming any physical, sensory or mental loss."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
  logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Rehabilitation Therapy @ CASAMED | Get Consultation now!",
            url: " https://casamed.in/rehabilitation-therapy ",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Connect with CASAMED to get rehabilitation therapy. We have certified therapists, who can assist patients in overcoming any physical, sensory or mental loss.",
          })}
        </script>
      </Helmet>
      <RTComponent />
      <Footer />
    </div>
  );
};

export default RehabilitationTherapy;
