import React, { useEffect, useState } from "react";
import SpecializationContainer from "../../Components/SpecializationContainer";
import { motion } from "framer-motion";
import { Col, Container, Image, ListGroup, Modal, Row } from "react-bootstrap";
import { Form, Button } from "react-bootstrap";

import {
  Card,
  Box,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import { Button as Buttonmui } from "@mui/material";
import { Link } from "react-router-dom";
import Footer from "../../Components/Footer";
import NavWhite from "../../Components/NavWhite.jsx";
import DocIcon from "../../Assets/DocIcon.png";
import styles from "./PartnerTest2.module.css";
import cx from "classnames";
import "semantic-ui-css";
import DoctorBanner from "../../Assets/Indian Doctor_Beard.webp";
import PhysioBanner from "../../Assets/Consult_Physio.webp";
import Pagination from "../../Components/Pagination/pagination";
import { priceData } from "../../Utils/constants";
import { Dropdown } from "semantic-ui-react";
import { cityOptions } from "../../Utils/constants";
import { isMobile } from "react-device-detect";
import axios from "axios";
import Loading from "../../Components/Loading/loading";
import { apiUrl } from "../../config";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import casasvg from "../../Assets/casamed.svg";
import { Helmet } from "react-helmet";
import YearsOfExperienceContainer from "../../Components/ExperienceContainer";
import DegreeContainer from "../../Components/DegreeContainer";

const imageVariant = {
  start: {
    rotate: "-180deg",
  },
  end: {
    rotate: 0,
    transition: {
      duration: 1,
    },
  },
};

const ConsultComponent = ({ props }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    console.log("Making API call to get countries data");
    axios
      .get("https://api.countrystatecity.in/v1/countries", {
        headers: {
          "X-CSCAPI-KEY":
            "M2NyWlE5Vm9XV1JVaWdhOElKNkRFN2NsVmdyR1FCMGxZWUJkMTNOYw==",
        },
      })
      .then((response) => {
        console.log("Response:", response.data);
        const countries = response.data || [];
        setCountries(countries);
        setSelectedCountry("");
        setStates([]);
        setSelectedState("");
        setCities([]);
        setSelectedCity("");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      console.log("Making API call to get states data");
      axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "M2NyWlE5Vm9XV1JVaWdhOElKNkRFN2NsVmdyR1FCMGxZWUJkMTNOYw==",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response.data);
          const states = response.data || [];
          setStates(states);
          setSelectedState("");
          setCities([]);
          setSelectedCity();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      console.log("Making API call to get cities data");
      axios
        .get(
          `https://api.countrystatecity.in/v1/countries/${selectedCountry}/states/${selectedState}/cities`,
          {
            headers: {
              "X-CSCAPI-KEY":
                "M2NyWlE5Vm9XV1JVaWdhOElKNkRFN2NsVmdyR1FCMGxZWUJkMTNOYw==",
            },
          }
        )
        .then((response) => {
          console.log("Response:", response.data);
          const cities = response.data || [];
          setCities(cities);
          setSelectedCity("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedCountry, selectedState]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    // setCountryName(event.target.value2);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    // setStateName(event.target.value2);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  console.log(countries, "countries");
  console.log(states, "states");
  console.log(selectedCountry, "selected country");

  const filteredCountry = countries.filter(
    (country) => country.iso2 === selectedCountry
  );
  const filteredState = states.filter((state) => state.iso2 === selectedState);

  const [view, setView] = useState("doctor");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  function handleBtn(event) {
    const { name } = event.target;
    if (name === "doctor") {
      document.getElementById("doctorBtn").classList.add("active");
      document.getElementById("physioBtn").classList.remove("active");
    } else if (name === "physio") {
      document.getElementById("doctorBtn").classList.remove("active");
      document.getElementById("physioBtn").classList.add("active");
    }
    setView(name);
  }

  // Pagination

  const [patientName, setPatientName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [nameError, setNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const validateName = () => {
    if (patientName.trim() === "") {
      setNameError("This field is required");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Please enter a valid phone number");
      return false;
    }
    return true;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateName() || !validatePhoneNumber()) {
      return;
    }

    axios
      .post(
        "/api/locationForm",
        {
          name: patientName,
          phoneNumber: phoneNumber,
          location: "",
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // alert('Form submitted successfully');

        setShowSnackbar(true);
        setSnackbarMessage("Form submitted successfully!");
        setSnackbarSeverity("success");

        setPatientName("");
        setPhoneNumber("");
      })
      .catch((error) => {
        console.error(error);
        // alert('Form submission failed');

        setShowSnackbar(true);
        setSnackbarMessage("Failed to submit form. Please try again later.");
        setSnackbarSeverity("error");
      });
  };

  const handleNameChange = (event) => {
    setPatientName(event.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setPhoneNumberError(false);
  };

  const [doctors, setDoctors] = useState([]);
  const [physios, setPhysios] = useState([]);

  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);

  const getPhysios = async () => {
    if (physios && physios.length > 0) {
      setPageData(physios);
      setLoading(false);
    } else {
      await axios
        .get(`/api/physio`, {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        })

        .then((res) => {
          setPhysios(res.data.data);
          setPageData(res.data.data);
          setLoading(false);
        })
        .catch((error) =>
          setAlertModal({
            show: true,
            message: error.response.data.message || "Something went wrong",
            button: false,
          })
        );
    }
  };
  const getDoctors = async () => {
    if (doctors && doctors.length > 0) {
      setPageData(doctors);
      setLoading(false);
    } else {
      await axios
        .get(`/api/doctor`, {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        })
        .then((res) => {
          setDoctors(res.data?.data);
          setPageData(res.data?.data);
          setLoading(false);
        })
        .catch((error) =>
          setAlertModal({
            show: true,
            message: error.response?.data?.message || "Something went wrong",
            button: false,
          })
        );
    }
  };

  useEffect(() => {
    setPageData([]);
    setLoading(true);
    if (view === "physio") {
      getPhysios();
    } else if (view === "doctor") {
      getDoctors();
    }
    setCurrentPage(1);
  }, [view]);

  const indexOfLastCard = currentPage * dataPerPage;
  const indexOfFirstCard = indexOfLastCard - dataPerPage;
  const currentData = pageData.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const PhysioModal = () => {
    const [physioOption, setPhysioOption] = useState();
    const [location, setLocation] = useState();
    const [amount, setAmount] = useState();

    const handleLocation = (e) => {
      const name = e.target.outerText;
      setLocation(name);
      setAmount(priceData[name]);
    };

    const handlePhysioOption = (e) => {
      setPhysioOption(e.target.name);
    };

    const handleAppointment = () => {
      if (physioOption) {
        let amt = amount;
        if (physioOption === "1") {
          amt = 450;
        } else if (physioOption === "2") {
          amt = 600;
        }
        props.history.push({
          pathname: "/appointment",
          state: {
            type: "physio",
            option: physioOption,
            amount: amt,
            location: location,
          },
        });
      }
    };

    console.log(currentData);

    return (
      <div>
        <Modal
          show={show}
          centered
          onHide={() => {
            setShow(false);
            setPhysioOption();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Please Select Any One Option</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ListGroup className="text-center">
              <ListGroup.Item
                action
                name="1"
                onClick={(e) => handlePhysioOption(e)}
              >
                One Time Evaluation (20 mins)
              </ListGroup.Item>
              <ListGroup.Item
                action
                name="2"
                onClick={(e) => handlePhysioOption(e)}
              >
                Online Session (45 mins)
              </ListGroup.Item>
              <ListGroup.Item
                action
                name="3"
                onClick={(e) => handlePhysioOption(e)}
              >
                Home Visit (45 mins)
              </ListGroup.Item>
            </ListGroup>
            {physioOption === "3" && (
              <div className="my-3">
                <h5>Please Select City: </h5>
                <Dropdown
                  placeholder="Select City"
                  fluid
                  search
                  selection
                  options={cityOptions}
                  onChange={(e) => handleLocation(e)}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
                setPhysioOption();
              }}
            >
              Close
            </Button>
            <Button variant="primary" onClick={handleAppointment}>
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const [alertModal, setAlertModal] = useState({
    show: false,
    message: "",
    button: true,
  });

  const LoginAlert = (alertModal) => {
    return (
      <div>
        <Modal
          show={alertModal.show}
          centered
          onHide={() => {
            setAlertModal({
              show: false,
            });
          }}
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>CASAMED ALERT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <strong>{alertModal.message}</strong>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {alertModal.button && (
              <Link to="/login">
                <Button>Login Now</Button>
              </Link>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const user = localStorage.getItem("user");
  var bookbtn = false;
  const handleBookNow = () => {
    var bookbtn = true;
    if (!user) {
      setAlertModal({
        show: true,
        message: "You need to be logged in to proceed further!",
        button: true,
      });
    } else {
      if (view === "physio") {
        setShow(true);
      } else {
        props.history.push({
          pathname: "/appointment",
          state: { type: "doctor", amount: 850 },
        });
      }
    }
  };

  const [search, setSearch] = useState("");

  const handleSearchNameChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearch = (query) => {
    // Perform search logic or call an API with the query
    console.log("Search:", query);
    // Reset the search input
    setSearch("");
  };

  const handleSearchClick = () => {
    handleSearch(search);
  };

  const SearchBar = () => {
    return (
      <Form.Group className="flex  flex-col pl-2">
        <Form.Label className="block tracking-wide text-gray-700 text-sm font-bold">
          Search
        </Form.Label>
        <div className="relative flex items-center">
          <div className="">
            <Form.Control
              className="w-full appearance-none rounded-sm shadow-md border py-2 px-4 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              id="search"
              name="search"
              value={search}
              placeholder="Search"
              onChange={handleSearchNameChange}
            />
          </div>
          <div className="flex justify-center  items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-400 absolute right-3 top-2  cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
              onClick={handleSearchClick}
            >
              <path
                fillRule="evenodd"
                d="M9 2a7 7 0 015.633 11.232l4.253 4.252a1 1 0 11-1.414 1.414l-4.252-4.253A7 7 0 119 2zm0 2a5 5 0 100 10A5 5 0 009 4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </div>
      </Form.Group>
    );
  };

  const [selectedYearsOfExperience, setSelectedYearsOfExperience] = useState(0);
  const [selectedDegree, setSelectedDegree] = useState("");
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);

  const [filteredData, setFilteredData] = useState([]);

  //   useEffect(() => {
  //     const applyFilters = () => {
  //       // Apply the filters to the currentData
  //       const filteredData = currentData.filter((item) => {
  //         // Apply specialization filter
  //         const hasSelectedSpecialization =
  //           selectedSpecializations.length === 0 ||
  //           selectedSpecializations.some((specialization) =>
  //             item.specialization.includes(specialization)
  //           );

  //         // Apply yearsOfExperience filter
  //         const hasSelectedYearsOfExperience =
  //           selectedYearsOfExperience === 0 ||
  //           item.yearsOfExperience >= selectedYearsOfExperience;

  //         // Apply degree filter
  //         const hasSelectedDegree =
  //           selectedDegree === '' || item.degree.includes(selectedDegree);

  //         // Return true if the item passes all filters
  //         return (
  //           hasSelectedSpecialization &&
  //           hasSelectedYearsOfExperience &&
  //           hasSelectedDegree
  //         );
  //       });

  //       setFilteredData(filteredData);
  //     };

  //     applyFilters();
  //   }, [currentData, selectedSpecializations, selectedYearsOfExperience, selectedDegree]);

  return (
    <div className="">
      <PhysioModal />
      {alertModal.show && LoginAlert(alertModal)}
      <div className="pb-16">
        <div className="bg-white pt-16 px-4">
          <h2 className="text-4xl pb-2 text-center">
            Search Doctor, Make an Appointment
          </h2>
          <p className="text-lg pt-2 text-center opacity-70">
            Discover the best doctors, clinic & hospital in the city nearest to
            you
          </p>

          <form onSubmit={handleSubmit}>
            <div className="flex xxs:flex-col md:flex-row xxs:items-center  justify-center md:items-end xxs:gap-y-8 md:gap-x-8 pt-8">
              <Form.Group className="flex flex-col">
                <Form.Label className="block uppercase tracking-wide text-gray-700 text-sm font-bold ">
                  Name
                  {nameError && (
                    <p className="text-red-500 text-xs pt-2 italic">
                      This field is required
                    </p>
                  )}
                </Form.Label>
                <Form.Control
                  className={`w-full appearance-none  rounded-sm shadow-md border  py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
                    nameError ? "border-red-500" : ""
                  }`}
                  type="text"
                  id="patientName"
                  name="patientName"
                  value={patientName}
                  placeholder="Patient Name"
                  onChange={handleNameChange}
                />
              </Form.Group>
              <Form.Group className="">
                <Form.Label className="block uppercase tracking-wide text-gray-700 text-sm font-bold">
                  Phone
                  {phoneNumberError && (
                    <p className="text-red-500 text-xs pt-2 italic">
                      This field is required / incorrect
                    </p>
                  )}
                </Form.Label>
                <Form.Control
                  className={`w-full appearance-none rounded-sm shadow-md border  py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
                    phoneNumberError ? "border-red-500" : ""
                  }`}
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  placeholder="Phone Number"
                  onChange={handlePhoneNumberChange}
                />
              </Form.Group>
              <div className="flex items-start">
                <Button
                  type="submit"
                  className="px-5 py-[8px] items-center capitalize active:-translate-y-1 cursor-pointer font-poppins hover:-translate-y-2 duration-300 text-white font-[600] bg-[#ff4f00] rounded-[4px] opacity-90"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>

          {/* <div
            className="mx-0 flex flex-row-reverse justify-between items-start relative md:h-[350px] xl:h-[450px]"
            >
            
        
            <div  
              className="partnerbanner"
            >
            <img
                src={view === "physio" ? PhysioBanner : DoctorBanner}
                className="absolute right-0 top-0 h-[100%] "
                alt="Banner"
              />
            </div>
            <div>
              <div className="md:w-[70%] xl:w-full md:pl-[6%] xl:pl-[6%] pt-[5%]">
              <motion.div
              variants={imageVariant}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}>
            
              <div className=" flex flex-col">
                <h1 className="xxs:text-2xl xxs:px-2 md:text-5xl pb-6 font-semibold text-whyus">
                  {view === "doctor"
                    ? "India’s Best Doctors Are Just A Click Away"
                    : "Get Instant Relief From Pain with a Physio Session"}
                </h1>
                <p className="text-whyus xxs:text-lg xxs:px-2  ">
                  {view === "doctor"
                    ? "Book a Session now for one on one doctor consultation and get answers to all your queries."
                    : "Book a session now and choose to have a virtual physio session or a home visit with one of top rated physios."}
                </p>
                <br />
              </div>
              </motion.div>
            </div>
              </div>
          </div> */}
        </div>
      </div>

      <Divider />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        className="py-4 gap-2 bg-white"
      >
        <Buttonmui
          className="mx-5"
          name="doctor"
          id="doctorBtn"
          variant={view === "doctor" ? "contained" : "outlined"}
          onClick={handleBtn}
        >
          Doctors
        </Buttonmui>
        <Buttonmui
          className="mx-5"
          name="physio"
          id="physioBtn"
          variant={view === "physio" ? "contained" : "outlined"}
          onClick={handleBtn}
        >
          Physios
        </Buttonmui>
      </div>

      {/* <div className="bg-white">
        <div className="mr-3 py-3 text-center  w-100">
          <Buttonmui color="success" variant="outlined" size="lg" onClick={handleBookNow}>
            Book Now
          </Buttonmui>
        </div>
      </div> */}
      <div className="flex bg-white flex-row justify-center pt-5 pb-8">
        <Pagination
          dataPerPage={dataPerPage}
          totalData={
            view === "doctor"
              ? pageData.length
              : pageData.length - 6 * dataPerPage
          }
          paginate={paginate}
        />
      </div>
      <div className="bg-white">
        <div className="px-[5%]">
          <div className="flex xxs:flex-col md:flex-row justify-center gap-x-[8%] xxs:items-center md:items-start bg-white rounded-lg">
            <div className="flex flex-row xxs:w-full md:w-1/4">
              <div className="flex flex-col justify-start items-start w-full">
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-col justify-center items-start gap-y-4 px-4">
                    <div className="w-full">
                      <SearchBar
                        search={search}
                        handleNameChange={handleNameChange}
                      />
                    </div>
                    <Form.Group className="pl-2">
                      <Form.Label className="block uppercase tracking-wide text-gray-700 text-sm font-bold">
                        Phone
                        {phoneNumberError && (
                          <p className="text-red-500 text-xs pt-2 italic">
                            This field is required / incorrect
                          </p>
                        )}
                      </Form.Label>
                      <Form.Control
                        className={`w-full appearance-none rounded-sm shadow-md border  py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
                          phoneNumberError ? "border-red-500" : ""
                        }`}
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        value={phoneNumber}
                        placeholder="Phone Number"
                        onChange={handlePhoneNumberChange}
                      />
                    </Form.Group>
                    <div className="flex pl-2  flex-col ">
                      <Form.Group className="flex flex-col w-full">
                        <Form.Label
                          htmlFor="country-select"
                          className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                        >
                          Country:
                        </Form.Label>
                        <Form.Select
                          id="country-select"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          required
                          className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Select a Country</option>
                          {countries.map((country) => (
                            <option key={country.iso2} value={country.iso2}>
                              {country.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="flex flex-col w-full">
                        <Form.Label
                          htmlFor="state-select"
                          className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                        >
                          State:
                        </Form.Label>
                        <Form.Select
                          id="state-select"
                          value={selectedState}
                          onChange={handleStateChange}
                          required
                          className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Select a State</option>
                          {states.map((state) => (
                            <option key={state.iso2} value={state.iso2}>
                              {state.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>

                      <Form.Group className="flex flex-col w-full">
                        <Form.Label
                          htmlFor="city-select"
                          className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2"
                        >
                          City:
                        </Form.Label>
                        <Form.Select
                          id="city-select"
                          value={selectedCity}
                          onChange={handleCityChange}
                          required="required"
                          className="block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        >
                          <option value="">Select a City</option>
                          {cities.map((city) => (
                            <option key={city.name} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div>
                      <SpecializationContainer data={currentData} />
                    </div>
                    <div>
                      <YearsOfExperienceContainer data={currentData} />
                    </div>
                    <div>
                      <DegreeContainer data={currentData} />
                    </div>
                  </div>
                </form>
              </div>
              {/* <div className="w-[15%] flex justify-center items-center text-black">
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    <hr/>
                </div> */}
            </div>

            <div className="grid xxs:grid-cols-1 md:grid-cols-3 justify-center items-center xxs:w-full md:w-3/4">
              {loading ? (
                <div style={{ height: "25vh" }}>
                  <Loading />
                </div>
              ) : (
                filteredData.map((item, idx) => (
                  <div
                    key={idx}
                    className=" border-t-1 border-b-1 border-r-1 border border-slate-700 w-full h-full"
                  >
                    <Link
                      to={`/${view}/${item._id}`}
                      className="block rounded-lg p-4 shadow-sm shadow-indigo-100"
                    >
                      <div className="flex justify-center relative items-center">
                        <div className="relative">
                          <img
                            alt={`${view === "physio" ? "Physio" : "Doctor"}`}
                            src={item.photoUrl || DocIcon}
                            className="h-40 w-40  p-[6px] border-[1.6px] border-casablue rounded-full object-cover"
                          />
                          <span
                            className="absolute top-[20%] right-[10%] transform translate-x-[50%] -translate-y-[50%] bg-casablue rounded-full w-2 h-2"
                            style={{ boxShadow: "0 0 0 3px white" }}
                          ></span>
                        </div>
                      </div>

                      <div className="mt-2">
                        <dl>
                          <div>
                            <dt className="sr-only">Degrees</dt>

                            <dd className="text-sm text-gray-500">
                              {item.degree && item.degree.length > 0 && (
                                <span>
                                  {" "}
                                  {item.degree.join(", ").length > 50
                                    ? item.degree.join(", ").substring(0, 50) +
                                      "..."
                                    : item.degree.join(", ")}
                                </span>
                              )}
                            </dd>
                          </div>

                          <div>
                            <dt className="sr-only">Name</dt>

                            <dd className="font-medium">
                              {item.name
                                .replace(
                                  /^(Dr\.?|Pt\.?|DR\.?|(Pt)\.?)\s*\.?\s*/,
                                  ""
                                )
                                .replace(/\s*\((pt|Pt|PT)\)$/i, "")
                                .trim()}
                            </dd>
                          </div>
                        </dl>

                        <div className="mt-6 flex  items-center gap-8 text-xs">
                          <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                            <svg
                              className="h-4 w-4 text-indigo-700"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M8 14v3m4-3v3m4-3v3M3 21h18M3 10h18M3 7l9-4 9 4M4 10h16v11H4V10z"
                              />
                            </svg>

                            <div className="mt-1.5 sm:mt-0">
                              <p className="text-gray-500">Experience</p>

                              <p className="font-medium">
                                {item.yearsOfExperience && (
                                  <span>{item.yearsOfExperience}+ years</span>
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2 flex-grow-2">
                            <svg
                              className="h-4 w-4 text-indigo-700"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"
                              />
                            </svg>

                            <div className="mt-1.5 sm:mt-0">
                              <p className="text-gray-500">Specialization</p>

                              <p className="font-medium">
                                {item.specialization &&
                                  item.specialization.length > 0 && (
                                    <span>
                                      {" "}
                                      {item.specialization.join(", ").length >
                                      20
                                        ? item.specialization
                                            .join(", ")
                                            .substring(0, 20) + "..."
                                        : item.specialization.join(", ")}
                                    </span>
                                  )}
                              </p>
                            </div>
                          </div>

                          {/* <div className="sm:inline-flex sm:shrink-0 sm:items-center sm:gap-2">
                            <svg
                            className="h-4 w-4 text-indigo-700"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                            />
                            </svg>

                            <div className="mt-1.5 sm:mt-0">
                            <p className="text-gray-500">Bedroom</p>

                            <p className="font-medium">4 rooms</p>
                            </div>
                        </div> */}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))
              )}
            </div>

            {/* <Link to={`/${view}/${item._id}`}>
                        <Button variant="outline-primary">View</Button>
                      </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

function Consult(props) {
  return (
    <div className="bg-white">
      <div className="pb-16 ">
        <NavWhite />
      </div>
      <Helmet>
        <title>Partners | CasaMed</title>
        <meta
          name="description"
          content="At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="physiotherapy treatment for back pain, physiotherapy for back pain, physiotherapy treatment for low back pain, therapy for lower back pain, back pain therapy, physiotherapy treatment"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/partners" />
        {/* Open Graph */}
        <meta property="og:title" content="Partners | CasaMed" />
        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content="https://casamed.in/partners" />
        <meta
          property="og:description"
          content="At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Partners | CasaMed",
            url: "https://casamed.in/partners",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail.",
          })}
        </script>
      </Helmet>
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      <ConsultComponent props={props} />
      <Footer />
    </div>
  );
}

export default Consult;
