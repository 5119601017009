import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const PRTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Pain Relief Treatment
      </h1>
      <div className="mx-5">
        <p>
          Are you suffering from joint pain and are unable to do your daily
          activities? Then from now onwards you don&#39;t have to worry about it
          because we have come with a comprehensive physical therapy treatment
          which will help you relieve your joint pain perpetually. We all know
          that joint is the most important part of our body without it we
          can&#39;t execute our everyday tasks.
        </p>
        <p>
          You may be wondering why joints ache or pain. The causes of joint pain
          can be different for each individual but generally, it is because of
          inflammation, illness, infection, or injury.{" "}
          <strong>
            Joint Pain relief treatment can be easily done with the help of{" "}
            <Link to="/physical-therapy-treatment">physical therapy</Link> . It
            will help you to reduce your pain and will make you more active than
            ever
          </strong>
          . We have several well-known physical therapists here at Casamed with
          numerous years of experience in treating the problem of joints. They
          have gained enough knowledge in this field and have successfully
          healed many people with their skills and abilities.
        </p>
        <p>
          At soon as you connect with us, the very first thing our medical
          experts do is to examine all your joint related problems, study your
          medical history, ask you about past medical treatments and any other
          ailment which you are suffering from. It will help us to treat you
          more effectively and efficiently. If needed, we conduct further
          radiological or diagnostic investigation to gain further insights
          about your particular joint area pain. After that, we will develop
          your comprehensive joint pain relief treatment plan.
        </p>
        <p>
          You will be recommended some physical movement posture by which you
          can easily get joint relief. Our experts will also provide you with a
          detailed plan curated for your specific needs which you may access
          through your mobile app. With this, you can practice those exercises
          daily for the faster recovery.
        </p>
        <p>
          For <strong>Joint relief treatment</strong>, our therapist also
          assists with their special therapeutics so that you can comfortably
          get rid off of your pain. Casamed offers range of treatments as per
          your choice.
        </p>
        <p>
          You can even book your appointment online on our website. For
          effective and fast recovery contact us today at{" "}
          <a href="tel:8235858626">8235 858 626</a>.
        </p>
        <h2>Why Choose Casamed for Pain Relief Treatment</h2>
        <ul>
          <li>
            <strong>Aid at the time of Emergency -</strong> We are there to help
            you 24/7 in an urgent situation. Book online appointment or call at{" "}
            <a href="tel:8235858626">8235 858 626</a>.
          </li>
          <li>
            <strong>Latest technology and equipment -</strong> We employ
            innovative, cutting-edge technology for your treatment. We always
            carry our latest tools used for recognizing the problem which you
            are facing and at the same time, we also help you heal with
            available resources.
          </li>
          <li>
            <strong>Knowledgeable and skillful therapists -</strong> Our medical
            experts are trained for years by which they have received their
            degrees in this field. They have also successfully healed many
            people who suffered from joint pain. Day-by-day, their only focus is
            on how we can easily get rid off of our pain.
          </li>
        </ul>
        <p>
          We can assure you that after visiting us you will definitely find
          relief from your joint pain. So, contact us for a pain-free and
          comfortable life.
        </p>
      </div>
    </div>
  );
};

const PainReliefTreatment = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>
          Pain Relief Treatment | Talk to Our Expert Doctors | CASAMED
        </title>
        <meta
          name="description"
          content="Get fast pain relief! Consult our expert doctors for effective treatment options. Regain your comfort & mobility today."
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="pain relief treatment" />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/pain-relief-treatment" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Pain Relief Treatment | Talk to Our Expert Doctors | CASAMED"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/pain-relief-treatment"
        />
        <meta
          property="og:description"
          content="Get fast pain relief! Consult our expert doctors for effective treatment options. Regain your comfort & mobility today."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
  logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Pain Relief Treatment | Talk to Our Expert Doctors | CASAMED",
            url: " https://casamed.in/pain-relief-treatment",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Get fast pain relief! Consult our expert doctors for effective treatment options. Regain your comfort & mobility today.",
          })}
        </script>
      </Helmet>
      <PRTComponent />
      <Footer />
    </div>
  );
};

export default PainReliefTreatment;
