import React from 'react'

function WhyUs(){
  return (
    <div className='xxs:pt-28 md:pt-32 md:px-20'>
        <p className='font-Cutive italic xxs:text-center text-whyus wraptext2 xxs:text-[24px] sm:text-[24px] xxs:px-8 md:px-16'>
        "With deep technical, clinical and business skillsets and under the guidance of industry stalwarts, we're rightly positioned to understand the shortcomings of traditional care system and design affordable and scalable technological solutions to address the same."
        </p>
    </div>
  )
}

export default WhyUs