import React from "react";
import live from "../../Assets/ProductViews/Live/liveback.png";
import Button from "../../Components/Button";

function Live() {
	return (
		<section id="livesession">
			<h2 className="z-10 text-casashade2 xxs:pb-5 md:pb-10 xxs:text-4xl md:text-6xl font-bold drop-shadow-md text-center">
				<span className="decoration-[#02B9ED] underline underline-offset-8">
					C
				</span>
				asa<span className="text-casablue">Med</span>{" "}
				<span className="text-red-500 animate-pulse">Live</span>
			</h2>
			<p className="xxs:px-[10%] md:px-[29%]  text-center text-casashade2 xxs:text-lg md:text-xl pb-4">
				We Hear You. We know that you are busy and we understand the
				need to hear from your health care professionals, who can give
				you the answers you need today. <br />
				<br />
				<span className="font-semibold">CasaMed Live</span> is a 24/7
				virtual clinic providing live chat with physicians,
				physiotherapists, nutritionists and gynecologists who can help
				when an answer just isn’t available offline.
			</p>

			<div className="bg--backlive xxs:py-4 md:py-36 xxs:m-2 md:m-8 xl:m-16">
				<div className="flex justify-center items-start flex-col xxs:py-0 md:py-36 pl-[15%]">
					<h2 className="xxs:text-3xl md:text-7xl xxs:pb-2 md:pb-4 font-semibold text-light">
						Live Sessions
					</h2>
					<p className="xxs:text-md md:text-2xl text-light xxs:pb-4 md:pb-8 font-regular">
						Live Interactive Sessions <br /> with our Experts
					</p>

					<button className="flex gap-3 xxs:px-[14px] md:px-[45px] xxs:py-[8px] md:py-[25px] items-center capitalize active:-translate-y-1 cursor-pointer font-poppins hover:-translate-y-2 duration-300 text-white font-semibold md:text-[24px] xxs:text-[12px] bg-casablue rounded-[42px]">
						Book Appointment
					</button>
				</div>
			</div>
		</section>
	);
}

export default Live;
