import React from "react";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import Loading from "../../Components/Loading/loading";
import susman2 from "../../Assets/AboutViews/Team/susman2.png";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import casasvg from "../../Assets/casamed.svg";
import { Helmet } from "react-helmet";

function TeamSingle() {
  const { slug } = useParams();
  const [team, setTeam] = useState({});
  // const [blogs, setBlogs] = useState({});
  const [loading, setLoading] = useState(true);
  const shareUrl = window.location.href;

  // const title = 'Speed Dial'

  useEffect(() => {
    axios
      .get(`/api/teams`, {
        headers: {
          Authorization: localStorage.getItem("token"),
          auth: {
            user: JSON.stringify(localStorage.getItem("user")),
          },
        },
      })
      .then((res) => {
        const team = res.data.data.find((team) => team.slug === slug);
        setTeam(team);
        console.log(team);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [slug]);

  return (
    <div className="">
      <div className="xxs:pb-[20%] md:pb-[10%] ">
        <Nav />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            {/* Meta title and Description */}
            <title>{team.metaName}</title>
            <meta name="description" content={team.metaDesc} />
            {/* Meta Keywords */}
            <meta name="keywords" content={team.metaKeywords} />
            {/* Canonical Tag */}
            <link
              rel="canonical"
              href={`https://casamed.in/about/team/${team.slug}`}
            />
            {/* Open Graph */}
            <meta property="og:title" content={team.metaName} />

            <meta property="og:site_name" content="Casamed" />
            <meta
              property="og:url"
              content={`https://casamed.in/about/team/${team.slug}`}
            />
            <meta property="og:description" content={team.metaDesc} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={casasvg} />
            {/* Schema MarkUp */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org/",
                "@type": "Organization",
                name: team.metaName,
                url: `https://casamed.in/about/team/${team.slug}`,
                logo: {
                  "@type": "ImageObject",
                  url: casasvg,
                },
                description: team.metaDesc,
              })}
            </script>
          </Helmet>

          <div className="flex xxs:flex-col lg:flex-row xxs:px-[10%] md:px-[5%] xl:px-[15%] md:justify-between">
            <div className="flex justify-center items-center">
              <img src={team.photoUrl} alt="" className="rounded-t-md" />
            </div>
            <div
              className={
                team.slug == "Sashmit"
                  ? "xxs:w-full md:w-[70%] xxs:px-0 lg:px-10 flex flex-col py-10"
                  : "xxs:w-full md:w-[100%] xxs:px-0 lg:px-10 flex flex-col py-10"
              }
            >
              <h1 className="text-xl font-semibold">{team.name}</h1>
              <h1 className="pb-10 text-md italic">{team.designation}</h1>
              <h1 className="text-lg whitespace-pre-wrap">
                {team.bio.replace(/<br\/>/g, "\n")}
              </h1>
            </div>
          </div>
        </>
      )}

      <div className="pt-12">
        <Footer />
      </div>
    </div>
  );
}

export default TeamSingle;
