import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const BTPComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Physiotherapy Treatment
      </h1>
      <div className="mx-5">
        <p>
          Over time, <strong>physiotherapy treatment</strong> has emerged as a
          promising method of{" "}
          <Link to="/pain-management-treatment">pain management</Link> . It is
          proven beneficial in assisting patients in regaining their health and
          improving their physical strength, function, and mobility.
        </p>
        <p>
          At Casamed, our physiotherapists personally visit you and do
          physiotherapy at your preferred location to maximise the benefits of
          your physiotherapy experience. Whether you have chronic back pain, a
          frozen shoulder, or a sports injury that needs to be addressed, our
          qualified, skilled, and experienced physiotherapists can help relieve
          pain from the comfort of your own home.{" "}
          <Link to="/physiotherapy-for-sciatica">
            Physiotherapy for Sciatica
          </Link>{" "}
          is one of our most availed services.
        </p>
        <p>Getting physiotherapy at home has several advantages.</p>
        <ol>
          <li>It saves your valuable time by avoiding unnecessary travel</li>
          <li>
            It helps avoid any pain aggravation that might be incurred during
            commute
          </li>
          <li>
            It helps you undergo{" "}
            <Link to="/rehabilitation-therapy">rehabilitation care</Link> in
            your safe space
          </li>
          <li>
            With time and comfort, your complete focus can be on{" "}
            <Link to="/physical-therapy-treatment">Physical therapy</Link> for
            best results
          </li>
        </ol>
        <h2>Why Choose Online Physiotherapy Treatment</h2>
        <p>
          Online <strong>physiotherapy treatment</strong> is another time-saving
          and efficient technique to treat ailments and speed up recovery. An
          online <strong>physiotherapy</strong> consultation may be beneficial
          if you are in a remote location or have time constraints. We have
          skilled physiotherapists who specialize in offering online
          physiotherapy consultations to people with musculoskeletal ailments
          who cannot receive traditional &quot;in-person&quot; physiotherapy
          care. These well experienced and skilled physiotherapists will go
          through your medical history, evaluate your case and determine the
          source of your discomfort. Their experience gives them valuable
          insights regarding the ailments remotely.
        </p>
        <p>
          Through our unique AI technology, we measure the range of motion about
          your joint and curate a custom{" "}
          <Link to="/joint-pain-therapy">joint pain therapy</Link> or{" "}
          <Link to="/muscle-pain-therapy">muscle pain therapy</Link> for your
          recovery. Our experts and physical therapists teach you about your
          ailments using 3D models. Following the consultation, you are provided
          a free evaluation report.
        </p>
        <p>
          Our core offering is Comprehensive Pain Management{" "}
          <Link to="/pain-management-treatment">Treatment</Link> under which our
          medical panel of super specialists, doctors and physiotherapists
          examine your condition through initial video consultation, diagnostics
          and pathological investigations. They provide professional advice, and
          propose future course of action. Based on the findings, they design a
          unique treatment suited for your specific individual needs.
        </p>
        <p>
          The core team at Casamed is a group of leading medical experts and
          hand-picked professionals to offer advice and support to our
          physiotherapists. Casamed&#39;s skilled experts keep an eye on you or
          someone you care about. Check-ins are done over the phone or through
          video calls if needed. They may be set up as a one-time appointment or
          a check-in every 15 days, allowing the patient to update the
          physiotherapist on their progress. The purpose is to check in
          regularly to see how far you&#39;ve come.
        </p>
        <div>
          <strong>
            <h3>Why Choose Casamed for Physiotherapy Treatment</h3>
            <ul>
              <li>Medical Costs are Reduced</li>
            </ul>
          </strong>
          <p>
            You save time and money by not having to go to a clinic. Our
            low-cost digital+home plans are designed to reduce your medical
            costs significantly.
          </p>
          <ul>
            <li>
              <strong>Comfort &amp; Convenience</strong>
            </li>
          </ul>
          <p>
            Recover in the privacy of your own home with the help of
            physiotherapists who provide individualized treatment and care
            plans.
          </p>
          <ul>
            <li>
              <strong>AI-Assistance in the Digital Age</strong>
            </li>
          </ul>
          <p>
            With innovative cutting-edge technology, we are able to accurately
            measure the effectiveness of care plan and recommend custom
            solutions for each case.
          </p>
          <ul>
            <li>
              <strong>Better Results and a Quicker Recovery</strong>
            </li>
          </ul>
          <p>
            We are quick to act. Casamed&#39;s guided physiotherapy acts faster
            than the traditional methods and has given better results in pain
            management.
          </p>
          <ul>
            <li>
              <strong>A Step-by-Step Approach to Pain Relief</strong>
            </li>
          </ul>
          <p>
            Divide and Conquer. Casamed&#39;s step by step scientific approach
            to pain management helps the patient to adapt and pace, preventing
            drop offs.
          </p>
          <ul>
            <li>
              <strong>
                Cutting-edge technology is used to curate a pain management
                solution
              </strong>
            </li>
          </ul>
          <p>
            Keep building better. Casamed&#39;s tech lives by this philosophy
            and that is why we are constantly working on R&amp;D to deliver the
            best in class tech solutions to pain management.
          </p>
          <ul>
            <li>
              <strong>
                UI that is simple and intuitive for people of all ages
              </strong>
            </li>
          </ul>
          <p>
            All great things are simple. And so is the Casamed UI. Seamless
            product experience forms the core of our customer experience.
          </p>
          <ul>
            <li>
              <strong>
                Encourage and help you in managing your pain on your own
              </strong>
            </li>
          </ul>
          <p>
            It&#39;s all you, after all. Casamed believes that you are your best
            mentor in this journey to recovery and hence we put you in charge to
            steer this ship. All our offerings are custom tailored to this end.
          </p>
          <ul>
            <li>
              <strong>
                Making pain relief a healthy and enjoyable experience
              </strong>
            </li>
          </ul>
        </div>
        <p>
          In the end, it should be fun. By eliminating the need for medicines
          and surgery and introducing a step by step recovery plan, Casamed
          shields the users from the unnecessary suffering involved in more
          traditional methods while simultaneously making recovery fun with
          guided plans and proven results.
        </p>
        <p>
          To get better outcomes, use our app to track your recovery and all the
          parameters that matter.
        </p>
        <p>
          Say goodbye to all your <strong>physiotherapy treatment</strong> and
          pain <Link to="/pain-management-treatment">management</Link> problems
          with our economic and dependable home services.
        </p>
      </div>
    </div>
  );
};

const BeatThePain = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title And Description */}
        <title>
          Physiotherapy treatment @ CASAMED | Consult expert Doctors
        </title>
        <meta
          name="description"
          content="Physiotherapy treatment in the comfort of your home. Physiotherapists from CASAMED provide therapy online with the help of AI technology."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Beat the Pain, Physiotherapy Treatment"
        />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/physiotherapy-treatment"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Physiotherapy treatment @ CASAMED | Consult expert Doctors "
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content="https://casamed.in/physiotherapy-treatment"
        />
        <meta
          property="og:description"
          content="Physiotherapy treatment in the comfort of your home. Physiotherapists from CASAMED provide therapy online with the help of AI technology."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: " Physiotherapy treatment @ CASAMED | Consult expert Doctors",
            url: "https://casamed.in/physiotherapy-treatment",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Physiotherapy treatment in the comfort of your home. Physiotherapists from CASAMED provide therapy online with the help of AI technology.",
          })}
        </script>
      </Helmet>
      <BTPComponent />
      <Footer />
    </div>
  );
};

export default BeatThePain;
