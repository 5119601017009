import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";

function TermsCond() {
  return (
    <section>
      <Helmet>
        {/* Meta title and Description */}
        <title>Terms & Conditions | CASAMED</title>
        <meta
          name="description"
          content="CasaMed Terms & Conditions: Your guide to understanding our policies. Clear, concise, and customer-friendly. Explore now!"
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Terms & Conditions, user agreement, terms of service, legal agreement, terms of use, service terms, Casamed terms, user rights, user responsibilities, terms agreement"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/termsconditions" />
        {/* Open Graph */}
        <meta property="og:title" content="Terms & Conditions | CASAMED" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content=" https://casamed.in/termsconditions" />
        <meta
          property="og:description"
          content="CasaMed Terms & Conditions: Your guide to understanding our policies. Clear, concise, and customer-friendly. Explore now!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Terms & Conditions | CASAMED",
            url: " https://casamed.in/termsconditions",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "CasaMed Terms & Conditions: Your guide to understanding our policies. Clear, concise, and customer-friendly. Explore now!",
          })}
        </script>
      </Helmet>
      <Nav />
      <div className="xxs:px-12 md:px-48 og--bg--grad md:pt-32 xxs:pt-24 md:pb-16 xxs:pb-0 text-left">
        <div className="flex justify-center">
          <h1 className="tracking-wide xxs:text-[32px] ss:text-[52px] font-semibold text-casashade2 text-center md:pb-12 xxs:pb-6">
            <strong className="decoration-[#02B9ED] underline underline-offset-8">
              T
            </strong>
            erms & Conditions
          </h1>
        </div>
        <h2 className="font-semibold text-lg">Terms of Use</h2>
        <p>
          Welcome to the Casamed digital care platform for musculoskeletal
          disorders (“Service”). Please read these terms and conditions
          carefully before using the Service. By accessing or using the Service
          on any computer, mobile phone, tablet or another device (collectively
          the “Device”), you, the User and/or the Partner agree to be bound by
          these Terms of Use (“Agreement”) and any other applicable law.
          <br />
          The Service and the content available therein are developed, operated
          and distributed by Sahika Healthcare Private Limited, a company having
          its registered address at Ground Floor, Block 1, New Road Saristabad
          (West), PO – Anishabad, Patna. India. (collectively “we”, “us”, “our”,
          “Casamed”, “Aggregator”).
          <br />
          We may change these Terms of Use at any time without notice, effective
          upon its posting on the Website and/or in the App (as applicable).
          Your continued use of the Service shall be considered your acceptance
          of the revised Terms of Use. If you do not agree to these Terms of
          Use, please do not use this Service.
        </p>
        <h2 className="font-semibold pt-2 text-lg">Scope of License</h2>
        <p>
          Subject to these Terms of Use, we grant you non-exclusive,
          non-transferable, non-sublicensable, revocable limited license to use
          the Service solely for your personal, non-commercial purposes. This
          license does not allow you to use the Service on any Device that you
          do not own or rightfully control, and you may not distribute or make
          the Service available over a network where it could be used by
          multiple devices at the same time. You agree not to rent, lease, lend,
          sell, redistribute or sublicense the Service. You may not copy (except
          as expressly permitted by this license or terms of the relevant
          certified application store, from which you have downloaded the App),
          decompile, reverse engineer, disassemble, attempt to derive the source
          code of, modify or create derivative works of the Website or the App,
          any updates, or any part thereof (except as and only to the extent any
          foregoing restriction is prohibited by applicable law). Any attempt to
          do so is a violation of our rights as a licensor. If you breach this
          restriction, you may be subject to prosecution and damages.
        </p>
        <h2 className="font-semibold pt-2 text-lg">Intellectual Property</h2>
        <p>
          All intellectual property on the Service, which includes materials
          protected by copyright, trademark, or patent laws, is proprietary
          either to us or to third parties. All trademarks, service marks and
          trade names are owned, registered and/or licensed by us. All content
          offered by the Service (except for personal information), including
          but not limited to text, software, scripts, code, designs, graphics,
          photos, sounds, music, videos, applications, interactive features and
          all other content (“Content”) is our intellectual property; all rights
          reserved.
        </p>
        <h2 className="font-semibold pt-2 text-lg">Your User Account</h2>
        <p>
          Access to certain features of the Service may be available to the
          registered users only. To create a user account, you must be at least
          18 years of age and are required to complete a registration form
          offered via the Service. User accounts for persons under the indicated
          age can be created by a parent or legal guardian. By creating an
          account for use of the Service, you warrant that all the information
          provided by you for registration is true and accurate. You also agree
          not to misrepresent your identity by registering an account in the
          name of another person.
          <br />
          As a registered user of the Service, you must not sell or otherwise
          transfer your account credentials (i.e. your username and password) to
          any third party. You acknowledge that you are solely responsible for
          maintaining confidentiality and security of your user account
          credentials. We are not responsible for any losses arising from the
          unauthorized use of your account. If you suspect that your account has
          been compromised, please contact us at the applicable email address
          specified in section “Contact Us” below.
          <br />
          Your user account may be accessed by entering your credentials on any
          platform, across which the Service operates. You may terminate your
          user account at any time as described in the Termination clause below.
          <br />
          We reserve the right to suspend or terminate your user account at any
          time without notice as provided by these Terms of Use.
        </p>
        <h2 className="font-semibold pt-2 text-lg">For Partners</h2>
        <p>
          The term “Partner” shall include “Doctors” providing medical care to
          Users on the Casamed digital platform as well as trained and qualified
          “Physiotherapists” providing their services on Casamed digital
          platform.
          <br />
          To begin the enrolment process, the Partner must complete the
          registration process for one or more of the Services on
          https://casamed.in/partners-onboarding-form/. As part of the
          application, the Partner must provide us with his (or his business’)
          legal name, address, phone number, e-mail address, applicable tax
          registration details (if any) as well as any other information that we
          may request from time to time.
          <br />
          Unless otherwise agreed, it shall be the duties and obligations of the
          Partner to:
          <ol className="pl-[50px] pb-[10px] ">
            <li className="list-decimal">
              provide quality services usually and/or inherently rendered by or
              expected from the Partner in general to Users of the APP and/or
              portal on charges and other terms and conditions mentioned
              hereinafter;
            </li>
            <li className="list-decimal">
              perform and/or discharge such other duties and responsibilities as
              may be requested by us from time to time;
            </li>
            <li className="list-decimal">
              not engage either directly or indirectly in any activity competing
              with that of Casamed including that the Partner shall cease,
              desist and refrain from acting on behalf of or as an officer,
              member, director, employee, shareholder, partner or hold any other
              office, of a person including corporate body involved in
              activities similar with that of Casamed;
            </li>
            <li className="list-decimal">
              keep full details of Services provided hereunder to Users and
              ensure that all persons engaged by the Partner for providing
              Services shall always act in good faith and in manner befitting of
              a professional of highest ethical integrity towards the Users;
            </li>
            <li className="list-decimal">
              in the event of any User complaint, allow Casamed to inspect,
              audit and copy all records relating to Services provided to User
              concerned;
            </li>
            <li className="list-decimal">
              provide the service of teleconsultation and video consultation
              wherein the User will book a teleconsultation/video consultation
              on the Casamed digital platform and the same shall be communicated
              to the Partner within 3 hours of such booking on digital portal.
              The Partner shall carry out the teleconsultation/video
              consultation within 48 hours of the booking made by the patient
              and report the same to the Aggregator along with all supporting
              documents. In case of any rescheduling requirement, the Partner
              will inform the Aggregator of the same, and provide an alternative
              date and time for the teleconsultation/video consultation. For
              video consultations and teleconsultations, the Partner is expected
              to follow all the guidelines laid down under the Telemedicine
              Practice Guidelines, 2020 along with all relevant laws, rules and
              regulations made for medical professionals and physiotherapists;
            </li>
            <li className="list-decimal">
              in addition to the above, the Partner shall also provide a free
              follow up session to the User within 30 days of the first session
              or consultation, wherein the Partner will check-up on the User’s
              condition and evaluate the effects of the treatment. If the
              Partner feels that the User requires further sessions or
              consultations as the case may be, the Partner will inform the User
              of the same and also inform the Aggregator that further sessions
              may be required to effectively treat the User.
            </li>
            <li className="list-decimal">
              the Partner will also offer home visits to the Users wherein the
              User books an appointment on the Aggregator’s digital platform,
              the same shall be communicated to the Partner within 3 hours of
              the such booking on the Aggregator’s portal. The Partner
              thereafter shall endeavour carry out the home visit within 48
              hours of the booking made by the User and report the same to the
              Aggregator along with all supporting documents. The consultation
              fees shall be as per the agreed terms.
            </li>
            <li className="list-decimal">
              the Partner will also offer home visits to the Users wherein the
              User books an appointment on the Aggregator’s digital platform,
              the same shall be communicated to the Partner within 3 hours of
              the such booking on the Aggregator’s portal. The Partner
              thereafter shall endeavour carry out the home visit within 48
              hours of the booking made by the User and report the same to the
              Aggregator along with all supporting documents. The consultation
              fees shall be as per the agreed terms.
            </li>
          </ol>
        </p>
        <h2 className="font-semibold pt-2 text-lg">Fees and Payments</h2>
        <p>
          The Service offers subscription to the Content which may be purchased
          as an in-app purchase via the App (“In-App Purchase”).
          <br />
          Depending on the purchase, please refer to the appropriate section
          below to read the applicable purchase terms.
          <br />
          By making the In-App Purchase, you are granted the right to access the
          features of the Service (Content, subscriptions) you have acquired as
          a result of such purchase on any platform across which the Service
          operates. To access the purchase on the Website or in the App, please
          use your user account credentials. The Purchase within the App can be
          made by using any payment method accepted by the Website. <br />
          By making Purchase you are granted access to the musculoskeletal care
          platform. You agree that Purchase is final, that we will not refund
          any transaction once it has been made and that the Purchase cannot be
          cancelled.
          <br />
          By purchasing a subscription plan of your choice to the Content via
          the App, you will pay the app store from which you have downloaded the
          App (“App Store”) the applicable fees (and any related taxes)
          disclosed to you through the App as they become due. DELETING THE APP
          DOES NOT CANCEL YOUR SUBSCRIPTIONS AND FREE TRIALS.
          <br />
          To the maximum extent permitted by applicable laws, we may change
          subscription fees at any time. We will give you a reasonable notice of
          any such pricing changes by posting the new prices on or through the
          App and/or by sending you an email notification. Any such change will
          not affect the ongoing subscription plans and will only come into
          effect on purchase of a new or add on plan.
          <br />
          By signing up for certain subscriptions, you agree that your
          subscription may be automatically renewed. Unless you cancel your
          subscription, you authorize the App Store(s) to charge you for the
          renewal term. The period of auto-renewal will be the same as your
          initial subscription period unless otherwise disclosed to you through
          the App. The renewal rate will be no more than the rate for the
          immediately prior subscription period, excluding any promotional and
          discount pricing, unless we notify you of a rate change prior to your
          auto-renewal. You must cancel your subscription in accordance with the
          cancellation procedures disclosed to you for the particular
          subscription.
          <br />
          We will not refund fees that may have accrued to your account and will
          not prorate fees for a cancelled subscription. We may offer a free
          trial subscription for the Service. Free trial provides you with
          access to the Service for a period of time, with details specified
          when you sign up for the offer. Unless you cancel before the end of
          the free trial, or unless otherwise stated, your access to the Service
          will automatically continue and you will be billed the applicable fees
          for the Service. We may send you a reminder when your free trial is
          about to end, but we do not guarantee any such notifications. <br />
          It is ultimately your responsibility to know when the free trial will
          end. We reserve the right, in our absolute discretion, to modify or
          terminate any free trial offer, your access to the Service during the
          free trial, or any of these terms without notice and with no
          liability. We reserve the right to limit your ability to take
          advantage of multiple free trials.
          <br />
          The Service and your rights to use it expire at the end of the paid
          period of your subscription. If you do not pay the fees or charges
          due, we may make reasonable efforts to notify you and resolve the
          issue; however, we reserve the right to disable or terminate your
          access to the Service (and may do so without notice). IN ADDITION TO
          PURCHASING SUBSCRIPTIONS, YOU MAY BE OFFERED ADD-ON ITEMS FOR AN
          ADDITIONAL FEE. This purchase is optional: your subscription is not
          conditional on such purchase. If you choose to purchase any add-on
          items, you will be immediately charged the relevant fee indicated on
          the purchase screen. In contrast to subscriptions, add-on items are
          one-off lifetime purchase that is final and cannot be cancelled.
          Add-on items do not provide for a free trial. You authorize the App
          Store(s) to charge the applicable fees to the payment card that you
          submit. <br />
          Subscriptions as well as add-on items purchased via an App Store are
          subject to such App Store’s refund policies. This means we cannot
          grant refunds. You will have to contact an App Store support.
        </p>
        <h2 className="font-semibold pt-2 text-lg">Disclaimer: </h2>
        <p>
          While the service is developed based on inputs from expert medical
          professionals, we recommend you to consult your physician and
          therapist before using the service. Your use of any aspect of the
          Service is at your own risk. You must consult with certified
          healthcare practitioners, physiotherapists or physicians and make your
          medical decisions based on their advice. We cannot and do not accept
          any liability in respect of any activities that you may undertake
          through the Service use. You should understand that when participating
          in any exercise or exercise program, there is the possibility of
          physical injury. If you engage in this exercise or exercise program,
          you agree that you do so at your own risk, are voluntarily
          participating in these activities, assume all risk of injury
          (including but not limited to bruise, cuts, soreness, muscle and
          tendon injuries, ligament and skeletal injuries, fractured and broken
          bones, concussions, heart attacks) to yourself, and agree to release
          and discharge Sahika Healthcare Private Limited from any and all
          claims or causes of action, known or unknown, arising out of CASAMED.
          <br />
          We make no representations or warranties whatsoever in respect of the
          Service. Information regarding health, medical advice and otherwise
          may be provided by third parties, including other users of the
          Service. We cannot accept any liability whatsoever in respect of any
          content which is provided by third parties and/or any other users of
          the Service. Any actions you take based on content, notifications and
          otherwise provided by the Service are taken at your sole risk and we
          will not accept any liability in respect thereof. You should always
          check any information provided through the Service to ensure its
          accuracy. To the maximum extent permitted by applicable law, the
          Service is provided on an “as is” and “as available” basis. We make no
          representations or warranties of any kind, express or implied, as to
          the operation of the Service or any information, content, materials or
          products included or referenced therein. To the full extent
          permissible by applicable law, we disclaim all warranties, express or
          implied, including, but not limited to, implied warranties of
          merchantability, non-infringement of third parties’ rights and fitness
          for a particular purpose. You acknowledge that your use of the Service
          is at your sole risk.
          <br />
          We disclaim any implied or statutory warranties regarding:
          <ul className="pl-[50px] pb-[10px]">
            <li className="list-disc">
              the security, accuracy, reliability, timeliness and performance of
              the Service; or
            </li>
            <li className="list-disc">
              the performance of or accuracy, quality, currency, completeness or
              usefulness of any information provided by the Service; or
            </li>
            <li className="list-disc">
              that the Service will be error-free or that any errors will be
              corrected.
            </li>
          </ul>
          We do not warrant that any description provided through the Service
          regarding healthcare or otherwise is accurate, complete, reliable,
          current, safe or error-free. No communication, information or advice
          given by us or any representative of ours, whether written or oral,
          shall create any warranty. If you choose to rely on such information,
          you do so solely at your own risk. Some states or jurisdictions do not
          allow the exclusion of certain warranties. Accordingly, some of the
          above exclusions may not apply to you.
          <br />
          The Service may not be available in all languages or in all countries,
          and we make no representation that the functionality of the Service
          would be appropriate, accurate or available for use in any particular
          location. The Service availability and pricing are subject to change.
          This disclaimer constitutes an essential part of these Terms of Use.
        </p>
        <h2 className="font-semibold pt-2 text-lg">
          Availability, Appointment, Rescheduling, Free review and/or
          Cancellation :
        </h2>
        <p>
          Tele-consultations/Video Consultation shall be available for
          Physiotherapy and other related services offered by CASAMED.
          Tele-consultation/Video Consultation from a specific
          doctor/physiotherapist shall be available subject to his/her
          availability/willingness/discretion. Confirmation of your appointment
          with a specific doctor/physician, as per his/her availability, shall
          be sent to you via SMS and/or email. CASAMED reserves the right to
          reschedule or cancel an appointment without any prior notice.
          <br />
          The time provided for consultation to you is indicative and actual
          consultation time may change depending on the consulting
          Doctor’s/Physiotherapist’s discretion. Your consulting Physician
          reserves the right to provide Post consultation free review.
          <br />
          Consultations can be booked, rescheduled or cancelled only with prior
          intimation to CASAMED and the booking, rescheduling of the
          Tele-Consultation sessions shall be the sole discretion of the
          CASAMED.
          <br />
          <span className="font-semibold">
            Cancellation & Rescheduling Deadlines:
          </span>
          <br />
          All consultations shall be deemed confirmed only upon successful
          payment and submission of requisite documents; failure to either of
          which may result in auto cancellation of the booked appointment.
          <br />
          Patient may further choose to cancel the appointment any time, but
          would be eligible for full refund only for cancellations made 24 hours
          prior to the appointment.
        </p>
        <h2 className="font-semibold pt-2 text-lg">
          Information and Documents Required :
        </h2>
        <p>
          You are solely responsible for the medical, health and personal
          information you provide in CASAMED App. The advice of the
          doctor/physiotherapist will depend upon the information you provide in
          the CASAMED App.
          <br />
          User discretion is advised while submitting/uploading your required
          personal and clinical information on the specified place and selecting
          the Doctor/Physiotherapist for seeking appropriate advice related to
          condition of the patient. This information shall include patient’s
          details including contact details, medical history,
          testing/investigations results/reports, prescriptions, card holder
          name and other relevant details.
          <br />
          CASAMED services are available for use for children, but the
          Registered Member for all patients under the age of 18 must be the
          patient’s parent or legal guardian. If you register as the parent or
          legal guardian on behalf of a minor, you will be fully responsible for
          complying with these Terms of Use
          <br />
          If the information provided by you is false/deceptive/inaccurate or
          misleading, CASAMED reserves the right to cancel your appointment
          without assuming any liability whatsoever. Hence, it’s advised to
          submit/upload all relevant and accurate information on the portal. The
          information provided by you may be used by for the purpose of services
          including analysis, research, training and disclosure (where required)
          to its affiliates, agents and government authorities The information
          provided by you can be kept with CASAMED indefinitely and can be used
          without revealing your identity.
        </p>
        <h2 className="font-semibold pt-2 text-lg">
          Equipment and Connectivity Requirements :
        </h2>
        <p>
          The services available on the website shall be provided to you via
          audio/video consultation, or as an offline review through e-mail.
          Please ensure that you are equipped with proper video conference
          equipment and internet connectivity as mentioned or is relevant for
          the service you want to avail. In case of inadequate video conference
          equipment, technical error or defective internet connectivity CASAMED
          reserves the right to reschedule or cancel your appointment without
          any notice.
          <br />
          You understand and acknowledge that in case on online consultation,
          there will not be any physical examination involved and the
          consultation services shall be provided remotely. The opinion
          delivered by the doctor / physiotherapist shall solely be based on the
          verbal communication between the concerned doctor or the
          physiotherapist and the patient and the test reports and other
          information provided/uploaded by you on the website.
          <br />
          CASAMED or the consulting Doctor/Physiotherapist shall not be liable
          for misdiagnosis / faulty judgment / interpretation error / perception
          error/Adverse events/ inefficacy of prescribed treatment or
          advice/validity of the advice or prescription provided by the
          consulting Doctor in your country of residence/ unavailability of the
          recommended or prescribed treatment or medication under any condition
          or circumstances. Users are advised to use their discretion for
          following the advice obtained post consultation from CASAMED.
          <br />
          CASAMED Services are not meant in any way for emergency and
          life-threatening conditions. It is advised to take the patient to the
          nearest hospital in such cases.
        </p>

        <h2 className="font-semibold pt-2 text-lg">
          Limitation of Liability :
        </h2>
        <p>
          To the maximum extent permitted by applicable law, under no
          circumstances and under no legal or equitable theory, whether in tort,
          contract, strict liability or otherwise, shall we, our affiliates, or
          any of our or their employees, directors, officers, agents, vendors or
          suppliers be liable to you or to any third party for any personal
          injury, including death, or for any indirect, special, incidental or
          consequential losses or damages of any nature arising out of or in
          connection with the use of or inability to use the Service, including,
          without limitation, damages for lost profits, loss of goodwill, loss
          of data, work stoppage, accuracy of results, or Device failure or
          malfunction, even if a representative of ours has been advised of or
          should have known of the possibility of such damages. In no event will
          we be liable for any damages in excess of fifty US dollars ($50).
          <br />
          Some jurisdictions do not allow the exclusion of certain warranties or
          the limitation or exclusion of liability for certain damages.
          Accordingly, some of the above limitations and disclaimers may not
          apply to you. To the extent that we may not, as a matter of applicable
          law, disclaim any implied warranty or limit liabilities, the scope and
          duration of such warranty and the extent of our liability will be the
          minimum permitted under such applicable law. <br />
          Any claims arising in connection with your use of the Service must be
          brought within one (1) year of the date of the event giving rise to
          such action occurred. Remedies under these Terms of Use are exclusive
          and are limited to those expressly provided for in these Terms of Use,
          even if the applicable remedy under these Terms of Use fails of its
          essential purpose.
        </p>

        <h2 className="font-semibold pt-2 text-lg">Indemnity :</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless including our
          officers, directors, employees, agents, subcontractors, licensors and
          suppliers, any of our affiliated companies or organizations, and any
          successors, assigns or licensees, from and against any claims, actions
          or demands, damages, losses, liabilities, judgments, settlements,
          costs or expenses (including attorneys’ fees and costs) arising
          directly or indirectly from or relating to:
          <ul className="pl-[50px] pb-[10px] ">
            <li className="list-disc">
              the breach of these Terms of Use by you or anyone using your
              Device or your user account credentials (password or login
              information);
            </li>
            <li className="list-disc">
              any other matter for which you are responsible under these Terms
              of Use or under any applicable law.
            </li>
          </ul>
          You agree that your use of the Service shall be in compliance with all
          applicable laws, regulations, and guidelines.
          <br />
          We reserve the right to assume the exclusive defense and control of
          any demand, claim or action arising hereunder or in connection with
          the Service and all negotiations for settlement or compromise. You
          agree to fully cooperate with us in the defense of any such demand,
          claim, action, settlement or compromise negotiations, as requested by
          us.
        </p>

        <h2 className="font-semibold pt-2 text-lg">Termination :</h2>
        <p>
          These Terms of Use are effective until terminated by either you or us.
          <br />
          You may terminate these Terms of Use at any time, provided that you
          discontinue any further use of the Service. You may also terminate
          your user account by using the appropriate deletion functions, if
          available through the Service.
          <br />
          If you violate these Terms of Use, we reserve the right to terminate
          your use or access to the Service, including by means of terminating
          your account.
          <br />
          We, however, may, in our sole discretion, terminate these Terms of
          Use, your access to any part or all of the Service, or your account,
          at any time and for any reason, without penalty or liability to you or
          any third party. In the event of your breach of these Terms of Use,
          these actions are in addition to and not in lieu or as limitation of
          any other right or remedy that may be available to us. Upon any
          termination of the Terms of Use by either you or us, you must promptly
          uninstall the App on all of your Devices and destroy all materials
          downloaded or otherwise obtained from the Service, all documentation,
          and all copies of such materials and documentation. The following
          provisions survive the expiration or termination of these Terms of Use
          for any reason whatsoever: Intellectual Property, Disclaimer,
          Limitations of Liability, Indemnity, Choice of Law and Dispute
          Resolution, Entire Agreement and Severability.
        </p>

        <h2 className="font-semibold pt-2 text-lg">
          Choice of Law and Dispute Resolution :
        </h2>
        <p>
          These Terms of Use and any non-contractual obligations arising out of
          or in connection with them shall be governed by, and construed in
          accordance with the laws of India. Any dispute, controversy or claim
          arising out of or in connection with these Terms of Use, or the
          breach, termination or invalidity thereof, shall be finally settled by
          arbitration. The seat of arbitration shall be Delhi, India. The
          language to be used in the arbitral proceedings shall be English.
        </p>

        <h2 className="font-semibold pt-2 text-lg">Entire Agreement :</h2>
        <p>
          These Terms of Use and Privacy Policy constitute the entire agreement
          between you and us pertaining to the subject matter hereof
          (“Agreement”). Anything contained in or delivered through the
          Application that is inconsistent with or conflicts with the terms of
          this Agreement is superseded by the terms of this Agreement. These
          Terms of Use may not be modified, in whole or in part, except as
          described elsewhere in these Terms of Use.
        </p>

        <h2 className="font-semibold pt-2 text-lg">Severability :</h2>
        <p>
          If any of the provisions of these Terms of Use are held to be not
          enforceable by a court or other tribunal of competent jurisdiction,
          then such provisions shall be amended, limited or eliminated to the
          minimum extent necessary so that these Terms of Use shall otherwise
          remain in full force and effect.
        </p>

        <h2 className="font-semibold pt-2 text-lg">Assignability :</h2>
        <p>
          You shall not assign or transfer these Terms of Use, by operation of
          law or otherwise, without our prior written and explicit consent. You
          agree that these Terms of Use, Privacy Policy and/or the Agreement
          between you and us in general may be assigned by us, in our sole
          discretion to any third party.
        </p>

        <h2 className="font-semibold pt-2 text-lg">Contact Us :</h2>
        <p>
          All notices to you relating to these Terms of Use shall be notified to
          you either via the Service or via the e-mail address that you provided
          to us.
          <br />
          Please submit any notices to us with respect to the Service or these
          Terms of Use via{" "}
          <a
            style={{ textDecoration: "underline", color: "#3b82f6" }}
            href="mailto: support@casamed.in"
          >
            support@casamed.in
          </a>
          .
        </p>
      </div>
      <div className="text-light">
        <Footer />
      </div>
    </section>
  );
}

export default TermsCond;
