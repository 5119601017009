import React from 'react'
// import { Link } from 'react-router-dom'
import { NavHashLink as Link } from 'react-router-hash-link';


import cost from "../../Assets/HomeViews/Why/cost.svg"
import live from "../../Assets/HomeViews/Why/live.svg"
import nsi from "../../Assets/HomeViews/Why/nsi.svg"
import visit from "../../Assets/HomeViews/Why/visit.svg"
import { motion } from 'framer-motion'
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Numbers from '../../Components/Numbers'

const items = [
    {
        head: "Non-Surgical Intervention",
        text: "Effective Non-Surgical Pain Relief @ 5% of the cost of surgery",
        img: nsi,
        link: "/product#prodfeatures",
    },
    {
        head: "Live Sessions",
        text: "Live Interactive Sessions with our Experts",
        img: live,
        link: "/product#livesession",
    },
    {
        head: "Cost Effective",
        text: "Expert advice at a fraction of hospital expenses",
        img: cost,
        link: "/product#prodfeatures",
    },
    {
        head: "Home Visits",
        text: "Expert Physiotherapy Care in the Comfort of Your Home",
        img: visit,
        link: "/homevisits",
    },
]





const imageVariant = {
    start: {
        rotate: "-180deg"
    },
    end: {
        rotate: 0,
        transition: {
            duration: 1
        }
    }
}

const primarysty = {
    color: "#024461",
    fontWeight: "700",
    fontSize: "1.5rem",
    fontStyle: "Poppins",

}
const secondarysty = {
    color: "#016D8D",
    fontWeight: "400",
    fontSize: "1.125rem",
    fontStyle: "Poppins",
    paddingRight: "0.5rem",
    

}

function Why(){
  return (
    <div className='og--bg--grad--inv' >
        
        <motion.div
            variants={imageVariant}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
            className='md:py-32 xxs:py-28  md:px-32 '>
                <h1 className='text-center -mt-14 xxs:pb-10 md:pb-10 text-casashade2 md:text-[56px] xxs:text-[36px] font-bold md:px-20 xxs:px-6'>
                    <span className='decoration-[#02B9ED] underline underline-offset-8'>W</span>hy Choose CasaMed?
                </h1>

                <Box className=' ' sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <nav aria-label="main mailbox folders">
                    
                    
                
                    <List className='xxs:grid xxs:grid-cols-1 md:grid md:grid-cols-2 justify-center items-center '>
                    
                    {items.map((item, index) =>(  
                    <ListItem key={index}  >
                        <Link 
                    to= {item.link}
                    >
                        <ListItemButton>
                        <ListItemIcon className='pr-6 xxs:w-[40%] sm:w-auto'>
                            <img src={item.img} className="w-full" alt=""/>
                        </ListItemIcon>
                        <ListItemText className='' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary={item.head}
                            secondary={item.text} 
                        />
                        </ListItemButton>
                        </Link>
                    </ListItem>
                    ))}                    
                    </List>
                    
                </nav>
                
                
                
                </Box>

            <div className='text-casashade2 flex justify-center items-center pt-16'>
            <Numbers  />
            </div>
        </motion.div>
    </div>
  )
}

export default Why

