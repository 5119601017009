import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";

function PrivacyPolicy() {
  return (
    <section className="">
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>Privacy Policy | Casamed - Your Privacy Matters to Us</title>
        <meta
          name="description"
          content="At Casamed, we prioritize the privacy and security of your personal information. Our Privacy Policy outlines how we collect, use, and protect your data when you use our services. Read our Privacy Policy to understand your rights and how we safeguard your information."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Privacy Policy, data protection, personal information, privacy rights, data security, Casamed privacy, privacy statement, privacy practices"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/joint-pain-therapy" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Privacy Policy | Casamed - Your Privacy Matters to Us"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/joint-pain-therapy"
        />
        <meta
          property="og:description"
          content="At Casamed, we prioritize the privacy and security of your personal information. Our Privacy Policy outlines how we collect, use, and protect your data when you use our services. Read our Privacy Policy to understand your rights and how we safeguard your information."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Privacy Policy | Casamed - Your Privacy Matters to Us",
            url: " https://casamed.in/joint-pain-therapy",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "At Casamed, we prioritize the privacy and security of your personal information. Our Privacy Policy outlines how we collect, use, and protect your data when you use our services. Read our Privacy Policy to understand your rights and how we safeguard your information.",
          })}
        </script>
      </Helmet>
      <div className="xxs:px-12  md:px-48 og--bg--grad md:pt-32 xxs:pt-24 md:pb-16 xxs:pb-0 text-left">
        <div className="flex justify-center">
          <h1 className="tracking-wide xxs:text-[32px] md:text-[52px] font-semibold text-casashade2 text-center md:pb-12 xxs:pb-6 ">
            <strong className=" decoration-[#02B9ED] underline underline-offset-8">
              P
            </strong>
            rivacy Policy
          </h1>
        </div>

        <p className="pb-10 pt-5">
          Casamed, a product of Sahika Healthcare Private Limited, is deeply
          committed to protecting the privacy and security of our customers’
          data. This statement sets forth Casamed’s Privacy Policy (“Privacy
          Policy”) and describes the practices that we will follow with respect
          to the privacy of the information of users of our website and mobile
          applications. By visiting casamed.in and using Casamed’s mobile
          application (collectively, the “Services”) you acknowledge that you
          accept the practices and policies outlined in this Privacy Policy.
          “You”, “your”, or similar terms refer to you as a user of the
          Services.
        </p>
        <p className="pb-10 pt-5">
          This policy applies to information we collect:
        </p>
        <ul className="pl-[50px] pb-[10px] ">
          <li className="list-disc">through the Services; and</li>
          <li className="list-disc">
            via email, text, or other electronic messages between you and any
            employee or agent of Casamed.
          </li>
        </ul>
        <p className="pb-10 pt-5">
          This policy does not apply to the actions of any company or entity
          that we do not control and to individuals who we do not directly
          employ or manage. As used in this Privacy Policy, the terms “using”
          and “processing” information may include, amongst other things,
          subjecting such information to analysis, using cookies, and managing
          information in a number of ways, including but not limited to:
          collection, storing, evaluating, modifying, deleting, using,
          combining, and/or disclosing.
        </p>
        <p className="pb-10 pt-5">
          Some of the information we collect may constitute protected health
          information (“PHI”) or electronic protected health information
          (“ePHI”) under the Indian IT Act 2000 or Health Insurance Portability
          and Accountability Act of 1996 (“HIPAA”), as amended from time to
          time. For more information, please refer to
          <a
            className="ppa"
            style={{ textDecoration: "underline", color: "#3b82f6" }}
            href="https://www.nhp.gov.in/data-ownership-of-ehr_mtl#:~:text=Protected%20Health%20Information%20(PHI)%20would,provision%20of%20health%20care%20to"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            National Health Portal’s (NHP’s) guidelines regarding Data ownership
            of Electronic Health Records (EHR)
          </a>
          . To the extent other state or local privacy and data protection laws
          apply to your data, we will comply with those requirements as well.
        </p>
        <p className="pb-10 pt-5">
          Should you have any questions about this policy or our practices,
          please send an email to hello@casamed.in.
        </p>
        <p className="pb-10 pt-5">
          <strong>What Information We Collect</strong>
        </p>
        <p className="pb-10 pt-5">
          Casamed collects some basic identifying information such as your name,
          contact number, e-mail address or other contact information, height,
          weight, gender, nature of lifestyle, date of birth. Casamed only
          collects other medical or personal information about you through the
          Services only when you voluntarily provide it to us. If you choose to
          go through our screening process and/or register for the Services we
          ask you to provide personal information, including but not limited to:
          your name, address, telephone number and/or email address, height,
          weight, and health information in connection with the use of our
          products or services. You may choose not to provide us with certain
          information, but that may result in our inability to provide you
          access to the Services.
        </p>
        <p className="pb-10 pt-5">
          <strong>What Information We Disclose</strong>
        </p>
        <p className="pb-10 pt-5">
          By using the Services you consent to and authorize Casamed to disclose
          your eligibility for and participation in the Services (i.e. that you
          meet the enrollment criteria for the Services and that you have
          elected at your own discretion to participate) to others, including:
          Casamed senior management and administrators, your personal Casamed
          consultant and other users of Casamed’s Services. In particular, these
          entities may be able to access a range of information about you, such
          as your name, and participation in Casamed’s program. Moreover, based
          on your request a physician or a therapist of your choice may be
          assigned to your case who will be able to view such information. Only
          information you explicitly consent to be visible to the consultants,
          therapists or physician will be shared. By default, only your first
          name is shared
        </p>
        <p className="pb-10 pt-5">
          <strong>How to Review and Change Your Personal Information</strong>
        </p>
        <p className="pb-10 pt-5">
          If you become a Casamed account, you may review your personal
          information by visiting the Casamed mobile applications and accessing
          the “Profile menu”. You may edit your information or in case of any
          difficulty, please contact us at support@casamed.in.
        </p>
        <p className="pb-10 pt-5">
          <strong>
            How We Use Personal Information That We Collect Online
          </strong>
          <br />
          (a) Internal Uses – We may use information we collect about you to:
        </p>
        <ul className="pl-[50px] pb-[10px] ">
          <li className="list-disc">
            Administer your account, including your assignment and enrollment in
            your Peer Group;
          </li>
          <li className="list-disc">
            Allow your Physician, therapist or Casamed Consultant to personalize
            your experience;
          </li>
          <li className="list-disc">
            Provide you with access to particular tools and services;
          </li>
          <li className="list-disc">
            Respond to your inquiries and send you administrative
            communications;
          </li>
          <li className="list-disc">
            Share reminders and service related updates with you through
            notifications;
          </li>
          <li className="list-disc">
            Obtain your feedback on our websites and our offerings;
          </li>
          <li className="list-disc">
            Statistically analyze user behavior and activity;
          </li>
          <li className="list-disc">Provide you with more relevant content;</li>
          <li className="list-disc">
            Conduct research and measurement activities;
          </li>
          <li className="list-disc">
            Send you personalized emails or secure electronic messages
            pertaining to your health information; or
          </li>
          <li className="list-disc">
            Contact you about the products and services that we offer.
          </li>
        </ul>
        <p className="pb-10 pt-5">
          We may combine personal and non-personal information collected by
          Casamed about you, and may combine this information with information
          provided by external sources.
        </p>
        <p className="pb-10 pt-5">
          (b) Disclosure of Personal Information to Third Parties
        </p>
        <p className="pb-10 pt-5">
          We will not disclose any personal information about any of our users
          to any third-party (excluding our vendors or contractors to whom we
          may provide such information for the limited purpose of providing
          services to us and who are obligated to keep the information
          confidential), unless: (1) you have authorized us to do so; (2) we are
          legally required to do so, and/or, (3) it is necessary to protect our
          property rights related to this website. We also may share aggregate,
          non-personal information about website usage with unaffiliated third
          parties, or via scientific research papers regarding our Services.
          This aggregate information does not contain any personal information
          about our users.
        </p>
        <p className="pb-10 pt-5">(c) Disclosure of Information by You</p>
        <p className="pb-10 pt-5">
          Through your use of the Services, you may choose to make certain
          elements of your information, including PHI that you post and/or
          disclose in the course of engaging with the Services, available to
          physicians and therapists. While we take considerable efforts to
          protect your privacy, we cannot take and expressly disclaim
          responsibility for whether and how physicians and therapists will use
          or disclose information disclosed by you through the Services. As
          discussed above, the Services provide you the ability to share
          personal information, including regarding your medical condition and
          history to your physician and therapist. Any information you
          voluntarily choose to provide through the Services may be visible to
          your physician and therapist. As such, you should only provide
          information you feel comfortable disclosing.
        </p>
        <p className="pb-10 pt-5">
          <strong>Cookie Placement</strong>
          <br />
          Certain Casamed websites, like many other commercial websites, may use
          a standard technology called “cookies” to collect information about
          how our website is used. Cookies were designed to help a website
          operator determine that a particular user had visited the site
          previously and thus save and remember any preferences that may have
          been set. We may use cookies to temporarily keep track of information
          about your current web browsing session which will be discarded as
          soon as you log out or close your web browser. This information also
          allows us to statistically monitor how many people are using our
          website and for what purpose. We may also make use of “persistent” or
          “memory based” cookies, which remain on your computer’s hard drive
          until you delete them. Although you have the ability to modify your
          browser to either accept all cookies, notify you when a cookie is
          sent, or reject all cookies, it may not be possible to utilize our
          services if you reject all cookies.
        </p>
        <p className="pb-10 pt-5">
          <strong>How We Protect Information Online</strong>
          <br />
          We exercise great care to protect your personal information through
          various administrative and physical safeguards. This includes, among
          other things, using industry standard techniques such as firewalls,
          256 bit encryption, and intrusion detection for information stored on
          our systems. However, while we strive to protect your personal
          information, we cannot ensure or warrant the security of any
          information you transmit to us or receive from us while that
          information is in transit. This is especially true for information you
          transmit to us via email since we have no way of protecting that
          information until it reaches us since email does not have the security
          features that are built into our websites.
          <br />
          In addition, we limit Casamed’s employees’ and contractors’ access to
          personal information. Only those employees and contractors with a
          business reason to know have access to this information, and then may
          only access or use the minimum necessary for the task at hand. We
          educate our employees about the importance of maintaining
          confidentiality of user information.
          <br />
          We also periodically review our security arrangements and safeguards.
        </p>
        <p className="pb-10 pt-5">
          <strong>How can you help protect your information?</strong>
          <br />
          If you are using a Casamed website or mobile application for which you
          registered and choose a password, we recommend that you do not share
          your authentication details or credentials with anyone. We will never
          ask you for your credentials in an unsolicited phone call or in an
          unsolicited email. Always remember to close your browser window when
          you have finished using the Services. This is to ensure that others
          cannot access your personal information and correspondence to us if
          others have access to your computer or mobile device.
        </p>
        <p className="pb-10 pt-5">
          <strong>Children</strong>
          <br />
          We are committed to protecting the privacy of children. The Casamed
          websites are not designed to be used by or intended to attract
          children under the age of 13. Individuals who we actually know are
          under the age of 13 are not permitted to use the Casamed websites and
          we will not collect their personal information.
        </p>
        <p className="pb-10 pt-5">
          <strong>Links to Other Websites</strong>
          <br />
          We want to provide website visitors valuable information, services,
          and products. Featured programs and other Casamed website content may
          link our users to third-party websites. Casamed does not control and
          is not responsible for privacy or security practices of any third
          party websites.
        </p>
        <p className="pb-10 pt-5">
          <strong>Note</strong>
          <br />
          As we continually update and improve our services, we may periodically
          make changes to this Privacy Policy. Or, we may modify this Privacy
          Policy to reflect new changes in laws or regulations. Accordingly,
          please check back and review this Privacy Policy periodically. Jan
          26th, 2021
        </p>
        <h2 className="text-[32px] pb-3 pt-6 font-semibold">
          Frequently Asked Questions
        </h2>
        <p className="pb-10 pt-5">
          <strong>What is Personal Data?</strong>
          <br />
          The term “Personal Data” according to global and local laws and
          regulations generally means any information relating to an identified
          or identifiable person or persons. It includes your name, phone
          number, email address, health records and any other information that
          directly identifies you. It also includes information that might be
          able to identify a device you use like cookies.
        </p>
        <p className="pb-10 pt-5">
          <strong>What types of personal data does Casamed collect?</strong>
          <br />
          Casamed collects your Name, Phone Number, Email Address, Date of
          Birth, Height, Weight, etc, if you willingly provide it during the
          course of your interaction with Casamed’s services. If you choose to
          go through our screening processes like Smart Diagnostics, you may be
          required to provide details about your health information, the nature
          of which depends on the product/service you are using.
        </p>
        <p className="pb-10 pt-5">
          <strong>
            How is the information collected online used by Casamed?
          </strong>
          <br />
          Casamed may use your personal information to customize your
          experience, administer your account, share reminders, provide you with
          more relevant content and contact you about our products and services.
        </p>
        <p className="pb-10 pt-5">
          <strong>How does Casamed use my medical information?</strong>
          <br />
          Casamed guards your medical/health related information with utmost
          care and does not share your records with any third parties without
          your consent. Even while examining your case, Casamed first obtains
          your consent to share the information with a relevant doctor, PI or
          health consultant. Casamed strictly adheres to National Health
          Portal’s
          <a
            className="ppa"
            style={{ textDecoration: "underline", color: "#3b82f6" }}
            href="https://www.nhp.gov.in/data-ownership-of-ehr_mtl#:~:text=Protected%20Health%20Information%20(PHI)%20would,provision%20of%20health%20care%20to"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            guidelines
          </a>{" "}
          regarding data ownership of Electronic Health Records.
        </p>
        <p className="pb-10 pt-5">
          <strong>How does Casamed protect my privacy?</strong>
          <br />
          We at Casamed understand the importance of privacy and data security
          and strive constantly to provide the highest standards of security and
          give you the confidence that your data is in safe hands.When it comes
          to your data, you are in control. With industry grade firewalls, 256
          bit encryption and intrusion detection systems your personal
          information is heavily guarded.
          <a
            className="ppa"
            style={{ textDecoration: "underline", color: "#3b82f6" }}
            href="https://casamed.in/privacy-policy/"
          >
            {" "}
            Learn more
          </a>{" "}
          about how we protect your privacy and keep your data safe.
        </p>
        <p className="pb-10 pt-5">
          <strong>
            Does Casamed store my bank details while processing payments?
          </strong>
          <br />
          No. Casamed does not collect any sensitive information like passwords
          or PINs when you make payment for any of our services. Casamed uses
          globally trusted payment partner Razorpay to process payments on our
          platform. Know more about
          <a
            className="ppa"
            style={{ textDecoration: "underline", color: "#3b82f6" }}
            href="https://razorpay.com/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Razorpay’s Privacy Policy
          </a>
          .
        </p>
        <p className="pb-10 pt-5">
          <strong>How can I review or change my personal information?</strong>
          <br />
          If you have a Casamed account, you may review your personal
          information by visiting the Casamed mobile applications and accessing
          the “Profile menu”. You may edit your information or in case of any
          difficulty, please contact us at
          <a
            className="ppa"
            style={{ textDecoration: "underline", color: "#3b82f6" }}
            href="mailto: support@casamed.in"
          >
            {" "}
            support@casamed.in
          </a>
          .
        </p>
      </div>
      <div className="text-light">
        <Footer />
      </div>
    </section>
  );
}

export default PrivacyPolicy;
