import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import WhyUs from "../Views/AboutViews/WhyUs";
import MissionVision from "../Views/AboutViews/MissionVision";
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";
import { FaWhatsapp, FaPhone } from "react-icons/fa";

function About() {
  return (
    <section className="w-full bg-light">
      <Helmet>
        {/* Meta title and Description */}
        <title>About Us | CasaMed</title>
        <meta
          name="description"
          content="Discover CasaMed's passion for health and well-being. Learn about our mission to provide top-notch medical solutions — your partner in wellness."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="muscle pain therapy,spondylosis therapy,neck pain therapy,shoulder pain therapy,cervical therapy"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/about" />

        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta property="og:title" content="About Us | CasaMed" />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content=" https://casamed.in/about" />
        <meta
          property="og:description"
          content="Discover CasaMed's passion for health and well-being. Learn about our mission to provide top-notch medical solutions — your partner in wellness."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "About Us | CasaMed",
            url: " https://casamed.in/about",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "Discover CasaMed's passion for health and well-being. Learn about our mission to provide top-notch medical solutions — your partner in wellness.",
          })}
        </script>
      </Helmet>

      <div className="stickynav bg-light">
        <Nav />
      </div>
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      <WhyUs />
      <MissionVision />
      <Footer />
    </section>
  );
}

export default About;
