import { React, useState, useEffect } from "react";
import Nav from "../Components/Nav";
import { Helmet } from "react-helmet";
import bannerImg from "../Assets/guwahati_banner.jpg";
import Numbers from "../Components/Numbers";
import Footer from "../Components/Footer";

import { makeStyles } from "@mui/styles";
import { Alert, AlertTitle } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";

import { IconButton, SnackbarContent } from "@mui/material";

import { CheckCircle, Error, Close } from "@material-ui/icons";

import MuiAlert from "@mui/material/Alert";
import PersonaStyled from "../Components/PersonaStyled";

import axios from "axios";

import { NavHashLink as Link1 } from "react-router-hash-link";
import { motion } from "framer-motion";

import { Flip } from "react-awesome-reveal";
import { Link, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

// import FAQItem from './TestFAQ'
// import testFAQ2 from './testFAQ2'
import Contact from "./ContactStyled";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { Avatar } from "@mui/material";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards, Autoplay, Keyboard } from "swiper";
import TestimonialRevamp2 from "../Components/TestimonialRevamp2";

const patnaPhysios = [
  {
    id: 1,
    name: "Akanchha Gupta",
    img: "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Team%2Fakanchha2.png?alt=media&token=3f129e37-c7f8-4b41-a3ae-8e3302cba8d5",
    degree: "MPTh (Ortho)",
  },
  {
    id: 2,
    name: "Prashant Kumar",
    img: "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FLocation_Page%2FPatnaPhysios%2FPrashant.jpeg?alt=media&token=0cd1ff03-f299-427b-b757-0ae53c547372",
    degree: "MPTh (Ortho)",
  },
  {
    id: 3,
    name: "Namita Singh",
    img: "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FLocation_Page%2FPatnaPhysios%2FNamita.jpeg?alt=media&token=722d6180-9e27-4c6b-a933-e9378b1405b9",
    degree: "BPTh",
  },
];

const primarysty = {
  color: "#024461",
  fontWeight: "700",
  fontSize: "17px",
  fontFamily: "Poppins",
};
const secondarysty = {
  color: "#024461",
  fontWeight: "400",
  fontSize: "15px",
  fontFamily: "Poppins",
  whiteSpace: "pre-line",
};

const imageVariant = {
  start: {
    rotate: "-180deg",
  },
  end: {
    rotate: 0,
    transition: {
      duration: 1,
    },
  },
};

const primarysty1 = {
  color: "#024461",
  fontWeight: "700",
  fontSize: "1.5rem",
  fontStyle: "Poppins",
};
const secondarysty1 = {
  color: "#016D8D",
  fontWeight: "400",
  fontSize: "1.125rem",
  fontStyle: "Poppins",
  paddingRight: "0.5rem",
};

console.log(` 

██████╗ █████╗ ███████╗ █████╗ ███╗   ███╗███████╗██████╗
██╔════╝██╔══██╗██╔════╝██╔══██╗████╗ ████║██╔════╝██╔══██╗
██║     ███████║███████╗███████║██╔████╔██║█████╗  ██║  ██║
██║     ██╔══██║╚════██║██╔══██║██║╚██╔╝██║██╔══╝  ██║  ██║
╚██████╗██║  ██║███████║██║  ██║██║ ╚═╝ ██║███████╗██████╔╝
 ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝╚═════╝
 `);

function LocationPage() {
  const [locationPage, setLocationPage] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const { slug } = useParams();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [nameError, setNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("This field is required");
      return false;
    }
    return true;
  };

  const validatePhoneNumber = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
      setPhoneNumberError("Please enter a valid phone number");
      return false;
    }
    return true;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  useEffect(() => {
    axios
      .get(`/api/locPages/${slug}`)
      .then((response) => {
        setLocationPage(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [slug]);

  if (!locationPage) {
    return <div>Loading...</div>;
  }
  // console.log(locationPage)

  const City =
    locationPage.slug.charAt(0).toUpperCase() + locationPage.slug.slice(1);

  const physioInLoc = locationPage.ptInLoc;
  console.log(physioInLoc);

  const ptLOC1 = physioInLoc.p1.replace(/\./g, ".<br/><br/>");
  const ptLOC2 = physioInLoc.p2.replace(/\./g, ".<br/><br/>");
  const ptLOC3 = physioInLoc.p3.replace(/\./g, ".<br/><br/>");

  // const ptInLocLinkP1 = physioInLoc.p1.toLowerCase().includes('physiotherapy') ? ptLOC1.replace(/physiotherapy/ig, `<a style="color: blue; text-decoration: underline;" target="_blank" href="https://casamed.in/physiotherapy">physiotherapy</a>`) : ptLOC1
  // const ptInLocLinkP2 = physioInLoc.p2.toLowerCase().includes('physiotherapy') ? ptLOC2.replace(/physiotherapy/ig, `<a style="color: blue; text-decoration: underline;" target="_blank" href="https://casamed.in/physiotherapy">physiotherapy</a>`) : ptLOC2
  const ptInLocLinkP3 = physioInLoc.p3.toLowerCase().includes("physiotherapy")
    ? ptLOC3.replace(
        /physiotherapy/i,
        `<a style="color: blue; text-decoration: underline;" target="_blank" href="https://casamed.in/physiotherapy">physiotherapy</a>`
      )
    : ptLOC3;

  // const physioLoc1 = ptInLocLinkP1.replace(/<br\/?>/g, '\n');
  // const physioLoc2 = ptInLocLinkP2.replace(/<br\/?>/g, '\n');
  const physioLoc3 = ptInLocLinkP3.replace(/<br\/?>/g, "\n");

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateName() || !validatePhoneNumber()) {
      return;
    }

    axios
      .post(
        "/api/locationForm",
        {
          name: name,
          phoneNumber: phoneNumber,
          location: City,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        }
      )
      .then((response) => {
        // console.log(response.data);
        // alert('Form submitted successfully');

        setShowSnackbar(true);
        setSnackbarMessage("Form submitted successfully!");
        setSnackbarSeverity("success");

        setName("");
        setPhoneNumber("");
      })
      .catch((error) => {
        console.error(error);
        // alert('Form submission failed');

        setShowSnackbar(true);
        setSnackbarMessage("Failed to submit form. Please try again later.");
        setSnackbarSeverity("error");
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    setNameError(false);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setPhoneNumberError(false);
  };

  console.log(locationPage.presence);

  return (
    <div>
      <div className="pb-15 w-[100vw] ">
        <Nav />
      </div>
      <Helmet>
        {/* Meta title and Description */}
        <title>{locationPage.metaTitle}</title>
        <meta name="description" content={locationPage.metaDesc} />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="muscle pain therapy,spondylosis therapy,neck pain therapy,shoulder pain therapy,cervical therapy"
        />

        <meta name="robots" content="INDEX,FOLLOW" />

        {/* Canonical Tag */}
        <link
          rel="canonical"
          href={`https://casamed.in/physiotherapy/${slug}`}
        />
        {/* Open Graph */}
        <meta property="og:title" content={locationPage.metaTitle} />

        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content={`https://casamed.in/physiotherapy/${slug}`}
        />
        <meta property="og:description" content={locationPage.metaDesc} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={locationPage.metaImg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: locationPage.metaTitle,
            url: `https://casamed.in/physiotherapy/${slug}`,
            logo: {
              "@type": "ImageObject",
              url: locationPage.metaImg,
            },
            description: locationPage.metaDesc,
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: locationPage.faqs.map((faq) => ({
              "@type": "Question",
              name: faq.question,
              acceptedAnswer: {
                "@type": "Answer",
                text: faq.answer,
              },
            })),
          })}
        </script>
      </Helmet>

      {locationPage.slug === "patna" && (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Physician",
              name: "Akanchha Gupta",
              image: "https://casamed.in/image1",
              currenciesAccepted: "INR",
              medicalSpecialty: "MPTh (Ortho)",
              priceRange: "500-2000",
              geo: {
                "@type": "GeoCoordinates",
                latitude: "25.60499530",
                longitude: "85.08468300",
              },
              address: [
                {
                  "@type": "PostalAddress",
                  addressLocality: "Bailey Road",
                  addressRegion: "Patna",
                  postalCode: "800014",
                  telephone: "03340585240",
                  addressCountry: {
                    "@type": "Country",
                    name: "India",
                  },
                },
              ],
            })}
          </script>

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Physician",
              name: "Prashant Kumar",
              image: "https://casamed.in/image2",
              currenciesAccepted: "INR",
              medicalSpecialty: "MPTh (Ortho)",
              priceRange: "500-2000",
              geo: {
                "@type": "GeoCoordinates",
                latitude: "25.60499530",
                longitude: "85.08468300",
              },
              address: [
                {
                  "@type": "PostalAddress",
                  addressLocality: "Bailey Road",
                  addressRegion: "Patna",
                  postalCode: "800014",
                  telephone: "03340585240",
                  addressCountry: {
                    "@type": "Country",
                    name: "India",
                  },
                },
              ],
            })}
          </script>

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Physician",
              name: "Namita Singh",
              image: "https://casamed.in/image2",
              currenciesAccepted: "INR",
              medicalSpecialty: "BPTh",
              priceRange: "500-2000",
              geo: {
                "@type": "GeoCoordinates",
                latitude: "25.60499530",
                longitude: "85.08468300",
              },
              address: [
                {
                  "@type": "PostalAddress",
                  addressLocality: "Bailey Road",
                  addressRegion: "Patna",
                  postalCode: "800014",
                  telephone: "03340585240",
                  addressCountry: {
                    "@type": "Country",
                    name: "India",
                  },
                },
              ],
            })}
          </script>

          {/* LOCAL BUSINESS SCHEMA */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org/",
              "@type": "LocalBusiness",
              name: "Physiotherapy in Patna, Bihar",
              areaServed: "Patna",
              telephone: "03340585240",
              image:
                "https://casamed.in/static/media/logo.ca2a73c42c559614a4ec05c3f7de5e9d.svg",
              aggregateRating: {
                "@type": "AggregateRating",
                ratingValue: "4.8",
                bestRating: "5",
                reviewCount: "175",
              },
            })}
          </script>
        </Helmet>
      )}

      {/* WEB PAGE ENTITY SCHEMA */}
      {locationPage.slug === "patna" ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              headline:
                "Best Physiotherapist in Patna | Premium Clinic & Home Physiotherapy",
              url: "https://casamed.in/physiotherapy/patna",
              about: [
                {
                  "@type": "Thing",
                  name: "physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "clinic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Clinic",
                    "https://www.google.com/search?q=clinic&kgmid=/m/03fk5c",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patna",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Patna",
                    "https://www.google.com/search?q=patna&kgmid=/m/0pclynz",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "treatment",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=treatment&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain",
                    "https://www.google.com/search?q=pain&kgmid=/m/062t2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "fee",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Fee",
                    "https://www.google.com/search?q=fee&kgmid=/m/03fk6m",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patients",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Patient",
                    "https://www.google.com/search?q=patients&kgmid=/m/028hfb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "health care",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health_care",
                    "https://www.google.com/search?q=health+care&kgmid=/m/01mw2x",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "shoulder",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Shoulder",
                    "https://www.google.com/search?q=shoulder&kgmid=/m/04f6ypl",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "shoulder pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Shoulder_problem",
                    "https://www.google.com/search?q=shoulder+pain&kgmid=/m/01ss8j",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "health",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health",
                    "https://www.google.com/search?q=health&kgmid=/m/0kt51",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Back_pain",
                    "https://www.google.com/search?q=back+pain&kgmid=/m/0142ky",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "hospital",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Hospital",
                    "https://www.google.com/search?q=hospital&kgmid=/m/0hpnr",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "knee pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Knee_pain",
                    "https://www.google.com/search?q=knee+pain&kgmid=/m/09v868h",
                  ],
                },
              ],
              mentions: [
                {
                  "@type": "Thing",
                  name: "physio",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physio&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapist assistants",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapist+assistants&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapists",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapists&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "healthcare",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health_care",
                    "https://www.google.com/search?q=healthcare&kgmid=/m/01mw2x",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sports physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=sports+physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "neuro",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Neurology",
                    "https://www.google.com/search?q=neuro&kgmid=/m/05b1j",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "spinal injury",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Spinal_cord_injury",
                    "https://www.google.com/search?q=spinal+injury&kgmid=/m/041zm1",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain management",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain_management",
                    "https://www.google.com/search?q=pain+management&kgmid=/m/025whv",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation center",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation+center&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "injury",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Injury",
                    "https://www.google.com/search?q=injury&kgmid=/m/02kh4w",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "injuries",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Injury",
                    "https://www.google.com/search?q=injuries&kgmid=/m/02kh4w",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "orthopedic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Orthopedic_surgery",
                    "https://www.google.com/search?q=orthopedic&kgmid=/m/027lz1",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "fractures",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Bone_fracture",
                    "https://www.google.com/search?q=fractures&kgmid=/m/071_jm",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "hospitals",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Hospital",
                    "https://www.google.com/search?q=hospitals&kgmid=/m/0hpnr",
                  ],
                },
              ],
            })}
          </script>
        </Helmet>
      ) : locationPage.slug === "guwahati" ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              headline:
                "Top-Rated Physiotherapy At Home In Guwahati: The One You Need",
              url: "https://casamed.in/physiotherapy/guwahati",
              about: [
                {
                  "@type": "Thing",
                  name: "guwahati",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Guwahati",
                    "https://www.google.com/search?q=guwahati&kgmid=/m/03fxfy",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotherapist",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapist&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "clinic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Clinic",
                    "https://www.google.com/search?q=clinic&kgmid=/m/03fk5c",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "guwahati, assam",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Guwahati",
                    "https://www.google.com/search?q=guwahati,+assam&kgmid=/m/03fxfy",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "treatment",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=treatment&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "hospital",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Hospital",
                    "https://www.google.com/search?q=hospital&kgmid=/m/0hpnr",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patients",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Patient",
                    "https://www.google.com/search?q=patients&kgmid=/m/028hfb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "fee",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Fee",
                    "https://www.google.com/search?q=fee&kgmid=/m/03fk6m",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain",
                    "https://www.google.com/search?q=pain&kgmid=/m/062t2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "india",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/India",
                    "https://www.google.com/search?q=india&kgmid=/m/03rk0",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "assam",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Assam",
                    "https://www.google.com/search?q=assam&kgmid=/m/019fm7",
                  ],
                },
              ],
              mentions: [
                {
                  "@type": "Thing",
                  name: "sports physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=sports+physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation centers",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation+centers&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "exercise therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=exercise+therapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapists",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapists&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physio",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physio&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain",
                    "https://www.google.com/search?q=pain&kgmid=/m/062t2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "lower back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Low_back_pain",
                    "https://www.google.com/search?q=lower+back+pain&kgmid=/m/020hwm",
                  ],
                },
              ],
            })}
          </script>
        </Helmet>
      ) : locationPage.slug === "chandigarh" ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              headline:
                "Top-Rated Physiotherapy At Home In Chandigarh: The One You Need",
              url: "https://casamed.in/physiotherapy/chandigarh",
              about: [
                {
                  "@type": "Thing",
                  name: "physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "chandigarh",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Chandigarh",
                    "https://www.google.com/search?q=chandigarh&kgmid=/m/0bs54h6",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain",
                    "https://www.google.com/search?q=pain&kgmid=/m/062t2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "clinic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Clinic",
                    "https://www.google.com/search?q=clinic&kgmid=/m/03fk5c",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physio",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physio&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "treatment",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=treatment&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patient",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Patient",
                    "https://www.google.com/search?q=patient&kgmid=/m/028hfb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sciatica",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Sciatica",
                    "https://www.google.com/search?q=sciatica&kgmid=/m/01_wxr",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "punjab",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Punjab",
                    "https://www.google.com/search?q=punjab&kgmid=/m/065ms",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=therapy&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "chronic back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Back_pain",
                    "https://www.google.com/search?q=chronic+back+pain&kgmid=/m/0142ky",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "spine",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Vertebral_column",
                    "https://www.google.com/search?q=spine&kgmid=/m/0hgn0x2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "exercise",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Exercise",
                    "https://www.google.com/search?q=exercise&kgmid=/m/019w6h",
                  ],
                },
              ],
              mentions: [
                {
                  "@type": "Thing",
                  name: "physios",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physios&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "exercise therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=exercise+therapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation centre",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation+centre&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "posture correction",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=posture+correction&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sprains",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Sprain",
                    "https://www.google.com/search?q=sprains&kgmid=/m/022n_8",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "lower back",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Low_back_pain",
                    "https://www.google.com/search?q=lower+back&kgmid=/m/020hwm",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapies",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapies&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Back_pain",
                    "https://www.google.com/search?q=back+pain&kgmid=/m/0142ky",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "lower back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Low_back_pain",
                    "https://www.google.com/search?q=lower+back+pain&kgmid=/m/020hwm",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "neck pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Neck_pain",
                    "https://www.google.com/search?q=neck+pain&kgmid=/m/02r3cvb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "healthy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health",
                    "https://www.google.com/search?q=healthy&kgmid=/m/0kt51",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapy&kgmid=/m/05_h7",
                  ],
                },
              ],
            })}
          </script>
        </Helmet>
      ) : locationPage.slug === "pune" ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              headline:
                "Top-Rated Physiotherapy At Home In Pune: The One You Need",
              url: "https://casamed.in/physiotherapy/pune",
              about: [
                {
                  "@type": "Thing",
                  name: "physiotherapist",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapist&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pune",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pune",
                    "https://www.google.com/search?q=pune&kgmid=/m/015y2q",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain",
                    "https://www.google.com/search?q=pain&kgmid=/m/062t2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "treatment",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=treatment&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physio",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physio&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patient",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Patient",
                    "https://www.google.com/search?q=patient&kgmid=/m/028hfb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "clinic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Clinic",
                    "https://www.google.com/search?q=clinic&kgmid=/m/03fk5c",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pune, maharashtra",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pune",
                    "https://www.google.com/search?q=pune,+maharashtra&kgmid=/m/015y2q",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "exercises",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Exercise",
                    "https://www.google.com/search?q=exercises&kgmid=/m/019w6h",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "orthopedic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Orthopedic_surgery",
                    "https://www.google.com/search?q=orthopedic&kgmid=/m/027lz1",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "muscle",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Skeletal_muscle",
                    "https://www.google.com/search?q=muscle&kgmid=/m/021jhy",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "knowledge",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Knowledge",
                    "https://www.google.com/search?q=knowledge&kgmid=/m/01k8wb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Back_pain",
                    "https://www.google.com/search?q=back+pain&kgmid=/m/0142ky",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "maharashtra",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Maharashtra",
                    "https://www.google.com/search?q=maharashtra&kgmid=/m/055vr",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "knee",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Knee",
                    "https://www.google.com/search?q=knee&kgmid=/m/019swr",
                  ],
                },
              ],
              mentions: [
                {
                  "@type": "Thing",
                  name: "physical rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sports physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=sports+physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation center",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation+center&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "manual therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Manual_therapy",
                    "https://www.google.com/search?q=manual+therapy&kgmid=/m/04mkk8",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physios",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physios&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "shoulder pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Shoulder_problem",
                    "https://www.google.com/search?q=shoulder+pain&kgmid=/m/01ss8j",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sporting injuries",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Sports_injury",
                    "https://www.google.com/search?q=sporting+injuries&kgmid=/m/0ht1b",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "health",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health",
                    "https://www.google.com/search?q=health&kgmid=/m/0kt51",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotheraphy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotheraphy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Back_pain",
                    "https://www.google.com/search?q=back+pain&kgmid=/m/0142ky",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sprains",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Sprain",
                    "https://www.google.com/search?q=sprains&kgmid=/m/022n_8",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "healthcare",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health_care",
                    "https://www.google.com/search?q=healthcare&kgmid=/m/01mw2x",
                  ],
                },
              ],
            })}
          </script>
        </Helmet>
      ) : locationPage.slug === "goa" ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              headline:
                "Top-Rated Physiotherapy At Home In Goa: The One You Need",
              url: "https://casamed.in/physiotherapy/goa",
              about: [
                {
                  "@type": "Thing",
                  name: "physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "goa",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Goa",
                    "https://www.google.com/search?q=goa&kgmid=/m/01c1nm",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "treatment",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=treatment&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "clinic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Clinic",
                    "https://www.google.com/search?q=clinic&kgmid=/m/03fk5c",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Pain",
                    "https://www.google.com/search?q=pain&kgmid=/m/062t2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patients",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Patient",
                    "https://www.google.com/search?q=patients&kgmid=/m/028hfb",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physio",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physio&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitation",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitation&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "exercise",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Exercise",
                    "https://www.google.com/search?q=exercise&kgmid=/m/019w6h",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "margao",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Margao",
                    "https://www.google.com/search?q=margao&kgmid=/m/04js8k",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "north goa",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/North_Goa_district",
                    "https://www.google.com/search?q=north+goa&kgmid=/m/05fwnv",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "muscle",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Skeletal_muscle",
                    "https://www.google.com/search?q=muscle&kgmid=/m/021jhy",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Therapy",
                    "https://www.google.com/search?q=therapy&kgmid=/m/03c1dkx",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "patient care",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Health_care",
                    "https://www.google.com/search?q=patient+care&kgmid=/m/01mw2x",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "manual therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Manual_therapy",
                    "https://www.google.com/search?q=manual+therapy&kgmid=/m/04mkk8",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "injuries",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Injury",
                    "https://www.google.com/search?q=injuries&kgmid=/m/02kh4w",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "nursing",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Nursing",
                    "https://www.google.com/search?q=nursing&kgmid=/m/05fh2",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "hospital",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Hospital",
                    "https://www.google.com/search?q=hospital&kgmid=/m/0hpnr",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "panjim",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Panaji",
                    "https://www.google.com/search?q=panjim&kgmid=/m/01rjt4",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "injury",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Injury",
                    "https://www.google.com/search?q=injury&kgmid=/m/02kh4w",
                  ],
                },
              ],
              mentions: [
                {
                  "@type": "Thing",
                  name: "trigger points",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Myofascial_trigger_point",
                    "https://www.google.com/search?q=trigger+points&kgmid=/m/05hr09",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotherapists",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapists&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "caretakers",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Caregiver",
                    "https://www.google.com/search?q=caretakers&kgmid=/m/08cwls",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotherapeutic",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotherapeutic&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "exercise therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=exercise+therapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physical therapist assistants",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physical+therapist+assistants&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "low back pain",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Low_back_pain",
                    "https://www.google.com/search?q=low+back+pain&kgmid=/m/020hwm",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "rehabilitations",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=rehabilitations&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "injuries",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Injury",
                    "https://www.google.com/search?q=injuries&kgmid=/m/02kh4w",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "physiotherapy",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Physical_therapy",
                    "https://www.google.com/search?q=physiotheraphy&kgmid=/m/05_h7",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "strokes",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Stroke",
                    "https://www.google.com/search?q=strokes&kgmid=/m/02y0js",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "sciatica",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Sciatica",
                    "https://www.google.com/search?q=sciatica&kgmid=/m/01_wxr",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "caregiver",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Caregiver",
                    "https://www.google.com/search?q=caregiver&kgmid=/m/08cwls",
                  ],
                },
                {
                  "@type": "Thing",
                  name: "panjim",
                  sameAs: [
                    "https://en.wikipedia.org/wiki/Panaji",
                    "https://www.google.com/search?q=panjim&kgmid=/m/01rjt4",
                  ],
                },
              ],
            })}
          </script>
        </Helmet>
      ) : (
        ""
      )}

      <div
        className="xxs:bg-auto md:bg-cover "
        style={{
          backgroundImage: `url(${bannerImg})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="w-full xxs:pt-[25%] md:pt-[12%] md:pb-[5%] xl:pt-[16%] xl:pb-[10%] flex items-center justify-center">
          <div className="text-center">
            <h1 className="text-4xl sm:text-5xl font-bold text-white  xxs:px-2 md:px-[15%]">
              Avail Physiotherapy Services At Home In {` ${City}`} From Our Best
              Physiotherapists.
              <br />
            </h1>
            <p className="mt-4 xxs:mb-4 md:mb-0 text-lg blur-[0.5px] sm:text-xl text-white inline-block">
              TRUSTED BY 1000+ LOCALS
            </p>
            <div className="flex xxs:mb-4 md:mb-0  md:flex-row xxs:flex-col justify-center items-center px-[2%]">
              <div className="md:pl-[5%] md:pr-[2%] xxs:mb-4 md:mb-0  xxs:w-full md:w-2/3 xl:w-[55%] xxs:text-center md:text-left">
                <p className="text-lg sm:text-xl font-medium xxs:leading-relaxed md:leading-loose tracking-wider text-white">
                  {slug === "patna"
                    ? `Top-notch Physiotherapy services in Patna with highly skilled Physiotherapists. Restore your mobility and vitality through personalised treatment plans. Regain strength and well-being with expert care in the heart of Patna. Your journey to optimal health starts here.`
                    : slug === "guwahati"
                    ? `Receive effective physiotherapy in Guwahati from skilled physiotherapists at CasaMed. Restore your mobility and well-being with personalised treatments. Whether you're recovering from an injury or seeking preventive care, experience top-notch physiotherapy services in Guwahati that can enhance your quality of life.`
                    : slug === "chandigarh"
                    ? `Discover top-quality Physiotherapy in Chandigarh at our premier Physiotherapy centre. Our experienced Physiotherapists are dedicated to providing personalised care and effective treatments, helping you recover from injuries and regain mobility.`
                    : slug === "kolkata"
                    ? `In Kolkata, CasaMed's skilled Physiotherapists offer high-quality Physiotherapy services, catering to a wide range of needs. Their expertise in enhancing mobility, alleviating pain, and improving overall well-being makes Physiotherapy an essential part of the city's healthcare landscape. Explore the transformative benefits of Physiotherapy in Kolkata with our specialised practitioners.`
                    : slug === `delhi`
                    ? `Top-notch Physiotherapy services in Delhi at our renowned Casamed Physiotherapy Centre. Our skilled team of Physiotherapy experts is dedicated to providing personalised care and effective treatments, all aimed at helping you achieve optimal health and wellness. Book your appointment now to experience the transformative benefits of it.`
                    : `Our experts provide you care at your convenient time and place in ${City} ${
                        locationPage.stateInd === ""
                          ? "."
                          : `, ${locationPage.stateInd}.`
                      }`}
                </p>
              </div>
              <div className=" mb-4">
                <form
                  id="callback"
                  className="flex flex-col xxs:gap-1  sm:gap-2 justify-center items-center bg-white xxs:p-2 sm:p-4  rounded-lg drop-shadow-lg shadow-lg"
                  onSubmit={handleSubmit}
                >
                  <h2 className="sm:text-4xl xxs:text-2xl font-medium text-neutral-700 pt-4">
                    Ask for a CallBack
                  </h2>
                  <p className="xxs:px-2 font-thin xxs:text-sm sm:text-base">
                    100+ Expert Physiotherapists for 200+ Conditions
                  </p>
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Name"
                      className={`w-full appearance-none mt-4  rounded-lg shadow-md border  py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
                        nameError ? "border-red-500" : ""
                      }`}
                      value={name}
                      onChange={handleNameChange}
                    />
                    {nameError && (
                      <p className="text-red-500 text-xs pt-2 italic">
                        This field is required
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <input
                      type="text"
                      placeholder="Mobile Number"
                      className={`w-full appearance-none mt-4  rounded-lg shadow-md border py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
                        phoneNumberError ? "border-red-500" : ""
                      }`}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                    {phoneNumberError && (
                      <p className="text-red-500 text-xs pt-2 italic">
                        This field is required / incorrect
                      </p>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="mt-4 px-6 py-2 w-full bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                  >
                    Begin Your Recovery
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor:
              snackbarSeverity === "success" ? "#43a047" : "#d32f2f",
          }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {snackbarSeverity === "success" ? (
                <CheckCircle style={{ marginRight: "8px" }} />
              ) : (
                <Error style={{ marginRight: "8px" }} />
              )}
              {snackbarMessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <Close />
            </IconButton>,
          ]}
        />
      </Snackbar>

      <div className="pt-8 ">
        <Numbers />
      </div>

      <div className="py-16  text-center bg-casalight">
        <h2 className="text-4xl sm:text-5xl font-bold text-whyus leading-tight">
          <span className="decoration-[#02B9ED] underline underline-offset-8">
            A
          </span>
          bout Us
        </h2>

        <div className="flex flex-row-reverse  justify-center items-center">
          <div className="xxs:px-[8%] sm:px-[25%]">
            <p className="pt-8 pb-4 text-lg text-left">
              <div
                dangerouslySetInnerHTML={{
                  __html: locationPage.aboutUs.p1.replace(/\./g, ".<br/><br/>"),
                }}
              />
            </p>

            <p className="pb-4 text-lg text-left">
              <div
                dangerouslySetInnerHTML={{
                  __html: locationPage.aboutUs.p2.replace(/\./g, ".<br/><br/>"),
                }}
              />
            </p>

            <p className="pb-4 text-lg text-left">
              <div
                dangerouslySetInnerHTML={{
                  __html: locationPage.aboutUs.p3.replace(/\./g, ".<br/><br/>"),
                }}
              />
            </p>
          </div>
        </div>
      </div>

      <div className="backPtInLoc bg-center xxs:px-8 md:px-32 py-16">
        <div className="text-left xxs:pl-0  md:pt-0 md:mr-[30%]">
          <h2 className="text-4xl sm:text-5xl font-bold text-whyus leading-tight ">
            <span className="decoration-[#02B9ED] underline underline-offset-8">
              P
            </span>
            hysiotherapy in {` ${City}`}
            <br />
            <br />
          </h2>

          <div className="flex md:flex-row xxs:flex-col justify-center items-center xxs:pr-0 md:pr-[30%]">
            <div className="text-left">
              <p className="mt-4 text-lg sm:text-xl text-whyus">
                <div
                  dangerouslySetInnerHTML={{
                    __html: ptLOC1.charAt(0).toUpperCase() + ptLOC1.slice(1),
                  }}
                />
              </p>
              <p className="mt-4 text-lg sm:text-xl text-whyus">
                <div
                  dangerouslySetInnerHTML={{
                    __html: ptLOC2.charAt(0).toUpperCase() + ptLOC2.slice(1),
                  }}
                />
              </p>
              <p className="mt-4 text-lg sm:text-xl text-whyus">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      physioLoc3.charAt(0).toUpperCase() + physioLoc3.slice(1),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <PersonaStyled />
      </div>
      <div className="og--bg--grad--inv w-full">
        <motion.div
          variants={imageVariant}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
          className="md:py-32 xxs:py-28 xxs:px-6 md:px-22 "
        >
          <h2 className="text-center -mt-14 xxs:pb-10 md:pb-10 text-casashade2 md:text-5xl xxs:text-4xl font-bold md:px-20 xxs:px-6">
            <span className="decoration-[#02B9ED] underline underline-offset-8">
              W
            </span>
            hy CasaMed Physiotherapy in {`${City}`}
          </h2>

          <Box
            className="md:py-8 "
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <nav aria-label="main mailbox folders">
              <List className="xxs:grid xxs:grid-cols-1 md:grid md:grid-cols-2 justify-center items-center ">
                {locationPage.whys.map((why, index) => (
                  <ListItem key={index}>
                    <Link1 to={`/physiotherapy/${slug}#${why.id}`}>
                      <ListItemButton>
                        <ListItemIcon className="pr-6">
                          <img
                            src={why.img}
                            className="xxs:w-1/2 md:w-full"
                            alt=""
                          />
                        </ListItemIcon>
                        <ListItemText
                          className=""
                          primaryTypographyProps={{ style: primarysty1 }}
                          secondaryTypographyProps={{ style: secondarysty1 }}
                          primary={why.head}
                          secondary={why.text}
                        />
                      </ListItemButton>
                    </Link1>
                  </ListItem>
                ))}
              </List>
            </nav>
          </Box>

          <div className="text-casashade2 flex flex-col gap-24 pt-16">
            {locationPage.whys.map((why, index) => (
              <div
                key={index}
                id={why.id}
                className={`flex ${
                  index % 2 === 0
                    ? "xxs:flex-col md:flex-row-reverse"
                    : "xxs:flex-col md:flex-row"
                } justify-center gap-[12%]`}
              >
                <div className="flex justify-center items-baseline">
                  <img src={why.imgJPG} className="h-[600px] rounded-lg" />
                </div>
                <div className="xxs:w-full xxs:pt-[10%] md:pt-0 md:w-1/3 flex flex-col md:items-start md:justify-center xxs:items-center xxs:justify-center">
                  <h3 className="font-semibold xxs:text-xl md:text-2xl capitalize fheading mb-6">
                    <span className="underline underline-offset-4 decoration-[#02B9ED] mr-0.5">
                      {why.headActual[0]}
                    </span>
                    <span>{why.headActual.slice(1)}</span>
                  </h3>
                  <p className="xxs:text-base md:text-lg">{why.detailedDesc}</p>
                </div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
      <section className="bg-white xxs:px-[2%] md:pl-[10%] md:pr-[4%] xl:px-[16%] xxs:py-16 md:py-16 grid xxs:grid-cols-1  md:grid-cols-2 md:justify-between  w-full">
        <div className="flex">
          <h2
            style={{ lineHeight: "inherit" }}
            className={`xxs:text-4xl md:text-[50px]   text-whyus xxs:text-center md:text-left  font-semibold font-poppins xxs:pb-6  `}
          >
            <span className="decoration-[#02B9ED] underline underline-offset-8">
              H
            </span>
            ow to avail Physiotherapy at Home <br />
            in
            <br className="sm:block hidden" /> <strong>{`${City}`}?</strong>
          </h2>
        </div>

        <Box
          className=" "
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <nav aria-label="main mailbox folders">
            <List className="xxs:grid grid-cols-1 justify-center items-center">
              {locationPage.hows.map((how, index, id) => (
                <Flip direction="horizontal" cascade damping={0.5}>
                  <Link to={`/physiotherapy/${slug}#callback`}>
                    <ListItem
                      className="xxs:pb-2 md:pb-8"
                      key={index}
                      disablePadding
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <img
                            src={how.icon}
                            className="xxs:w-[80%] md:w-full"
                            index={id}
                            alt=""
                          />
                        </ListItemIcon>
                        <ListItemText
                          className="xxs:pl-6 md:px-6"
                          primaryTypographyProps={{ style: primarysty }}
                          secondaryTypographyProps={{ style: secondarysty }}
                          primary={how.head}
                          secondary={how.content}
                          sx={{ fontFamily: "Poppins" }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                </Flip>
              ))}
            </List>
          </nav>
        </Box>
      </section>

      {locationPage.slug === "patna" && (
        <section className="bg-white xxs:px-[2%] xl:px-[16%] xxs:py-16 md:py-16 grid-cols-1  w-full">
          <div className="flex justify-center items-center">
            <h2
              style={{ lineHeight: "inherit" }}
              className={`xxs:text-4xl md:text-[50px]  text-center text-whyus  font-semibold font-poppins xxs:pb-6  `}
            >
              <span className="decoration-[#02B9ED] underline underline-offset-8">
                C
              </span>
              asaMed's Physiotherapist in <strong>{`${City}`}?</strong>
            </h2>
          </div>

          <p className="py-2 text-lg text-whyus xxs:px-[2%] text-center md:px-[15%]">
            CasaMed takes great pride in stating that all our professionals are
            well-qualified with valid licences, extensive training, and a wealth
            of experience.
            <br />
            <br />
            They are dedicated to providing you with the utmost quality of
            Physiotherapy care available in Patna.
          </p>

          <div className="w-full flex xxs:flex-col md:flex-row justify-center items-center md:gap-x-8">
            {patnaPhysios.map((physio, id) => (
              <div
                key={id}
                className="relative flex w-96 flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-md"
              >
                <div className="relative mx-4 mt-4 h-80 overflow-hidden rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg">
                  <img src={physio.img} alt="profile-picture" />
                </div>
                <div className="p-6 text-center">
                  <h4 className="mb-2 block font-sans text-2xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                    {physio.name}
                  </h4>
                  <p className="block bg-gradient-to-tr from-pink-600 to-pink-400 bg-clip-text font-sans text-base font-medium leading-relaxed text-transparent antialiased">
                    {physio.degree}
                  </p>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="flex justify-center gap-7 p-6 pt-2">
        <a
          href="#facebook"
          className="block bg-gradient-to-tr from-blue-600 to-blue-400 bg-clip-text font-sans text-xl font-normal leading-relaxed text-transparent antialiased"
        >
          <i className="fab fa-facebook" aria-hidden="true"></i>
        </a>
        <a
          href="#twitter"
          className="block bg-gradient-to-tr from-light-blue-600 to-light-blue-400 bg-clip-text font-sans text-xl font-normal leading-relaxed text-transparent antialiased"
        >
          <i className="fab fa-twitter" aria-hidden="true"></i>
        </a>
        <a
          href="#instagram"
          className="block bg-gradient-to-tr from-purple-600 to-purple-400 bg-clip-text font-sans text-xl font-normal leading-relaxed text-transparent antialiased"
        >
          <i className="fab fa-instagram" aria-hidden="true"></i>
        </a>
      </div> */}
        </section>
      )}

      <div
        className={`${
          slug === "guwahati"
            ? "backlocation"
            : slug === "kolkata"
            ? "backlocationkol"
            : slug === "delhi"
            ? "backlocationdel"
            : slug === "bangalore"
            ? "backlocationbeng"
            : slug === "vizag"
            ? "backlocationviz bg-center"
            : slug === "chandigarh"
            ? "backlocationcha bg-center"
            : slug === "pune"
            ? "backlocationpune bg-center"
            : slug === "mumbai"
            ? "backlocationmum bg-center"
            : slug === "goa"
            ? "backlocationgoa bg-center"
            : slug === "patna"
            ? "backlocationpatna bg-center"
            : slug === "jaipur"
            ? "backlocationjaipur bg-top"
            : ""
        } py-16`}
      >
        <h2 className="text-4xl sm:text-5xl font-bold text-white  tracking-wide text-center pt-16">
          <span className="decoration-[#02B9ED] underline underline-offset-8">
            A
          </span>
          reas we Service
        </h2>
        <div className="flex xxs:flex-col md:flex-row xxs:px-[8%] md:px-[10%] py-16 justify-center items-center">
          <div className="flex justify-center items-center">
            <div
              className={`grid xxs:grid-cols-1 ${
                slug === "guwahati" ||
                slug === "bangalore" ||
                slug === "pune" ||
                slug === "patna" ||
                slug === "jaipur"
                  ? "md:grid-cols-3"
                  : slug === "kolkata" || slug === "mumbai" || slug === "goa"
                  ? "md:grid-cols-2"
                  : slug === "vizag" || slug === "chandigarh"
                  ? "md:grid-cols-1"
                  : ""
              }  xxs:gap-4 md:gap-x-16 md:gap-y-8 justify-start items-center `}
            >
              {locationPage.serviceAreas.map((serviceArea, index) => (
                <div key={index} className="flex items-center gap-4">
                  <img src={serviceArea.locIcon} />

                  <div
                    className={`text-xl text-white xxs:font-semibold md:font-medium`}
                  >
                    {serviceArea.area}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div id="serviceAreaImage" className='md:w-1/2 xxs:hidden md:visible flex justify-center items-end'>
                <img src={locationPage.serviceAreaImg} className="rounded-lg xxs:h-0 md:h-[900px]"/>
            </div> */}
        </div>
      </div>

      <div className="xxs:pt-20 xxs:pb-8 md:pt-32 md:pb-16">
        <>
          <div className="tracking-wide flex justify-center items-center">
            {/* <h2 style={{lineHeight:1.4}} className='xxs:text-3xl md:text-6xl  max-w-4xl mx-auto  font-semibold text-center xxs:pb-8 md:pb-8 text-casashade2 drop-shadow-lg'>
            <span className="decoration-[#02B9ED] underline underline-offset-8">U</span>ser Testimonials
        </h2> */}
          </div>
          <div className="w-full">
            <TestimonialRevamp2 />
          </div>
        </>
      </div>

      <div className="pt-32 pb-16">
        <div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
          <div className="flex flex-col items-center">
            <h2 className="font-bold text-whyus text-5xl mt-5 tracking-tight">
              FAQ
            </h2>
            <p className="text-whyus text-opacity-70 text-xl mt-3">
              Frequenty asked questions
            </p>
          </div>
          <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
            {locationPage.faqs.map((faq, index) => (
              <div className="xxs:py-5 md:py-10 " key={index}>
                <details className="group">
                  <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                    <span className="text-xl text-whyus"> {faq.question}</span>
                    <span className="transition group-open:rotate-180">
                      <svg
                        fill="none"
                        height="24"
                        shapeRendering="geometricPrecision"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        width="24"
                      >
                        <path d="M6 9l6 6 6-6"></path>
                      </svg>
                    </span>
                  </summary>
                  <p className="text-whyus text-opacity-70 text-lg mt-3 group-open:animate-fadeIn">
                    {faq.answer}
                  </p>
                </details>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="og--bg--grad--inv">
        <div className="">
          <section className="xxs:px-6 md:px-16 pb-16">
            <div className="text-center pb-16">
              <h2 className="font-bold text-blue-400 text-4xl">Contact us</h2>

              <h3 className="mt-2 text-2xl font-semibold  md:text-3xl text-whyus">
                We’d love to hear from you
              </h3>

              <p className="mt-3 text-gray-500 ">Write to our support team.</p>
            </div>
            <div className="container px-6 py-8 mx-auto bg-[url('https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100')] bg-no-repeat bg-cover  ">
              {/* <div className="text-center ">
            <p className="font-bold text-blue-400 text-3xl">Contact us</p>

            <h1 className="mt-2 text-2xl font-semibold  md:text-3xl text-whyus">We’d love to hear from you</h1>

            <p className="mt-3 text-gray-500 ">Chat to our friendly team.</p>
        </div> */}

              {/* <img className="object-cover w-full h-64 mt-10 rounded-lg lg:h-96" src="https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100" alt="" /> */}

              <div className="flex md:flex-row xxs:flex-col justify-evenly items-center ">
                <div className="grid grid-cols-1 gap-12 mt-10 xxs:w-full xxs:text-center md:text-left md:w-[21%] ">
                  {locationPage.slug === "patna" && (
                    <div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
                      <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                          />
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                          />
                        </svg>
                      </span>

                      <h2 className="mt-4 text-base font-medium text-gray-800 ">
                        Visit us
                      </h2>
                      <p className="mt-2 text-sm text-gray-500 ">
                        Visit our office HQ..
                      </p>
                      <a
                        className="mt-2 text-sm text-blue-500 "
                        href={locationPage.contact.address.toString()}
                      >
                        1st Floor, Krishna Aangan, 38 Pillar, Aashiyan More,
                        Bailey Rd, near to PARAS HMRI, opp. Punjab National
                        Bank, Raja Bazar, Patna, Bihar 800014
                      </a>
                    </div>
                  )}

                  <div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
                    <a href={locationPage.contact.number.toString()}>
                      <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                          />
                        </svg>
                      </span>
                    </a>

                    <h2 className="mt-4 text-base font-medium text-gray-800 ">
                      Call us
                    </h2>
                    <p className="mt-2 text-sm text-gray-500 ">
                      Mon-Fri from 8am to 5pm.
                    </p>
                    <a
                      className="mt-2 text-sm text-blue-500 "
                      href={locationPage.contact.number.toString()}
                    >
                      033-40585240
                    </a>
                  </div>

                  <div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
                    <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>
                    </span>

                    <h2 className="mt-4 text-base font-medium text-gray-800 ">
                      Chat to sales
                    </h2>
                    <p className="mt-2 text-sm text-gray-500 ">
                      Write to our friendly team.
                    </p>
                    <a
                      className="mt-2 text-sm text-blue-500 "
                      href="mailto:support@casamed.in"
                    >
                      support@casamed.in
                    </a>
                  </div>
                </div>

                <div className="md:w-1/3 xxs:w-full">
                  <form
                    id="callback"
                    className="xxs:mt-4 sm:mt-8 flex flex-col xxs:gap-1  sm:gap-2 justify-center items-center bg-blue-50 xxs:p-2 sm:p-6  rounded-lg drop-shadow-lg shadow-lg xxs:text-center"
                    onSubmit={handleSubmit}
                  >
                    <h2 className="sm:text-4xl xxs:text-2xl font-medium text-neutral-700 pt-4">
                      Ask for a CallBack
                    </h2>
                    <p className="font-thin xxs:text-sm sm:text-base px-[10%] text-center py-3 ">
                      The Best Physiotherapy Home Care Service In {City} With
                      Experienced Physiotherapists is here to help you!
                    </p>
                    <p className="font-thin xxs:text-sm sm:text-base ">
                      Feel free to call us right now or fill out the form to get
                      a call back!
                    </p>
                    <input
                      type="text"
                      placeholder="Name"
                      className="mt-4 px-4 w-full py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
                      value={name}
                      onChange={handleNameChange}
                    />
                    <input
                      type="text"
                      placeholder="Mobile Number"
                      className="mt-4 px-4 py-2 w-full rounded-lg shadow-md focus:outline-none focus:shadow-outline"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                    {/* <input
              type="text"
              placeholder="Location"
              className="mt-4 px-4 py-2 w-full rounded-lg shadow-md focus:outline-none focus:shadow-outline"
            /> */}
                    <button
                      type="submit"
                      className="mt-4 px-6 py-2 w-full bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    >
                      Begin Your Recovery
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

        <h2 className="xxs:pt-8 md:pt-16 text-4xl text-center sm:text-5xl font-bold text-whyus leading-tight">
          Our{" "}
          <span className="decoration-[#02B9ED] underline underline-offset-8">
            P
          </span>
          resence
        </h2>
        <br />
        <div className="flex xxs:flex-col gap-4 md:flex-row justify-center items-center flex-wrap pt-8">
          {locationPage.presence.map((pres, idx) => (
            <div
              key={idx}
              className="flex flex-col justify-center items-center"
            >
              <Link
                to={`/physiotherapy/${pres.city.toLowerCase()}`}
                target="_blank"
              >
                <Avatar
                  imgProps={{ style: { borderRadius: "50%" } }}
                  src={pres.photoLink}
                  style={{
                    width: 110,
                    height: 110,
                    border: "1px solid lightgray",
                    padding: 1,
                    marginBottom: 0,
                  }}
                />
              </Link>
            </div>
          ))}
        </div>

        <Footer />
      </div>
    </div>
  );
}

export default LocationPage;
