import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import data from "./Utils/data.json";

import ScrollToTop from "./Utils/scrollToTop";
import Home from "./Pages/Home";
import Product from "./Pages/Product";
import NavTest from "./Pages/NavTest";

import About from "./Pages/About";

import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsCond from "./Pages/TermsCond";
import TermsCondCP from "./Pages/TermsCondCP";
import Pricing from "./Pages/Pricing";
import Contact from "./Pages/Contact";
import HomeVisits from "./Pages/HomeVisits";
import Arthiritis from "./Pages/Arthiritis";
import PCOD from "./Pages/PCOD";
import BlogTest from "./Views/BlogViews/BlogTest";
// import PartnerTest from './Pages/PartnerTest';

// import PartnerPageNew from "./Pages/PartnerTest2/PartnersPageNew";
import PartnersPageDesigned from "./Pages/PartnerTest2/PartnersPageDesigned";
import Test2Partner from "./Pages/PartnerTest2/Test2Partner";

import PartnerProfilePageNew from "./Pages/PartnerTest2/PartnerProfilePageNew";
// import LiveSessionForm from "./Pages/LiveSessionForm";
import SingleBlog from "./Views/BlogViews/singleBlog";
// import { Testcountry } from './Pages/Testcountry';

import JointPainTherapy from "./Pages/JointReplacement";
import GolfersElbowTreatment from "./Pages/GolfersElbow";
import BestOrthopedicSurgeon from "./Pages/BestOrthoSurgeon";
import RehabilitationTherapy from "./Pages/RehabTreatment";
import PhysicalTherapyTreatment from "./Pages/PhysicalTherapyTreatments";
import BeatThePain from "./Pages/BeatThePain";
import MusclePainTherapy from "./Pages/MuscleTherapy";
import PainManagementTreatment from "./Pages/PainManagement";
import PainReliefTreatment from "./Pages/PainRelief";
import OccupationalTherapyTreatment from "./Pages/OccupationalTherapy";

import TeamSingle from "./Views/AboutViews/TeamSingle";
// import TeamTest from "./Views/AboutViews/Teamtest";
// import MentorsTest from "./Views/AboutViews/MentorsTest";
import MentorSingle from "./Views/AboutViews/MentorSingle";
import EditorialGuidelines from "./Pages/EditorialGuidelines";

// import TestFAQ from './Pages/TestFAQ';

import LocationPage from "./Pages/LocationPage";
import LocPageRheumat from "./Pages/LocPageRheumat";
import CerebralPalsy from "./Pages/CerebralPalsy";
import Physiotherapy from "./Pages/Physiotherapy";
import Rheumatology from "./Pages/Rheumatology";

import ArthritisBlog from "./Pages/ArthritisBlog";
// import TestRevamp from './Components/TestimonialRevamp';
// import TestRevamp2 from "./Components/TestimonialRevamp2";
// import Carousel from "./Carousel";
// import PartnersRevampedTest from "./Pages/PartnerTest2/PartnersRevampedTest";
import Spinal from "./Pages/Spinal";
import Hemiplegia from "./Pages/Hemiplegia";
import Autism from "./Pages/Autism";
import OccupationalTherapyLoc from "./Pages/OccupationalTherapyLoc";
import KneePain from "./Pages/KneePain";
import Paralysis from "./Pages/Paralysis";
import BestOrthoDoc from "./Pages/BestOrthoDoc";
import Cervical from "./Pages/Cervical";
import InterferentialTreatment from "./Pages/InterferentialTreatment";
import ShoulderPain from "./Pages/ShoulderPain";
import BackPain from "./Pages/BackPain";
import LaserTherapy from "./Pages/LaserTherapy";
import PainManagement from "./Pages/PainManagementLoc";
import City from "./Pages/City";
import LocationDropdown from "./Pages/LocationDropdown";
// import RoomPage from "./Pages/room";

// import ContactForm2  from './Pages/TestCountry3';

// import Nav from './Views/Nav'

// import AgoraAppBuilder from "@appbuilder/react";
import Participants from "./Components/Participant";
import Brand from "./Pages/TATA_1MG/Brand";
import Test from "./Pages/TATA_1MG/test";
import CasaDoc from "./Pages/CasaDoc";
import { PCODPatna } from "./Pages/PCODPatna";

// import { renderToStaticMarkup } from 'react-dom/server';

// const html = renderToStaticMarkup(<Brand />);
// console.log(html);

const App = () => {
	// const specialDoctorId = '619259c0b643a263ac784e90';
	// const specialDoctorSlug = 'specialised-orthopedic-doctor-of-hand-and-wrist';
	// const location = useLocation();
	// const history = useNavigate();

	// useEffect(() => {
	//   const id = location.pathname.split('/').pop();
	//   const isSpecialDoctor = id === specialDoctorId;

	//   if (isSpecialDoctor) {
	//     const syntheticUrl = `/doctor/${specialDoctorSlug}`;
	//     history.replace(syntheticUrl);
	//   }
	// }, [location.pathname, history, specialDoctorId, specialDoctorSlug]);

	// const loadScript = (src) => {
	//   return new Promise((resolve) => {
	//     const script = document.createElement("script");
	//     script.src = src;
	//     script.onload = () => {
	//       resolve(true);
	//     };
	//     script.onerror = () => {
	//       resolve(false);
	//     };
	//     document.body.appendChild(script);
	//   });
	// };

	// useEffect(() => {
	//   loadScript("https://checkout.razorpay.com/v1/checkout.js");
	// });

	return (
		<div className="w-full">
			<Router>
				<ScrollToTop />
				<Routes>
					{/* <Route path="/stream-test" element={<AgoraAppBuilder.View />} /> */}
					<Route
						exact
						path="/"
						element={<Home />}
					/>
					{/* <Route exact path = "/contacttest" element={<ContactUs />} /> */}
					<Route
						exact
						path="/product"
						element={<Product />}
					/>
					<Route
						exact
						path="/casadoc"
						element={<CasaDoc />}
					/>
					<Route
						exact
						path="/blog"
						element={<BlogTest />}
					/>
					{/* <Route exact path="/about/team" element={<TeamTest />} /> */}
					{/* <Route exact path="/about/mentors" element={<MentorsTest />} /> */}
					<Route
						exact
						path="/about/mentors/:slug"
						element={<MentorSingle />}
					/>
					<Route
						exact
						path="/about/team/:slug"
						element={<TeamSingle />}
					/>
					<Route
						exact
						path="/blog/:slug"
						element={<SingleBlog />}
					/>
					<Route
						exact
						path="/joint-pain-therapy"
						element={<JointPainTherapy />}
					/>
					<Route
						exact
						path="/golfers-elbow-treatment"
						element={<GolfersElbowTreatment />}
					/>
					<Route
						exact
						path="/best-orthopedic-surgeon"
						element={<BestOrthopedicSurgeon />}
					/>
					<Route
						exact
						path="/rehabilitation-therapy"
						element={<RehabilitationTherapy />}
					/>
					<Route
						exact
						path="/home-rehabilitation-services"
						element={<HomeVisits />}
					/>
					<Route
						exact
						path="/physical-therapy-treatment"
						element={<PhysicalTherapyTreatment />}
					/>
					<Route
						exact
						path="/muscle-pain-therapy"
						element={<MusclePainTherapy />}
					/>
					<Route
						exact
						path="/physiotherapy-treatment"
						element={<BeatThePain />}
					/>
					<Route
						exact
						path="/pain-management-treatment"
						element={<PainManagementTreatment />}
					/>
					<Route
						exact
						path="/brand"
						element={<Brand />}
					/>
					{/* <Route exact path="/testT" element={<Test />} /> */}
					<Route
						exact
						path="/pain-relief-treatment"
						element={<PainReliefTreatment />}
					/>
					<Route
						exact
						path="/occupational-therapy-treatment"
						element={<OccupationalTherapyTreatment />}
					/>
					<Route
						exact
						path="/about"
						element={<About />}
					/>
					<Route
						exact
						path="/physiotherapy"
						element={<Physiotherapy />}
					/>
					<Route
						exact
						path="/arthritis/rheumatoid"
						element={<Rheumatology />}
					/>
					{/* <Route exact path = "/login" element={<Login />} /> */}
					<Route
						exact
						path="/privacypolicy"
						element={<PrivacyPolicy />}
					/>
					<Route
						exact
						path="/termsconditions"
						element={<TermsCond />}
					/>
					<Route
						exact
						path="/termsconditions/channelpartner"
						element={<TermsCondCP />}
					/>
					<Route
						exact
						path="/pricing"
						element={<Pricing />}
					/>
					<Route
						exact
						path="/location-test"
						element={<LocationDropdown data={data} />}
					/>
					<Route
						exact
						path="/contactus"
						element={<Contact />}
					/>
					<Route
						exact
						path="/patna/pcod-treatment"
						element={<PCODPatna />}
					/>
					<Route
						exact
						path="/homevisits"
						element={<HomeVisits />}
					/>
					<Route
						exact
						path="/arthiritis-therapy"
						element={<Arthiritis />}
					/>
					<Route
						exact
						path="/pcod"
						element={<PCOD />}
					/>
					{/* <Route exact path = "/blogtest" element={<BlogTest />} /> */}
					{/* <Route exact path = "/partnertest" element={<PartnerTest />} /> */}
					{/* <Route exact path = "/partners" element={<PartnerTest2 />} /> */}
					{/* <Route exact path="/partners" element={<PartnerPageNew />} /> */}
					<Route
						exact
						path="/partners"
						element={<Test2Partner />}
					/>
					{/* <Route exact path="/live-session/join" element={<Participants />} /> */}

					{/* <Route path="/room/:roomId" element={<RoomPage />} /> */}
					{/* <Route exact path = "/testcountry2" element={<TestCountry2 />} /> */}
					<Route
						exact
						path="/editorial-policy"
						element={<EditorialGuidelines />}
					/>

					<Route
						exact
						path="/arthritis"
						element={<ArthritisBlog />}
					/>
					<Route
						exact
						path="/test-price"
						element={<Test />}
					/>
					{/* <Route exact path = "/testrevamp" element={<TestRevamp />} /> */}
					{/* <Route exact path = "/testrevamp2" element={<TestRevamp2 />} /> */}
					{/* <Route exact path = "/carousel" element={<Carousel />} /> */}

					<Route
						exact
						path="/:slug"
						element={<City />}
					/>
					<Route
						exact
						path="/physiotherapy/:slug"
						element={<LocationPage />}
					/>
					<Route
						exact
						path="/arthritis/rheumatoid/:slug"
						element={<LocPageRheumat />}
					/>
					<Route
						exact
						path="/:slug/cerebral-palsy"
						element={<CerebralPalsy />}
					/>
					<Route
						exact
						path="/:slug/spinal"
						element={<Spinal />}
					/>
					<Route
						exact
						path="/:slug/hemiplegia"
						element={<Hemiplegia />}
					/>
					<Route
						exact
						path="/:slug/autism"
						element={<Autism />}
					/>
					<Route
						exact
						path="/:slug/occupational-therapy"
						element={<OccupationalTherapyLoc />}
					/>
					<Route
						exact
						path="/:slug/knee-pain"
						element={<KneePain />}
					/>
					<Route
						exact
						path="/:slug/paralysis"
						element={<Paralysis />}
					/>
					<Route
						exact
						path="/:slug/orthopaedic"
						element={<BestOrthoDoc />}
					/>
					<Route
						exact
						path="/:slug/cervical"
						element={<Cervical />}
					/>
					<Route
						exact
						path="/:slug/ift-therapy"
						element={<InterferentialTreatment />}
					/>
					<Route
						exact
						path="/:slug/shoulder-pain"
						element={<ShoulderPain />}
					/>
					<Route
						exact
						path="/:slug/back-pain"
						element={<BackPain />}
					/>
					<Route
						exact
						path="/:slug/laser-therapy"
						element={<LaserTherapy />}
					/>
					<Route
						exact
						path="/:slug/pain-management"
						element={<PainManagement />}
					/>
					{/* <Route exact path = "/navtest" element={<NavTest />} /> */}
					{/* <Route exact path = "/test-faq" element={<TestFAQ />} /> */}

					{/* <Route exact path = "/testcountry3" element={<ContactForm2 />} /> */}

					{/* <Route exact path="/doctor/:id" element={<PartnerProfile />} /> */}
					<Route
						exact
						path="/doctor/:slug"
						element={<PartnerProfilePageNew />}
					/>
					{/* <Route exact path="/partnerstest" element={<PartnersPageDesigned />} /> */}
					<Route
						exact
						path="/physio/:slug"
						element={<PartnerProfilePageNew />}
					/>
					{/* <Route exact path="/partnerstest" element={<PartnersRevampedTest />} /> */}
					{/* <Route exact path="/physio/:id" element={<PartnerProfile />} /> */}
					{/* <Route exact path="/about/team/susman" element={<TeamSingle />} /> */}
				</Routes>
			</Router>
		</div>
	);
};

export default App;
