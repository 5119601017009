import React, { useState, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid, Card, CardActionArea, CardMedia, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(4)
    },
    header: {
      fontWeight: "bold",
      marginBottom: theme.spacing(2),
      opacity: "70%",
      fontSize: '24px'
    },
    grid: {
      display: "flex",
      overflowX: "auto",
      alignItems: "stretch",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
      },
        
    //   justifyContent: "flex-start",
      flexWrap: "nowrap",
    //   height: 290

      
    },
    card: {
      maxWidth: 345,
      height: 280, 
      flex: "0 0 auto",
      margin: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
    },
    media: {
      height: 140,
    },
    content: {
      flex: "1 0 auto",
    },
    body: {
      display: "-webkit-box",
      boxOrient: "vertical",
      lineClamp: 4,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }));


  
  const RelatedBlogs = () => {
  const {slug} = useParams()
  const classes = useStyles();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true);
    axios.get(`/api/blogs`).then((response) => {
      const relatedBlogs = response.data.data.filter((blog) => blog.slug !== slug).sort(() => Math.random() - 0.5).slice(0, 4);
      console.log(relatedBlogs)
      setBlogs(relatedBlogs);
      setLoading(false);
    });
  }, [slug]);

  if (loading) {
    return <p>Loading...</p>;
  }


  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.header}>
        Related Blogs
      </Typography>
      
      <Grid  container spacing={0} className={classes.grid}>
        {blogs.map((blog, slug) => (
          <Grid key={slug}>
            <Card className={classes.card}>
              <CardActionArea component={Link} to={`/blog/${blog.slug}`} target="_blank" rel="noopener noreferrer">
                <CardMedia
                  className={classes.media}
                  image={blog.photo}
                  title={blog.title}
                />
                <CardContent className={classes.content}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {blog.title}
                  </Typography>
                  {/* <Typography variant="body2" color="textSecondary" component="p" className={classes.body}>
                    {blog.body}
                  </Typography> */}
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Link to={`/blog`}>
        <Typography variant="caption" display="block" align="right" style={{marginTop: "6px"}}>
          See More <ArrowForwardIosIcon fontSize="small" />
        </Typography>
      </Link>
    </div>
  );
};

export default RelatedBlogs;
