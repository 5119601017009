import { useState, useEffect } from "react";

import ut1 from "../Assets/HomeViews/Testimonials/ut1.webp";
import ut2 from "../Assets/HomeViews/Testimonials/ut2.webp";
import ut3 from "../Assets/HomeViews/Testimonials/ut3.jpg";
import ut4 from "../Assets/HomeViews/Testimonials/ut4.jpg";

const TestimonialRevamp2 = () => {
  const testimonials = [
    {
      avatar: ut1,
      name: "Arnav Anshuman",
      title: "Sports Injury",
      quote:
        "The detailed enquiry on the various facets of pain and bodily reactions is exceptional. One can be as specific about their concern and get immaculate responses for their issue",
    },
    {
      avatar: ut2,
      name: "Vishnu Kumari",
      title: "Arthiritis - Chronic Pain",
      quote:
        "Casamed proved to be really useful with expert consultation, Audio and video assisted rehabilitation and reminder notification to ensure adherence to care regime. With continued efforts, I was able to gain full functional mobility and pain relief",
    },
    {
      avatar: ut3,
      name: "Venkatesan",
      title: "Posture Issues",
      quote:
        "My Strenous schedule leaves little to desire in terms of physical pain management. Casamed solves this problem and how! I can folow my personalized plan, even when at work at my convenience!",
    },
    {
      avatar: ut4,
      name: "Anjali Kalita",
      title: "Post Surgical Rehab (Hip Replacement Surgery)",
      quote:
        "Detailed questionnaire to diagnose the problem, fluidic navigation and expert advice. Prompt feedback and Physio support!",
    },
  ];

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonial(
        (prevIndex) => (prevIndex + 1) % testimonials.length
      );
    }, 5000); // Change the interval duration (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [testimonials.length]);

  return (
    <section className="py-14">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h3 className=" text-casashade2 font-semibold xxs:text-3xl md:text-5xl pb-12">
            What people are saying
          </h3>
          <div
            className={`testimonial-container   ${
              currentTestimonial === 1
                ? `xxs:h-[380px] md:h-[320px]`
                : currentTestimonial === 2
                ? `xxs:h-[330px] md:h-[290px]`
                : currentTestimonial === 0
                ? `xxs:h-[330px] md:h-[270px]`
                : `xxs:h-[260px]`
            }`}
          >
            {testimonials.map((item, idx) => (
              <div
                key={idx}
                className={`testimonial-slide ${
                  currentTestimonial === idx ? "active" : ""
                }`}
              >
                <figure>
                  <blockquote className="xxs:pt-20 md:pt-16">
                    <p className="text-casashade2 text-xl font-regular sm:text-2xl">
                      “{item.quote}“
                    </p>
                  </blockquote>
                  <div className="mt-6">
                    <img
                      src={item.avatar}
                      className="w-16 h-16 mx-auto rounded-full"
                      alt="Avatar"
                    />
                    <div className="mt-3">
                      <span className="block text-gray-800 font-semibold">
                        {item.name}
                      </span>
                      <span className="block text-gray-600 text-sm mt-0.5">
                        {item.title}
                      </span>
                    </div>
                  </div>
                </figure>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-6">
          <ul className="flex gap-x-3 justify-center">
            {testimonials.map((item, idx) => (
              <li key={idx}>
                <button
                  className={`w-2.5 h-2.5 rounded-full duration-150 ring-offset-2 ring-indigo-600 focus:ring ${
                    currentTestimonial === idx ? "bg-indigo-600" : "bg-gray-300"
                  }`}
                  onClick={() => setCurrentTestimonial(idx)}
                ></button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TestimonialRevamp2;
