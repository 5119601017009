import { Flip, Fade } from 'react-awesome-reveal'
import { Link } from 'react-router-dom';
import monitor from '../../Assets/HomeViews/How/monitor.svg';
import value from '../../Assets/HomeViews/How/value.svg';
import transparency from '../../Assets/HomeViews/How/transparency.svg';
import ai from '../../Assets/HomeViews/How/ai.svg';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const primarysty = {
    color: "#024461",
    fontWeight: "700",
    fontSize: "17px",
    fontFamily: "Poppins",    

}
const secondarysty = {
    color: "#024461",
    fontWeight: "400",
    fontSize: "15px",
    fontFamily: "Poppins",
    whiteSpace: "pre-line",

}

const howitems = [
    {
      id: 1,
      icon: monitor,
      head: "Patient Monitoring",
      content1:"PT-grade automated feedback delivered to your smartphone.",
      content2: "",
    },
    {
      id: 2,
      icon: value,
      head: "Enhancing Value",
      content1:"Connecting Patients with top medical experts for guided pain relief. Integrates with leading medical providers",
      
    },
    {
      id: 3,
      icon: transparency,
      head: "Transparency",
      content1: "Multiple touch points throughout the pain relief journey.",
      
    },
    {
      id: 4,
      icon: ai,
      head: "AI Physio Assist",
      content1: "Computer Vision enabled Posture Correcting AI.",
      
    },
  ];
  


const How = () => {
  return (
    <section className="bg-white  xxs:px-[2%] md:pl-[10%] md:pr-[4%] xl:px-[16%] xxs:py-16 md:py-16 grid xxs:grid-cols-1  md:grid-cols-2 md:justify-between  ">
        <Fade direction="left" triggerOnce>
        <div className="flex">
            <h2 className={`text-[50px]   text-whyus xxs:text-center md:text-left font-semibold font-poppins xxs:pb-6  `}>
                <span className='decoration-[#02B9ED] underline underline-offset-8'>H</span>ow is CasaMed 
                <br className="sm:block hidden" /> <strong>different?</strong>
            </h2>
        </div>
        </Fade>

     
    <Box className=' ' sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
    <nav aria-label="main mailbox folders">
        <List className='xxs:grid grid-cols-1 justify-center items-center'>
        {howitems.map((howitem, id) =>(
        <Flip direction="horizontal" cascade damping={0.5}>
          <Link
            to="/product">
        <ListItem className="xxs:pb-2 md:pb-8" key={howitem.id} disablePadding >
            <ListItemButton>
            <ListItemIcon >
                <img src={howitem.icon} className="xxs:w-[80%] md:w-full" index={id} alt=""/>
            </ListItemIcon>
            <ListItemText  className='xxs:pl-6 md:px-6' 
                primaryTypographyProps={{ style: primarysty }} 
                secondaryTypographyProps={{ style: secondarysty }} 
                primary={howitem.head}
                secondary={howitem.content1}  
                sx={{fontFamily: 'Poppins'}}
                
            />
            </ListItemButton>
        </ListItem>
        </Link>
        </Flip>
        ))}
        
        
        
        </List>
    </nav>



    </Box>
    
    </section>
  )
};

export default How;

