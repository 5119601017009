import React from 'react';
import bannergirl from "../../Assets/BlogViews/BlogHead/bloghead.png"
import whatwedo from "../../Assets/BlogViews/BlogHead/whatwedo.png"
import casablog from "../../Assets/BlogViews/BlogHead/casablog.svg"



function BlogHead(){
  return (
    <section className='py-0 px-8 bg-blogbanner'>
        <div className='flex flex-col justify-center items-center'>
            <div className='flex justify-center'>
                <img src={casablog} className="pt-4 xxs:w-[80%] md:w-full xxs:pb-4 md:pb-0" alt="" />
            </div>

            <div className='flex xxs:flex-col md:flex-row justify-around items-center xxs:px-0 md:px-16'>
                <div className='flex justify-center items-center xxs:w-full md:w-1/2'>
                <img src={whatwedo} className=" xxs:w-[60%] md:w-[80%]" alt="" />
                </div>
                <div className='flex justify-start items-start xxs:pt-4 md:pt-0'>
                <img src={bannergirl} className="xxs:pr-16 " alt="" />
                </div>
            </div>

        </div>
        
    </section>
  )
}

export default BlogHead