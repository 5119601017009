import React, { useEffect, useState } from "react";
import casasvg from "../../Assets/casamed.svg";
import { Helmet } from "react-helmet";

import { ContactPhoneOutlined } from "@material-ui/icons";

import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Image,
  Card,
  Form,
  InputGroup,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import axios from "axios";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import ProfileIcon from "../../Assets/ProfileIcon.webp";
import styles from "./PartnerProfile.module.css";
import { Icon } from "semantic-ui-react";
import CasamedLogo from "../../Assets/HomeViews/Logo/logo.svg";
import cx from "classnames";
import { apiUrl } from "../../config";
import { FaWhatsapp, FaPhone } from "react-icons/fa";

import { Button as Buttonmui } from "@mui/material";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const PartnerProfile = (props) => {
  const { slug } = useParams();
  const history = useNavigate();
  const user = JSON.stringify(localStorage.getItem("user"));

  const [edit, setEdit] = useState(false);
  const [data, setData] = useState(undefined);

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const partner = window.location.pathname.split("/")[1];

  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    userPhone: "",
  });

  const [selectedItem, setSelectedItem] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [stateSnackbar, setStateSnackbar] = React.useState({
    openS: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openS } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenMuiModal = (item) => {
    setOpen(true);
    setSelectedItem(item);
  };
  const handleCloseMuiModal = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmitMuiDialog = async () => {
    if (!selectedItem) {
      console.error("No partner selected!");
      return;
    }

    try {
      const payload = {
        partnerName: selectedItem.name,
        partnerCity: selectedItem.city,
        userName: userData.userName,
        userPhone: userData.userPhone,
      };

      const response = await axios.post("/api/partnerPageForm", payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
          auth: {
            user: JSON.stringify(localStorage.getItem("user")),
          },
        },
      });

      if (response.status === 201) {
        console.log(
          "Form submitted successfully:",
          response.data,
          response.status
        );
        // Close the modal
        handleCloseMuiModal();
        // Clear the user data form fields
        setUserData({
          userName: "",
          userPhone: "",
        });
        setSnackbarMessage("Form submitted successfully!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Failed to submit form.");
        setAlertSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while submitting the form.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // console.log(partner)
  // console.log(id)

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`/api/${partner}Pages/${slug}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        })
        .then((res) => {
          setData(res?.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
          console.log(error);
        });
    };
    getData();
  }, [edit]);

  //   console.log(data)

  //   history.push(doctorUrl)

  return (
    <div className="og--bg--grad">
      <div className="xxs:pb-8 md:pb-16">
        <Nav />
      </div>
      <Helmet>
        <title>Partners | CasaMed</title>
        <meta
          name="description"
          content="At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="physiotherapy treatment for back pain, physiotherapy for back pain, physiotherapy treatment for low back pain, therapy for lower back pain, back pain therapy, physiotherapy treatment"
        />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href={`https://casamed.in/${partner}Pages/${slug}`}
        />
        <meta name="robots" content="noindex" />

        {/* Open Graph */}
        <meta property="og:title" content="Partners | CasaMed" />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content={`https://casamed.in/${partner}Pages/${slug}`}
        />
        <meta
          property="og:description"
          content="At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Partners | CasaMed",
            url: `https://casamed.in/${partner}Pages/${slug}`,
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail.",
          })}
        </script>
      </Helmet>
      <meta name="robots" content="noindex" />
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      {loading && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            zIndex: "99999999",
          }}
        >
          <Spinner
            animation="border"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        </div>
      )}
      {data && (
        <main className="profile-page  xxs:px-[5%] md:px-[15%] ">
          <div className=" flex  flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg ">
            <div
              className="relative rounded-t-lg w-full xxs:h-[300px] md:h-[500px] bg-center bg-cover"
              style={{
                backgroundImage:
                  "url('https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Location%20Pages%2FGuwahati%2Fguwahati_banner.jpg?alt=media&token=b7d93744-04af-42c2-80c2-99252091ea75')",
              }}
            >
              <span
                id="blackOverlay"
                className="w-full h-full  opacity-50 bg-black"
              ></span>
            </div>
            <div className="px-6">
              <div className="flex flex-wrap justify-center">
                <div className="w-full absolute -mt-20 px-4 lg:order-2 flex justify-center">
                  <div className="">
                    <img
                      alt="..."
                      src={data.photoUrl}
                      className=" shadow-xl object-cover  rounded-full h-[125px] w-[125px] align-middle "
                      style={{}}
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-20">
                <h3 className="text-4xl font-semibold leading-normal mb-4 text-gray-800 ">
                  {data.name}
                </h3>

                <Buttonmui
                  sx={{
                    letterSpacing: "1.5px",
                  }}
                  variant="contained"
                  endIcon={<ContactPhoneOutlined />}
                  onClick={() => handleOpenMuiModal(data)}
                >
                  Contact Partner
                </Buttonmui>
                {/* Modal */}
                <Dialog
                  open={open}
                  onClose={handleCloseMuiModal}
                  className="my-dialog"
                >
                  <DialogTitle>Contact Partner</DialogTitle>
                  <DialogContent sx={{ paddingBottom: "2px" }}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userName"
                      name="userName"
                      label="Your Name"
                      type="text"
                      variant="standard"
                      fullWidth
                      onChange={handleChange}
                      value={userData.userName}
                      inputProps={{
                        style: {
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          marginTop: "2px",
                        },
                      }}
                    />
                  </DialogContent>
                  <DialogContent sx={{ paddingTop: "2px" }}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userPhone"
                      name="userPhone"
                      label="Your Phone"
                      type="tel"
                      variant="standard"
                      fullWidth
                      onChange={handleChange}
                      value={userData.userPhone}
                      inputProps={{
                        style: {
                          paddingLeft: "6px",
                          paddingRight: "6px",
                          paddingTop: "4px",
                          paddingBottom: "4px",
                          marginTop: "2px",
                        },
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Buttonmui onClick={handleCloseMuiModal} color="primary">
                      Cancel
                    </Buttonmui>
                    <Buttonmui onClick={handleSubmitMuiDialog} color="primary">
                      Submit
                    </Buttonmui>
                  </DialogActions>
                </Dialog>

                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical, horizontal }}
                >
                  <MuiAlert
                    onClose={handleCloseSnackbar}
                    severity={alertSeverity}
                    elevation={6}
                    variant="filled"
                  >
                    {snackbarMessage}
                  </MuiAlert>
                </Snackbar>

                <div className="text-sm leading-normal mt-0 text-gray-500 font-bold uppercase">
                  <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                  {data.state && `${data.city}, ${data.state}`}
                </div>
                <div className="mb-2 text-gray-700">
                  <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                  {partner === "doctor"
                    ? "DOCTOR"
                    : partner === "physio"
                    ? "PHYSIO"
                    : partner}
                </div>
                <div className="mb-8 text-gray-700">
                  <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
                  <span className="font-semibold">
                    {data.yearsOfExperience}
                  </span>{" "}
                  Years of Experience
                </div>
                <div className="mb-2 text-gray-700">
                  <i className="fas fa-university mr-2  text-lg text-gray-500"></i>
                  <span className="font-semibold">Bio</span>

                  <div className="flex flex-row justify-center items-start sm:px-[16%] xl:px-[20%]">
                    <FaQuoteLeft size={96} viewBox="0 512 512 512" />
                    <p className="text-center pl-4 pt-2">{data.description}</p>
                    <FaQuoteRight
                      size={96}
                      className="mt-12"
                      viewBox="0 -512 512 512"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-10 py-10 border-t border-gray-300 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <span className="fas font-semibold fa-map-marker-alt text-md text-gray-500 uppercase">
                      City
                    </span>
                    <br />
                    {data.city}
                  </div>
                </div>
                <div className="flex flex-wrap justify-center pt-4">
                  <div className="w-full lg:w-9/12 px-4">
                    <span className="fas font-semibold fa-map-marker-alt text-md text-gray-500 uppercase">
                      Degree
                    </span>
                    <br />
                    {data.degree && (
                      <ul className="list-none pl-0 mx-auto max-w-max">
                        {data.degree.map((degree, index) => (
                          <li className="text-center italic" key={index}>
                            {degree}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap justify-center pt-4">
                  <div className="w-full lg:w-9/12 px-4">
                    <span className="fas font-semibold fa-map-marker-alt text-md text-gray-500 uppercase">
                      Specialization
                    </span>
                    <br />
                    {data.specialization && (
                      <ul className="list-disc pl-0 mx-auto max-w-max">
                        {data.specialization.map((specialization, index) => (
                          <li
                            className="xxs:text-center sm:text-left"
                            key={index}
                          >
                            {specialization}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                {partner === "physio" ? (
                  <div className="flex flex-wrap justify-center pt-4">
                    <div className="w-full lg:w-9/12 px-4">
                      <span className="fas font-semibold fa-map-marker-alt text-md text-gray-500 uppercase">
                        Areas Of Expertise
                      </span>
                      <br />
                      {data.areaOfExpertise && (
                        <ul className="list-disc pl-0 mx-auto max-w-max">
                          {data.areaOfExpertise.map((expertise, index) => (
                            <li
                              className="xxs:text-center sm:text-left"
                              key={index}
                            >
                              {expertise}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </main>
      )}
      ;
      <Footer />
    </div>
  );
};

export default PartnerProfile;
