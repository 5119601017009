import React, { useEffect, useState } from "react";
import "./singleBlog.css";
import { Link } from "react-router-dom";
import {Image } from "react-bootstrap";
import Button from "../../Components/Button";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import { Theme } from "../../Utils/colorSchema";
import { useParams } from "react-router-dom";
import axios from "axios";
import Loading from "../../Components/Loading/loading";
import { SanitizeHTML } from "../../Components/Sanitize HTML/SanitizeHTML";
import verified from "../../Assets/BlogViews/verified.png"
import { apiUrl } from "../../config";
import { FacebookShareButton, FacebookIcon, 
  WhatsappShareButton, WhatsappIcon, 
  LinkedinShareButton, LinkedinIcon,
  TwitterShareButton, TwitterIcon
}
from "react-share"
import { CardActions } from "@mui/material";
import { Helmet } from "react-helmet";
import casasvg from "../../Assets/casamed.svg"

import {Divider} from "@mui/material";
// import { width } from "@mui/system";

import RelatedBlogs from "../../Components/Posts/relatedBlogs";

import DOMPurify from "dompurify"




const SingleBlog = (props) => {
  const { slug } = useParams();
  const [post, setPost] = useState({});
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [blogs, setBlogs] = useState({});
  const [loading, setLoading] = useState(true);

  const [tocItems, setTocItems] = useState([]);

  const shareUrl= window.location.href

  // const headingRefs = useRef([]);

  // const title = 'Speed Dial'

  
  

  useEffect(() => {
    axios.get(`/api/blogs`, {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        })
        .then((res) => {
          const blog = res.data.data.find(blog => blog.slug === slug && blog.status === 'active')
          setPost(blog);
          setBlogs(blog);
          
          const filteredBlogs = res.data.data.filter((blog) => blog.slug !== slug);
          setRelatedBlogs(filteredBlogs);
          setLoading(false);
          
          // console.log(blog)
          

        })
        .catch(error => {
          console.error(error);
        });
  }, [slug]);



  const generateTableOfContents = () => {
    const headings = post.body.match(/<h[1-6].*?id="(Heading-\d+)".*?>(.*?)<\/h[1-6]>/gm);
    // console.log(headings)
    if (!headings) {
      return null;
    }
  
    const tocItems = headings.map((heading) => {
      const matches = heading.match(/<h[1-6].*?id="(Heading-\d+)".*?>(.*?)<\/h[1-6]>/);
      // console.log(matches)
      const id = matches[1];
      const text = matches[2].replace(/<\/?[^>]+(>|$)/g, '').replace(/:$/, '');
      const sanitizedText = DOMPurify.sanitize(text);
      return (
        
        <li className="pb-2 text-lg font-medium list-inside list-decimal" key={id}>
          <a href={`#${id}`} dangerouslySetInnerHTML={{ __html: sanitizedText }} />
        </li>
      );
    } 
    );
    // console.log(tocItems) 
  
    return (
      <div className="border-4 border-whyus p-4 border-opacity-60 rounded-lg">
        {tocItems}
      </div>
    );
  };
  
  
  
  
  

  // console.log(post.body)
  // console.log(tocItems)


  // console.log([post])



  // const getNextBlog = (post, slug) => {
  //   const currentIndex = post.findIndex(blog => blog.slug === slug);
  //   const nextIndex = (currentIndex + 1) % post.length;
  //   return blogs[nextIndex];
  // };

  // const getPrevBlog = (post, slug) => {
  //   const currentIndex = post.findIndex(blog => blog.slug === slug);
  //   const prevIndex = (currentIndex - 1 + post.length) % post.length;
  //   return blogs[prevIndex];
  // };

  // const nextBlog = getNextBlog(slug);
  // const prevBlog = getPrevBlog(slug);

  

  return (
    <div style={{ backgroundColor: "#EBF9FF" }}>
      <Nav />
      {loading && post && post.status === 'active' ? (
        <div style={{ height: "50vh" }}>
          <Loading />
        </div>
      ) : (
        
        
        <>
        <Helmet>
        {/* Meta title and Description */}
        <title>
          {post.metaTitle}
        </title>
        <meta
          name="description"
          content={post.metaDesc}
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content={post.metaKeywords}
        />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href={`https://casamed.in/blog/${post.slug}`}
        />

        <meta name="robots" content="INDEX,FOLLOW" />

        
        {/* Open Graph */}
        <meta
          property="og:title"
          content={post.metaTitle}
        />

        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content={`https://casamed.in/blog/${post.slug}`}
        />
        <meta
          property="og:description"
          content={post.metaDesc}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content= "https://casamed.in/static/media/casamed.781b488579b2af913fa91f7ef30c42de.svg"
        />
        {/* Schema MarkUp */}

        <script data-rh="true" type="application/ld+json">
          {JSON.stringify({
            "@context":"https://schema.org",
            "@type":"MedicalWebPage",
            headline: post.metaTitle,
            description:post.metaDesc,
            datePublished: post.createdAt,
            dateModified: post.updatedAt,
            url:`https://casamed.in/blog/${post.slug}`,
            publisher:
              {"@type":"Organization","name":"CasaMed","logo":{"@type":"ImageObject","url":"https://casamed.in/static/media/casamed.781b488579b2af913fa91f7ef30c42de.svg"}},
            author:
              {"@type":"Person","name": post.slug === "physiotherapy-helps-neurological-disorders" ? "Dr. Chaitra Basavaraj" : "Dr. Akanchha Gupta" },
            reviewedBy:
              {"@type":"Person","name":post.slug === "physiotherapy-helps-neurological-disorders" ? "Dr. Akanchha Gupta" : "Arnav Anshuman"}
          })}
            </script>

          <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": post.faqs?.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            }))
          })}
        </script>
      </Helmet>
      
      <article itemScope itemType="https://schema.org/BlogPosting"> 
          <div className="singlePost ">
            <div className="xxs:px-6 xxs:pt-12 md:pt-16">
              
              <div className="singlePostMedia xxs:pt-12 md:pt-16">
                {post.photo && (
                  <img className="xxs-w-full md:w-[70%] rounded-lg" src={post.photo} alt="" itemProp="image" />
                )}
                {post.photo && post.video ? (
                  <span className="m-1"></span>
                ) : null}
                {post.video && (
                  <iframe
                    src={post.video}
                    width="50%"
                    height="300px"
                    allowFullScreen
                    itemProp="video"
                  />
                )}
              </div>
              <h1 className="singlePostTitle xxs:text-[32px] sm:text-[42px] xxs:px-0 md:px-[20%] xxs:pt-6 md:pt-16">{post.title}</h1>
              <div className=" md:px-[20%] xxs:pt-4 sm:pt-8 pb-8">
              {["author", "writtenBy", "reviewedBy"].map((item, idx) =>
                post[item] ? (
                  <div
                    className="flex justify-start items-start"
                    key={idx}
                  >
                    {console.log(post[item])}
                    <div>
                    <div className=" flex flex-row gap-2 items-center" >
                      <Image className="xxs:w-[8%] md:w-[4%]" src={verified} />
                      
                      <strong className="text-sm">
                        {item.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()}
                        :{" "}
                      </strong>
                    </div>
                    
                      <div className="italic py-4 text-xs">{slug === 'physiotherapy-helps-neurological-disorders' ?  post[item].replace(/<br\/>/g, "\n \n") : <a href = 'https://casamed.in/about/team/Akanchha' target="_blank">{post[item].replace(/<br\/>/g, "\n \n")}</a>}</div>
                  </div>

                  
                  </div>
                ) : null
              )}
            </div>
            <p className="xxs:px-0 md:px-[20%] xxs:leading-relaxed  md:leading-loose text-[1.2rem] pb-4 italic">
            <meta itemProp="timeRequired" content="PT3M" />Reading Time : 3 mins
            </p>


              <p itemProp="articleBody" className="singlePostDesc xxs:px-0 md:px-[20%] xxs:leading-relaxed  md:leading-loose text-[1.35rem] pt-4">
                <div dangerouslySetInnerHTML={{ __html: post.body }} />
              
              <p className="pt-16 pb-6 italic leading-normal text-black opacity-75 text-[18px]">
              The information provided here is for educational purposes to help you manage your pain and prevent it from becoming a recurring condition. In case of discomfort, seek medical help
              </p>
              </p>

              
            
            </div> 
            
            <div className= "mx-[5%] xxs:mt-4 xxs:mb-12 sm:mt-16 sm:mb-32 h-[2.5px] opacity-40" style={{backgroundColor: '#9f9f9f'}}>
              <hr/>
            </div>
            
          </div>
      </article>
          <div className="text-center flex flex-col justify-center items-center my-4">
            <Link to = '/blog'><Button>Back</Button></Link>
        <Divider sx={{ color: '#1AB519', borderBottomWidth: 3}}></Divider>
            <CardActions className='flex justify-between '>
                            <div className='flex xxs:gap-[25%] sm:gap-[15%] justify-center items-center xxs:pt-4 md:pt-8'>
                            <FacebookShareButton
                                url={shareUrl}
                                quote={post.title}
                                className="hover:drop-shadow-none duration-200                      
                                drop-shadow-2xl
                                hover:-translate-y-2 "
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={post.title}
                                className="hover:drop-shadow-none duration-200                      
                                drop-shadow-2xl
                                hover:-translate-y-2 "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={shareUrl}
                                quote={post.title}
                                className="hover:drop-shadow-none duration-200                      
                                drop-shadow-2xl
                                hover:-translate-y-2 "
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            
                            <TwitterShareButton
                                url={shareUrl}
                                quote={post.title}
                                className="hover:drop-shadow-none duration-200                      
                                drop-shadow-2xl
                                hover:-translate-y-2 "
                            >
                                <TwitterIcon size={32} round />
                            </TwitterShareButton>
                            </div>
                            {/* <Button sx={{padding: 0, fontSize: {xs:'8px', md: '12px', xl: '16px'}}}>Share</Button > */}
                        </CardActions>
                        
          </div>
          
        </>
      
      )}

     
          <div className="px-[10%] flex  justify-center items-center">
          <RelatedBlogs/>
          </div>
      <Footer />
    </div>
  );
};

export default SingleBlog;
