import React from 'react'

import { shops } from '../../Constants'

import Slideshow from '../../Components/Particles'



function Shop(){
  return (
    <div id="casashop" className='xxs:px-3 md:px-12 xxs:pb-16 md:pb-36'>

        <h1 className='text-casashade2 xxs:pb-4 md:pb-10 xxs:text-4xl md:text-6xl font-bold text-center drop-shadow-md'>
            <span className='decoration-[#02B9ED] underline underline-offset-8 '>C</span>asa<span className='text-casablue'>Med</span> <span className=''>Shop</span>
        </h1>
        <div className='xxs:pb-8 md:pb-16 xxs:px-[10%] md:px-[20%] xl:px-[30%] flex justify-center items-center flex-col text-center text-casashade2 xxs:text-lg  md:text-xl'>
        <p className=''>
        At CasaMed, we understand that the answer to pain is in your hands. We manufacture and sell a wide range of high-quality and affordable training merchandise that can help you reach your full potential. Our products are designed to be used by anyone from novice to professional.<br/><br/>
        Browse through our exhaustive range of accessories such as weights, training equiment, posture correcting apparatus and much more!


        </p>
        </div>
        <div className='w-full flex justify-center items-center text-casashade2'>

        <Slideshow />
        </div>
    </div>
  )
}

export default Shop