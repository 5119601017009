import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import pcod1 from "../Assets/PCOD/pcodinfo.svg";
import pcod2 from "../Assets/PCOD/1.jpg";
import pcod3 from "../Assets/PCOD/2.jpg";
import { Helmet } from "react-helmet";

function PCOD() {
  return (
    <section className="bg-end">
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>
          Understanding PCOD: Symptoms, Causes, and Management | CasaMed
        </title>
        <meta
          name="description"
          content="PCOS: The comprehensive guide to understanding Polycystic Ovarian Syndrome - its causes, symptoms, and management. Stay informed!"
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Polycystic Ovarian Syndrome, PCOD symptoms, PCOD causes, PCOD management, PCOD treatment, PCOD diet, PCOD physiotherapy, PCOD support, Casamed"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/pcod" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content=" Understanding PCOD: Symptoms, Causes, and Management | CasaMed"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content=" https://casamed.in/pcod " />
        <meta
          property="og:description"
          content="PCOS: The comprehensive guide to understanding Polycystic Ovarian Syndrome - its causes, symptoms, and management. Stay informed!"
        />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Understanding PCOD: Symptoms, Causes, and Management | CasaMed",
            url: " https://casamed.in/pcod",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "PCOS: The comprehensive guide to understanding Polycystic Ovarian Syndrome - its causes, symptoms, and management. Stay informed!",
          })}
        </script>
      </Helmet>
      <div className="xxs:pt-24 md:pt-32 xxs:pb-6 md:pb-16">
        <h1 className="text-center text-whyus xxs:text-4xl md:text-5xl font-semibold">
          PCOD
        </h1>
      </div>
      <p className="xxs:px-[6%] xxs:text-center md:text-left md:px-[10%] pb-2 text-lg">
        PCOS has become a silent epidemic with an estimated 1 in 5 (20%) women
        suffering from PCOS in India. The condition can have serious health
        impacts if not monitored in time, this number comes out to be around 40
        Million in case of India whereas ObGyn shoftfall of about 40%. With the
        rise in obesity and sedentary lifestyles, PCOS is also on the rise.
      </p>
      <div className="grid grid-cols-1">
        <div className="xxs:text-center md:text-left xxs:px-[6%] md:px-[10%] grid xxs:grid-cols-1 md:grid-cols-2 pb-8">
          <div className="pb-4 xxs:pr-0 md:pr-[2%] xl:pr-[1%]">
            <p className=" text-lg pb-2">
              Polycystic ovaries (PCOS) is a hormonal disorder that can cause
              irregular periods, infertility, and hair growth on your face and
              body. It’s caused by an imbalance of male and female hormones.
            </p>
            <p className="text-lg ">
              PCOD is a condition that affects millions of women worldwide and
              has been overlooked by most doctors for years. It is a hormonal
              imbalance that causes irregular ovulation or no ovulation at all.
              It also results in excess production of male hormones such as
              testosterone and DHEA-S and low levels of female hormones such as
              estrogen, progesterone and human chorionic gonadotropin (HCG).
              This imbalance causes changes in the appearance and behavior of
              women, leading to problems with fertility and menstrual cycles for
              many women.
            </p>
          </div>
          <div className="flex justify-center items-end xl:items-center xxs:pl-0 md:pl-[2%] xl:pl-[1%] ">
            <img src={pcod1} className="xxs:w-full md:w-full xl:w-[80%]" />
          </div>
        </div>

        <p className="xxs:text-center md:text-left xxs:px-[6%] md:px-[10%] text-lg md:pb-1 xl:pb-4">
          Physiotherapy may be useful in managing some of the symptoms
          associated with PCOS, such as weight gain and insulin resistance.
        </p>
        <div className="xxs:text-center md:text-left xxs:px-[6%] md:px-[10%] grid xxs:grid-cols-1 md:grid-cols-2 pb-8">
          <div className="flex justify-start items-center xl:items-center md:pr-[2%] xl:pr-[0%]">
            <img
              src={pcod2}
              className="rounded-lg xxs:w-full xxs:pb-4 md:pb-0 md:w-full xl:w-[95%]"
            />
          </div>
          <div className="pb-4 md:pl-[2%] xl:pl-[0%] flex flex-col md:justify-start xl:justify-center ">
            <p className="text-lg pb-2">
              Physical activity is an important part of managing PCOS, as it can
              help regulate hormones and improve overall health. This can
              include activities such as walking, swimming, biking, or other
              forms of aerobic exercise. A physiotherapist can provide guidance
              and support on developing a personalized exercise plan that is
              safe and effective for someone with PCOS.
              <br />
              Exercise can help improve insulin sensitivity and control blood
              sugar levels, which may be beneficial for individuals with PCOS.
            </p>
            <p className="text-lg">
              Physical therapy should not be used as a standalone treatment for
              PCOS. It is best used in conjunction with other treatments, such
              as lifestyle changes and medication, to manage the condition
              effectively. Women with PCOS should consult with their healthcare
              provider to determine the best treatment plan for their individual
              needs. We at CasaMed make sure you've got all these angles
              covered, right from the comfort of your homes.
            </p>
          </div>
        </div>

        <p className="xxs:text-center md:text-left xxs:px-[6%] md:px-[10%] pb-2 text-lg">
          At CasaMed, we believe in a holistic approach to the treatment of
          PCOS. This involves a combination of lifestyle changes and medication,
          as well as the use of physiotherapy to manage symptoms and improve
          overall health. Our PCOS treatment plan includes the following
          components:
        </p>
        <div className="grid grid-cols-1">
          <div className="xxs:text-center md:text-left xxs:px-[6%] md:px-[10%] grid xxs:grid-cols-1 md:grid-cols-2 pb-8 ">
            <div className="pb-4 md:pr-[2%] xl:pr-[1%] flex flex-col md:justify-start xl:justify-center">
              <p className=" text-lg pb-2">
                <br />
                <strong>Diet and exercise:</strong> Eating a healthy, balanced
                diet and getting regular exercise can help regulate hormones and
                improve overall health. Our dietitians and exercise
                physiologists can provide guidance and support on making
                positive changes to diet and physical activity.
              </p>
              <p className="text-lg pb-2">
                <strong>Medication:</strong> We may prescribe medication to
                regulate periods and manage symptoms, such as birth control
                pills and metformin.
              </p>
              <p className="text-lg pb-2">
                <strong>Physiotherapy:</strong> Our physiotherapists can provide
                guidance and support on exercise and physical activity, as well
                as techniques such as manual therapy and massage to help manage
                symptoms such as pain and stiffness.
              </p>
            </div>
            <div className="flex justify-center items-end xl:items-center md:pl-[2%] xl:pl-[1%] pt-2">
              <img src={pcod3} className="rounded-lg md:w-full xl:w-[80%]" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default PCOD;
