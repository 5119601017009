import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import hv1 from "../Assets/HomeVisits/1.webp";
import hv2 from "../Assets/HomeVisits/2.webp";
import hv3 from "../Assets/HomeVisits/3.webp";

const HRSComponent = () => {
  return (
    <div className="pt-24 xxs:pb-16 md:pb-24 md:px-[6%] xl:px-[10%] text-whyus ">
      <h1 className="text-center xxs:text-3xl md:text-5xl font-semibold ">
        Home Rehabilitation Services
      </h1>
      <div className="mx-5">
        <div className="flex xxs:flex-col-reverse md:flex-row items-start justify-between py-6">
          <p className="xxs:pr-0 xxs:text-center md:text-left md:pr-[4%] xl:pr-[5%]">
            Rehabilitation is one of the necessary treatments in today&#39;s
            world for health coverage. If you are searching for a 
            <strong>home rehabilitation service</strong> connect with us,
            Casamed is here to assist you in your journey.
            <br />
            <br />
            Having treated thousands of patients, we understand how difficult it
            gets for an individual when they cannot perform their daily
            activities. Connect with our professionals to learn more about the
            necessary details and cost estimation. We have certified therapists
            who can provide <strong>Home Rehabilitation services</strong> to
            overcome physical, sensory, or mental loss because of any illness or
            injury.
            <br />
            <br />
            Rehabilitation therapy is primarily three types; physical,
            occupational, and speech therapy. We also offer online services for
            those who cannot travel to our center because of time constraints or
            distance.
            <br />
            <br />
            Our therapist carefully evaluates the complete medical background of
            an individual and then plans the treatment accordingly. Proper and
            adequate rehabilitation therapy reverses the disability of the
            patient and guides them to perform their daily activities. During
            the course of treatment, the patient usually interacts with a team
            of orthopaedic surgeons, psychologists, speech, physical and
            occupational therapists, nurses, and vocational counselors.
          </p>
          <img
            src={hv1}
            className=" xxs:w-[100%] xxs:pb-4 md:pb-0 w-[40%] md:w-[48%] xl:w-[35%] rounded-lg"
          />
        </div>
        <div className="flex xxs:flex-col md:flex-row items-center justify-between py-6">
          <img
            src={hv2}
            className="xxs:w-[100%] md:w-[48%] xl:w-[35%] rounded-lg xxs:pb-4 md:pb-0"
          />
          <p className=" xxs:text-center md:text-left xxs:pl-0 md:pl-[4%] xl:pl-[5%]">
            During physical therapy, we guide our patients to regain the use of
            bone, muscles, or nervous system through heat therapy, exercise, or
            any other technique. Exercise is by far considered one of the best
            cures because this helps the patient to overcome their disability.
            <br />
            <br />
            At Casamed, we intend to provide drug-free, non-invasive, and
            natural treatment. For better results, our therapist will also guide
            you regarding the changes in lifestyle that will boost your
            condition. Apart from that, we also offer occupational therapy,
            through which our therapists nurture you to restore old skills or
            learn new skills, adjust to disabilities through adaptive equipment,
            orthotics, and modification of the patient&#39;s home
            environment. We also have speech therapists who may guide you to
            restore speech that is lost because of illness or trauma. The
            duration of the treatment depends on the condition of the patients.
            Generally, it varies from six months to a few years, but the crucial
            part is to ensure that the treatment guarantees for long-term
            relief.
            <br />
            <br />
            Hence, to overcome your disability feel free to reach us and plan
            your session with our therapist. Contact us now to schedule your
            appointment and we will be providing the 
            <strong>rehabilitation therapy at your doorstep</strong>.
          </p>
        </div>

        <div className="flex xxs:flex-col md:flex-row-reverse items-center justify-between py-6">
          <img
            src={hv3}
            className="xxs:w-[100%] md:w-[48%] xl:w-[35%] rounded-lg xxs:pb-4 md:pb-0"
          />
          <div className="xxs:pr-0 md:pr-[4%] xl:pr-[5%]">
            <h2 className="xxs:text-2xl md:text-3xl xxs:text-center md:text-left  font-semibold pb-4">
              Why Choose Casamed Home Rehabilitation Services ?
            </h2>
            <ul>
              <li>
                <strong>Helps to overcome your disability</strong>
              </li>
            </ul>
            <p className="pb-2">
              Our therapists are highly trained, they will help and guide you to
              beat the pain after carefully evaluating your case. They will help
              you gain full functional mobility of the muscles and joints, learn
              new skills or restore the old ones.
            </p>
            <ul>
              <li>
                <strong>Assist you with manual therapy treatment</strong>
              </li>
            </ul>
            <p className="pb-2">
              Our therapist uses heat treatment which helps to ease the
              muscle&#39;s pain and strengthen movements. In additional to that,
              they also use innovative devices to assist patients in walking.
              Health aids to bathing and grooming are also recommended.
            </p>
            <ul>
              <li>
                <strong>Educate you about rehabilitation therapy</strong>
              </li>
            </ul>
            <p className="pb-2">
              Our therapist at Casamed educates the patient and their family
              members during the process of therapy about the cause of the
              disorder and how it can be overcome. They also help in
              understanding the dos and don’ts during the process of therapy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const HomeRehabilitationServices = () => {
  return (
    <div className="og--bg--grad">
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>Home Rehabilitation Services | CasaMed</title>
        <meta
          name="description"
          content="Regain independence & overcome health challenges with Casamed's Home Rehab services. Certified therapists, personalized care. Connect with us today."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="occupational therapy treatment,rehabilitation therapy, home, visits, home visit, home visits"
        />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/home-rehabilitation-services"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content=" Home Rehabilitation Services | CasaMed"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/home-rehabilitation-services "
        />
        <meta
          property="og:description"
          content="Regain independence & overcome health challenges with Casamed's Home Rehab services. Certified therapists, personalized care. Connect with us today."
        />
        <meta property="og:type" content="website" />

        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Home Rehabilitation Services | CasaMed",
            url: " https://casamed.in/home-rehabilitation-services",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Regain independence & overcome health challenges with Casamed's Home Rehab services. Certified therapists, personalized care. Connect with us today.",
          })}
        </script>
      </Helmet>
      <HRSComponent />
      <Footer />
    </div>
  );
};

export default HomeRehabilitationServices;
