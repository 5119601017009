import Post from "./post/post";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, CardActions } from "@mui/material";
import axios from "axios";
import Loading from "../Loading/loading";
import { FacebookShareButton, FacebookIcon, 
  WhatsappShareButton, WhatsappIcon, 
  LinkedinShareButton, LinkedinIcon,
  TwitterShareButton, TwitterIcon
} 
from 'react-share'

import BackPain from "../../Assets/BlogViews/BackPain_Sitting.jpg"
import arth1 from "../../Assets/Arthiritis/arth1.jpg"

// import shortid from 'shortid'

import { apiUrl } from "../../config";
import Blog from "../../Pages/Blog";


export default function Posts() {

  const {slug} = useParams

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const history = useNavigate();

    const shareUrl= window.location.href

    // const title = 'Speed Dial'
    

    const bodyNew = "The field of physiotherapy concentrates on helping patients with physical impairments, improving their mobility and functional ability, and maintaining healthy muscles to prevent the recurrence of the pain.It also helps in easing pain and stiffness in muscles, joints and bones. Physiotherapy treatment for back pain is a mixture of both passive and active treatments. In passive physiotherapy the patient does not need to move their muscles..."

    const bodyNew2 = "Physiotherapy(PT) is a healthcare profession that uses physical techniques, such as exercises, manual therapy, and other modalities, to restore function and movement to areas affected by an injury, illness, or disability. It helps in improving the quality of life of a person affected by an injury, disease, or other conditions that cause pain or restrictions in daily life activities. Physiotherapy includes rehabilitation, prevention of injury, maintaining social well-being, and promotion of health and fitness. Physiotherapists will assess your condition and work with you to form a tailor-made treatment plan so as to have a fast recovery and get back to doing activities you love."

    const bodyNew3 = "Osteoarthritis of the knee is a degenerative joint disorder that affects the entire joint, including the cartilage, joint lining, ligaments and bone. It is the most common form of osteoarthritis. It is caused by the breakdown of the cartilage which is the protective tissue that cushions the ends of the bones between the joints. This causes the bones in the knee joint to rub against each other causing pain, swelling and difficulty in moving the joint. It can also lead to extra bone growth on the edges of the joints, which can restrict movement or rub against other tissues. The breaking down of the cartilage also causes the other tissues within the knee joint to work more than usual. This results in stretching of the supporting tissues surrounding the joint causing the joint to become less stable."
    
    const bodyNew4 = "Arthritis is a degenerative disease that affects the joints in our body. It causes symptoms including swelling, pain, stiffness, and reduced range of motion in joints. These symptoms usually develop over time, but in some cases, they may also appear suddenly. The severity of the symptoms varies from person to person. These symptoms may stay the same for years, but they can also progress and worsen over time without proper treatment or care."

    // const config = {
    //   headers: {
    //     Authorization: 'Bearer ' + newAccessToken, // replace with the new access token
    //     auth: {
    //       user: JSON.stringify(localStorage.getItem("user")),
    //     },
    //   },
    // };

    

    useState(() => {
      const getBlogs = async () => {
        await axios
          .get(`/api/blogs`, {
            headers: {
              Authorization: localStorage.getItem("token"),
              auth: {
                user: JSON.stringify(localStorage.getItem("user")),
              },
            },
          })
          .then((res) => {
            setBlogs(res.data.data);
            setLoading(false);
            
          })
          .catch((err) => console.log(err));
      };
      getBlogs();
    }, []);

    // const handleClick = (post) => {
    //   // const id = post._id;
    //   // const sid = shortid(id)
    //   const slug = post.slug
    //   history.push(`/blog/${slug}`);
    // };

    // const sid = shortid(blog._id)
    
    return (
      <div className="mt-10 xxs:px-6 md:px-[10%]" style={{ height: loading ? "50vh" : null }}>

        {loading  ? (
          <Loading />
        ) : (
          blogs.map((post, slug) => (
            <div key={slug} className="grid grid-cols-1 xxs:px-0 md:px-[10%] pb-12">
            { post.status === 'active' &&
              (<Link to={`/blog/${post.slug}`} target="_blank" rel="noopener noreferrer" >
              <Post
                title={post.title}
                img={`${post.slug === 'physiotherapy' ? 'https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FWhat%20is%20Physiotherapy%2F1.jpg?alt=media&token=95d68ab2-0298-454b-8f91-f7e20740a2d6' : post.slug === 'osteo2' ? 'https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FKnee%20Osteo%2F9.jpg?alt=media&token=2d869aaa-be1a-4db6-ac46-fca6eeb63aa7'  : post.photo}`}
                body={`${post.dispSnippet ? post.dispSnippet : post.body }`}
                // handleClick={() => handleClick(post)}
              />
                          <CardActions className='flex justify-between '>
                              <div className='flex gap-[5%] pr-2'>
                              <FacebookShareButton
                                  url={shareUrl}
                                  quote={post.title}
                                  className="hover:drop-shadow-none duration-200                      
                                  drop-shadow-2xl
                                  hover:-translate-y-2 "
                              >
                                  <FacebookIcon size={32} round />
                              </FacebookShareButton>
                              
                              <WhatsappShareButton
                                  url={shareUrl}
                                  quote={post.title}
                                  className="hover:drop-shadow-none duration-200                      
                                  drop-shadow-2xl
                                  hover:-translate-y-2 "
                              >
                                  <WhatsappIcon size={32} round />
                              </WhatsappShareButton>

                              <LinkedinShareButton
                                  url={shareUrl}
                                  quote={post.title}
                                  className="hover:drop-shadow-none duration-200                      
                                  drop-shadow-2xl
                                  hover:-translate-y-2 "
                              >
                                  <LinkedinIcon size={32} round />
                              </LinkedinShareButton>
                              
                              <TwitterShareButton
                                  url={shareUrl}
                                  quote={post.title}
                                  className="hover:drop-shadow-none duration-200                      
                                  drop-shadow-2xl
                                  hover:-translate-y-2 "
                              >
                                  <TwitterIcon size={32} round />
                              </TwitterShareButton>
                              </div>
                              {/* <Button sx={{padding: 0, fontSize: {xs:'8px', md: '12px', xl: '16px'}}}>Share</Button > */}
                              <Button sx={{padding: 0, fontSize: {xs:'14px', md: '16px', xl: '16px'}}}>Learn More</Button>
                          </CardActions>
              </Link>)
}
            </div>
            
          ))
        )}
          
      </div>
    );
  }
