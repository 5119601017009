import Footer from "../Components/Footer";
import Nav from "../Components/Nav";

import ut1 from "../Assets/HomeViews/Testimonials/ut1.webp";
import pcod from "../Assets/pcodloc.webp";
import FAQSection from "../Components/FAQS";
import { Helmet } from "react-helmet";

export const PCODPatna = () => {
	const faqs = [
		{
			question: "What is Polycystic Ovarian Disorder (PCOD)?",
			answer: "Polycystic Ovarian Disorder (PCOD) is a hormonal disorder causing enlarged ovaries with small cysts on the outer edges. Symptoms include irregular menstrual cycles, acne, thinning hair, and weight gain.",
		},
		{
			question: "How is PCOD diagnosed at CasaMed Clinic?",
			answer: "Diagnosis involves a thorough medical history, physical examination, blood tests to measure hormone levels, and an ultrasound to check for cysts on the ovaries.",
		},
		{
			question:
				"What treatments are available for PCOD at CasaMed Clinic?",
			answer: "Treatments include medication to regulate menstrual cycles and hormones, physical therapy to manage symptoms, and personalised nutrition plans to support overall health.",
		},
		{
			question: "Can lifestyle changes help manage PCOD?",
			answer: "Yes, lifestyle changes such as regular exercise, a healthy diet, and weight management can significantly improve PCOD symptoms and overall well-being.",
		},
		{
			question: "Is PCOD treatment at CasaMed Clinic non-surgical?",
			answer: "Yes, CasaMed Clinic offers non-surgical, non-invasive treatment options for sustainable relief from PCOD symptoms.",
		},
		{
			question: "How often should I visit the clinic for PCOD treatment?",
			answer: "The frequency of visits depends on your treatment plan. Regular follow-up appointments are essential to monitor progress and make necessary adjustments to your treatment.",
		},
		{
			question: "Can I get pregnant if I have PCOD?",
			answer: "Yes, with the right treatment and lifestyle changes, many women with PCOD can conceive and have healthy pregnancies. CasaMed Clinic provides comprehensive care to support fertility and overall health.",
		},
	];

	return (
		<div className="">
			<Nav />
			<Helmet>
				<title>
					Effective Polycystic Ovarian Disorder Treatment in Patna |
					CasaMed Clinic
				</title>
				<meta
					name="description"
					content="CasaMed Clinic in Patna offers top-notch Polycystic Ovarian Disorder (PCOD) treatment with expert gynaecologists, advanced physiotherapy, and personalised nutrition plans."
				/>
				{/* Meta Keywords */}

				{/* Canonical Tag */}
				<link
					rel="canonical"
					href="https://www.casamed.in/patna/pcod-treatment"
				/>
				<meta
					name="ROBOTS"
					content="INDEX, FOLLOW"
				/>
				{/* Open Graph */}
				<meta
					property="og:title"
					content=" Effective Polycystic Ovarian Disorder Treatment in Patna | CasaMed Clinic"
				/>
				<meta
					property="og:site_name"
					content="CasaMed"
				/>
				<meta
					property="og:url"
					content=" https://www.casamed.in/patna/pcod-treatment"
				/>
				<meta
					property="og:description"
					content="CasaMed Clinic in Patna offers top-notch Polycystic Ovarian Disorder (PCOD) treatment with expert gynaecologists, advanced physiotherapy, and personalized nutrition plans."
				/>
				<meta
					property="og:type"
					content="website"
				/>
				<meta
					property="og:image"
					content={pcod}
				/>
				<meta
					name="twitter:card"
					content="summary_large_image"
				/>
				<meta
					name="twitter:title"
					content="Effective Polycystic Ovarian Disorder Treatment in Patna | CasaMed Clinic"
				/>
				<meta
					name="twitter:description"
					content="CasaMed Clinic in Patna offers top-notch Polycystic Ovarian Disorder (PCOD) treatment with expert gynaecologists, advanced physiotherapy, and personalized nutrition plans."
				/>
				<meta
					name="twitter:image"
					content={pcod}
				/>
				<link
					rel="canonical"
					href="https://www.casamed.in/patna/pcod-treatment"
				/>

				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "MedicalClinic",
						name: "CasaMed Clinic",
						description:
							"CasaMed Clinic in Patna offers top-notch Polycystic Ovarian Disorder (PCOD) treatment with expert gynaecologists, advanced physiotherapy, and personalised nutrition plans.",
						url: "https://www.casamed.in/patna/pcod-treatment",
						address: {
							"@type": "PostalAddress",
							streetAddress: "Gardanibagh",
							addressLocality: "Patna",
							addressRegion: "BR",
							postalCode: "800001",
							addressCountry: "IN",
						},
						contactPoint: {
							"@type": "ContactPoint",
							telephone: "+91-8235858626",
							contactType: "Customer Service",
						},
						openingHoursSpecification: {
							"@type": "OpeningHoursSpecification",
							dayOfWeek: [
								"Monday",
								"Tuesday",
								"Wednesday",
								"Thursday",
								"Friday",
								"Saturday",
							],
							opens: "09:00",
							closes: "18:00",
						},
					})}
				</script>
			</Helmet>
			<div className="pt-20 pb-10">
				<h2 className="text-4xl font-bold  text-copyColor text-center md:max-w-[50%] mx-auto">
					Comprehensive Polycystic Ovarian Disorder (PCOD) Treatment
					in Patna at CasaMed Clinic
				</h2>

				<div className="mt-3 flex justify-center space-x-3 text-gray-500 ">
					<div className="flex items-center gap-3">
						<div className="relative h-10 w-10 flex-shrink-0">
							<img
								alt="Arnav"
								src={ut1}
								className="rounded-full object-cover"
								sizes="40px"
								style={{
									position: "absolute",
									height: "100%",
									width: "100%",
									inset: "0px",
									color: "transparent",
								}}
							/>
						</div>
						<div>
							<p className="text-gray-800 dark:text-gray-400">
								{`Arnav Anshuman`}
							</p>
							<div className="flex items-center space-x-2 text-sm">
								<time
									className="text-gray-500 dark:text-gray-400"
									dateTime="2022-10-21T15:48:00.000Z"
								>
									{`21 June 2024`}
								</time>
								<span>· {`3`} min read</span>
							</div>
						</div>
					</div>
				</div>

				<div className="flex justify-center items-center my-8">
					<img
						src={pcod}
						className={`md:max-w-4xl h-full rounded-lg object-cover object-center`}
						alt={"pcod"}
					/>
				</div>

				<div className="max-w-[340px] md:max-w-3xl mx-auto font-poppins">
					<p className="font-semibold text-xl">
						CasaMed Clinic, located in Gardanibagh, Patna, is a
						premier destination for comprehensive Polycystic Ovarian
						Disorder (PCOD) treatment. With over 30 years of
						experience, our leading gynaecologist,{" "}
						<a
							href="https://casamed.in/about/mentors/DrAmita"
							style={{
								color: "#3b82f6 ",
							}}
						>
							Dr. Amita Singh
						</a>
						, offers exceptional surgical and gynaecological care.
						Our state-of-the-art facility is equipped with top
						physiotherapy services and a dedicated team of
						nutritionists who curate sustainable and effective PCOD
						care plans, ensuring guaranteed recovery for our
						patients. We attract patients from Bihar, Assam,
						Jharkhand, Odisha, West Bengal, Uttar Pradesh, and Nepal
						due to our unparalleled care and expertise.
					</p>

					<h2 className="text-[2rem] font-bold mt-[2rem] mb-[1rem]">
						Why Choose CasaMed Clinic for PCOD Treatment?
					</h2>

					<ul className="ml-[1.5rem] mb-[1rem] text-[1.125rem]">
						<li className="mb-[0.5rem] list-disc">
							<strong>Expert Gynaecological Care:</strong> Led by
							Dr. Amita Singh, our team of gynaecologists provides
							expert care for PCOD, ensuring patients receive the
							best treatment options available.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Integrated Physiotherapy Services:</strong>{" "}
							Our top-notch physiotherapy centre offers
							specialised physical therapy for Polycystic Ovarian
							Disorder, aiding in symptom management and overall
							well-being.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Personalized Nutrition Plans:</strong> Our
							nutritionists design personalised diet plans to help
							manage PCOD symptoms effectively and sustainably.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Advanced Medical Facilities:</strong>{" "}
							Equipped with world-class equipment, CasaMed Clinic
							offers non-invasive, non-surgical treatments for
							sustainable relief.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Trusted by Patients Globally:</strong>{" "}
							Patients from across Bihar and neighbouring regions
							trust CasaMed Clinic for their PCOD treatment,
							benefiting from our comprehensive care approach.
						</li>
					</ul>
					<h2 className="text-[2rem] font-bold mt-[2rem] mb-[1rem]">
						Detailed Steps for PCOD Pain Relief
					</h2>

					<ul className="ml-[1.5rem] mb-[1rem] text-[1.125rem]">
						<li className="mb-[0.5rem] list-disc">
							<strong>Initial Consultation and Diagnosis:</strong>{" "}
							Our expert gynaecologists conduct a thorough
							evaluation to diagnose PCOD accurately.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Customised Treatment Plan:</strong> Based on
							the diagnosis, a personalised treatment plan is
							crafted, including medication, lifestyle changes,
							and physical therapy.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Physiotherapy Sessions:</strong> Regular
							physiotherapy sessions help manage PCOD symptoms,
							improve physical health, and enhance fertility.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Nutritional Support:</strong> Our
							nutritionists provide tailored diet plans to support
							hormone balance and overall health.
						</li>
						<li className="mb-[0.5rem] list-disc">
							<strong>Ongoing Monitoring:</strong> Continuous
							monitoring and follow-up appointments ensure the
							effectiveness of the treatment plan and make
							necessary adjustments.
						</li>
					</ul>
				</div>
				<section className="  relative max-w-[340px] md:max-w-3xl mx-auto font-poppins">
					<FAQSection faqs={faqs} />
				</section>
			</div>
			<Footer />
		</div>
	);
};
