import Post from "../../Components/Posts/post/post";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button, CardActions } from "@mui/material";
import axios from "axios";
import Loading from "../../Components/Loading/loading";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import InfoIcon from "@mui/icons-material/Info";

import BackPain from "../../Assets/BlogViews/BackPain_Sitting.jpg";

// import shortid from 'shortid'

// import { apiUrl } from "../../config";
// import Blog from "../../Pages/Blog";

export default function Posts() {
  const { slug } = useParams;

  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useNavigate();

  const shareUrl = window.location.href;

  // const title = 'Speed Dial'

  const bodyNew =
    "The field of physiotherapy concentrates on helping patients with physical impairments, improving their mobility and functional ability, and maintaining healthy muscles to prevent the recurrence of the pain.It also helps in easing pain and stiffness in muscles, joints and bones. Physiotherapy treatment for back pain is a mixture of both passive and active treatments. In passive physiotherapy the patient does not need to move their muscles...";

  // const config = {
  //   headers: {
  //     Authorization: 'Bearer ' + newAccessToken, // replace with the new access token
  //     auth: {
  //       user: JSON.stringify(localStorage.getItem("user")),
  //     },
  //   },
  // };

  useState(() => {
    const getTeams = async () => {
      await axios
        .get(`/api/teams`, {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        })
        .then((res) => {
          setTeams(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };
    getTeams();
  }, []);

  // const handleClick = (post) => {
  //   // const id = team._id;
  //   // const sid = shortid(id)
  //   const slug = team.slug
  //   history.push(`/blog/${slug}`);
  // };

  // const sid = shortid(blog._id)
  return (
    <>
      <div className="px-[5%]">
        <div className="lg:px-16 xl:px-32">
          <h1 className="xxs:text-[30px] sm:text-[50px] text-casashade2 tracking-wider font-bold  xxs:text-center md:text-left ">
            Our <br className="xxs:hidden md:visible" />
            <span className="underline underline-offset-8 decoration-[#00CC6D]">
              T
            </span>
            eam
          </h1>
        </div>

        <section className="text-casablue xxs:py-8 md:py-16 xxs:px-0 lg:px-16 xl:px-32 ">
          <div className="md:grid xxs:flex xxs:flex-col xxs:justify-center xxs:items-center md:grid-cols-3 justify-center xxs:gap-16 md:gap-16 items-start">
            {loading ? (
              <Loading />
            ) : (
              teams.map((team, slug) => (
                <div className="flex-col justify-center items-center">
                  <div
                    key={slug}
                    className="grid grid-cols-1 xxs:px-0 md:px-[10%] pb-12"
                  >
                    <Link
                      to={`/about/team/${team.slug}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="fullwrap " sx={{ innerWidth: 600 }}>
                        <img
                          src={team.photoUrl}
                          alt=""
                          className="rounded-t-md"
                        />
                        <div className="fullcap ">
                          <h1 className="text-ellipsis line-clamp-4 ">
                            {team.bio.replace(/<br\/>/g, "\n")}
                          </h1>
                          <br />
                          <div className="flex justify-center items-center ">
                            <button className="flex flex-row items-center justify-center">
                              <InfoIcon className="mr-1" /> Know More
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="flex border-slate-300  border-[3px] border-t-0 px-3 py-1 justify-between items-center font-semibold text-casashade2 rounded-b-md bg-whitish">
                        <h1 className="">{team.name}</h1>
                        <h1
                          className={
                            team.slug == "Akanchha"
                              ? "font-thin text-[12px] pr-2"
                              : "font-thin text-[14px] pr-2"
                          }
                        >
                          {team.designation}
                        </h1>
                      </div>
                    </Link>
                  </div>
                </div>
              ))
            )}
          </div>
        </section>
      </div>
    </>
  );
}
