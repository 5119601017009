import { motion } from 'framer-motion'
import logindesign from "../../Assets/ProductViews/ProdHeader/logindesign.png"
import logindesign2 from "../../Assets/ProductViews/ProdHeader/logindesign2.png"
import logindesign3 from "../../Assets/ProductViews/ProdHeader/logindesign3.png"


const headerVariant = {
    start: {
        opacity: 0
    },
    end: {
        opacity: 1,
        transition: {
            duration: 1
        }
    }
}


const textVariant2 = {
    start: {
        x: "10",
        opacity: 0
    },
    end: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.75
        }
    }
}


function ProdHeader() {
    return (
       
         

        <div className=''>                  
        <motion.header
            variants={headerVariant}
            animate="end"
            initial='start'
            className='grid px-8 md:grid-cols-3 xxs:grid-cols-1 md:pt-24 xxs:pt-12  xxs:px-6 justify-center items-center xxs:gap-8'>

            <motion.div
                variants={textVariant2}
                initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
             className=' flex justify-center items-center '>
                <img className=''   src={logindesign} alt="prodhero1" />
            </motion.div>

            <motion.div
                variants={textVariant2}
                initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
             className='flex justify-center items-center   '>
                <img className='  '   src={logindesign2} alt="prodhero2" />
            </motion.div>
            <motion.div
                variants={textVariant2}
                initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
             className=' flex justify-center items-center '>
                <img className='   '   src={logindesign3} alt="prodhero3" />
            </motion.div>
            
           
                   
        </motion.header>

    


            

        


        


        
         </div>

         
        
        
        
        
        
    )
}

export default ProdHeader