import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { StarRounded as Star } from "@material-ui/icons";

import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
// import { useTheme as useNextTheme } from 'next-themes'
// import { Switch, useTheme } from '@nextui-org/react'

import { DataGrid } from "@mui/x-data-grid";

import {
  Button,
  Col,
  Container,
  Image,
  ListGroup,
  Modal,
  Row,
} from "react-bootstrap";
import {
  Card,
  Box,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Button as Buttonmui } from "@mui/material";

import Footer from "../../Components/Footer";
import Nav from "../../Components/Nav";
import DocIcon from "../../Assets/DocIcon.png";

import "semantic-ui-css";
import DoctorBanner from "../../Assets/PartnerViews/docBannerImg.png";

import PaginationH from "../../Components/Pagination/pagination";
import axios from "axios";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import casasvg from "../../Assets/casamed.svg";
import { Helmet } from "react-helmet";
import Herostats from "../../Views/HomeViews/HeroStats";
import CornerRibbon from "../../Components/CornerRibbon";

import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const imageVariant = {
  start: {
    rotate: "-180deg",
  },
  end: {
    rotate: 0,
    transition: {
      duration: 1,
    },
  },
};

const ConsultComponent = ({ props }) => {
  const [view, setView] = useState("doctor");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  // Pagination

  const [doctors, setDoctors] = useState([]);
  const [physios, setPhysios] = useState([]);

  const [pageData, setPageData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(9);

  const [uniqueCities, setUniqueCities] = useState([]);
  const [uniqueExpertise, setUniqueExpertise] = useState([]);
  const [uniqueDegrees, setUniqueDegrees] = useState([]);
  const [uniqueSpecializations, setUniqueSpecializations] = useState([]);
  const [uniqueExperience, setUniqueExperience] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedSpecializations, setSelectedSpecializations] = useState(
    new Set()
  );
  const [selectedCities, setSelectedCities] = useState(new Set());
  const [selectedExperience, setSelectedExperience] = useState(new Set());
  const [selectedExpertise, setSelectedExpertise] = useState(new Set());
  const [selectedDegrees, setSelectedDegrees] = useState(new Set());

  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState({
    userName: "",
    userPhone: "",
  });

  const [selectedItem, setSelectedItem] = useState(null);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [stateSnackbar, setStateSnackbar] = React.useState({
    openS: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openS } = stateSnackbar;

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleOpenMuiModal = (item) => {
    setOpen(true);
    setSelectedItem(item);
  };
  const handleCloseMuiModal = () => setOpen(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmitMuiDialog = async () => {
    if (!selectedItem) {
      console.error("No partner selected!");
      return;
    }

    try {
      const payload = {
        partnerName: selectedItem.name,
        partnerCity: selectedItem.city,
        userName: userData.userName,
        userPhone: userData.userPhone,
      };

      const response = await axios.post("/api/partnerPageForm", payload, {
        headers: {
          Authorization: localStorage.getItem("token"),
          auth: {
            user: JSON.stringify(localStorage.getItem("user")),
          },
        },
      });

      if (response.status === 201) {
        console.log(
          "Form submitted successfully:",
          response.data,
          response.status
        );
        // Close the modal
        handleCloseMuiModal();
        // Clear the user data form fields
        setUserData({
          userName: "",
          userPhone: "",
        });
        setSnackbarMessage("Form submitted successfully!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
      } else {
        setSnackbarMessage("Failed to submit form.");
        setAlertSeverity("error");
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while submitting the form.");
      setAlertSeverity("error");
      setOpenSnackbar(true);
    }
  };

  // Rest of your component code...

  const [hasFetchedDoctors, setHasFetchedDoctors] = useState(false);
  const [hasFetchedPhysios, setHasFetchedPhysios] = useState(false);

  const getDoctors = async () => {
    if (hasFetchedDoctors) return;
    try {
      const response = await axios.get("/api/doctorPages", {
        headers: {
          Authorization: localStorage.getItem("token"),
          auth: {
            user: JSON.stringify(localStorage.getItem("user")),
          },
        },
      });
      setDoctors(response?.data);
      setPageData(response?.data);
      setHasFetchedDoctors(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      setLoading(false);
    }
  };

  const getPhysios = async () => {
    if (hasFetchedPhysios) return;
    try {
      const response = await axios.get("/api/physioPages", {
        headers: {
          Authorization: localStorage.getItem("token"),
          auth: {
            user: JSON.stringify(localStorage.getItem("user")),
          },
        },
      });
      setPhysios(response?.data);
      setPageData(response?.data);
      setHasFetchedPhysios(true);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching physios:", error);
      setLoading(false);
    }
  };

  function handleBtn(event) {
    const { name } = event.target;
    if (name === "doctor") {
      document.getElementById("doctorBtn").classList.add("active");
      document.getElementById("physioBtn").classList.remove("active");
      setPageData(doctors); // Use the already fetched data
    } else if (name === "physio") {
      document.getElementById("doctorBtn").classList.remove("active");
      document.getElementById("physioBtn").classList.add("active");
      setPageData(physios); // Use the already fetched data
    }
    setView(name);

    resetFilter();
  }

  useEffect(() => {
    getDoctors();
    getPhysios();
  }, []);

  const experienceRanges = [
    { label: "< 10", min: 0, max: 9 },
    { label: "10 - 25", min: 10, max: 25 },
    { label: "> 25", min: 26, max: Infinity },
  ];

  useEffect(() => {
    let data = [];
    let dataLength = 0;

    if (view === "doctor") {
      data = doctors;
      dataLength = doctors.length;
    } else if (view === "physio") {
      data = physios;
      dataLength = physios.length;
    }

    if (data.length > 0) {
      // Filter data based on selected filters

      const filtered = data.filter((partner) => {
        if (
          selectedSpecializations.size === 0 &&
          selectedCities.size === 0 &&
          selectedExperience.size === 0 &&
          selectedExpertise.size === 0 &&
          selectedDegrees.size === 0
        ) {
          return true; // Show all data if no filters selected
        } else {
          const experience = partner.yearsOfExperience;
          const experienceMatches = experienceRanges.some(
            (range) =>
              selectedExperience.has(range.label) &&
              experience >= range.min &&
              experience <= range.max
          );

          return (
            (selectedSpecializations.size === 0 ||
              partner.specialization.some((spec) =>
                selectedSpecializations.has(spec)
              )) &&
            (selectedCities.size === 0 || selectedCities.has(partner.city)) &&
            (selectedExperience.size === 0 || experienceMatches) &&
            (selectedExpertise.size === 0 ||
              partner.areaOfExpertise.some((area) =>
                selectedExpertise.has(area)
              )) &&
            (selectedDegrees.size === 0 ||
              partner.degree.some((area) => selectedDegrees.has(area)))
          );
        }
      });

      setFilteredData(filtered);
    }
  }, [
    doctors,
    physios,
    selectedSpecializations,
    selectedCities,
    selectedExperience,
    selectedExpertise,
    selectedDegrees,
    view, // Include the view in the dependency array
  ]);

  const toggleSpecialization = (spec) => {
    const updatedSpecializations = new Set(selectedSpecializations);
    if (updatedSpecializations.has(spec)) {
      updatedSpecializations.delete(spec);
    } else {
      updatedSpecializations.add(spec);
    }
    setSelectedSpecializations(updatedSpecializations);
  };
  const toggleCity = (city) => {
    const updatedCities = new Set(selectedCities);
    if (updatedCities.has(city)) {
      updatedCities.delete(city);
    } else {
      updatedCities.add(city);
    }
    setSelectedCities(updatedCities);
  };

  const toggleExperience = (range) => {
    const updatedExperience = new Set(selectedExperience);
    if (updatedExperience.has(range.label)) {
      updatedExperience.delete(range.label);
    } else {
      updatedExperience.add(range.label);
    }
    setSelectedExperience(updatedExperience);
  };

  const toggleExpertise = (expertise) => {
    const updatedExpertise = new Set(selectedExpertise);
    if (updatedExpertise.has(expertise)) {
      updatedExpertise.delete(expertise);
    } else {
      updatedExpertise.add(expertise);
    }
    setSelectedExpertise(updatedExpertise);
  };
  const toggleDegrees = (degree) => {
    const updatedDegrees = new Set(selectedDegrees);
    if (updatedDegrees.has(degree)) {
      updatedDegrees.delete(degree);
    } else {
      updatedDegrees.add(degree);
    }
    setSelectedDegrees(updatedDegrees);
  };

  useEffect(() => {
    let data = [];
    if (view === "doctor") {
      data = doctors;
    } else if (view === "physio") {
      data = physios;
    }

    if (data.length > 0) {
      const specializations = [
        ...new Set(
          data.flatMap((partner) => partner.specialization).filter(Boolean)
        ),
      ];
      const degrees = [
        ...new Set(data.flatMap((partner) => partner.degree).filter(Boolean)),
      ];
      const expertises = [
        ...new Set(
          data.flatMap((partner) => partner.areaOfExpertise).filter(Boolean)
        ),
      ];
      const experiences = [
        ...new Set(
          data.map((partner) => partner.yearsOfExperience).filter((exp) => exp)
        ),
      ].sort((a, b) => b - a); // Sorts numbers in descending order;
      const cities = [
        ...new Set(data.map((partner) => partner.city).filter(Boolean)),
      ];

      setUniqueSpecializations(specializations);
      setUniqueCities(cities);
      setUniqueExpertise(expertises);
      setUniqueDegrees(degrees);
      setUniqueExperience(experiences);
    }
  }, [doctors, physios, view]); // Include both doctors, physios, and view in the dependency array

  console.log(uniqueSpecializations, "Unique Specializations");
  console.log(uniqueCities);
  console.log(uniqueExperience);
  console.log(uniqueExpertise);

  useEffect(() => {
    setLoading(true);
    if (view === "physio") {
      if (!hasFetchedPhysios) {
        getPhysios();
      } else {
        setPageData(physios);
      }
    } else if (view === "doctor") {
      if (!hasFetchedDoctors) {
        getDoctors();
      } else {
        setPageData(doctors);
      }
    }
    setLoading(false);
    setCurrentPage(1);
  }, [view]);

  const indexOfLastCard = currentPage * dataPerPage;
  const indexOfFirstCard = indexOfLastCard - dataPerPage;
  const currentData = filteredData.slice(indexOfFirstCard, indexOfLastCard);
  const lengthData = indexOfLastCard - indexOfFirstCard + 1;
  const initialPageNumber = Math.round(lengthData / 2);

  // <PaginationNextUI rounded total={lengthData} initialPage={Math.round((lengthData/2))} />

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [alertModal, setAlertModal] = useState({
    show: false,
    message: "",
    button: true,
  });

  const resetFilter = () => {
    // Clear all selected filter sets
    setSelectedSpecializations(new Set());
    setSelectedCities(new Set());
    setSelectedExperience(new Set());
    setSelectedExpertise(new Set());
    setSelectedDegrees(new Set());
  };

  return (
    <>
      <motion.div
        variants={imageVariant}
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          type: "spring",
          damping: 6,
          delay: 0.8,
          duration: 1,
        }}
      >
        <div className="flex md:px-12 md:h-[500px] flex-col pt-12 md:flex-row-reverse w-full items-center md:items-end bg-light backBlue justify-center relative">
          <img
            src={DoctorBanner}
            className="pt-2 md:pt-0 md:h-[410px] z-1000 "
            alt="Banner"
          />
          <div
            className={`flex flex-col justify-center mr-0 w-full pt-8 items-center md:items-start ${
              view === "doctor" ? `md:w-[50%]` : `md:w-[45%] md:mr-16`
            }`}
          >
            <h2
              className={`xxs:text-2xl text-center md:text-left md:text-7xl pb-6 font-black text-whyus flex justify-center items-center tracking-wide `}
            >
              {view === "doctor"
                ? "Find a doctor & book online"
                : "Find a physiotherapist & book online"}
            </h2>

            <p className="text-whyus text-lg text-center md:text-left">
              {view === "doctor"
                ? "Book a Session now for one on one doctor consultation and get answers to all your queries."
                : "Book a session now and choose to have a virtual physio session or a home visit with one of top rated physios."}
            </p>
            <div className="xxs:hidden md:block">
              <Buttonmui
                href="#DataCard"
                variant="contained"
                sx={{
                  height: "50px",
                  marginTop: "2rem",
                  zIndex: 100,
                }}
              >
                Contact Partners
              </Buttonmui>
            </div>

            <br />
          </div>
          <div className="md:absolute md:top-[92%] rounded-br-[30px] md:rounded-tr-0 py-4 rounded-tr-[30px] md:left-0  bg-[#e0e8f3] md:h-[80px] md:w-[90%] flex justify-center items-center ">
            <div className="w-full">
              <Herostats />
            </div>
          </div>
        </div>
      </motion.div>

      <div className="flex pt-4 md:pt-32 justify-center items-center flex-col">
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            position: "relative",
          }}
          className="py-4 gap-2 flex-col md:flex-row items-center bg-[#fafcfd]"
        >
          <div className=" inline-block">
            <h2
              className={`xxs:text-3xl md:text-7xl pb-6 font-black text-whyus text-opacity-90 tracking-wide md:absolute md:left-[13%] xl:left-[18%] md:-top-1/2 md:w-1/3`}
            >
              Choose a{" "}
              <span className="decoration-[#02B9ED] underline underline-offset-8">
                P
              </span>
              artner.
            </h2>
          </div>
          <div className="flex gap-4">
            <Buttonmui
              className="mx-5"
              name="doctor"
              id="doctorBtn"
              variant={view === "doctor" ? "contained" : "outlined"}
              onClick={handleBtn}
            >
              Doctors
            </Buttonmui>
            <Buttonmui
              className="mx-5"
              name="physio"
              id="physioBtn"
              variant={view === "physio" ? "contained" : "outlined"}
              onClick={handleBtn}
            >
              Physios
            </Buttonmui>
          </div>
        </div>

        {/* <div className="bg-[#fafcfd]">
        <div className="mr-3 py-3 text-center  w-100">
          <Buttonmui color="success" variant="outlined" size="lg" onClick={handleBookNow}>
            Book Now
          </Buttonmui>
        </div>
      </div> */}
        <div className="flex flex-col justify-center items-center bg-[#fafcfd] pt-0  md:pt-10 pb-8">
          {/* <PaginationNextUI css={{color: "White"}} shadow total={lengthData} initialPage={initialPageNumber} /> */}
          <br />
          <div className=""></div>
          <PaginationH
            dataPerPage={dataPerPage}
            totalData={filteredData.length}
            currentPage={currentPage}
            onPageChange={paginate}
          />
        </div>
        <div className="bg-[#fafcfd] flex flex-col md:flex-row items-center md:items-start justify-between gap-8">
          <div className="space-y-2 flex flex-row flex-wrap md:flex-col gap-2 justify-center items-baseline">
            <details className="w-[200px]  overflow-y-auto rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden ">
              <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white p-4 text-gray-900 transition">
                <span className="text-sm font-medium"> City </span>

                <span className="transition group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </summary>

              <div
                className="border-t h-[200px] border-gray-200 bg-white"
                style={{ overflowY: "auto" }}
              >
                <header className="flex items-center justify-between p-4">
                  <span className="text-sm text-gray-700">
                    {" "}
                    {selectedCities.size} Selected{" "}
                  </span>

                  <button
                    type="button"
                    className="text-sm text-gray-900 underline underline-offset-4"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </header>

                <ul className="space-y-1 border-t border-gray-200 p-4">
                  {uniqueCities.map((city) => (
                    <li key={city}>
                      <label
                        htmlFor={`Filter${city}`}
                        className="inline-flex items-center gap-2"
                      >
                        <input
                          type="checkbox"
                          id={`Filter${city}`}
                          className="h-5 w-5 rounded border-gray-300"
                          checked={selectedCities.has(city)}
                          onChange={() => toggleCity(city)}
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {city}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </details>
            <details className="w-[200px]  overflow-y-auto rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white p-4 text-gray-900 transition">
                <span className="text-sm font-medium"> Degree </span>

                <span className="transition group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </summary>

              <div
                className="border-t border-gray-200 bg-white h-[200px]"
                style={{ overflowY: "auto" }}
              >
                <header className="flex items-center justify-between p-4">
                  <span className="text-sm text-gray-700">
                    {" "}
                    {selectedDegrees.size} Selected{" "}
                  </span>

                  <button
                    type="button"
                    className="text-sm text-gray-900 underline underline-offset-4"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </header>

                <ul className="space-y-1 border-t border-gray-200 p-4">
                  {uniqueDegrees.map((degree) => (
                    <li key={degree}>
                      <label
                        htmlFor={`Filter${degree}`}
                        className="inline-flex items-center gap-2"
                      >
                        <input
                          type="checkbox"
                          id={`Filter${degree}`}
                          className="h-5 w-5 rounded border-gray-300"
                          checked={selectedDegrees.has(degree)}
                          onChange={() => toggleDegrees(degree)}
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {degree}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </details>

            <details className="w-[200px]  overflow-y-auto rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white p-4 text-gray-900 transition">
                <span className="text-sm font-medium"> Specialization</span>

                <span className="transition group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </summary>

              <div
                className="border-t border-gray-200 bg-white h-[200px]"
                style={{ overflowY: "auto" }}
              >
                <header className="flex items-center justify-between p-4">
                  <span className="text-sm text-gray-700">
                    {" "}
                    {selectedSpecializations.size} Selected{" "}
                  </span>

                  <button
                    type="button"
                    className="text-sm text-gray-900 underline underline-offset-4"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </header>

                <ul
                  className="space-y-1 border-t border-gray-200 p-4"
                  style={{ overflowY: "auto" }}
                >
                  {uniqueSpecializations.map((specialization) => (
                    <li key={specialization}>
                      <label
                        htmlFor={`Filter${specialization}`}
                        className="inline-flex items-center gap-2"
                      >
                        <input
                          type="checkbox"
                          id={`Filter${specialization}`}
                          className="h-5 w-5 rounded border-gray-300"
                          checked={selectedSpecializations.has(specialization)}
                          onChange={() => toggleSpecialization(specialization)}
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {specialization}
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </details>

            {view === "physio" && (
              <details className="w-[200px]  overflow-y-auto rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden">
                <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white p-4 text-gray-900 transition">
                  <span className="text-sm font-medium">
                    {" "}
                    Area of Expertise{" "}
                  </span>

                  <span className="transition group-open:-rotate-180">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                  </span>
                </summary>

                <div
                  className="border-t border-gray-200 bg-white h-[200px]"
                  style={{ overflowY: "auto" }}
                >
                  <header className="flex items-center justify-between p-4">
                    <span className="text-sm text-gray-700">
                      {" "}
                      {selectedExpertise.size} Selected{" "}
                    </span>

                    <button
                      type="button"
                      className="text-sm text-gray-900 underline underline-offset-4"
                      onClick={resetFilter}
                    >
                      Reset
                    </button>
                  </header>

                  <ul className="space-y-1 border-t border-gray-200 p-4">
                    {uniqueExpertise.map((expertise) => (
                      <li key={expertise}>
                        <label
                          htmlFor={`Filter${expertise}`}
                          className="inline-flex items-center gap-2"
                        >
                          <input
                            type="checkbox"
                            id={`Filter${expertise}`}
                            className="h-5 w-5 rounded border-gray-300"
                            checked={selectedExpertise.has(expertise)}
                            onChange={() => toggleExpertise(expertise)}
                          />
                          <span className="text-sm font-medium text-gray-700">
                            {expertise}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              </details>
            )}

            <details className="w-[200px]  overflow-y-auto rounded border border-gray-300 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-2 bg-white p-4 text-gray-900 transition">
                <span className="text-sm font-medium">
                  {" "}
                  Years Of Experience{" "}
                </span>

                <span className="transition group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </span>
              </summary>

              <div
                className="border-t border-gray-200 bg-white h-[200px]"
                style={{ overflowY: "auto" }}
              >
                <header className="flex items-center justify-between p-4">
                  <span className="text-sm text-gray-700">
                    {" "}
                    {selectedExperience.size} Selected{" "}
                  </span>
                  <button
                    type="button"
                    className="text-sm text-gray-900 underline underline-offset-4"
                    onClick={resetFilter}
                  >
                    Reset
                  </button>
                </header>
                <ul className="space-y-1 border-t border-gray-200 p-4">
                  {experienceRanges.map((range) => (
                    <li key={range.label}>
                      <label
                        htmlFor={`Filter${range.label}`}
                        className="inline-flex items-center gap-2"
                      >
                        <input
                          type="checkbox"
                          id={`Filter${range.label}`}
                          className="h-5 w-5 rounded border-gray-300"
                          checked={selectedExperience.has(range.label)}
                          onChange={() => toggleExperience(range)}
                        />
                        <span className="text-sm font-medium text-gray-700">
                          {range.label} Years
                        </span>
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </details>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-2 gap-y-4   justify-center items-center">
            {loading ? (
              <div>
                <CircularProgress sx={{ color: "blue" }} />
              </div>
            ) : (
              currentData.map((item, idx) => (
                <div
                  id="DataCard"
                  key={idx}
                  className="bg-white flex flex-col items-center justify-center rounded-md w-[350px] h-auto relative pt-[12%] drop-shadow-md drop-shadow-slate-300 "
                >
                  <div className="ribbon">
                    {/* <!-- The ribbon --> */}
                    <span>Verified</span>
                  </div>
                  <div className="absolute flex justify-center items-center top-5 left-0 h-[12%] w-[20%] bg-[#fff4ef] rounded-r-lg">
                    <span className="text-[#fcbd3c] flex justify-center gap-1 items-center ">
                      <Star />
                      <span>
                        {parseFloat(item.rating.$numberDecimal).toFixed(1)}
                      </span>
                    </span>
                  </div>
                  <img
                    src={item.photoUrl || DocIcon}
                    className="w-[100px] h-[100px] object-cover rounded-full mb-4 shadow-md shadow-1primary"
                    alt="docIcon"
                  />
                  <span className="tracking-wide text-lg text-slate-600">
                    {item.name}
                  </span>
                  <Typography variant="caption">{item.city}</Typography>
                  <span className="text-[#aeb1bf] my-4">
                    {item.yearsOfExperience} years of Experience
                  </span>
                  <span className="w-[70%] py-3 mb-4 rounded-md bg-[#e4edff] tracking-wide text-sm text-[#2b78ff] font-semibold text-center">
                    {item.specialization[1]
                      ? item.specialization[1]
                      : item.specialization[0] || `Orthopedics`}
                  </span>
                  <span className="text-slate-600 mb-4">
                    {item.degree[1]
                      ? `${item.degree[0]}, ${item.degree[1]}`
                      : item.degree[0]}
                  </span>
                  <div className="bg-[#aeb1bf] opacity-20 w-full h-[1.2px] "></div>
                  <div className="flex justify-center items-center h-[20%]   w-full relative">
                    <Buttonmui
                      sx={{
                        color: "#aeb1bf",
                        width: "50%",
                        height: "100%",
                        paddingY: "4%",
                        letterSpacing: "1.5px",
                      }}
                      onClick={() => handleOpenMuiModal(item)}
                    >
                      Contact Partner
                    </Buttonmui>
                    {/* Modal */}
                    <Dialog
                      open={open}
                      onClose={handleCloseMuiModal}
                      className="my-dialog"
                    >
                      <DialogTitle>Contact Partner</DialogTitle>
                      <DialogContent sx={{ paddingBottom: "2px" }}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userName"
                          name="userName"
                          label="Your Name"
                          type="text"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          value={userData.userName}
                          inputProps={{
                            style: {
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                              marginTop: "2px",
                            },
                          }}
                        />
                      </DialogContent>
                      <DialogContent sx={{ paddingTop: "2px" }}>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userPhone"
                          name="userPhone"
                          label="Your Phone"
                          type="tel"
                          variant="standard"
                          fullWidth
                          onChange={handleChange}
                          value={userData.userPhone}
                          inputProps={{
                            style: {
                              paddingLeft: "6px",
                              paddingRight: "6px",
                              paddingTop: "4px",
                              paddingBottom: "4px",
                              marginTop: "2px",
                            },
                          }}
                        />
                      </DialogContent>
                      <DialogActions>
                        <Buttonmui
                          onClick={handleCloseMuiModal}
                          color="primary"
                        >
                          Cancel
                        </Buttonmui>
                        <Buttonmui
                          onClick={handleSubmitMuiDialog}
                          color="primary"
                        >
                          Submit
                        </Buttonmui>
                      </DialogActions>
                    </Dialog>

                    <Buttonmui
                      sx={{
                        color: "#aeb1bf",
                        width: "50%",
                        height: "100%",
                        paddingY: "4%",
                        letterSpacing: "1.5px",
                      }}
                      href={`${view}/${item.slug}`}
                    >
                      Know More
                    </Buttonmui>
                    <div className="w-[1.6px] absolute bg-[#aeb1bf] opacity-20 h-full left-1/2"></div>
                  </div>
                </div>
              ))
            )}
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical, horizontal }}
            >
              <MuiAlert
                onClose={handleCloseSnackbar}
                severity={alertSeverity}
                elevation={6}
                variant="filled"
              >
                {snackbarMessage}
              </MuiAlert>
            </Snackbar>
          </div>
        </div>
      </div>
    </>
  );
};

function Consult(props) {
  return (
    <div className="bg-[#fafcfd]">
      <div className="pb-[2%] ">
        <Nav />
      </div>
      <Helmet>
        <title>Partners | CasaMed</title>
        <meta
          name="description"
          content="At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="physiotherapy treatment for back pain, physiotherapy for back pain, physiotherapy treatment for low back pain, therapy for lower back pain, back pain therapy, physiotherapy treatment"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/partners" />
        {/* Open Graph */}
        <meta property="og:title" content="Partners | CasaMed" />
        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content="https://casamed.in/partners" />
        <meta
          property="og:description"
          content="At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Partners | CasaMed",
            url: "https://casamed.in/partners",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "At CasaMed, we take pride to have the best Doctors & Physiotherapists as our partners. Read to know about them in detail.",
          })}
        </script>
      </Helmet>
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      <ConsultComponent props={props} />
      <Footer />
    </div>
  );
}

export default Consult;
