import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet'

const EditorialGuidelines = () => {
  return (
    <div className='og--bg--grad--inv'>

<Helmet>
        {/* Meta title and Description */}
        <title>
          Editorial Policy | CasaMed
        </title>
        <meta
          name="description"
          content="CasaMed Editorial Policy: Trustworthy health information. We maintain strict standards to ensure accuracy, objectivity, and transparency. Learn more."
        />
        
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/editorial-policy"
        />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Editorial Policy | CasaMed"
        />

        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content="https://casamed.in/editorial-policy"
        />
        <meta
          property="og:description"
          content="CasaMed Editorial Policy: Trustworthy health information. We maintain strict standards to ensure accuracy, objectivity, and transparency. Learn more."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content= "https://casamed.in/static/media/casamed.781b488579b2af913fa91f7ef30c42de.svg"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Editorial Policy | CasaMed",
            url: "https://casamed.in/editorial-policy",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed.781b488579b2af913fa91f7ef30c42de.svg",
            },
            description:
              "CasaMed Editorial Policy: Trustworthy health information. We maintain strict standards to ensure accuracy, objectivity, and transparency. Learn more.",
          })}
        </script>
      </Helmet>
        <div className='xxs:pb-[25%] xs:pb-[20%] ss:pb-[15%] sm:pb-[10%] md:pb-[10%] lg:pb-[6%]'>
        <Nav/>
        </div>
        <div className='sm:px-[15%] xxs:px-[10%]' >
        <h1 className='text-whyus text-center xxs:text-xl sm:text-4xl font-medium pb-4'><strong className='decoration-[#02B9ED] underline underline-offset-8'>E</strong>ditorial Guideline</h1>
        <p className="pb-2 xxs:text-md sm:text-lg ">We believe in helping you along your journey toward a pain-free life by giving you the latest research and insights on various health topics written by authors who specialise in their respective fields to help you on your path to a pain-free life.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">We are committed to providing accurate and reliable information, hence we have our best doctors review all the content to ensure that the information is updated and accurate.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Each content passes through several steps before getting published on CasaMed.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">The review process is as follows:</p>
      <ul className='xxs:text-md sm:text-lg list-inside list-disc pb-4'>
        <li>Research with Medical professional Input</li>
        <li>Expertly written by an author who specialises in that particular subject</li>
        <li>Medically reviewed by our best doctors</li>
        <li>Organising it through editing</li>
        <li>Proofreads and Fact-checking</li>
        <li>Review of the final draft</li>
        <li>Publish</li>
      </ul>
      <p className="pb-2 xxs:text-md sm:text-lg ">The process of creating content starts with developing ideas and then selecting those ideas based on various guidelines.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">When a topic is finalised, our medical writer conducts research with our pre-designed process to ensure he finds out as much as possible about the topics that must be addressed.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">With the assistance of our medical team, the outlining process begins to ensure all topics that need to be covered are properly outlined.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">We start the content writing part once all the resources have been selected and the outline has been completed. Our dedicated writing team then begins to work on the topic.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">In addition to thorough research, they use a pre-made checklist to ensure quality and detail in their content while explaining your health in a way you will enjoy reading.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Their goal is to ensure all information is accurate, balanced, current, and suitable for families.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">The articles on CasaMed are reviewed by physicians and other health experts before they are published in order to ensure accurate and up-to-date medical information at all times.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Articles also display the review date and name of the expert reviewer, along with the writer, to showcase their expertise. Moreover, we also review our content to ensure all the content published on our website is up to date and meets the latest healthcare guidelines.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Throughout the process of editing, proofreading, and fact-checking, we ensure all the content published on our website is factual, grammatically correct, and structured so the user can get the most accurate information on any topic possible.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Our dedicated editing team handles all these things by reviewing every piece of content, ensuring it passes and fulfils the criteria before moving forward to the final stage.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Once all the processes and criteria are fulfilled, the content moves to a final draft review for publication. The Founding and SEO Team checks the content on several parameters to ensure it is factual, current, and approved by medical professionals and others before publishing it.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">After it is approved, it goes through final editing, which involves adding resources, inserting images, linking out to references, and finally getting published.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">The CasaMed editors and experts review each article's resources and links to ensure they provide high-quality information.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">Although CasaMed makes every effort to verify the accuracy and up-to-dateness of the information provided by these resources, the information tends to change, and not all of it is necessarily up-to-date.</p>
      <p className="pb-2 xxs:text-md sm:text-lg ">It is the sole intention of the CasaMed expert team to provide these resources for your convenience and not to endorse them in any way.</p>
      <p className="xxs:text-md sm:text-lg pb-4">Our apologies if you've encountered any errors on our end. We're working hard to ensure everything runs smoothly (we're still human), but we do appreciate your feedback and insights.</p>
      <p className="pb-2 xxs:text-md sm:text-lg italic">Please feel free to reach out to us at support@casamed.in with any concerns or comments.</p>
      </div>
      <div className=''>
        <Footer />
      </div>
    </div>
  )
}

export default EditorialGuidelines