import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import { Button } from '@mui/material';
import  { Form, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { typeOf } from 'mathjs';

function TestCountry2() {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setnumber] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
//   const [countryName, setCountryName] = useState('');
//   const [stateName, setStateName] = useState('');

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const history = useNavigate()


  useEffect(() => {
    console.log('Making API call to get countries data');
    axios.get('https://api.countrystatecity.in/v1/countries', {
      headers: {
        'X-CSCAPI-KEY': 'M2NyWlE5Vm9XV1JVaWdhOElKNkRFN2NsVmdyR1FCMGxZWUJkMTNOYw=='
      }
    })
    .then(response => {
      // console.log('Response:', response.data);
      const countries = response.data || [];
      setCountries(countries);
      setSelectedCountry('');
      setStates([]);
      setSelectedState('');
      setCities([]);
      setSelectedCity('');
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    if (selectedCountry) {


      console.log('Making API call to get states data');
      axios.get(`https://api.countrystatecity.in/v1/countries/${selectedCountry}/states`, {
        headers: {
          'X-CSCAPI-KEY': 'M2NyWlE5Vm9XV1JVaWdhOElKNkRFN2NsVmdyR1FCMGxZWUJkMTNOYw=='
        }
      })
      .then(response => {
        // console.log('Response:', response.data);
        const states = response.data || [];
        setStates(states);
        setSelectedState('');
        setCities([]);
        setSelectedCity();
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {

      console.log('Making API call to get cities data');
      axios.get(`https://api.countrystatecity.in/v1/countries/${selectedCountry}/states/${selectedState}/cities`, {
        headers: {
          'X-CSCAPI-KEY': 'M2NyWlE5Vm9XV1JVaWdhOElKNkRFN2NsVmdyR1FCMGxZWUJkMTNOYw=='
        }
      })
      .then(response => {
        // console.log('Response:', response.data);
        const cities = response.data || [];
        setCities(cities);
        setSelectedCity('');
      })
      .catch(error => {
        console.log(error);
      });
    }
  }, [selectedCountry, selectedState]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    // setCountryName(event.target.value2);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    // setStateName(event.target.value2);

  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  // console.log(countries, 'countries')
  // console.log(states, 'states')
  // console.log(selectedCountry, 'selected country')

  const filteredCountry = countries.filter(country => country.iso2 === selectedCountry);
  const filteredState = states.filter(state => state.iso2 === selectedState);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // const { name, value } = e.target
    const response = await axios.post('/api/newusers2', {
      country: filteredCountry[0].name,
      state: filteredState[0].name,
      city: selectedCity, // for demo purposes, select the first city in the list
      firstName,
      lastName,
      email,
      number,
      subject,
      message,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }, {
      headers: {
        Authorization: localStorage.getItem('token'),
        auth: {
          user: JSON.stringify(localStorage.getItem('user')),
        },
      },
    })
    .then((response) =>{
        alert("Form Submiited Successfully")
        setIsSubmitted(true);
        console.log(response.data, typeOf(response.data)); // do something with the response
    })
    .catch((error) =>{
        setErrorMessage("Please Fill the Form Correctly")
        console.log(error)
    })

  };

  if (isSubmitted) {
    // Redirect to homepage after successful form submission
    history("/");
    return null;
  }


  return (
    <form onSubmit={handleSubmit}>
    <div className='flex flex-col items-start gap-3'>

    <div className='flex xxs:flex-col md:flex-row justify-center items-baseline'>

        <Form.Group className="xxs:w-full md:w-1/2 xxs:px-3 md:px-0">
                <Form.Label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                First Name
                </Form.Label>
                <Form.Control className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" 
                    type="text" 
                    id="firstName"
                    name="firstName" 
                    value={firstName} 
                    placeholder="Lance"
                    onChange={(e) => setFirstName(e.target.value)}
                    required 
                />
                
        </Form.Group>
        <Form.Group className="xxs:w-full md:w-1/2 px-3">
        <Form.Label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
            Last Name
        </Form.Label>
        <Form.Control className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
            type="text"
            id="lastName" 
            name="lastName" 
            value={lastName} 
            placeholder="Reddick"
            onChange={(e) => setLastName(e.target.value)}
            required 
        />
        </Form.Group>
      </div>


      <div className='flex  xxs:flex-col md:flex-row justify-between items-baseline'> 
      <div className='flex flex-col w-full md:w-1/3 xxs:px-3 md:px-0 items-baseline mr-8 '> 
      <label htmlFor="country-select" className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Country:</label>
      <select id="country-select"  value={selectedCountry} onChange={handleCountryChange} required className='block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'>
        <option value="">Select a Country</option>
        {countries.map(country => (
          <option key={country.iso2}  value={country.iso2}>{country.name}</option>
        ))}
      </select>
      </div>
      

      
      <div className='flex  xxs:px-3  md:px-0 flex-col items-baseline mr-8'>
          <label htmlFor="state-select" className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>State:</label>
          <select id="state-select" value={selectedState} onChange={handleStateChange} required className='block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'>
            <option value="">Select a State</option>
            {states.map(state => (
              <option key={state.iso2}  value={state.iso2}>{state.name}</option>
            ))}
            </select>
        </div>
      
      

    
        <div className='flex  xxs:px-3  md:px-0 flex-col items-baseline '>        
          <label htmlFor="city-select" className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2' >City:</label>
          <select id="city-select" value={selectedCity} onChange={handleCityChange} required="required" className='block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white'>
            <option value="">Select a City</option>
            {cities.map(city => (
              <option key={city.name} value={city.name}>{city.name}</option>
            ))}
            </select>
        </div>
        </div>

    <div className='flex xxs:px-3 md:px-0 xxs:flex-col md:flex-row justify-between gap-3'> 
      <div className='flex flex-col mb-2'>
        <label htmlFor="email" className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
        />
      </div>
      <div className='flex flex-col mb-2'>
        <label htmlFor="number" className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Mobile Number</label>
        
        <Form.Control className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                type="text" 
                name="number" 
                value={number} 
                placeholder="9876543210"
                onChange={(e) => setnumber(e.target.value)}
                required
            />
      </div>
      </div>
    
      

      <div>
      <Form.Group className='xxs:px-3  md:px-0'>
            <Form.Label className='block uppercase tracking-wide text-gray-700 text-xs font-bold '>
                Select Subject?
            </Form.Label>
            <Form.Check className='flex flex-wrap gap-6 items-center'
                type='radio'
                name="subject"
                value={subject}
                required="required"
            >
                <Form.Check.Input 
                    type='radio'
                    name='subject'
                    value='general' 
                    onChange={(e) => setSubject(e.target.value)}  
                />
                <Form.Check.Label>
                    General Inquiry
                </Form.Check.Label>
            </Form.Check>
            <Form.Check className='flex flex-wrap gap-6 items-center'
                type='radio'
                value={subject}
                required="required"
            >
                <Form.Check.Input 
                    type='radio'
                    name='subject'
                    value='corporate' 
                    onChange={(e) => setSubject(e.target.value)}  
                />
                <Form.Check.Label>
                    Corporate Inquiry
                </Form.Check.Label>
            </Form.Check>
            <Form.Check className='flex flex-wrap gap-6 items-center'
                type='radio'
                value={subject}
                required="required"   
            >
                <Form.Check.Input 
                    type='radio'
                    name='subject'
                    value='product'  
                    onChange={(e) => setSubject(e.target.value)} 
                />
                <Form.Check.Label>
                    Product Issue
                </Form.Check.Label>
            </Form.Check>
        </Form.Group>
      </div>

      <Form.Group className='my-6 w-full xxs:px-3  md:px-0'>
        <label htmlFor="message" className='block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2'>Message</label>
        
        <Form.Control
        className='w-full'
        as='textarea'
        name='message'
        value={message}
        placeholder="Write Your Message (Optional)"
        rows={4}
        onChange={(e) => setMessage(e.target.value)}
        />
      </Form.Group>

      
    </div>
    <div className='flex justify-center'>
      <Button type="submit" className='px-5 py-[9px] items-center capitalize active:-translate-y-1 cursor-pointer font-poppins hover:-translate-y-2 duration-300 text-white font-[600] bg-casablue rounded-[12px]'>Submit</Button>
    </div>
    </form>
  )
      
 }

 export default TestCountry2
         
