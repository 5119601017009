import arrowup from "../Assets/HomeViews/HeaderStats/arrowup.svg";
import arrowdown from "../Assets/HomeViews/HeaderStats/arrowdown.svg";

export const shops = [
  {
    img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Shop/shop2girl.jpeg",
  },
  {
    img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Shop/shoparm.jpeg",
  },
  {
    img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Shop/shopbelt.jpeg",
  },
  {
    img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Shop/shopkneeblue.jpeg",
  },
];

export const states = [
  {
    label: "Andhra Pradesh",
    value: "ap",
  },
  {
    label: "Arunachal Pradesh",
    value: "arunachal",
  },
  {
    label: "Assam",
    value: "assam",
  },
  {
    label: "Bihar",
    value: "bihar",
  },
  {
    label: "Chhatisgarh",
    value: "chtis",
  },
  {
    label: "Goa",
    value: "ga",
  },
  {
    label: "Gujarat",
    value: "gj",
  },
  {
    label: "Haryana",
    value: "hr",
  },
  {
    label: "Jharkhand",
    value: "jh",
  },
  {
    label: "Karnataka",
    value: "ka",
  },
  {
    label: "Kerala",
    value: "kl",
  },
  {
    label: "Madhya Pradesh",
    value: "mp",
  },
  {
    label: "Maharashtra",
    value: "mh",
  },
  {
    label: "Manipur",
    value: "manipur",
  },
  {
    label: "Meghalaya",
    value: "meghalaya",
  },
  {
    label: "Mizoram",
    value: "mizoram",
  },
  {
    label: "Nagaland",
    value: "nl",
  },
  {
    label: "Odisha",
    value: "od",
  },
  {
    label: "Punjab",
    value: "pb",
  },
  {
    label: "Rajasthan",
    value: "rj",
  },
  {
    label: "Sikkim",
    value: "sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "tn",
  },
  {
    label: "Telangana",
    value: "tl",
  },
  {
    label: "Tripura",
    value: "tripura",
  },
  {
    label: "Uttar Pradesh",
    value: "up",
  },
  {
    label: "Uttarakhand",
    value: "uk",
  },
  {
    label: "West Bengal",
    value: "wb",
  },
  {
    label: "Andaman and Nicobar",
    value: "andaman",
  },
  {
    label: "Chandigarh",
    value: "ch",
  },
  {
    label: "DNHDD",
    value: "dnhdd",
  },
  {
    label: "Delhi",
    value: "dl",
  },
  {
    label: "Jammu and Kashmir",
    value: "jk",
  },
  {
    label: "Lakshadweep",
    value: "lakshadweep",
  },
  {
    label: "Ladakh",
    value: "ladakh",
  },
  {
    label: "Puducherry",
    value: "pudu",
  },
];

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "partners",
    title: "Partners",
  },
  {
    id: "product",
    title: "Product",
  },
  {
    id: "blog",
    title: "Blog",
  },
  {
    id: "help",
    title: "Help",
  },
  {
    id: "connect",
    title: "Connect",
  },
];

export const items = [
  {
    heading: "Expert Consultation - Case Assessment by Super-Specialists",
    text1:
      "Prompt Pain Management by the country's leading Orthopaedicians and Physiotherapists",
    text2: "Medical History and Investigation Evaluation.",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/featureImage1.png",
    style: "md:ml-20",
  },
  {
    heading: "detailed statistics",
    text1:
      "Multi-level Analysis powered by Smart Physio Analysis helping you to assess your Recovery Journey",
    text2:
      "Diagnostic Tools designed by experts for accurate identification of problem areas.",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/feature-2-img.png",
    style: "md:flex-row-reverse  ",
  },
  {
    heading: "Computer Vision enabled AI assist ",
    text1: "While generic posture correction apps tract only a single joint,",
    text2:
      "CasaMed's CasaAI Motion Assist™️ tracks the complete body motion to give a much more natural PT experience ",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/motionassist.png",
    style: "md:ml-20",
  },
  {
    heading: "Live Sessions",
    text1:
      "Get all your pain management needs sorted by our expert live sessions",
    text2:
      "Covering a foray of pain areas such as Orthopaedic, Posture Issues, Gynae/PCOD related, you can rest assured that all your queries shall be heard and answered !",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/live.png",
    style: "md:flex-row-reverse  ",
  },
  {
    heading: "Smart Diagnostics",
    text1:
      "AI based smart diagnostics tool with Logic driven Symptom Addressal which helps you figure out the best plan for you.",
    text2:
      "Choose from over 9 Pain points - Result displayed instantly with recommended plans",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/painpoints.png",
    style: "md:flex-row-reverse  ",
  },
  {
    heading: "Personalised Plans",
    text1:
      "A plethora of Affordable Subscription Plans to choose from. High Degree of Personalization results in a pain management plan curated just for you!",
    text2:
      "These plans also Smart-modify themselves based on continuous user feedback.",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/plans.svg",
    style: "md:flex-row-reverse  ",
  },
  {
    heading: "Audio - Visual Guidance",
    text1:
      "Library of over 500 exercises takes care of all your pain-management needs through high quality Audio and Video guidance throughout the exercise.",
    text2:
      "CasaMed's intuitive UI makes sure that your pain relief journey is as hassle free as possible.",
    button: "learn more",
    image:
      "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9*/HomeViews/Features/videoguidance.svg",
    style: "md:flex-row-reverse  ",
  },
];

// export const feedback = [
//   {
//     id: "feedback-1",
//     content:
//       "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
//     name: "Herman Jensen",
//     title: "Founder & Leader",
//     img: people01,
//   },
//   {
//     id: "feedback-2",
//     content:
//       "Money makes your life easier. If you're lucky to have it, you're lucky.",
//     name: "Steve Mark",
//     title: "Founder & Leader",
//     img: people02,
//   },
//   {
//     id: "feedback-3",
//     content:
//       "It is usually people in the money business, finance, and international trade that are really rich.",
//     name: "Kenn Gallagher",
//     title: "Founder & Leader",
//     img: people03,
//   },
// ];

export const stats = [
  {
    id: "stats-1",
    title: `AVERAGE PAIN REDUCTION PER USER`,
    value: "68%",
    arrow: arrowdown,
  },
  {
    id: "stats-2",
    title: `DEPRESSION & ANXIETY REDUCTION`,
    value: "58%",
    arrow: arrowdown,
  },
  {
    id: "stats-3",
    title: "INCREASE IN WORK PRODUCTIVITY",
    value: "62%",
    arrow: arrowup,
  },
];
// export const statsProb = [
//   {
//     id: "stats-1",
//     title: `MSK + ANTENATAL`,
//     pic: MSKstats,
//   },
//   {
//     id: "stats-2",
//     title: `PCOD/S`,
//     pic: PCODstats,
//   },
//   {
//     id: "stats-3",
//     title: "",
//     pic: Totalstats,
//   },
// ];

// export const footerLinks = [
//   {
//     title: "Useful Links",
//     links: [
//       {
//         name: "Content",
//         link: "https://www.hoobank.com/content/",
//       },
//       {
//         name: "How it Works",
//         link: "https://www.hoobank.com/how-it-works/",
//       },
//       {
//         name: "Create",
//         link: "https://www.hoobank.com/create/",
//       },
//       {
//         name: "Explore",
//         link: "https://www.hoobank.com/explore/",
//       },
//       {
//         name: "Terms & Services",
//         link: "https://www.hoobank.com/terms-and-services/",
//       },
//     ],
//   },
//   {
//     title: "Community",
//     links: [
//       {
//         name: "Help Center",
//         link: "https://www.hoobank.com/help-center/",
//       },
//       {
//         name: "Partners",
//         link: "https://www.hoobank.com/partners/",
//       },
//       {
//         name: "Suggestions",
//         link: "https://www.hoobank.com/suggestions/",
//       },
//       {
//         name: "Blog",
//         link: "https://www.hoobank.com/blog/",
//       },
//       {
//         name: "Newsletters",
//         link: "https://www.hoobank.com/newsletters/",
//       },
//     ],
//   },
//   {
//     title: "Partner",
//     links: [
//       {
//         name: "Our Partner",
//         link: "https://www.hoobank.com/our-partner/",
//       },
//       {
//         name: "Become a Partner",
//         link: "https://www.hoobank.com/become-a-partner/",
//       },
//     ],
//   },
// ];

// export const socialMedia = [
//   {
//     id: "social-media-1",
//     icon: instagram,
//     link: "https://www.instagram.com/",
//   },
//   {
//     id: "social-media-2",
//     icon: facebook,
//     link: "https://www.facebook.com/",
//   },
//   {
//     id: "social-media-3",
//     icon: twitter,
//     link: "https://www.twitter.com/",
//   },
//   {
//     id: "social-media-4",
//     icon: linkedin,
//     link: "https://www.linkedin.com/",
//   },
// ];
