import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const PTTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Get the Best Physical Therapy Treatment
      </h1>
      <div className="mx-5">
        <p>
          Physical therapy helps people to overcome injuries, physical
          illnesses, that restrict their ability to function and move. It helps
          heal people of all age groups including children, adults, and old
          people. <strong>Physical therapy enables</strong> them in getting back
          to their prior working condition and encourages them to change their
          lifestyle and activities to prevent themselves from future injuries
          and get healthier life and body. Even doctors recommend physical
          therapy treatment when they notice early symptoms. This is referred to
          as a conservative approach to dealing with problems.
        </p>
        <p>
          Casamed&#39;s physical therapists are well qualified, talented,
          trained, and have been offering best quality service for years. They
          diagnose and heal people enabling them to perform their daily
          activities like before. Our therapists not only helps in restoring the
          mobility and physical functions but also guide towards a better life
          to boost your wellness. Through{" "}
          <strong>physical therapy treatment</strong>, people can avoid the
          chances of surgery and get cured without consuming drugs.
        </p>
        <p>
          Our therapists have deep knowledge of the subject and are extremely
          skilled at performing their work. Our experts motivate and encourage
          people for the toughest problem of physical dysfunction and guide them
          to help alleviate the pain they are feeling. They apply the knowledge
          of neuroscience, cardiovascular, pulmonary system, exercise
          physiology, anatomy, and other disciplines in the process of treating
          their patient. Apart from that our therapists have good communication
          skills and build strong interpersonal relationships to provide
          compassionate care. They apply several physical therapy treatment
          techniques such as electrical stimulation, Ultrasound, TENS and more
          to effectively cure their patients.
        </p>
        <p>
          Casamed also offers online consultation and sessions. If you are
          unable to reach the clinic of a physical therapist we assist you and
          offer you services through online video calls.
        </p>
        <p>What are the benefits which Casamed offers?</p>
        <h2>Benefits of Choosing Casamed For Physical Therapy</h2>
        <p>
          <strong>Experienced physiotherapist-</strong> At Casamed, we have
          highly experienced physiotherapists. They know how to handle critical
          situations and treat you effectively. Through our treatment patients
          gain back complete mobility and the ability to perform their tasks.
        </p>
        <p>
          <strong>Helps recover from stroke-</strong> After stroke, some people
          lose their ability to move and function. Our specialists help
          strengthen the weak part of the body and assist you in improving
          balance and gait.
        </p>
        <p>
          <strong>Convenience-</strong> Post examination of the medical history
          of the patient, our therapists treat you. They apply the method of
          treatment that is convenient for you to perform. They are friendly and
          motivate you throughout their sessions.
        </p>
        <p>
          <strong>Online services-</strong> You can book an appointment and get
          online consultation through Casamed. Through our website, you get full
          information about the services we render.
        </p>
        <p>
          Hence through <strong>physical therapy</strong>, you recover from
          injury, illness, and other health-related issues and successfully
          overcome pain. Casamed therapists are always ready to offer services
          and assist you in overcoming all the health issues you face.
        </p>
      </div>
    </div>
  );
};

const PhysicalTherapyTreatment = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      {/* Meta Title and Description */}
      <Helmet>
        <title>
          Best Physiotherapy treatment & Theracon physical therapy – Casamed
        </title>
        <meta
          name="description"
          content="Experience the best physiotherapy treatment at CasaMed, tailored to your optimal recovery and well-being needs. Schedule your Appointment Now!"
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="physical therapy treatment" />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/physical-therapy-treatment"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content=" Physical Therapy Treatment | Casamed"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/physical-therapy-treatment"
        />
        <meta
          property="og:description"
          content="Experience the best physiotherapy treatment at CasaMed, tailored to your optimal recovery and well-being needs. Schedule your Appointment Now!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: " Physical Therapy Treatment | Casamed ",
            url: " https://casamed.in/physical-therapy-treatment",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Experience the best physiotherapy treatment at CasaMed, tailored to your optimal recovery and well-being needs. Schedule your Appointment Now!",
          })}
        </script>
      </Helmet>
      <PTTComponent />
      <Footer />
    </div>
  );
};

export default PhysicalTherapyTreatment;
