import React from "react";
import { Helmet } from "react-helmet";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import arth1 from "../Assets/Arthiritis/arth1.jpg";
import arth2 from "../Assets/Arthiritis/arth2.jpg";
import arth3 from "../Assets/Arthiritis/arth3.jpg";

const ATComponent = () => {
  return (
    <section className="">
      <div className="xxs:pt-24 md:pt-32 xxs:pb-6 md:pb-16">
        <h1 className="text-center text-whyus xxs:text-3xl md:text-5xl font-semibold">
          Arthiritis Therapy
        </h1>
      </div>

      <div className="grid grid-cols-1">
        <div className="xxs:px-[6%] md:px-[10%] grid xxs:grid-cols-1 md:grid-cols-2 pb-8">
          <div className="pb-4 md:pr-[2%] xl:pr-[1%] flex flex-col items-center justify-center">
            <p className="xxs:text-center md:text-left text-lg pb-2">
              Arthritis is the most common cause of pain among the population
              irrespective of their gender. It is generally understood as a
              diverse type of joint distress or joint disease. Arthritis is a
              typical cause of disability in the present world. More than 400
              million individuals worldwide have Arthritis and make it very
              challenging for individuals to be physically active.{" "}
            </p>
            <p className="xxs:text-center md:text-left text-lg pb-2">
              Arthritis is a medical condition featuring the joint stiffness,
              joint damage or inflammation of one or more joints with symptoms
              of swelling, pain and burning sensation. There are diverse types
              of arthritis; around 200 conditions affect joints, the tissues
              covering the joint, and other connective tissue.
            </p>
            <p className="text-lg xxs:text-center md:text-left">
              Physical therapy can be helpful for some types of arthritis.
              Exercises can improve range of motion and strengthen the muscles
              surrounding joints.
            </p>
          </div>
          <div className="flex justify-center items-start xl:items-center xxs:pl-0 md:pl-[2%] xl:pl-[1%] ">
            <img
              src={arth1}
              className="xxs:w-full md:w-full xl:w-[80%] rounded-lg"
            />
          </div>
        </div>

        <h2 className="py-4 px-[10%] text-center text-3xl font-semibold">
          Connect with our experts at CasaMed to understand the next steps.
        </h2>
        <p className="xxs:px-[6%] md:px-[10%] text-lg pb-2 px-[10%] xxs:text-center md:text-left">
          We know how frustrating it could be when you cannot perform your daily
          activities because of a medical conditions. We have certified
          therapists who can assist the patient in overcoming their disability.
        </p>
        <div className="xxs:px-[6%] md:px-[10%] grid xxs:grid-cols-1 md:grid-cols-2 pb-8">
          <div className="flex justify-start items-center xl:items-center xxs:pr-0 md:pr-[2%] xl:pr-[0%]">
            <img
              src={arth2}
              className="xxs:w-full md:w-full xl:w-[95%] rounded-lg xxs:pb-4 md:pb-0"
            />
          </div>
          <div className="pb-4 xxs:pl-0 md:pl-[2%] xl:pl-[0%]  flex flex-col justify-center">
            <p className="text-lg pb-2 xxs:text-center md:text-left">
              Our <strong>Arthritis Therapy</strong> Experts are trained in
              handling and treating musculoskeletal problems like sports
              injuries, osteoarthritis, bursitis, rheumatoid arthritis, and
              other issues related to chronic and acute joint pain. CasaMed's
              qualified physiotherapists can help find out the underlying
              problems, and factors that contribute to joint pain such as the
              incorrect posture, poor pattern of movement, tight and weak
              muscles. Through thorough guidance and care approach, they will
              help you to beat the pain and feel better.
            </p>
            <p className="text-lg xxs:text-center md:text-left">
              The platform also offers online sessions for those who have time
              constraints or live outside the city. We assist our patients with{" "}
              <strong>arthritis therapy</strong> so that they attain relief from
              muscle and joint pain.
              <br />
              <br />
              Visit our website and reach out to our{" "}
              <strong>
                therapist immediately to schedule your appointment for the
                session on arthritis therapy
              </strong>
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1">
          <p className=" text-lg py-4 xxs:px-[6%] md:px-[10%]">
            We nourish drug-free, non-invasive and natural treatments. For
            better results, our therapist will also direct you about the changes
            in lifestyle that will boost your condition. However, the duration
            of treatment might vary for each individual depending on the
            patient&#39;s health condition. Generally, it ranges between six
            months to a few years, but the critical part is the care guarantees
            long-term relief. Hence, to overcome your disability feel free to
            reach us and plan your session with our therapist. To learn more
            about the pricing and other necessary details connect with our
            professionals or call us at <br /> <strong>1800-572-4868</strong>.
          </p>
          <h2 className="py-4 xxs:px-[6%] md:px-[10%] text-3xl font-semibold xxs:text-center md:text-left">
            Why Choose Casamed for Arthritis Therapy
          </h2>
          <div className="xxs:px-[6%] md:px-[10%] xxs:grid-cols-1 md:grid grid-cols-2 pb-8">
            <div className="pb-4 md:pr-[2%] xl:pr-[1%]">
              <p className="text-lg ">
                <ul>
                  <li>
                    <strong>Helps in effective movement</strong>
                  </li>
                </ul>
                <p>
                  Our therapist solves your problem and assist you by providing
                  techniques for pushing effectively and safely. They will guide
                  you in such a way that you can move your body while performing
                  several activities for minimizing pain.
                </p>
                <ul>
                  <li>
                    <strong>Assist you with manual therapy treatment</strong>
                  </li>
                </ul>
                <p>
                  Apart from enthusiastic participation, our therapist delivers
                  passive techniques such as Joint mobilization, massage therapy
                  helps in increasing joint space, offer better motion range
                  reduce pain and swelling.
                </p>
                <ul>
                  <li>
                    <strong>Educate you about joint pain</strong>
                  </li>
                </ul>
                <p>
                  Our therapist at Casamed educates the patient or the family
                  member involved during the process of therapy about the cause
                  of the disorder and how it can be overcome. They also help in
                  understanding the thing one need to avoid during the complete
                  process of therapy.
                </p>
                <ul>
                  <li>
                    <strong>
                      Encourage and help you in managing your pain on your own.
                    </strong>
                  </li>
                </ul>
                <p>
                  It&#39;s all you, after all. Casamed believes that you are
                  your best mentor in this journey to recovery and hence we put
                  you in charge to steer this ship. All our offerings are custom
                  tailored to this end.
                </p>
                <ul>
                  <li>
                    <strong>
                      Making pain relief a healthy and enjoyable experience.
                    </strong>
                  </li>
                </ul>
              </p>
            </div>
            <div className="flex justify-center items-center xl:items-center md:pl-[2%] xl:pl-[1%] pt-2">
              <img src={arth3} className="rounded-lg md:w-full xl:w-[80%] " />
            </div>
          </div>
          <p className="text-lg py-2 px-[10%]">
            In the end, it should be fun. By eliminating the need for medicines
            and surgery and introducing a step by step recovery plan, Casamed
            shields the users from the unnecessary suffering involved in more
            traditional methods while simultaneously making recovery fun with
            guided plans and proven results.
          </p>
          <p className="text-lg px-[10%]">
            For acquiring more details about our services to reach us or track
            our services via our website and get precise information. Get better
            and convenient therapy through our home service.
          </p>
        </div>
      </div>
    </section>
  );
};

const ArthritisTherapy = () => {
  return (
    <div className="bg-end">
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>
          Best Orthopedic Surgeon & Lower Back Pain Treatment – CasaMed
        </title>
        <meta
          name="description"
          content="Casamed online centre has the best orthopedic surgeons, who are well known for their services. Book your appointment for back pain treatment, arthritis therapy and muscle stiffness treatment now."
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="arthritis therapy" />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/arthritis-therapy" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content=" Get the Arthritis Therapy | Diagnose Your Arthritis - Casamed"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/arthritis-therapy "
        />
        <meta
          property="og:description"
          content=" Treat your long due pain through our expert arthritis
therapy solution at Casamed. Read more about our professional therapy here. Call us today."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: " Get the Arthritis Therapy | Diagnose Your Arthritis - Casamed",
            url: " https://casamed.in/arthritis-therapy",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              " Treat your long due pain through our expert arthritis therapy solution at Casamed. Read more about our professional therapy here. Call us today.",
          })}
        </script>
      </Helmet>
      <ATComponent />
      <Footer />
    </div>
  );
};

export default ArthritisTherapy;
