import React from "react"

function Subscribe() {
    return (
        <div id='subscribe' className="bg-casashade2 text-white flex-col md:flex-row mx-auto xxs:gap-5 md:gap-10 items-center rounded-xl xxs:py-6 md:py-10 w-10/12 max-w-4xl xxs:px-10 md:px-20 flex bg-no-repeat">
            <div className=' text-center md:text-left w-full md:w-1/2'>
                <h3 className='text-xl font-bold mb-6'>
                    Begin your Recovery Now!
                </h3>
                <p>
                    Sit back and let us get back to you with a follow-up and a personalized recovery plan.
                </p>
            </div>
            <form className='flex flex-col justify-center md:items-start items-center '>
                <input className=' placeholder-white outline-none py-2 w-[225px] mb-6 border-b-2 bg-transparent' type="number" placeholder='Enter Your Phone Number' />
                                  
                    <input className= 'px-5 py-[9px] items-center capitalize active:-translate-y-1 cursor-pointer font-poppins hover:-translate-y-2 duration-300 text-white font-[600] bg-casablue rounded-[12px] ' type ="submit" value="Submit"/>
                
            </form>


        

        </div>

        


    )
}

export default Subscribe