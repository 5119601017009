import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { shops as images } from '../Constants';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

const Slideshow = ({ shops, showPreviousButton = true, showNextButton = true }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);

  useEffect(() => {
    const autoplay = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      setNextIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change the duration (in milliseconds) as needed

    return () => clearInterval(autoplay);
  }, [shops]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setNextIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setNextIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className='xxs:h-auto md:h-[400px] lg:h-[500px] flex justify-center items-center ' style={{ position: 'relative',  }}>
      
      <AnimatePresence mode='wait'>
        <motion.img
          key={currentIndex}
          src={images[currentIndex].img}
          alt={`Slideshow Image ${currentIndex}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{ width: '100%', height: '100%', objectFit: 'fit', borderRadius: '15px' }}
        />
        <motion.img
          key={nextIndex}
          src={images[nextIndex].img}
          alt={`Slideshow Image ${nextIndex}`}
          style={{ display: 'none', borderRadius: '15px' }}
        />
      </AnimatePresence>
      
      <div className='absolute flex flex-row-reverse justify-center items-center' style={{top: '108%', left: '50%'}}>
      {showPreviousButton && (
        <motion.button
          onClick={handlePrev}
          whileHover={{ scale: 1.1, translateY: -3 }}
          whileTap={{ scale: 0.9 }}
          style={{
            position: 'absolute',
            left: '40%',
            top: '108%',
            paddingLeft: '16px',
            transition: '0.8sec'
          }}
        >
          <ChevronRight />
        </motion.button>
      )}
      {showNextButton && (
        <motion.button
          onClick={handleNext}
          whileHover={{ scale: 1.1, translateY: -3 }}
          whileTap={{ scale: 0.9 }}
          style={{
            position: 'absolute',
            right: '40%',
            top: '108%',
            paddingRight: '16px',
            transition: '0.8sec'            
          }}
          
        >
          <ChevronLeft />
        </motion.button>
      )}
      </div>
    </div>
  );
};

export default Slideshow;
