import { Button } from "@mui/material";
import React, { useState } from "react";

const BundlePricing = () => {
  const pricingItems = [
    {
      type: "Posture Care Plan",
      price: "₹2899",
      subscription: "90 days",
      description1: "Personalised Care Plan",
      description2:
        "Carefully curated targeted exercises, ergonomic adjustments, and personalized interventions",
      features: [
        "2 Orthopaedic Doctors consultation",
        "12 Live Physiotherapy Sessions",
        "3 Sessions with Motion Expert",
        "Free Personalised Care Plan",
        "Unlimited Access to guided Pain Relief Sessions",
        "10% Discount on Home PT Sessions",
      ],
      buttonText: "SELECT PLAN",
      buttonUrl: "https://rzp.io/l/casamed-posture-care-plan-90",
      active: false,
    },
    {
      type: "Post Op Recovery Plan",
      price: "₹2899",
      subscription: "90 days",
      description1: "Comprehensive Post-Op Plan",
      description2:
        "Personalized, vigilant plan for enhanced comfort and swift healing.",
      features: [
        "18 Live Physiotherapy Sessions",
        "Free Customised Post Op Rehab Plan",
        "Unlimited Access to Guided Rehab Sessions",
        "1 Free Consultation by Certified Nutritionist",
        "Nutrition & Diet Chart for 3 Months",
      ],
      buttonText: "SELECT PLAN",
      buttonUrl: "https://rzp.io/l/casamed-post-op-plan-90",
      active: false,
    },
    {
      type: "Chronic Pain Care",
      plans: [
        {
          price: "₹5999",
          subscription: "90 days",

          features: [
            "4 Orthopaedic / Rheumatology Consultations",
            "12 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Pain Relief Sessions",
            "2 Free Consultation by Certified Nutritionist",
            "Nutrition & Diet Chart for 3 Months",
            "Chat Support with Nutritionist & Personal Therapist",
          ],
          buttonUrl: "https://rzp.io/l/casamed-chronic-pain-plan-90",
        },
        {
          price: "₹11999",
          subscription: "180 days",

          features: [
            "4 Orthopaedic / Rheumatology Consultations",
            "25 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Pain Relief Sessions",
            "2 Free Consultation by Certified Nutritionist",
            "Nutrition & Diet Chart for 3 Months",
            "Chat Support with Nutritionist & Personal Therapist",
          ],
          buttonUrl: "https://rzp.io/l/casamed-chronic-pain-plan-180",
        },
        {
          price: "₹17999",
          subscription: "365 days",

          features: [
            "4 Orthopaedic / Rheumatology Consultations",
            "40 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Pain Relief Sessions",
            "2 Free Consultation by Certified Nutritionist",
            "Nutrition & Diet Chart for 3 Months",
            "Chat Support with Nutritionist & Personal Therapist",
          ],
          buttonUrl: "https://rzp.io/l/casamed-chronic-pain-plan-365",
        },
      ],

      description1: "Holistic Chronic Pain Plan",
      description2:
        "Curating a meticulous blend of medical expertise, tailored interventions, and collaborative patient input, ensuring complete recovery",

      buttonText: "SELECT PLAN",

      active: false,
    },
    {
      type: "Acute / Sports Injury Care",
      price: "₹5999",
      subscription: "30 days",
      description1: "Dynamic Care Plan",
      description2:
        "Personalised plans integrating targeted interventions, swift rehabilitation strategies, and proactive monitoring for sustained relief",
      features: [
        "1 Orthopaedic Consultations",
        "15 Live Physiotherapy Sessions",
        "Free Personalised Care Plan",
        "Unlimited Access to Guided Pain Relief Sessions",
        "6 Modality based Treatment Sessions at Home",
        "1 Free Consultation by Certified Nutritionist",
        "Nutrition & Diet Chart for 1 Month",
      ],
      buttonText: "SELECT PLAN",
      buttonUrl: "https://rzp.io/l/acute-sports-injury-plan-30",
      active: false,
    },
    {
      type: "Cerebral Palsy Care Plan",
      plans: [
        {
          price: "₹5999",
          subscription: "90 days",

          features: [
            "4 Neuro Consultations",
            "12 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Pain Relief Sessions",
            "2 Sessions with Motion Expert for Guided Recovery",
            "Chat Support with Therapist & Motion Expert",
          ],
          buttonUrl: "https://rzp.io/l/casamed-CP-care-plan-90",
        },
        {
          price: "₹11999",
          subscription: "180 days",

          features: [
            "4 Neuro Consultations",
            "25 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Pain Relief Sessions",
            "4 Sessions with Motion Expert for Guided Recovery",
            "Chat Support with Therapist & Motion Expert",
          ],
          buttonUrl: "https://rzp.io/l/casamed-CP-care-plan-180",
        },
        {
          price: "₹17999",
          subscription: "365 days",

          features: [
            "4 Neuro Consultations",
            "40 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Pain Relief Sessions",
            "8 Sessions with Motion Expert for Guided Recovery",
            "Chat Support with Therapist & Motion Expert",
          ],
          buttonUrl: "https://rzp.io/l/casamed-CP-care-plan-365",
        },
      ],

      description1: "Holistic Chronic Pain Plan",
      description2:
        "Meticulously curated CP care plan with utmost medical precision to deliver quality care and healing",

      buttonText: "SELECT PLAN",

      active: false,
    },
    {
      type: "PCOD Care Plan",
      plans: [
        {
          price: "₹5999",
          subscription: "90 days",

          features: [
            "4 Gynae Consultations",
            "12 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Mobility Rehab Sessions",
            "1 Free Consultation by Certified Nutritionist",
            "Nutrition & Diet Chart for 3 Months",
            "Chat Support with Nutritionist & Personal Therapist",
          ],
          buttonUrl: "https://rzp.io/l/casamed-PCOD-care-plan-90",
        },
        {
          price: "₹11999",
          subscription: "180 days",

          features: [
            "4 Gynae Consultations",
            "25 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Mobility Rehab Sessions",
            "1 Free Consultation by Certified Nutritionist",
            "Nutrition & Diet Chart for 3 Months",
            "Chat Support with Nutritionist & Personal Therapist",
          ],
          buttonUrl: "https://rzp.io/l/casamed-PCOD-care-plan-180",
        },
        {
          price: "₹17999",
          subscription: "365 days",

          features: [
            "4 Gynae Consultations",
            "40 Live Physiotherapy Sessions",
            "Free Personalised Care Plan",
            "Unlimited Access to Guided Mobility Rehab Sessions",
            "1 Free Consultation by Certified Nutritionist",
            "Nutrition & Diet Chart for 3 Months",
            "Chat Support with Nutritionist & Personal Therapist",
          ],
          buttonUrl: "https://rzp.io/l/casamed-PCOD-care-plan-365",
        },
      ],

      description1: "Holistic Chronic Pain Plan",
      description2:
        "Tailored PCOD Reversal Plan to reclaiming control over PCOD symptoms and fostering holistic well-being",

      buttonText: "SELECT PLAN",

      active: false,
    },
  ];

  const addOns = [
    "2 Consultations by Orthopaedic Surgeon @ ₹1000",
    "1 Free Consultation by Certified Nutritionist along with Nutrition & Diet Chart for 1 Month @ ₹1000",
    "Chat Support with Therapist & Nutritionist @ ₹1000",
    "Offline Home based Sessions ( Machine/ Modality Treatment) @ ₹1000 per Session",
  ];

  const [selectedPlans, setSelectedPlans] = useState({
    "Chronic Pain Care": "90 days",
    "Cerebral Palsy Care Plan": "90 days",
    "PCOD Care Plan": "90 days",
  });

  const handlePlanChange = (planType, subscription) => {
    setSelectedPlans({ ...selectedPlans, [planType]: subscription });
  };

  return (
    <div>
      <div className="mt-10">
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[30px] md:mb-[60px] max-w-[510px]  text-center">
              <h2 className="mb-3 text-3xl font-bold leading-[1.208] text-whyus sm:text-4xl md:text-5xl">
                <span className="decoration-[#02B9ED] underline underline-offset-8">
                  C
                </span>
                are Plans
              </h2>
              <p className="text-whyus text-body-color dark:text-dark-6 text-md">
                Holistic care plans provide quick, targeted solutions for pain
                management and rehabilitation for sustainable long term pain
                relief.
              </p>
              <p className="text-whyus text-body-color dark:text-dark-6 text-md">
                Tailored for various needs like posture care, post-op recovery,
                chronic pain, and sports injuries, they ensure efficient and
                personalized treatment at home.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" grid grid-cols-1 md:grid-cols-2 justify-center items-center px-4 md:px-[10%] lg:px-[20%] xl:px-[25%]">
        {pricingItems.map((item, index) => (
          <div className="w-full px-4  " key={item.buttonUrl}>
            <div
              className={`relative border-t-8 ${
                item.type === "Posture Care Plan"
                  ? `border-t-[#c0feff]`
                  : item.type === "Post Op Recovery Plan"
                  ? `border-t-[#ffe3fe]`
                  : item.type === "Chronic Pain Care"
                  ? `border-t-[#f4ffc1]`
                  : item.type === "Cerebral Palsy Care Plan"
                  ? `border-t-[#ffc0cb]`
                  : item.type === "PCOD Care Plan"
                  ? `border-t-[#e3ffe9]`
                  : `border-t-[#fff2c5]`
              } ${
                item.type === "Posture Care Plan" ||
                item.type === "Post Op Recovery Plan"
                  ? "h-auto md:h-[680px]"
                  : "h-auto md:h-[840px] lg:h-[800px]"
              } z-0 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-8 py-10 shadow-pricing dark:border-dark-3 dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-[50px]`}
            >
              <span className="mb-3 block text-2xl font-semibold text-primary">
                <span className="link-active font-bold">
                  {item.type.slice(0, 1)}
                </span>
                {item.type.slice(1)}
              </span>
              <h2 className="mb-5 text-xl font-bold text-casadarkshade ">
                {item.type === "Chronic Pain Care" ||
                item.type === "Cerebral Palsy Care Plan" ||
                item.type === "PCOD Care Plan"
                  ? item.plans.map((plan, planIndex) => {
                      if (selectedPlans[item.type] === plan.subscription) {
                        return <span key={plan.buttonUrl}>{plan.price}</span>;
                      }
                      return null;
                    })
                  : item.price}
                <span className="text-base font-medium text-body-color dark:text-dark-6">
                  /{" "}
                  {item.type === "Chronic Pain Care" ||
                  item.type === "Cerebral Palsy Care Plan" ||
                  item.type === "PCOD Care Plan"
                    ? item.plans.map((plan, planIndex) => {
                        if (selectedPlans[item.type] === plan.subscription) {
                          return (
                            <span key={planIndex}>{plan.subscription}</span>
                          );
                        }
                        return null;
                      })
                    : item.subscription}
                </span>
              </h2>
              <div className="flex justify-start items-center mb-6 ">
                {item.type === "Chronic Pain Care" ||
                item.type === "Cerebral Palsy Care Plan" ||
                item.type === "PCOD Care Plan" ? (
                  <>
                    {/* Radio buttons and other content for Chronic Pain Care */}
                    <Button
                      asChild
                      variant="outlined"
                      className="border-2 tracking-widest text-casablue2-700 font-semibold border-casablue2-200 hover:bg-casablue2-50 active:scale-95 transition-all duration-500 fade-in-50"
                    >
                      <a
                        href={
                          selectedPlans[item.type]
                            ? item.plans.find(
                                (plan) =>
                                  plan.subscription === selectedPlans[item.type]
                              )?.buttonUrl
                            : "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.buttonText}
                      </a>
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    className="border-2 tracking-widest text-casablue2-700 font-semibold border-casablue2-200 hover:bg-casablue2-50 active:scale-95 transition-all duration-500 fade-in-50"
                  >
                    <a
                      href={item.buttonUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.buttonText}
                    </a>
                  </Button>
                )}
              </div>
              <p className="mb-8 border-b border-stroke  pb-8 text-base text-body-color dark:border-dark-3 dark:text-dark-6  text-center md:text-left">
                <span className="font-bold text-lg">{item.description1}</span>
                <br />

                {item.type === "Chronic Pain Care" ||
                item.type === "Cerebral Palsy Care Plan" ||
                item.type === "PCOD Care Plan" ? (
                  <div className="flex flex-col md:flex-row justify-between items-center py-2">
                    {/* Radio buttons for Chronic Pain Care */}
                    {item.plans.map((plan) => (
                      <div
                        key={plan.buttonUrl}
                        className="flex justify-center items-baseline"
                      >
                        <input
                          className="mr-2"
                          type="radio"
                          id={`plan-${plan.subscription}-${index}`}
                          name={`plan-${index}`}
                          checked={
                            selectedPlans[item.type] === plan.subscription
                          }
                          onChange={() =>
                            handlePlanChange(item.type, plan.subscription)
                          }
                        />
                        <label htmlFor={`plan-${plan.subscription}-${index}`}>
                          {plan.subscription}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

                {item.description2}
              </p>
              {item.type === "Chronic Pain Care" ||
              item.type === "Cerebral Palsy Care Plan" ||
              item.type === "PCOD Care Plan"
                ? item.plans.map((plan, index) => {
                    if (selectedPlans[item.type] === plan.subscription) {
                      return (
                        <div key={index}>
                          {/* <h2>{plan.price}</h2> */}
                          {plan.features.map((feature, featureIndex) => (
                            <div className="mb-4 flex flex-col gap-[6px]">
                              <p
                                className="text-base text-body-color dark:text-dark-6"
                                key={featureIndex}
                              >
                                {feature}
                              </p>
                            </div>
                          ))}
                        </div>
                      );
                    }
                    return null;
                  })
                : item.features.map((item, index) => (
                    <div className="mb-4 flex flex-col gap-[6px]" key={index}>
                      <p className="text-base text-body-color dark:text-dark-6">
                        {item}
                      </p>
                    </div>
                  ))}
              <div>
                <span className="absolute right-0 top-7 z-[0]">
                  <svg
                    width={77}
                    height={172}
                    viewBox="0 0 77 172"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
                    <defs>
                      <linearGradient
                        id="paint0_linear"
                        x1={86}
                        y1={0}
                        x2={86}
                        y2={172}
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3056D3" stopOpacity="0.09" />
                        <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                <span className="absolute right-4 top-4 z-[0]">
                  <svg
                    width={41}
                    height={89}
                    viewBox="0 0 41 89"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="38.9138"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 38.9138 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 38.9138 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 38.9138 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 38.9138 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 38.9138 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 38.9138 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 38.9138 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="38.9138"
                      cy="1.42021"
                      r="1.42021"
                      transform="rotate(180 38.9138 1.42021)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 26.4157 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 26.4157 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 26.4157 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 26.4157 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 26.4157 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 26.4157 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 26.4157 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="26.4157"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 26.4157 1.4202)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 13.9177 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 13.9177 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 13.9177 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 13.9177 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 13.9177 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 13.9177 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 13.9177 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="13.9177"
                      cy="1.42019"
                      r="1.42021"
                      transform="rotate(180 13.9177 1.42019)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="87.4849"
                      r="1.42021"
                      transform="rotate(180 1.41963 87.4849)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="74.9871"
                      r="1.42021"
                      transform="rotate(180 1.41963 74.9871)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="62.4892"
                      r="1.42021"
                      transform="rotate(180 1.41963 62.4892)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="38.3457"
                      r="1.42021"
                      transform="rotate(180 1.41963 38.3457)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="13.634"
                      r="1.42021"
                      transform="rotate(180 1.41963 13.634)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="50.2754"
                      r="1.42021"
                      transform="rotate(180 1.41963 50.2754)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="26.1319"
                      r="1.42021"
                      transform="rotate(180 1.41963 26.1319)"
                      fill="#3056D3"
                    />
                    <circle
                      cx="1.41963"
                      cy="1.4202"
                      r="1.42021"
                      transform="rotate(180 1.41963 1.4202)"
                      fill="#3056D3"
                    />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center -mt-2">
        <img
          referrerPolicy="origin"
          src="https://badges.razorpay.com/badge-dark.png "
          style={{ height: "45px", width: "113px" }}
          alt="Razorpay | Payment Gateway | Neobank"
        />
      </div>
      <div className="px-4 md:px-[25%] mb-8">
        <h2 className="text-lg font-semibold opacity-80  text-center md:text-left mt-6 mb-2">
          Add Ons:
        </h2>
        <div className="flex flex-col">
          {addOns.map((addOn, index) => (
            <div className=" text-xs font-thin" key={index}>
              <ul className="font-thin px-[5%]">
                <li className="list-disc">{addOn}</li>
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BundlePricing;
