import {
  FaYoutube,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";

import logofooter from "../Assets/Footer/logofooter.svg";
import image1 from "../Assets/Footer/visa.png";
import image2 from "../Assets/Footer/mastercard.png";
import image3 from "../Assets/Footer/upi.png";
import image4 from "../Assets/Footer/rupay.png";
// import image3 from '../../assets/bitcoin.png'
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="px-8 xxs:py-8 md:py-20 md:px-16 lg:px-24 rounded text-light">
      <div className="flex flex-wrap justify-between gap-12 mb-10 items-start bg-casadarkshade rounded-2xl p-8">
        <div className=" w-fit sm:w-1/3 lg:w-1/5">
          <Link to="/">
            <img className="w-[70%]" src={logofooter} alt="logo" />
          </Link>
        </div>

        <div className="w-full sm:w-1/3 lg:w-1/6">
          <h3 className="text-xl text-center mb-6">
            We accept the following payment platforms
          </h3>
          <div className="flex flex-wrap justify-center gap-4 items-center">
            <div className="w-20">
              <img className="w-full" src={image1} alt="visa" />
            </div>
            <div className="w-20">
              <img className="w-full" src={image2} alt="masterCard" />
            </div>
            <div className="w-20">
              <img className="w-full" src={image3} alt="upi" />
            </div>
            <div className="w-20">
              <img className="w-full" src={image4} alt="rupay" />
            </div>
            {/* <div className='w-20'>
                            <img className='w-full' src={image3} alt="Bitcoin" />
                        </div> */}
          </div>
        </div>

        <ul className="flex flex-col text-center lg:text-left w-full sm:w-1/3 lg:w-1/5">
          <li className="mb-6">
            <h3 className="text-xl">Quick Links</h3>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/">Home</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/product">Product</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/about">About Us</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/blog">Blog</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/contactus">Contact</Link>
          </li>
        </ul>

        <ul className="flex flex-col lg:text-left text-center w-full sm:w-1/3 lg:w-1/5">
          <li className="mb-6">
            <h3 className="text-xl">Resources</h3>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/privacypolicy">Privacy Policy</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/termsconditions">Terms and Conditions</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/termsconditions/channelpartner">
              Channel Partner - TnC
            </Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/pricing">Corporate Pricing</Link>
          </li>
          <li className="hover:text-white mb-4 duration-200 hover:translate-x-2 text-gray-400">
            <Link to="/editorial-policy">Editorial Guideline</Link>
          </li>
        </ul>
      </div>

      <div className="flex flex-col md:flex-row flex-wrap justify-center items-center mb-6 gap-6 md:justify-between font-medium  text-casashade2">
        <p>&copy; 2023 CasaMed. All rights reserved</p>
        <ul className="flex gap-4 items-center">
          <li className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200">
            <a href="https://www.youtube.com/channel/UCmJ_XJ3uVLjyGDJ3YpmX-aw/featured">
              {" "}
              <FaYoutube size={24} />{" "}
            </a>
          </li>
          <li className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200">
            <a href="https://www.facebook.com/casamedIN">
              <FaFacebook size={24} />
            </a>
          </li>
          <li className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200">
            <a href="https://www.instagram.com/casamed_in/">
              <FaInstagram size={24} />
            </a>
          </li>
          <li className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200">
            <a href="https://twitter.com/casamed1">
              <FaTwitter size={24} />
            </a>
          </li>
          <li className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200">
            <a href="https://www.linkedin.com/company/casamedin/">
              <FaLinkedin size={24} />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
