import React, { useEffect } from "react";
import Nav from "../../Components/Nav";
import Footer from "../../Components/Footer";
import { Grid, Container } from "@mui/material";
// import Blogs from "../Components/Blogs/Blogs"
import Posts from "../../Components/Posts/posts";
import BlogHead from "./BlogHead";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import casasvg from "../../Assets/casamed.svg";
import { Helmet } from "react-helmet";

// import { getBlogs } from '../actions/blogs'
// import { useDispatch } from 'react-redux'

function Blog() {
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getBlogs());
  // }, [dispatch]);

  return (
    <section className="w-full bg-light">
      <div className="stickynav xxs:pb-16 md:pb-16 bg-light">
        <Nav />
      </div>
      <Helmet>
        {/* Meta title and Description */}
        <title>Blog | CasaMed</title>
        <meta
          name="description"
          content="Stay up-to-date with the latest healthcare news, trends, and insights on CasaMed's Blog Page. Our expert writers cover a wide range of topics, from patient care and practice management to industry regulations and technology."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Back pain, Muscle pain, Join pain relief"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href={`https://casamed.in/blog/`} />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta property="og:title" content="Blog | CasaMed" />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content={`https://casamed.in/blog/`} />
        <meta
          property="og:description"
          content="Stay up-to-date with the latest healthcare news, trends, and insights on CasaMed's Blog Page. Our expert writers cover a wide range of topics, from patient care and practice management to industry regulations and technology."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Blog | CasaMed",
            url: `https://casamed.in/blog`,
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "Stay up-to-date with the latest healthcare news, trends, and insights on CasaMed's Blog Page. Our expert writers cover a wide range of topics, from patient care and practice management to industry regulations and technology.",
          })}
        </script>
      </Helmet>
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>

      <div>
        <BlogHead />
      </div>

      <div>
        <div>
          <Posts />
        </div>
      </div>

      <Footer />
    </section>
  );
}

export default Blog;
