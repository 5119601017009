import React from "react";
import Nav from "../Components/Nav";
import Header from "../Views/HomeViews/Header";
import HeroStats from "../Views/HomeViews/HeroStats";
import Why from "../Views/HomeViews/Why";
import How from "../Views/HomeViews/How";
import Features from "../Views/HomeViews/Features";
import Persona from "../Views/HomeViews/Persona";
import Awards from "../Views/HomeViews/Awards";

import Testim from "../Components/TestimonialRevamp2";
import Subscribe from "../Views/HomeViews/Subscribe";
// import Try from "../Views/Try"
// import Try2 from "../Views/Try2"
import Footer from "../Components/Footer";
import { FaPhone, FaWhatsapp } from "react-icons/fa";
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";

import { Layout } from "./Layout";
import Test from "../Pages/TATA_1MG/test";

function Home() {
  return (
    <Layout>
      <Nav />
      <Helmet>
        {/* Meta title and Description */}
        <title>
          CasaMed | Best Online Consultation for Muscle & Joint Pain
        </title>
        <meta
          name="description"
          content="Offering the best online consultation for Muscle & Joint Pain therapy. Visit us today or choose our tele doctor consultation for a virtual meeting."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Back pain, Muscle pain, Join pain relief"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="CasaMed | Best Online Consultation for Muscle & Joint Pain"
        />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content=" https://casamed.in/" />
        <meta
          property="og:description"
          content="Offering the best online consultation for Muscle & Joint Pain therapy. Visit us today or choose our tele doctor consultation for a virtual meeting."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "CasaMed | Best Online Consultation for Muscle & Joint Pain",
            url: " https://casamed.in/",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "Offering the best online consultation for Muscle & Joint Pain therapy. Visit us today or choose our tele doctor consultation for a virtual meeting.",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "VideoObject",
            name: "CasaMed | Say Goodbye to Pain with our App - Transforming the Doctor-Patient Relationship!",
            description:
              "Do not let the burden of physical pain impact your mental well being. CasaMed is here to assist and hand hold you throughout the journey of pain relief.  Our innovative app provides comprehensive support for both patients and doctors, guiding patients through their journey of pain relief and equipping doctors with invaluable insights to help tailor treatment plans to individual needs.  With our app, patients can track their symptoms, monitor their progress, and receive personalised recommendations for managing their pain.  Meanwhile, doctors can access detailed reports and analytics to gain a deeper understanding of their patients' conditions and make more informed treatment decisions.  Say goodbye to the frustration and uncertainty of pain management - try our app today and experience the future of pain relief!",
            thumbnailUrl: "https://i.ytimg.com/vi/sYg__GHnjng/default.jpg",
            uploadDate: "2023-04-22T08:46:26Z",
            duration: "PT1M2S",
            embedUrl: "https://www.youtube.com/embed/sYg__GHnjng",
            interactionCount: "90",
          })}
        </script>
      </Helmet>
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      <div className="og--bg--grad pt-10">
        <Header />
        <div className="pb-[32px]">
          <HeroStats />
        </div>
      </div>
      <div className="">
        <Why />
      </div>
      <How />
      <div id="Care_Plans">
        <Test />
      </div>
      <div className="bg-featuresnew">
        <Features />
        {/* <Try2 /> */}
        {/* <Try /> */}
      </div>
      <Persona />
      <div className="xxs:pt-16 md:pt-16 xxs:pb-16 md:pb-32 bg-light text-light">
        <Awards />
        <div className="xxs:py-16 md:py-32">
          <Testim />
        </div>
        <Subscribe />
        <Footer />
      </div>
    </Layout>
  );
}

export default Home;
