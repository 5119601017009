import { motion } from "framer-motion";
import appscreen from "../../Assets/HomeViews/Header/appscreen.png";
// import Startedmodal from "./Startedmodal"
import { Link } from "react-router-dom";

const headerVariant = {
  start: {
    opacity: 0,
  },
  end: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

// const imageVariant = {
//     start: {
//         rotate: "-180deg"
//     },
//     end: {
//         rotate: 0,
//         transition: {
//             duration: 1
//         }
//     }
// }

const textVariant = {
  start: {
    x: "-100",
    opacity: 0,
  },
  end: {
    x: 0,
    opacity: 1,
  },
};
const textVariant2 = {
  start: {
    x: "10",
    opacity: 0,
  },
  end: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.75,
    },
  },
};

function Header() {
  return (
    <div className="">
      <motion.header
        variants={headerVariant}
        animate="end"
        initial="start"
        className="flex xxs:px-12 md:px-24 justify-between xxs:py-12 md:pt-24 md:pb-32"
      >
        <div
          id="text"
          className="w-full  md:w-1/2 pt-20 sm:pt-10 xxs:pt-5 xs:pt-5 ss:pt-8 "
        >
          <motion.div
            variants={textVariant}
            transition={{ delay: 0.2, duration: 0.2 }}
            className="flex mb-6 text-sm tracking-normal bg-gray-500 w-fit items-center px-1 py-1 rounded-3xl  "
          >
            <div className="bg-white  rounded-2xl px-2 text-primary py-1">
              60% OFF!
            </div>
            <p className="px-2">
              Register <strong>Now</strong> with Coupon Code{" "}
              <strong>CASANEW60</strong>
            </p>
          </motion.div>
          <motion.h1
            variants={textVariant}
            transition={{ delay: 0.2, duration: 0.4 }}
            className="flex-1 relative font-poppins font-bold ss:text-[58px] text-[52px] md:text-[62px]     "
          >
            THE NEXT <br className="sm:block hidden" />{" "}
            <span className="">GENERATION IN</span>{" "}
            <div className="lg:w-[1100px] sm:flex-col  ">
              <span className="font-poppins font-bold ss:text-[58px] text-[52px] ss:leading-[100px] leading-[72px] w-[50%] md:text-[62px] md:leading-[72px] lg:text-[52px] lg:leading-[72px] xl:text-[72px] xl:leading-[82px] xxs:text-[36px] xs:text-[46px] textback--bluegrad ">
                PAIN MANAGEMENT
              </span>
            </div>
          </motion.h1>

          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              type: "spring",
              damping: 6,
              delay: 0.8,
              duration: 0.6,
            }}
            className="mt-6 w-fit"
          >
            {/* <Button style="capitalize font-poppins  font-[700] ">
                        GET STARTED
                        <div className='p-1 bg-white rounded-full text-secondary'>
                            <IoIosArrowForward size={18} />
                        </div>
                    </Button> */}
            {/* <Startedmodal /> */}
            <button className="flex gap-3 px-5 py-[13px] items-center capitalize active:-translate-y-1 cursor-pointer font-poppins hover:-translate-y-2 duration-300 text-white font-[700] bg-casablue rounded-[12px] animate-bounce">
              <Link to="/contactus">GET STARTED</Link>
            </button>
          </motion.div>
        </div>

        <motion.div
          variants={textVariant2}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
          className="w-[40%]  pt-10   "
        >
          <img
            className="w-full object-contain  md:w-[100%] md:mt-20 lg:mt-0  lg:w-[80%] xxs:w-[0%] xs:w-[0%] ss:w-[0%] sm:w-[0%]  xxl:w-[70%] xxxl:w-[60%] "
            src={appscreen}
            alt="hero"
          />
        </motion.div>
      </motion.header>
    </div>
  );
}

export default Header;
