import { useState, useRef, useEffect } from "react";
import YouTube from "react-youtube";
import ReactDOM from "react-dom";

export const VideoPlayer = () => {
  const [isInViewport, setIsInViewport] = useState(false);
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const videoElement = ReactDOM.findDOMNode(videoRef.current);
      if (videoElement) {
        const { top, bottom } = videoElement.getBoundingClientRect();
        const isInView = top >= 0 && bottom <= window.innerHeight;
        setIsInViewport(isInView);

        if (isInView && isPlayerReady && player) {
          player.playVideo();
        } else if (!isInView && player) {
          player.pauseVideo();
        }
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () =>
      window.removeEventListener("scroll", handleScroll, { passive: true });
  }, [player, isPlayerReady]);

  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 0,
      controls: 1,
      modestbranding: 1,
    },
  };

  const onReady = (event) => {
    console.log("Player Ready");
    setPlayer(event.target); // Store the player object in the state variable
    setIsPlayerReady(true);
  };

  const onStateChange = (event) => {
    if (event.data === 1) {
      setIsPlayerReady(true);
      player.playVideo();
    }
  };

  return (
    <div className="w-[100%]">
      <YouTube
        videoId="sYg__GHnjng"
        opts={opts}
        onReady={onReady}
        onStateChange={onStateChange}
        ref={videoRef}
        className="video-player-container"
      />
    </div>
  );
};
