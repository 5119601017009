import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const OTTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Occupational Therapy Treatment
      </h1>
      <div className="mx-5">
        <p>
          One of most difficult problems the elderly face is their diminishing
          ability to do the daily simple tasks with ease. We find these people
          struggling to complete their daily activities themselves. Ageing
          associated cognitive impairment or reduced mobility due to muscle and
          joint stiffness or pain and subsequent difficulty in performing basic
          tasks of daily life may leave some people frustrated, confused, or
          even embarrassed. Occupational therapy is recommended to address this
          problem and help people regain control over their life. Undergoing
          occupational therapy helps people reclaim the lost confidence and
          increases their quality of life.
        </p>
        <p>
          <strong>Occupational therapy treatment</strong> assists people at
          every stage of their life to recover, maintain and develop the skills
          required to perform daily activities. Occupational therapists through
          activity, exercise and environmental change help the patient to regain
          their lost skill, build a new one or improve upon their existing
          skill.
        </p>
        <p>
          At Casamed, we have therapists with master&#39;s degree in
          Occupational Therapy. They meet with our patients, evaluate their
          condition and then draw out rehabilitation plans designed to address
          individual needs. Our therapists set the session durations according
          to the condition of patients and measure the recovery progress every
          week by clearly defining daily activity goals and tracking the same.
        </p>
        <p>
          We have highly experienced therapists and diagnostic tools designed by
          top experts for easy identification of problems. Through our platform,
          you can also avail online consultation services with Best orthopaedic
          surgeons and top-class doctors. You can contact us any time and get
          some guidance and solution to your problems.
        </p>
        {/* <p>
          <strong>What are the benefits of hiring a Casamed therapist?</strong>
        </p> */}
        <h2>Why Choose Casamed For Occupational Therapy Treatment</h2>
        <ul>
          <li>
            <strong>Increase independence-</strong> By working on elders&#39;
            cognitive and physical health, we help restore their lost
            independence. Our primary focus is on hygiene tasks such as
            brushing, going to bed, and taking shower. We even help them pay
            bills and learn to cook for themselves.
          </li>
          <li>
            <strong>Improve memory-</strong> Along with improving physical
            health, our therapist also focuses on strengthening the mental
            condition of the patient. Some patients face memory lapse related
            problems. Our occupational therapists help in increasing memory
            function while preventing memory loss. By using puzzles and memory
            games, the therapists provide the patient an opportunity to
            strengthen their memory.
          </li>
          <li>
            <strong>Better outlook-</strong> Occupational therapists like ours
            help patients develop a better outlook towards life. When people
            start losing their ability to work, they become apprehensive towards
            life and often contemplate about its quality.{" "}
            <strong>
              Occupational therapy treatment helps them overcome such negative
              thoughts
            </strong>{" "}
            and start living life to the fullest.
          </li>
          <li>
            <strong>Life transition-</strong> We go through various transitions
            in life and with old age, it keeps getting tougher. Most elderly go
            through major life altering situations such as retirement,
            relocation, loss of partner and loss of good friends and relatives
            etc. Our experienced therapist helps these people navigate through
            such critical transitions by providing them education and enabling
            them with healthy coping skills.
          </li>
        </ul>
      </div>
    </div>
  );
};

const OccupationalTherapyTreatment = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>
          Occupational Therapy treatment -Tele-consultation | CASAMED
        </title>
        <meta
          name="description"
          content="Get occupation therapy treatment in your home with CASAMED expert team. We are providing therapy online with the help of AI technology."
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="occupational therapy treatment" />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/occupational-therapy-treatment"
        />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Occupational Therapy treatment -Tele-consultation | CASAMED"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/occupational-therapy-treatment"
        />
        <meta
          property="og:description"
          content="Get occupation therapy treatment in your home with CASAMED expert team. We are providing therapy online with the help of AI technology."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Occupational Therapy treatment -Tele-consultation | CASAMED",
            url: " https://casamed.in/occupational-therapy-treatment ",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Get occupation therapy treatment in your home with CASAMED expert team. We are providing therapy online with the help of AI technology.",
          })}
        </script>
      </Helmet>
      <OTTComponent />
      <Footer />
    </div>
  );
};

export default OccupationalTherapyTreatment;
