import React, { useState } from "react";

const formatText = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const DropdownComponent = ({ data }) => {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
    setSelectedDistrict(null);
    setSelectedBlock(null);
  };

  const handleDistrictChange = (e) => {
    setSelectedDistrict(e.target.value);
    setSelectedBlock(null);
  };

  const handleBlockChange = (e) => {
    setSelectedBlock(e.target.value);
  };

  return (
    <div className="p-4">
      <select onChange={handleStateChange} className="p-2 m-2 border rounded">
        <option>Select State</option>
        {data
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((state, index) => (
            <option key={index} value={state.name}>
              {formatText(state.name)}
            </option>
          ))}
      </select>

      {selectedState && (
        <select
          onChange={handleDistrictChange}
          className="p-2 m-2 border rounded"
        >
          <option>Select District</option>
          {data
            .find((state) => state.name === selectedState)
            .districtList.sort((a, b) => a.name.localeCompare(b.name))
            .map((district, index) => (
              <option key={index} value={district.name}>
                {formatText(district.name)}
              </option>
            ))}
        </select>
      )}

      {selectedDistrict && (
        <select onChange={handleBlockChange} className="p-2 m-2 border rounded">
          <option>Select Sub-District</option>{" "}
          {data
            .find((state) => state.name === selectedState)
            .districtList.find((district) => district.name === selectedDistrict)
            .blockList.map((block, index) => (
              <option key={index} value={block}>
                {formatText(block)}
              </option>
            ))}
        </select>
      )}
    </div>
  );
};

export default DropdownComponent;
