import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const BOSComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Best Orthopaedic Surgeon
      </h1>
      <div className="mx-5">
        <p>
          Are you looking for a well-qualified and experienced orthopedic
          surgeon? If so, then you are at the right place. Connect with Casamed
          to know more about the necessary details. We understand how difficult
          it gets to perform daily activities when you are struggling with
          muscle stiffness, joint pain or other related ailments. Our 
          <strong>best orthopedic surgeon</strong> first studies your case
          history, evaluate the condition and then treats accordingly. Proper
          and adequate treatment helps the patient to overcome their problems
          and live life without any hassle.
        </p>
        <p>
          Some of the widely prevalent orthopedic problems are arthritis,
          osteoarthritis, rheumatoid, etc. Another significant orthopedic
          problem among people nowadays is lower back pain and the reasons can
          be overuse and strenuous activities, inappropriate use, and exposure
          to continual vibrations. Connect with our team of experts and they
          will assist you with the further steps. Our doctors are well trained
          and experienced in performing surgery when needed. They have cured
          thousands of patients through joint replacement or other orthopaedic
          surgical procedures. You can put your trust in us regarding orthopedic
          surgery because our doctors have been serving the community for a
          while now. When patients and their associates put their trust in our
          service then the journey becomes easy for all of us guaranteeing
          better results.
        </p>
        <p>
          We also provide online sessions for those who have time constraints or
          live outside the city. During the journey of pain relief by managing
          orthopedic problems, the patient comes in contact with multiple
          stakeholders apart from doctors such as a psychologist, physical
          therapists, and vocational counselor.
        </p>
        <p>
          We understand that after the surgery is performed there are many
          changes in the patient&#39;s body so our therapist helps them to
          modify their lifestyle to boost the conditions. Every bone, tissue,
          and tendon in the body is a part of the skeleton system and a
          potential contributor to orthopaedic circumstances. There are ways to
          control some of the minor injuries, and the best way to stop injuries
          to your bones and tendons is to keep up the training. Stretching,
          running walking, and aerobic helps not only the cardiovascular system
          but your bones and joints. Contact us at 1800 572 4868 to schedule
          your appointment with us:
        </p>
        <h2>Why Choose Casamed's Best Orthopedic Surgeon</h2>
        <p>
          <strong>Educated you about orthopedic problems</strong>
          <br />
          Our <strong>best orthopedic surgeon</strong> educates you about the
          cause of the pain and then guides you regarding the treatment. Apart
          from that, other medical experts help you to improve your lifestyle
          and make relevant changes that can boost the process of recovery
          faster.
        </p>
        <p>
          <strong>Helps in effective movement</strong>
          <br />
          Our therapist solves your mobility limitation problem and helps you by
          nourishing you with techniques for moving effectively and safely. They
          will guide you in the ways through which you move your body while
          performing several activities for minimizing pain. Connect with us to
          learn more about our services.
        </p>
        <p>
          <strong>Assist you with manual therapy treatment</strong>
          <br />
          Apart from energetic participation, our therapist provides some other
          techniques such as Joint mobilization, massage therapy helps in
          increasing joint space, offers a better range of motion to reduce pain
          and swelling.
        </p>
      </div>
    </div>
  );
};

const BestOrthopedicSurgeon = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta title and Description */}
        <title>Best Orthopedic Surgeon & Pain Treatment | CASAMED</title>
        <meta
          name="description"
          content="CASAMED partners with expert doctors for the best orthopedic surgeon. Connect with us to know more about the necessary details."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="best orthopedic surgeon,lower back pain treatment,muscle stiffness treatment,arthritis therapy"
        />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/best-orthopedic-surgeon"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Best Orthopedic Surgeon & Lower Back Pain Treatment - CASAMED"
        />

        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/best-orthopedic-surgeon"
        />
        <meta
          property="og:description"
          content="CASAMED partners with expert doctors for the best orthopedic surgeon. Connect with us to know more about the necessary details."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Best Orthopedic Surgeon & Lower Back Pain Treatment - CASAMED",
            url: " https://casamed.in/best-orthopedic-surgeon",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "CASAMED partners with expert doctors for the best orthopedic surgeon. Connect with us to know more about the necessary details.",
          })}
        </script>
      </Helmet>
      <BOSComponent />
      <Footer />
    </div>
  );
};

export default BestOrthopedicSurgeon;
