import React, { useState } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";

import bgcasadoc from "../Assets/bgcasadoc.png";
import justdial from "../Assets/justdial.svg";
import * as DOMPurify from "dompurify";
import { Button } from "@mui/material";

const CasaDoc = () => {
	const faqs = [
		{
			question: "₹900/patient/month? For ALL that? What’s the catch?",
			answer: `File that question away under “Those moments when you tell your colleagues about how CasaDoc gives you way more functionality than their pricier system at less than half the cost. <br/><br/><span class="font-semibold">Yes, we offer ALL that at THAT price.</span> <br/><br/>You see, we’re able to offer CasaDoc at an affordable price point because of our innovative technology. Dynamically growing and shrinking by the minute, our servers, database clusters and global infrastructure scales up and down during off peak hours. This allows us to pass on the savings to you.`,
		},
		{
			question: "What's Included in the free trial?",
			answer: "You get the full CasaDoc experience, minus the File Uploads feature which is an Add On.",
		},
		{
			question: "What happens if I signup during my trial?",
			answer: "Simple, you continue where you left off. Also, any days you had remaining in your trial will be added to your renewal date, so you don’t lose them!",
		},
		{
			question: "What currency is CasaDoc billed in?",
			answer: "Prices are in Indian Rupees for India-based clinics and US dollars for the rest of the world.",
		},
		{
			question: "What if I don't have an internet connection?",
			answer: "Sorry! CasaDoc requires an internet connection.",
		},
		{
			question: "How long does it take to setup CasaDoc?",
			answer: `We recommend watching the <a href="https://youtu.be/sYg__GHnjng" rel="noreferrer" target="_blank" rel="noopener noreferrer" class="underline text-blue-500">CasaDoc Product Demo</a> video on our YouTube Channel. It takes about 45-minutes, so grab a coffee while you become a CasaDoc expert. You can follow along and setup your clinic at the same time.`,
		},
	];

	const [activeIndex, setActiveIndex] = useState(null);

	const handleItemClick = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div className="og--bg--grad--inv">
			<div>
				<Nav />
			</div>
			<div className="pt-[90px] ">
				<div className="text-center ">
					<div className="flex justify-center items-center flex-col pb-5 md:pb-20 pt-10 px-4 md:px-0">
						<h3 className="px-4 py-2 bg-blue-200/40 font-semibold text-2xl rounded-full text-blue-900">
							Pain Management Software for Healthcare
							Practitioners
						</h3>
						<h2 className="text-3xl md:text-7xl font-medium pt-4">
							Manage your Practice,
						</h2>
						<h2 className="text-3xl md:text-7xl font-medium py-2">
							Efficiently. Effortlessly.
						</h2>
						<p className="text-[#838383] text-md md:text-2xl pt-4">
							Dont let running a clinic wear you down.
						</p>
						<p className="text-[#838383] text-md md:text-2xl pt-2">
							CasaDoc starts at{" "}
							<span className=" text-black font-semibold">
								₹900/patient/month*
							</span>
						</p>
						<div className="flex justify-center items-center flex-col md:flex-row gap-4 pt-4">
							{/* <Button href="/contactus" target="_blank" variant="contained" className="text-md bg-[#009AD2]">
					Start Your Free 30 Days
				</Button>
				<Button href="/contactus" target="_blank" variant="outlined" className="text-md">
					Book a Demo
				</Button> */}
							<hhdjtthek>hhdj The rest of the gusn</hhdjtthek>

							<Button
								href="https://casadoc.in"
								target="_blank"
								sx={{
									backgroundColor: "#009AD2",

									color: "white",
									fontWeight: "600",

									"&:hover": {
										backgroundColor: "white",
										color: "#009AD2",
									},
									paddingX: "0.8rem",
									paddingY: "0.8rem",
								}}
							>
								Start Your Free 30 Days
							</Button>
							<Button
								href="/contactus"
								target="_blank"
								variant="outlined"
								sx={{
									color: "black",
									fontWeight: "600",

									"&:hover": {
										backgroundColor: "white",
										color: "#00678C",
									},
									paddingX: "0.8rem",
									paddingY: "0.8rem",
								}}
							>
								Book a Demo
							</Button>
						</div>
						<div className="flex justify-center gap-1.5 items-center text-[#333333] pt-4">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64c92b319613dfaca511d8c9_Check.png"
								loading="lazy"
								width="15"
								alt="check mark"
							/>
							<div className="text-md ">
								No credit card required. Available 1-on-1
								support.
							</div>
						</div>
						<div className="text-sm">* Introductory Offer</div>
						<div className="flex justify-center items-center pt-8">
							<img
								src={bgcasadoc}
								alt="bg"
								className="w-full md:w-[80%]"
							/>
						</div>
						<div
							data-hover="false"
							data-delay="0"
							className="feature-accordion-dropdown w-dropdown"
						>
							<div
								className="feature-accordion-toggle w-dropdown-toggle"
								id="w-dropdown-toggle-2"
								aria-controls="w-dropdown-list-2"
								aria-haspopup="menu"
								aria-expanded="false"
								role="button"
								tabindex="0"
							>
								<div
									className="accordion-icon w-icon-dropdown-toggle"
									aria-hidden="true"
								></div>
								{/* <div className="feature-accordion-dropdown-text">
					Explore Features
					</div> */}
							</div>
						</div>
						<div className="flex gap-16 justify-center items-center flex-col md:flex-row py-20 max-w-[1200px] mx-auto">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e1f600598bf1ab029e4f_home-h1.webp"
								srcset="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e1f600598bf1ab029e4f_home-h1-p-500.webp 500w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e1f600598bf1ab029e4f_home-h1-p-800.webp 800w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e1f600598bf1ab029e4f_home-h1.webp 1080w"
								alt="img123"
								className=" object-cover w-full md:max-w-[600px]"
							/>

							<div className="flex justify-between h-full py-10 flex-col  ">
								<div className="w-full  md:min-w-[620px] tracking-tight flex justify-center items-center md:items-start flex-col text-center md:text-left">
									<h3 className="px-4 py-2 bg-blue-200/40 font-medium  rounded-full text-blue-900">
										Clinic Management Software for Clinic
										Owners
									</h3>
									<h2 className="text-3xl md:text-5xl font-medium pt-4 tracking-tighter">
										Revolutionize Your Practice
									</h2>
									<h2 className="text-3xl md:text-5xl font-medium md:pt-4 tracking-tighter pb-8">
										with CasaDoc's Automation
									</h2>

									<p className="text-[#838383] text-2xl leading-relaxed">
										Discover the Future of Clinic Management
										with CasaDoc's Comprehensive Automation
										Solution.
									</p>
									<p className="text-[#838383] text-2xl pb-4 leading-relaxed">
										Let CasaDoc efficiently manage your
										staff, insurers, accounts receivable,
										and all other aspects your clinic needs
										to operate seamlessly.
									</p>
								</div>
								<div className="w-full md:max-w-[640px]">
									<h3 className="text-left text-xl pb-4">
										Our Features:
									</h3>
									<nav
										className=" tracking-tight feature-accordion-dropdown-list w-dropdown-list flex justify-start items-center md:items-start flex-col md:grid md:grid-cols-2 md:w-[540px] gap-y-4"
										id="w-dropdown-list-2"
										aria-labelledby="w-dropdown-toggle-2"
									>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca7805b43ba42a711b9e57_Frame.png"
												loading="lazy"
												width="25"
												alt="schedule"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Scheduling
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block "
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca7996762123637344b0be_Frame%20(2).png"
												loading="lazy"
												width="25"
												alt="security"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Insurance
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca799558fbfaac1bb21de0_Frame%20(4).png"
												loading="lazy"
												width="25"
												alt="document"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Documentation
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca79955abc64efb6b8fd79_Frame%20(1).png"
												loading="lazy"
												width="25"
												alt="billing"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Billing
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca7996dbc9f6e8b244a156_Frame%20(3).png"
												loading="lazy"
												width="25"
												alt="payment"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Payment Processing
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca7995af07074dd1b9e9af_Frame%20(5).png"
												loading="lazy"
												width="25"
												alt="report"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Reports
											</div>
										</a>
									</nav>
								</div>
								<div className="flex justify-start items-start flex-col md:flex-row gap-4 pt-16">
									<Button
										href="https://casadoc.in"
										target="_blank"
										sx={{
											backgroundColor: "#009AD2",
											color: "white",
											fontWeight: "600",

											"&:hover": {
												backgroundColor: "white",
												color: "#009AD2",
											},
											paddingX: "0.8rem",
											paddingY: "0.8rem",
										}}
									>
										Start Your Free 30 Days
									</Button>
									<Button
										href="/contactus"
										target="_blank"
										variant="outlined"
										sx={{
											color: "black",
											fontWeight: "600",

											"&:hover": {
												backgroundColor: "white",
												color: "#00678C",
											},
											paddingX: "0.8rem",
											paddingY: "0.8rem",
										}}
									>
										Book a Demo
									</Button>
								</div>
							</div>
						</div>
						<div className="flex gap-16 justify-center items-center flex-col md:flex-row-reverse py-10 max-w-[1200px] mx-auto">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e0d800598bf1ab00f1ae_home-h2.webp"
								alt="img123"
								className=" object-cover w-full md:max-w-[600px]"
							/>

							<div className="flex justify-between h-full py-10 flex-col  ">
								<div className="w-full  md:min-w-[540px] tracking-tight flex justify-center items-center md:items-start flex-col text-center md:text-left">
									<h3 className="px-4 py-2 bg-blue-200/40 font-medium  rounded-full text-blue-900">
										Practitioners
									</h3>
									<h2 className="text-3xl md:text-5xl font-medium pt-4 tracking-tighter pb-8">
										Streamline Patient Management with a
										Click.
									</h2>

									<p className="text-[#838383] text-2xl pb-4 leading-relaxed">
										Effortlessly facilitate patient
										payments, rebookings, and streamline
										your schedule. Allocate time for
										reviewing notes and forms before your
										next appointment.
									</p>
								</div>
								<div className="w-full md:max-w-[640px]">
									<h3 className="text-left text-xl pb-4">
										Our Features:
									</h3>
									<nav
										className=" tracking-tight feature-accordion-dropdown-list w-dropdown-list flex justify-start items-center md:items-start flex-col md:grid md:grid-cols-2 md:w-[540px] gap-y-4"
										id="w-dropdown-list-2"
										aria-labelledby="w-dropdown-toggle-2"
									>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64cbdde989ebeda694e3622e_Frame%20(8).png"
												loading="lazy"
												width="25"
												alt="document"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Charting
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca79955abc64efb6b8fd79_Frame%20(1).png"
												loading="lazy"
												width="25"
												alt="billing"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Billing
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64cbdde95ccd48eec3cc000e_Frame%20(7).png"
												loading="lazy"
												width="25"
												alt="payment"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Forms
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64cbdde97fbc8154110c9bbb_Frame%20(6).png"
												loading="lazy"
												width="25"
												alt="report"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Snippets
											</div>
										</a>
									</nav>
								</div>
								<div className="flex justify-start items-start flex-col md:flex-row gap-4 pt-16">
									<Button
										href="https://casadoc.in"
										target="_blank"
										sx={{
											backgroundColor: "#009AD2",
											color: "white",
											fontWeight: "600",

											"&:hover": {
												backgroundColor: "white",
												color: "#009AD2",
											},
											paddingX: "0.8rem",
											paddingY: "0.8rem",
										}}
									>
										Start Your Free 30 Days
									</Button>
									<Button
										href="/contactus"
										target="_blank"
										variant="outlined"
										sx={{
											color: "black",
											fontWeight: "600",

											"&:hover": {
												backgroundColor: "white",
												color: "#00678C",
											},
											paddingX: "0.8rem",
											paddingY: "0.8rem",
										}}
									>
										Book a Demo
									</Button>
								</div>
							</div>
						</div>
						<div className="flex gap-16 justify-center items-center flex-col md:flex-row py-20 max-w-[1200px] mx-auto">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e0d99c981e0194c7a2c5_home-h3.webp"
								alt="img123"
								className=" object-cover w-full md:max-w-[600px]"
							/>

							<div className="flex justify-between h-full py-10 flex-col  ">
								<div className="w-full  md:min-w-[600px] tracking-tight flex justify-center items-center md:items-start flex-col text-center md:text-left">
									<h3 className="px-4 py-2 bg-blue-200/40 font-medium  rounded-full text-blue-900">
										Patient Communication
									</h3>
									<h2 className="text-3xl md:text-5xl font-medium pt-4 tracking-tighter pb-8">
										Your Clinic's Own App, Right on Their
										Phones
									</h2>

									<p className="text-[#838383] text-2xl pb-4 leading-relaxed">
										Experience the exclusivity of our
										clinic-branded app, enabling your
										patients to effortlessly book
										appointments, make payments, complete
										forms, and much more.
									</p>
									<img
										src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d120bec6601eb4d2903556_pwa-android-apple-frame.png"
										loading="lazy"
										width="170"
										sizes="(max-width: 479px) 100vw, 170.00001525878906px"
										alt=""
										srcset="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d120bec6601eb4d2903556_pwa-android-apple-frame-p-500.png 500w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d120bec6601eb4d2903556_pwa-android-apple-frame.png 664w"
										className="pwa-android-apple pb-8"
									/>
								</div>
								<div className="w-full md:max-w-[640px]">
									<h3 className="text-left text-xl pb-4">
										Our Features:
									</h3>
									<nav
										className=" tracking-tight feature-accordion-dropdown-list w-dropdown-list flex justify-start items-center md:items-start flex-col md:grid md:grid-cols-2 md:w-[540px] gap-y-4"
										id="w-dropdown-list-2"
										aria-labelledby="w-dropdown-toggle-2"
									>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca7805b43ba42a711b9e57_Frame.png"
												loading="lazy"
												width="25"
												alt="schedule"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Scheduling
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block "
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d1098c0150bbca4a28d9ac_web%20portal%20%26%20app.png"
												loading="lazy"
												width="25"
												alt="security"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Web Portal & App
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d1098c2d246495d9caec98_waitlists.png"
												loading="lazy"
												width="25"
												alt="document"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Waitlists
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64ca79955abc64efb6b8fd79_Frame%20(1).png"
												loading="lazy"
												width="25"
												alt="billing"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Billing
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64cbdde95ccd48eec3cc000e_Frame%20(7).png"
												loading="lazy"
												width="25"
												alt="payment"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Forms
											</div>
										</a>
										<a
											href="/features/medical-reporting-software"
											className="flex justify-start items-center gap-4 p-5 bg-white rounded-lg w-[250px] feature-accordion-dropdown-link-block"
											tabindex="0"
										>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d1098c329c4ef4d07906bc_help%20centre.png"
												loading="lazy"
												width="25"
												alt="report"
												className="feature-accordion-link-image"
											/>
											<div className="feature-accordion-link-text font-medium">
												Help Centre
											</div>
										</a>
									</nav>
								</div>
								<div className="flex justify-start items-start flex-col md:flex-row gap-4 pt-16 ">
									<Button
										href="https://casadoc.in"
										target="_blank"
										sx={{
											backgroundColor: "#009AD2",
											color: "white",
											fontWeight: "600",

											"&:hover": {
												backgroundColor: "white",
												color: "#009AD2",
											},
											paddingX: "0.8rem",
											paddingY: "0.8rem",
										}}
									>
										Start Your Free 30 Days
									</Button>
									<Button
										href="/contactus"
										target="_blank"
										variant="outlined"
										sx={{
											color: "black",
											fontWeight: "600",

											"&:hover": {
												backgroundColor: "white",
												color: "#00678C",
											},
											paddingX: "0.8rem",
											paddingY: "0.8rem",
										}}
									>
										Book a Demo
									</Button>
								</div>
							</div>
						</div>
					</div>

					<div className="flex justify-center items-center flex-col pb-20">
						<h2 className="text-3xl md:text-5xl font-medium pt-4 tracking-tighter ">
							Loved by Practitioners,
						</h2>
						<h2 className="text-3xl md:text-5xl font-medium pb-8 tracking-tighter ">
							Trusted by Clinic Owners
						</h2>

						<div className="flex justify-center items-center  py-8">
							<img
								src={justdial}
								alt="rating"
								className="object-cover h-[50px] w-auto"
							/>
						</div>

						<div className="flex gap-x-16 justify-center items-stretch max-w-[1180px] mx-auto flex-col md:flex-row px-4 md:px-0">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e0da7edfed0221f9c9f7_home-testimonial-photo.webp"
								loading="lazy"
								sizes="(max-width: 991px) 100vw, (max-width: 1200px) 90vw, 1080px"
								srcset="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e0da7edfed0221f9c9f7_home-testimonial-photo-p-500.webp 500w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e0da7edfed0221f9c9f7_home-testimonial-photo-p-800.webp 800w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e0da7edfed0221f9c9f7_home-testimonial-photo.webp 1080w"
								alt=""
								className="object-cover w-full md:max-w-[380px]"
							/>
							<div>
								<img
									src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d113e4d34d865b1f6238c2_%E2%80%9C.png"
									loading="lazy"
									width="54"
									alt="quote"
									class="object-cover max-w-[200px]"
								/>
							</div>

							<div
								className="flex flex-col flex-grow  justify-between items-start w-[1/2] border-t-2 border-b-2 text-left"
								style={{
									borderTop: "2px solid silver",
									borderBottom: "2px solid silver",
								}}
							>
								<div className="pt-12">
									<p className="text-3xl pb-4 leading-normal">
										"A user-friendly software, makes doing
										patient reports so easy, the duplicate
										function is a dream!"
									</p>
								</div>
								<div className="pb-12">
									<p className="text-[#333333] text-3xl ">
										Dr. Rajni Desai
									</p>
									<p className="text-[#838383] text-3xl">
										Owner, RMT
									</p>
								</div>
							</div>
						</div>
					</div>

					<div
						className=""
						style={{ backgroundColor: "rgb(222 245 255 / 0.65)" }}
					>
						<div className="flex justify-center items-center md:items-start flex-col max-w-[1200px] mx-auto pt-20">
							<h3 className="px-4 py-2  bg-blue-200/40 font-medium rounded-full text-blue-950 text-left">
								FAQ
							</h3>
							<h2 className="text-3xl md:text-5xl font-medium pt-4 tracking-tighter ">
								We've got Answers!
							</h2>
							<div className="grid grid-cols-1 md:grid-cols-2 w-full justify-center items-start gap-4 pt-16 pb-40 px-4 md:px-0">
								{faqs.map((item, index) => (
									<div className="flex justify-center items-center flex-col w-full p-5  md:min-w-[540px] bg-white feature-accordion-dropdown-link-block rounded-lg ">
										<div className="flex justify-between items-start w-full">
											<p className="text-lg font-medium">
												{item.question}
											</p>
											<img
												src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/64d3abd4ac99facac319c1c3_plus-icon.png"
												loading="lazy"
												width="25"
												alt="plus"
												class="faq-dropdown-plus-icon"
												onClick={() =>
													handleItemClick(index)
												}
												style={{
													transform:
														activeIndex === index
															? "rotate(45deg)"
															: "rotate(0deg)",
													transition:
														"transform 0.3s ease",
												}}
											/>
										</div>
										{activeIndex === index && (
											<div className="flex justify-start text-left items-start pt-4 text-[#838383]">
												<div
													dangerouslySetInnerHTML={{
														__html: DOMPurify.sanitize(
															item.answer,
															{
																ADD_ATTR: [
																	"target",
																],
															}
														),
													}}
												/>
											</div>
										)}
									</div>
								))}
							</div>
						</div>
					</div>

					<div className=" bg-[#76E1FF] flex flex-col footBar">
						<div>
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut.webp"
								loading="lazy"
								sizes="(max-width: 1080px) 100vw, 1080px"
								srcset="
			https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut-p-500.webp  500w,
			https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut-p-800.webp  800w,
			https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut.webp       1080w
		"
								alt=""
								className="hidden 	:block"
							/>
						</div>
						<div className="flex justify-center items-center flex-col pt-10 md:pt-0">
							<h2 class="text-3xl md:text-5xl font-medium  tracking-tighter">
								Get started with
								<br />
								CasaDoc today!
							</h2>
							<div class="text-2xl pb-4 leading-relaxed max-w-[800px] pt-6">
								Try CasaDoc and discover that running your
								practice doesn’t need to feel overwhelming
							</div>
							<div className="flex justify-start items-stretch md:items-start flex-col md:flex-row gap-4 pt-4">
								<Button
									href="https://casadoc.in"
									target="_blank"
									sx={{
										backgroundColor: "#04374D",
										color: "white",

										"&:hover": {
											fontWeight: "600",
											backgroundColor: "white",
											color: "#00678C",
										},
										paddingX: "1rem",
										paddingY: "1rem",
									}}
								>
									Start Your Free 30 Days
								</Button>
								<Button
									href="/contactus"
									target="_blank"
									variant="outlined"
									sx={{
										borderWidth: "2px",
										borderColor: "white",
										color: "black",

										"&:hover": {
											fontWeight: "600",
											backgroundColor: "white",
											color: "#00678C",
										},
										paddingX: "1rem",
										paddingY: "1rem",
									}}
								>
									Book a Demo
								</Button>
							</div>
						</div>
						<div className="hidden md:block">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut.webp"
								loading="lazy"
								sizes="(max-width: 1080px) 100vw, 1080px"
								srcset="
			https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut-p-500.webp  500w,
			https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut-p-800.webp  800w,
			https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut.webp       1080w
		"
								alt=""
							/>
						</div>
						<div className="flex md:hidden">
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut.webp"
								loading="lazy"
								className="w-[50%]"
								alt=""
								srcset="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut-p-500.webp 500w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut-p-800.webp 800w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f9ae17248e51ab66ee_calendar-date-picker-cut.webp 1080w"
							/>
							<img
								src="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut.webp"
								loading="lazy"
								alt=""
								className="w-[50%]"
								srcset="https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut-p-500.webp 500w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut-p-800.webp 800w, https://assets-global.website-files.com/63ee703c0c31c8867210a47c/6501e2f92ced156224647065_booking-and-invoice-cut.webp 1080w"
							/>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default CasaDoc;
