import React, { useState } from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import call from "../Assets/ConnectView/call.svg";
import mail from "../Assets/ConnectView/mail.svg";
import location from "../Assets/ConnectView/location.svg";
import {
  FaInstagram,
  FaYoutube,
  FaFacebook,
  FaLinkedin,
  FaWhatsapp,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
// import {ContactForm} from './ContactForm'

import axios from "axios";
import TestCountry2 from "./TestCountry2";
// import { Alert } from 'react-bootstrap'
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";

function Contact() {
  return (
    <section className="w-full">
      <Helmet>
        {/* Meta title and Description */}
        <title>Contact Us | CASAMED</title>
        <meta
          name="description"
          content="Contact Now to get in touch for Guaranteed Muscle and Joint Pain Relief. We have the best Doctors and Physiotherapists to understand your pain."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="muscle pain therapy,spondylosis therapy,neck pain therapy,shoulder pain therapy,cervical therapy"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/contactus" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta property="og:title" content="Contact Us | CASAMED" />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content=" https://casamed.in/contactus" />
        <meta
          property="og:description"
          content="Contact Now to get in touch for Guaranteed Muscle and Joint Pain Relief. We have the best Doctors and Physiotherapists to understand your pain"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Contact Us | CASAMED",
            url: " https://casamed.in/contactus",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "Contact Now to get in touch for Guaranteed Muscle and Joint Pain Relief. We have the best Doctors and Physiotherapists to understand your pain",
          })}
        </script>
      </Helmet>
      <Nav />
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      <div className="xxs:px-4 md:px-16 xxs:pt-24 xxs:pb-0 md:pt-24  md:pb-0 flex xxs:flex-col md:flex-row justify-center  items-center">
        <div
          style={{
            background: "linear-gradient(180deg, #01B9ED 0%, #1C3C60 100%)",
            borderRadius: "10px",
            boxShadow: "0 7px 7px rgba(0,0,0,0.25), 0 5px 5px rgba(0,0,0,0.22)",
          }}
          className="flex overflow-hidden relative  flex-col text-light px-8  py-12"
        >
          <h1 className="xxs:text-xl font-semibold md:text-3xl pb-2">
            <span className="underline underline-offset-8 decoration-white">
              C
            </span>
            ontact Information
          </h1>
          <h4 className="xxs:text-md md:text-lg text-gray-300  pb-12">
            Connect with us over any of the following means!
          </h4>
          <br className="xxs:hidden md:visible" />
          <br />
          <br />

          <div className="flex flex-col ">
            <div className="flex flex-row pb-2 items-center">
              <img src={call} className="pr-6" />
              <a href="tel:8235858626">8235858626</a>
            </div>
            <div className="flex flex-row pb-2  items-center ">
              <img src={mail} className="pr-6" />
              <a href="mailto:support@casamed.in">support@casamed.in</a>
            </div>
            {/* <div className='flex flex-row pb-2 items-center w-2/3'>
                        <img src={location} className="pr-6" />
                        <a target="_blank" href="https://www.google.com/maps/place/CASAMED/@25.6048488,85.0861066,15z/data=!4m14!1m7!3m6!1s0x39ed5751af295a63:0x26beaa820b7e5a3!2sCASAMED!8m2!3d25.6048488!4d85.0861066!16s%2Fg%2F11t_w1sth2!3m5!1s0x39ed5751af295a63:0x26beaa820b7e5a3!8m2!3d25.6048488!4d85.0861066!16s%2Fg%2F11t_w1sth2?hl=en-IN">North side, 1st Floor, Krishna Aangan, Bailey Rd, Opp. Pillar No.38, Near Paras Hospital, Ashiana More, Raja Bazar, Patna, Bihar 800014</a>
                    </div> */}

            <br className="xxs:hidden md:visible" />
            <br />
            <br />
            <br />
            <br />
            <div className="flex flex-row  items-center ">
              <a
                className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200 pr-6"
                href="https://www.facebook.com/casamedIN"
              >
                <FaFacebook size={24} />
              </a>
              <a
                className="hover:text-casablue text-gray-400 hover:-translate-y-1 pr-6 duration-200"
                href="https://www.youtube.com/channel/UCmJ_XJ3uVLjyGDJ3YpmX-aw/featured"
              >
                <FaYoutube size={24} />
              </a>

              <a
                className="hover:text-casablue text-gray-400 hover:-translate-y-1 pr-6 duration-200"
                href="https://www.instagram.com/casamed_in/"
              >
                <FaInstagram size={24} />
              </a>
              <a
                className="hover:text-casablue text-gray-400 hover:-translate-y-1 pr-6 duration-200"
                href="https://twitter.com/casamed1"
              >
                <FaTwitter size={24} />
              </a>
              <a
                className="hover:text-casablue text-gray-400 hover:-translate-y-1 duration-200"
                href="https://www.linkedin.com/company/casamedin/"
              >
                <FaLinkedin size={24} />
              </a>
            </div>
            <div className="xxs:opacity-50 md:opacity-100">
              <div className="circle1 absolute bottom-[12%] right-[12%]"></div>
              <div className="circle2 absolute -bottom-10 -right-10 "></div>
            </div>
          </div>
        </div>
        <div className="bg-transparent xxs:px-4 md:px-12 xxs:pt-12 xxs:pb-0 md:py-12">
          <TestCountry2 />
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Contact;
