import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";

const ChannelPartnerTerms = () => {
  return (
    <section className="w-full">
      <Helmet>
        {/* Meta title and Description */}
        <title>Terms & Conditions | Channel Partner | CASAMED</title>
        <meta
          name="description"
          content="CasaMed Terms & Conditions | Channel Partner: Your guide to understanding our policies. Clear, concise, and customer-friendly. Explore now!"
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Terms & Conditions | Channel Partner, user agreement, terms of service, legal agreement, terms of use, service terms, Casamed terms, user rights, user responsibilities, terms agreement"
        />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/termsconditions/channelpartner"
        />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Terms & Conditions | Channel Partner | CASAMED"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/termsconditions/channelpartner"
        />
        <meta
          property="og:description"
          content="CasaMed Terms & Conditions | Channel Partner: Your guide to understanding our policies. Clear, concise, and customer-friendly. Explore now!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Terms & Conditions | Channel Partner | CASAMED",
            url: " https://casamed.in/termsconditions/channelpartner",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "CasaMed Terms & Conditions | Channel Partner: Your guide to understanding our policies. Clear, concise, and customer-friendly. Explore now!",
          })}
        </script>
      </Helmet>
      <Nav />
      <article
        style={{
          background: "rgb(255, 255, 255)",
          background:
            "linear-gradient(120deg, rgba(255,255,255,1) 0%, rgba(235,249,255,1) 36%)",
        }}
      >
        <div className="container mx-auto p-8">
          <h1 className="text-4xl font-bold mb-6 pt-[8%]">
            Sales Channel Partner Agreement - Terms and Conditions
          </h1>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">1. Definitions:</h2>
            <p className="text-base mb-4">
              Welcome to Casamed, complete pain care platform (the "Service").
              By accessing or using the Service on any computer, mobile phone,
              tablet, or another device (collectively the "Device"), you agree
              to be bound by these Channel Partner - Terms & Conditions |
              Channel Partner (this "Agreement") and CasaMed's Terms of Use{` `}
              <a
                href="https://casamed.in/termsconditions/channelpartner"
                className="text-blue-500"
              >
                (https://casamed.in/termsconditions/channelpartner)
              </a>{" "}
              and any other applicable law.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">2. Purpose:</h2>
            <p className="text-base mb-4">
              This agreement outlines the terms and conditions governing the
              partnership between CasaMed and the Partner for the marketing and
              sale of CasaMed's services.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">
              3. Partner's Responsibilities:
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                Sales and Marketing: The Partner agrees to actively promote,
                market, and sell CasaMed's services to potential clients within
                their designated geographic area.
              </li>
              <li className="mb-2">
                Compliance: The Partner shall adhere to all relevant healthcare
                compliance regulations, including but not limited to HIPAA, and
                shall ensure that all employees and representatives comply with
                these regulations.
              </li>
              <li className="mb-2">
                Training: The Partner will participate in training sessions
                provided by CasaMed to understand its services, compliance
                requirements, and procedures adequately.
              </li>
              <li className="mb-2">
                Client Support: The Partner is responsible for providing initial
                client support, answering general inquiries, and facilitating
                the introduction of clients to the CasaMed.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">4. Compensation:</h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                Commission: The CasaMed shall pay the Partner a commission based
                on mutually agreed-upon terms for each successful sale referred
                by the Partner.
              </li>
              <li className="mb-2">
                Payment: Commission payments shall be made according to the
                payment schedule agreed upon by both parties.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">5. Confidentiality:</h2>
            <p className="text-base mb-4">
              The partner agrees to maintain the confidentiality of any
              proprietary information, including client data and business
              strategies, shared during the course of this partnership.
            </p>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                a) This partnership creates a relationship of confidence and
                trust between the partner and Casamed with respect to certain
                information of a confidential, proprietary or trade secret
                nature. For the purposes of this Agreement, all such
                confidential, proprietary or trade secret information will be
                referred to as "Proprietary Information".
              </li>
              <li className="mb-2">
                b) Proprietary Information includes without limitation: <br />
                All technology developed or licensed by or for Casamed or
                licensed to Casamed by a third party, and any documentation or
                listing pertaining to such technology; the term "technology" as
                used in this paragraph refers to technology in various stages of
                development or any product thereof and includes without
                limitation the literal elements of a program (source code,
                object code or otherwise), its audio-visual components (menus,
                screens, structure or organization), any human or machine
                readable form of the program, and any writing or medium in which
                the program or the information therein is stored, written or
                described, including without limitation, diagrams, flowcharts,
                designs, drawings, specification, models, data, bug reports and
                customer information.
                <p>
                  Marketing and sales plans, product development plans,
                  competitive analyses, benchmark test results, business and
                  financial plans or forecasts, non-public financial
                  information, agreements, and partner, customer and employee
                  lists of Casamed.{" "}
                </p>
                <p>
                  Any information or material not described above which relates
                  to Casamed's inventions, technological developments,
                  "know-how", purchasing, accounting, merchandising, or
                  licensing.{" "}
                </p>
                <p>
                  Any information of the type described above which Casamed has
                  a legal obligation to treat as confidential, or which Casamed
                  treats as proprietary or designates as confidential, whether
                  or not owned or developed by Casamed. Proprietary Information
                  shall not include information known publicly or generally
                  employed in the trade, nor shall it include generic knowledge
                  that you would have learned in the course of similar
                  employment elsewhere.
                </p>
              </li>
              <li className="mb-2">
                c) At all times, both during and after the partnership with
                Casamed, the partner will hold Proprietary Information in
                confidence. They will not use, transfer, publish, disclose, or
                report Proprietary Information directly or indirectly, except
                such disclosure to other Casamed employees or authorised third
                parties as may be necessary in the ordinary course of performing
                their duties for Casamed or otherwise as directed by Casamed.
              </li>
              <li className="mb-2">
                d) The partners agree that they have not brought any proprietary
                information of any former partner, CasaMed’s competitor or any
                other related service provider to Casamed and that they will not
                use any proprietary information of a former partner, CasaMed’s
                competitor or any other related service provider in the
                performance of their work with Casamed unless they have written
                authorization from the former partner, CasaMed’s competitor or
                any other related service provider.
              </li>
              <li className="mb-2">
                e) Partner will promptly disclose to Casamed all ideas,
                processes, inventions, modification, and improvements
                (collectively referred to as "Inventions") relating to any work
                or business carried on by Casamed, conceived by them alone or
                with others during the term of the partnership, whether or not
                conceived during regular business hours.
              </li>
              <li className="mb-2">
                f) All such Inventions shall be the sole and exclusive property
                of Casamed. They also agree to execute without receiving
                additional compensation: (a) any formal documents necessary to
                assign any Inventions to Casamed; and (b) all documents required
                to obtain a patent, register a copyright, or enforce Casamed's
                rights in such Inventions. These obligations shall continue
                beyond the termination of partnership with respect to Inventions
                they conceive or make during the period of their partnership.
              </li>
              <li className="mb-2">
                g) The partners will not during their partnership with Casamed
                carry on either alone or in partnership or be directly or
                indirectly employed or concerned in any business undertaking
                other than that of Casamed, except as a shareholder in a
                publicly quoted company unless they have obtained the previous
                written consent of Casamed. Further, they will not accept gifts,
                entertainment or other favours from persons or bodies with which
                Casamed has business dealings unless they have obtained the
                previous written consent of Casamed.
              </li>
              <li className="mb-2">
                h) They will not recruit or hire any Casamed employee for six
                months after termination of their partnership with Casamed
                without Casamed's express written consent.
              </li>
              <li className="mb-2">
                i) The partner shall upon termination of their partnership with
                Casamed and upon Casamed's request reaffirm their recognition of
                the importance of maintaining the confidentiality of Casamed's
                Proprietary Information and reaffirm all of the obligations set
                forth in this Agreement.
              </li>
              <li className="mb-2">
                j) The partner shall upon termination of their partnership with
                Casamed return all property belonging to Casamed, including
                without limitation all Proprietary Information, documents,
                software, discs, diskettes, tapes, and any other form of media,
                copies of any of the above, microcomputer systems, laptops,
                computer terminals, modems, other hardware, telephones, credit
                cards, and/or company automobile.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">
              6. Term and Termination:
            </h2>
            <ul className="list-disc pl-5">
              <li className="mb-2">
                Term: This agreement commences and is effective from the moment
                the form is submitted duly filled and continues until terminated
                by mutual agreement or on disciplinary grounds.
              </li>
              <li className="mb-2">
                Termination: CasaMed reserves the right to terminate this
                agreement for cause if the partner breaches any material term or
                condition of this agreement.
              </li>
            </ul>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">7. Liability:</h2>
            <p className="text-base mb-4">
              The partner agrees to indemnify and hold the company harmless from
              and against all loss or damage, including reasonable attorneys’
              fees, costs and expenses incurred by the company as a result of
              any claims related to or arising out of your failure to perform
              your duties under this Agreement with reasonable care. Also, the
              Partner shall be liable for any claims, damages, or losses
              incurred by the CasaMed, its clients, or third parties resulting
              from the Partner's actions.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">
              8. Entire Agreement:
            </h2>
            <p className="text-base mb-4">
              This agreement constitutes the entire understanding between the
              parties and supersedes all prior agreements, oral or written,
              between the parties.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">9. Amendment:</h2>
            <p className="text-base mb-4">
              This agreement may be amended or modified only in writing and
              signed by both parties.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">10. Severability:</h2>
            <p className="text-base mb-4">
              If any provision of this agreement is held to be invalid or
              unenforceable, the remaining provisions shall remain in full force
              and effect.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">11. Assignment:</h2>
            <p className="text-base mb-4">
              Neither party may assign or transfer its rights or obligations
              under this agreement without the prior written consent of the
              other party.
            </p>
          </section>

          <section className="mb-6">
            <h2 className="text-2xl font-semibold mb-4">12. Counterparts:</h2>
            <p className="text-base mb-4">
              This agreement may be executed in multiple counterparts, each of
              which shall be deemed an original and all of which, when taken
              together, shall constitute one agreement.
            </p>
          </section>
        </div>

        <Footer />
      </article>
    </section>
  );
};

export default ChannelPartnerTerms;
