import React from "react";
import { useRef, useEffect } from "react";

// import { Link } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link";

import { items } from "../../Constants";
import Button from "../../Components/Button";
import { Fade } from "react-awesome-reveal";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative, Autoplay, Keyboard, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import { shops as shops2 } from "../../Constants";
import { VideoPlayer } from "../../Components/VideoPlayer";
import Slideshow from "../../Components/Particles";

function Features() {
	return (
		<section
			id="featuresmain"
			className="xxs:py-10 md:py-20 bg-featureshade  text-white px-2 md:px-16 lg:px-24"
		>
			<div className="tracking-wide">
				<h2
					style={{ lineHeight: 1.4 }}
					className="xxs:text-3xl md:text-5xl  font-semibold text-center mb-10 "
				>
					<span className="decoration-[#02B9ED] underline underline-offset-8">
						O
					</span>
					ne Stop Shop for All Your Pain Management Needs
				</h2>
			</div>
			<div className="flex justify-center items-center py-8">
				<VideoPlayer />
			</div>
			<ul className="-pt-10 px-2">
				{items.slice(0, 4).map((item, index) => (
					<li key={index}>
						<div
							className={`${item.style} pb-20 pt-20 flex flex-col md:flex-row xxs:justify-center xxs:items-center md:justify-between  gap-12 md:gap-0 md:items-center`}
						>
							<div
								className={`w-full flex-col ${
									index === 0 || 2 ? "xl:pl-24 xxs:pl-0" : ""
								} md:w-1/2 right-0`}
							>
								<h3 className="font-bold xxs:text-xl md:text-3xl capitalize fheading mb-6">
									<span className="underline underline-offset-4 decoration-[#02B9ED] mr-0.5">
										{item.heading[0]}
									</span>
									<span>{item.heading.slice(1)}</span>
								</h3>

								<m direction="bottom">
									<p className="text-light  xxs:text-lg md:text-2xl xl:mr-80 fdesca lg:mr-6 mb-6">
										{item.text1}
									</p>
									<p className="text-light  xxs:text-lg md:text-2xl xl:mr-80 fdescb lg:mr-6 mb-6">
										{item.text2}
									</p>

									<Link
										to={`${
											item.heading === "Live Sessions"
												? "/product#livesession"
												: "/product"
										}`}
									>
										<Button>{item.button}</Button>
									</Link>
								</m>
							</div>
							<div className="w-full grid justify-center  items-center md:w-1/2 xxs:w-full">
								<Fade direction="right">
									<div className="flex xxs:justify-center xxs:items-center">
										<img
											className={` md:w-[80%] xl:w-[90%] xxs:w-[70%]    rounded-xl shadow-xl z-0 `}
											src={item.image}
											alt="features"
										/>
									</div>
								</Fade>
							</div>
						</div>
					</li>
				))}
			</ul>
			<br />
			<Fade direction="down">
				<h3 className="font-bold xxs:text-2xl md:text-5xl text-center fheading xxs:pb-6 md:pb-8">
					<span className="underline underline-offset-8 decoration-[#02B9ED]">
						C
					</span>
					asaMed Shop
				</h3>
			</Fade>
			<Fade direction="up">
				<div className="flex justify-center items-center flex-col pb-6 rounded-lg">
					<p className="text-light  xxs:text-lg md:text-xl text-center mb-6">
						<strong className="md:text-2xl xxs:text-xl font-semibold">
							One Stop Shop
						</strong>{" "}
						for all your Pain Manangement Needs
					</p>
					<p className="text-light text-center xxs:text-lg md:text-xl mb-6 xxs:px-4 md:px-[30%]">
						Browse through our exhaustive range of accessories such
						as weights, training equiment, posture correcting
						apparatus and much more!
					</p>
				</div>
			</Fade>
			<div className="w-full xxs:px-0 md:px-[5%] flex flex-col justify-center items-center">
				<Slideshow />

				<Link
					to="/product#casashop"
					className="w-[75%]"
				>
					<div className="flex justify-center items-center xxs:pt-12 md:pt-24">
						<Button>Learn More</Button>
					</div>
				</Link>
			</div>
		</section>
	);
}

export default Features;
