import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OurValues from "./OurValues";
import TeamTest from "./Teamtest";
import MentorsTest from "./MentorsTest";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      className="flex justify-center items-center flex-col  pt-16"
      sx={{ width: "100%" }}
    >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            sx={{
              paddingX: { xs: "2px", md: "16px" },
              fontWeight: "700",
              fontSize: { xs: "medium", md: "xx-large" },
              color: "#016D8D",
            }}
            label="Our Team"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              paddingX: { xs: "2px", md: "16px" },
              fontWeight: "700",
              fontSize: { xs: "medium", md: "xx-large" },
              color: "#016D8D",
            }}
            label="Vision"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              paddingX: { xs: "2px", md: "16px" },
              fontWeight: "700",
              fontSize: { xs: "medium", md: "xx-large" },
              color: "#016D8D",
            }}
            label="Mission"
            {...a11yProps(2)}
          />
          <Tab
            sx={{
              paddingX: { xs: "2px", md: "16px" },
              fontWeight: "700",
              fontSize: { xs: "medium", md: "xx-large" },
              color: "#016D8D",
            }}
            label="Values"
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel className="md:px-48 xxs:px-12" value={value} index={1}>
        <p className="leading-loose  text-lg text-casashade2 ">
          Our core goal is to ensure that all our customers are relieved of
          their pain and discomfort that comes with musculoskeletal disorders,
          working towards preventing mobility disorders, which have occurred due
          to chronic ailments, trauma or sports injury and lead a healthier
          life.
        </p>

        <p className="text-casashade2 opacity-70 text-2xl leading-loose tracking-wide pt-8 pb-8  italic">
          <span className="not-italic font-semibold text-3xl">
            Our Vision :
          </span>{" "}
          "Authentic Digital Pain Management powered by <strong>AI</strong> and
          real <strong>Doctors and Health Professionals</strong>,<br />{" "}
          Everywhere, for Everyone."
        </p>
      </TabPanel>
      <TabPanel className="xxs:px-12 md:px-48" value={value} index={2}>
        <p className="leading-loose  text-lg text-casashade2 ">
          Casamed aims at harnessing technology to provide effective and
          efficient physiotherapy solutions for people in need of several pain
          management solutions. With its cutting edge technology, Casamed
          delivers rehabilitation at your doorstep, saving the hassle and
          discomfort of repeated visits to the physiotherapist by summoning the
          best doctors and physiotherapist at your fingertips.
          <br />
          <br />
          We at Casamed pride ourselves in being an extremely customer centric
          organization and we will always strive to bring the best to you and
          ensure an unparalleled customer experience, earning the faith that you
          have placed in us.
        </p>

        <p className="text-casashade2 opacity-70 text-2xl leading-loose tracking-wide pt-8 pb-8  italic">
          <span className="not-italic font-semibold text-3xl">
            Our Mission :
          </span>{" "}
          "Casamed works relentlessly to apply cutting edge technologies to
          physiotherapy,so as to transform physiotherapy into a fruitful and
          relieving experience for all our customers by working around the taboo
          and hopelessness that accompanies traditional methods of physiotherapy
          with our strong <strong>patient centric values</strong> and our
          refined <strong>technological prowess</strong>.""
        </p>
      </TabPanel>

      <TabPanel className="xxs:px-0 md:px-48" value={value} index={3}>
        <OurValues />
      </TabPanel>

      <TabPanel className="xxs:px-6 md:px-16" value={value} index={0}>
        <MentorsTest />
        <TeamTest />
      </TabPanel>
    </Box>
  );
}
