import { React, useState, useEffect } from "react";
import Nav from "../Components/Nav";
import { Helmet } from "react-helmet";
import Numbers from "../Components/Numbers";
import Footer from "../Components/Footer";

import Snackbar from "@mui/material/Snackbar";

import { IconButton, SnackbarContent } from "@mui/material";

import { CheckCircle, Error, Close } from "@material-ui/icons";

import axios from "axios";

import { NavHashLink as Link1 } from "react-router-hash-link";
import { motion } from "framer-motion";

import { Flip } from "react-awesome-reveal";
import { Link, useParams } from "react-router-dom";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import "swiper/css";
import "swiper/css/effect-cards";

// import required modules

const primarysty = {
	color: "#024461",
	fontWeight: "500",
	fontSize: "20px",
	fontFamily: "Poppins",
};
const secondarysty = {
	color: "#024461",
	fontWeight: "400",
	fontSize: "15px",
	fontFamily: "Poppins",
	whiteSpace: "pre-line",
};

const imageVariant = {
	start: {
		rotate: "-180deg",
	},
	end: {
		rotate: 0,
		transition: {
			duration: 1,
		},
	},
};

const primarysty1 = {
	color: "#024461",
	fontWeight: "700",
	fontSize: "1.5rem",
	fontStyle: "Poppins",
};
const secondarysty1 = {
	color: "#016D8D",
	fontWeight: "400",
	fontSize: "1.125rem",
	fontStyle: "Poppins",
	paddingRight: "0.5rem",
};

console.log(` 

██████╗ █████╗ ███████╗ █████╗ ███╗   ███╗███████╗██████╗
██╔════╝██╔══██╗██╔════╝██╔══██╗████╗ ████║██╔════╝██╔══██╗
██║     ███████║███████╗███████║██╔████╔██║█████╗  ██║  ██║
██║     ██╔══██║╚════██║██╔══██║██║╚██╔╝██║██╔══╝  ██║  ██║
╚██████╗██║  ██║███████║██║  ██║██║ ╚═╝ ██║███████╗██████╔╝
 ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝╚═════╝
 `);

function City() {
	const [cityPage, setCityPage] = useState(null);
	const [name, setName] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const { slug } = useParams();

	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState("success");

	const [nameError, setNameError] = useState(false);
	const [phoneNumberError, setPhoneNumberError] = useState(false);

	const validateName = () => {
		if (name.trim() === "") {
			setNameError("This field is required");
			return false;
		}
		return true;
	};

	const validatePhoneNumber = () => {
		const phoneRegex = /^[0-9]{10}$/;
		if (!phoneRegex.test(phoneNumber)) {
			setPhoneNumberError("Please enter a valid phone number");
			return false;
		}
		return true;
	};

	const handleSnackbarClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setShowSnackbar(false);
	};

	useEffect(() => {
		axios
			.get(`/api/cityPages/${slug}`)
			.then((response) => {
				setCityPage(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	}, [slug]);

	if (!cityPage) {
		return <div>Loading...</div>;
	}
	// console.log(cityPage)

	const City = cityPage.slug.charAt(0).toUpperCase() + cityPage.slug.slice(1);

	const handleSubmit = (event) => {
		event.preventDefault();

		if (!validateName() || !validatePhoneNumber()) {
			return;
		}

		axios
			.post(
				"/api/cityForm",
				{
					name: name,
					phoneNumber: phoneNumber,
					location: City,
				},
				{
					headers: {
						Authorization: localStorage.getItem("token"),
						auth: {
							user: JSON.stringify(localStorage.getItem("user")),
						},
					},
				}
			)
			.then((response) => {
				// console.log(response.data);
				// alert('Form submitted successfully');

				setShowSnackbar(true);
				setSnackbarMessage("Form submitted successfully!");
				setSnackbarSeverity("success");

				setName("");
				setPhoneNumber("");
			})
			.catch((error) => {
				console.error(error);
				// alert('Form submission failed');

				setShowSnackbar(true);
				setSnackbarMessage(
					"Failed to submit form. Please try again later."
				);
				setSnackbarSeverity("error");
			});
	};

	const handleNameChange = (event) => {
		setName(event.target.value);
		setNameError(false);
	};

	const handlePhoneNumberChange = (event) => {
		setPhoneNumber(event.target.value);
		setPhoneNumberError(false);
	};

	//  console.log(cityPage.intro)

	return (
		<div>
			<div className="pb-15 w-[100vw] ">
				<Nav />
			</div>
			<Helmet>
				{/* Meta title and Description */}
				<title>{cityPage.metaTitle}</title>
				<meta
					name="description"
					content={cityPage.metaDesc}
				/>
				{/* Meta Keywords */}
				<meta
					name="keywords"
					content="muscle pain therapy,spondylosis therapy,neck pain therapy,shoulder pain therapy,cervical therapy"
				/>

				{cityPage.slug === "patna" || cityPage.slug === "jaipur" ? (
					<meta
						name="robots"
						content="index, follow"
					/>
				) : (
					<meta
						name="robots"
						content="noindex, nofollow"
					/>
				)}

				{/* Canonical Tag */}
				<link
					rel="canonical"
					href={`https://casamed.in/${slug}`}
				/>
				{/* Open Graph */}
				<meta
					property="og:title"
					content={cityPage.metaTitle}
				/>

				<meta
					property="og:site_name"
					content="Casamed"
				/>
				<meta
					property="og:url"
					content={`https://casamed.in/${slug}`}
				/>
				<meta
					property="og:description"
					content={cityPage.metaDesc}
				/>
				<meta
					property="og:type"
					content="website"
				/>
				<meta
					property="og:image"
					content={cityPage.metaImg}
				/>

				{/* Facebook Meta Tags */}
				<meta
					property="og:url"
					content={`https://casamed.in/${slug}`}
				/>
				<meta
					property="og:type"
					content="website"
				/>
				<meta
					property="og:title"
					content={cityPage.smMetaTitle}
				/>
				<meta
					property="og:description"
					content={cityPage.smMetaDesc}
				/>
				<meta
					property="og:image"
					content={cityPage.headerImg}
				/>

				{/* Twitter Meta Tags */}
				<meta
					name="twitter:card"
					content="summary_large_image"
				/>
				<meta
					property="twitter:url"
					content={`https://casamed.in/${slug}`}
				/>
				<meta
					name="twitter:title"
					content={cityPage.smMetaTitle}
				/>
				<meta
					name="twitter:description"
					content={cityPage.smMetaDesc}
				/>
				<meta
					name="twitter:image"
					content={cityPage.headerImg}
				/>

				{/* Schema MarkUp */}
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org/",
						"@type": "Organization",
						name: cityPage.metaTitle,
						url: `https://casamed.in/${slug}`,
						logo: {
							"@type": "ImageObject",
							url: cityPage.metaImg,
						},
						description: cityPage.metaDesc,
					})}
				</script>

				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "FAQPage",
						mainEntity: cityPage.faqs.map((faq) => ({
							"@type": "Question",
							name: faq.question,
							acceptedAnswer: {
								"@type": "Answer",
								text: faq.answer,
							},
						})),
					})}
				</script>
			</Helmet>

			<div className="relative">
				<img
					className={`w-full xxs:h-[950px]  lg:h-screen  object-cover ${
						cityPage.slug === "guwahati" ? " object-top" : ""
					}`}
					src={cityPage.headerImg}
					alt="Hero section background"
				/>
				<div className="absolute top-28 left-0 w-full h-screen flex items-center justify-center">
					<div className="text-center">
						<h1 className="text-4xl sm:text-5xl font-bold text-white pt-12 xxs:px-2 md:px-[15%]">
							CasaMed in {` ${City}`}: Your Premier Healthcare
							Service Provider
							<br />
						</h1>
						<p className="mt-4 text-lg blur-[0.5px] sm:text-xl text-white">
							Your Path to Pain-Free Living!
						</p>
						<div className="flex md:flex-row xxs:flex-col justify-center items-center px-[2%]">
							<div className="">
								<form
									id="callback"
									className="xxs:mt-4 sm:mt-8 flex flex-col xxs:gap-1  sm:gap-2 justify-center items-center bg-white xxs:p-2 sm:p-4  rounded-lg drop-shadow-lg shadow-lg"
									onSubmit={handleSubmit}
								>
									<h2 className="sm:text-4xl xxs:text-2xl font-medium text-neutral-700 pt-4">
										Ask for a CallBack
									</h2>
									<p className="xxs:px-2 font-thin xxs:text-sm sm:text-base">
										100+ Expert Physiotherapists for 200+
										Conditions
									</p>
									<div className="w-full">
										<input
											type="text"
											placeholder="Name"
											className={`w-full appearance-none mt-4  rounded-lg shadow-md border  py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
												nameError
													? "border-red-500"
													: ""
											}`}
											value={name}
											onChange={handleNameChange}
										/>
										{nameError && (
											<p className="text-red-500 text-xs pt-2 italic">
												This field is required
											</p>
										)}
									</div>
									<div className="w-full">
										<input
											type="text"
											placeholder="Mobile Number"
											className={`w-full appearance-none mt-4  rounded-lg shadow-md border py-2 px-4 leading-tight focus:outline-none focus:shadow-outline ${
												phoneNumberError
													? "border-red-500"
													: ""
											}`}
											value={phoneNumber}
											onChange={handlePhoneNumberChange}
										/>
										{phoneNumberError && (
											<p className="text-red-500 text-xs pt-2 italic">
												This field is required /
												incorrect
											</p>
										)}
									</div>

									<button
										type="submit"
										className="mt-4 px-6 py-2 w-full bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
									>
										Begin Your Recovery
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				open={showSnackbar}
				autoHideDuration={5000}
				onClose={handleSnackbarClose}
			>
				<SnackbarContent
					style={{
						backgroundColor:
							snackbarSeverity === "success"
								? "#43a047"
								: "#d32f2f",
					}}
					message={
						<span style={{ display: "flex", alignItems: "center" }}>
							{snackbarSeverity === "success" ? (
								<CheckCircle style={{ marginRight: "8px" }} />
							) : (
								<Error style={{ marginRight: "8px" }} />
							)}
							{snackbarMessage}
						</span>
					}
					action={[
						<IconButton
							key="close"
							color="inherit"
							onClick={handleSnackbarClose}
						>
							<Close />
						</IconButton>,
					]}
				/>
			</Snackbar>

			<div className="pt-8 ">
				<Numbers />
			</div>

			<div className="og--bg--grad--inv w-full">
				<div className="px-[5%] md:px-[25%] text-center py-16">
					{cityPage.intro.map((intro, index) => (
						<div key={index}>
							<p
								style={{ lineHeight: "2.5rem" }}
								className="mt-4 text-lg lead sm:text-xl text-casashade2"
							>
								{intro.para}
							</p>
						</div>
					))}
				</div>

				<motion.div
					variants={imageVariant}
					initial={{ opacity: 0, x: 100 }}
					animate={{ opacity: 1, x: 0 }}
					transition={{
						type: "spring",
						damping: 6,
						delay: 0.8,
						duration: 1,
					}}
					className="md:py-32 xxs:pb-28 xxs:pt-14 px-[5%] md:px-[18%] xxl:px-[25%] "
				>
					<h2 className="text-center xxs:pb-20 md:pb-10 text-casashade2 md:text-5xl xxs:text-4xl font-bold md:px-20">
						<span className="decoration-[#02B9ED] underline underline-offset-8">
							T
						</span>
						reatment benefits with CasaMed in {`${City}`}
					</h2>

					<Box
						className="md:py-8 "
						sx={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<nav aria-label="main mailbox folders">
							<div className="flex justify-center items-center">
								<List className="flex flex-col justify-center items-center ">
									{cityPage.treatBenefits.map(
										(benefit, index) => (
											<ListItem key={index}>
												<Link1
													to={`/${slug}#${benefit.id}`}
												>
													<ListItemButton className="flex flex-col sm:flex-row  sm:gap-8 justify-center items-center">
														<ListItemIcon className="xxs:w-[20%] sm:w-auto">
															<img
																src={
																	benefit.icon
																}
																className="xxs:w-full sm:w-[100px]"
																alt=""
															/>
														</ListItemIcon>
														<ListItemText
															className="flex flex-col gap-2 xxs:text-center sm:text-left"
															primaryTypographyProps={{
																style: primarysty1,
															}}
															secondaryTypographyProps={{
																style: secondarysty1,
															}}
															primary={
																benefit.title
															}
															secondary={
																benefit.desc
															}
														/>
													</ListItemButton>
												</Link1>
											</ListItem>
										)
									)}
								</List>
							</div>
						</nav>
					</Box>

					<div className="text-casashade2 flex flex-col gap-24 pt-16">
						{cityPage.treatBenefits.map((benefit, index) => (
							<div
								key={index}
								id={benefit.id}
								className={`flex ${
									index % 2 === 0
										? "xxs:flex-col md:flex-row-reverse"
										: "xxs:flex-col md:flex-row"
								} justify-center gap-[10%]`}
							>
								<div className="flex justify-center items-baseline">
									<img
										src={benefit.img}
										className="h-[600px] w-[400px] object-cover object-center rounded-lg"
									/>
								</div>
								<div className="xxs:w-full xxs:pt-[10%] md:pt-0 md:w-1/3 flex flex-col md:items-start md:justify-center xxs:items-center xxs:justify-center">
									<h3 className="font-semibold xxs:text-xl md:text-2xl capitalize fheading mb-6">
										<span className="underline underline-offset-4 decoration-[#02B9ED] mr-0.5">
											{benefit.head[0]}
										</span>
										<span>{benefit.head.slice(1)}</span>
									</h3>
									<p className="xxs:text-base md:text-lg">
										{benefit.detailedDesc}
									</p>
								</div>
							</div>
						))}
					</div>
					<div className="flex justify-center items-center">
						<div className="xxs:px-[5%] pt-16  text-center">
							{cityPage.treatBenefitsPara.map(
								(benefitpara, index) => (
									<p
										style={{ lineHeight: "2.5rem" }}
										key={index}
										className="mt-4 text-lg sm:text-xl text-casashade2"
									>
										{benefitpara.para}
									</p>
								)
							)}
						</div>
					</div>
				</motion.div>
			</div>

			{cityPage.slug === "hyderabad" ? (
				<div className="xxs:py-16 sm:py-24">
					<div className="">
						<h2 className="text-5xl font-bold text-whyus text-center first-letter:decoration-[#02B9ED] first-letter:underline first-letter:underline-offset-8">
							Our Offerings
						</h2>
						<h4 className="text-lg font-thin text-center text-casashade2 tracking-widest pt-8 ">
							Treatment and Services we offer:
						</h4>
					</div>
					<div className="pt-6 flex justify-center items-center">
						<ul className="capitalize grid grid-cols-1 xxs:text-center sm:text-left justify-center items-center gap-x-4 gap-y-2 xxs:text-lg sm:text-xl font-medium">
							{cityPage.treatProvides.map(
								(treatProvide, index) => (
									<Link
										to={`/${treatProvide.link}/${slug}`}
										className=""
										target="_blank"
										key={index}
									>
										<ListItemButton className=" ">
											<ListItemText
												primaryTypographyProps={{
													style: primarysty,
												}}
												className="py-1 xxs:text-center text-casashade sm:text-left"
											>
												<span className="underline underline-offset-4 decoration-[#02B9ED] ">
													{treatProvide.treatment[0]}
												</span>
												<span>
													{treatProvide.treatment.slice(
														1
													)}
												</span>
											</ListItemText>
										</ListItemButton>
									</Link>
								)
							)}
						</ul>
					</div>
				</div>
			) : cityPage.slug !== "patna" && cityPage.slug !== "jaipur" ? (
				<div className="xxs:py-16 sm:py-24">
					<h2 className="text-5xl font-bold text-whyus text-center first-letter:decoration-[#02B9ED] first-letter:underline first-letter:underline-offset-8">
						Our Offerings
					</h2>
					<h4 className="text-lg font-thin text-center text-casashade2 tracking-widest pt-8 ">
						Treatment and Services we offer:
					</h4>

					<div className="pt-6 flex justify-center items-center">
						<ul className="capitalize grid grid-cols-1 xxs:text-center sm:text-left justify-center items-center gap-x-4 gap-y-2 xxs:text-lg sm:text-xl font-medium">
							{cityPage.treatProvides.map(
								(treatProvide, index) => (
									<Link
										to={`/${treatProvide.link}/${slug}`}
										className=""
										target="_blank"
										key={index}
									>
										<ListItemButton className=" ">
											<ListItemText
												primaryTypographyProps={{
													style: primarysty,
												}}
												className="py-1 xxs:text-center text-casashade sm:text-left"
											>
												<span className="underline underline-offset-4 decoration-[#02B9ED] ">
													{treatProvide.treatment[0]}
												</span>
												<span>
													{treatProvide.treatment.slice(
														1
													)}
												</span>
											</ListItemText>
										</ListItemButton>
									</Link>
								)
							)}
							{cityPage.serviceOffers.map(
								(serviceOffer, index) => (
									<Link
										to={`/${serviceOffer.link}/${slug}`}
										className=""
										target="_blank"
										key={index}
									>
										<ListItemButton className=" ">
											<ListItemText
												primaryTypographyProps={{
													style: primarysty,
												}}
												className="py-1 xxs:text-center text-casashade sm:text-left"
											>
												<span className="underline underline-offset-4 decoration-[#02B9ED] ">
													{serviceOffer.service[0]}
												</span>
												<span>
													{serviceOffer.service.slice(
														1
													)}
												</span>
											</ListItemText>
										</ListItemButton>
									</Link>
								)
							)}
						</ul>
					</div>
				</div>
			) : (
				<div className="">
					<section className="body-back2 py-16">
						<header className="flex-col text-center flex-wrap justify-center items-center font-poppins text-casashade2 xxs:pb-2 px-6 sm:pb-8">
							<div className="flex justify-center items-center">
								<h2
									style={{ lineHeight: 1.4 }}
									className="pb-12 font-semibold xxs:text-3xl md:text-5xl md:w-[70%] w-full"
								>
									<span className="decoration-[#02B9ED] underline underline-offset-8">
										T
									</span>
									reatments We Provide
								</h2>
							</div>
							<h4 className="text-[20px] pb-10">
								Treatments that we provide at Casamed in{" "}
								{`${City}`}
							</h4>
						</header>

						<section
							id="persona"
							className="flex flex-wrap justify-center items-center font-poppins text-whyus"
						>
							<ul className="capitalize grid grid-cols-1 xxs:text-center sm:text-left justify-center items-center gap-x-4 gap-y-2 xxs:text-lg sm:text-xl font-medium">
								{cityPage.treatProvides.map(
									(treatProvide, index) =>
										treatProvide.treatment ===
										"Rheumatoid Treatment" ? (
											<Link
												to={`/${treatProvide.link}/${slug}`}
												className=""
												target="_blank"
												key={index}
											>
												<ListItemButton className=" ">
													<ListItemText
														primaryTypographyProps={{
															style: primarysty,
														}}
														className="py-1 xxs:text-center text-casashade sm:text-left"
													>
														<span className="underline underline-offset-4 decoration-[#02B9ED] ">
															{
																treatProvide
																	.treatment[0]
															}
														</span>
														<span>
															{treatProvide.treatment.slice(
																1
															)}
														</span>
													</ListItemText>
												</ListItemButton>
											</Link>
										) : (
											<Link
												to={`/${slug}/${treatProvide.link}`}
												target="_blank"
												key={index}
											>
												<ListItemButton>
													<ListItemText
														primaryTypographyProps={{
															style: primarysty,
														}}
														className="py-1 text-casashade xxs:text-center sm:text-left "
													>
														<span className="underline underline-offset-4 decoration-[#02B9ED] ">
															{
																treatProvide
																	.treatment[0]
															}
														</span>
														<span>
															{treatProvide.treatment.slice(
																1
															)}
														</span>
													</ListItemText>
												</ListItemButton>
											</Link>
										)
								)}
								{City === "Patna" && (
									<Link
										to={`/${slug}/pcod-treatment`}
										target="_blank"
									>
										<ListItemButton>
											<ListItemText
												primaryTypographyProps={{
													style: primarysty,
												}}
												className="py-1 text-casashade xxs:text-center sm:text-left "
											>
												<span className="underline underline-offset-4 decoration-[#02B9ED] ">
													P
												</span>
												<span>COD Treatment</span>
											</ListItemText>
										</ListItemButton>
									</Link>
								)}
							</ul>
						</section>
					</section>

					<section className="bg-white xxs:px-[2%] md:pl-[10%] md:pr-[4%] xl:px-[16%] xxs:py-16 md:py-16 grid xxs:grid-cols-1 md:grid-cols-2 md:justify-between w-full">
						<div className="flex">
							<h2
								style={{ lineHeight: "inherit" }}
								className={`xxs:text-4xl md:text-[50px] text-whyus xxs:text-center md:text-left font-semibold font-poppins xxs:pb-6`}
							>
								<span className="decoration-[#02B9ED] underline underline-offset-8">
									S
								</span>
								ervices We Offer
								<br />
								<br className="sm:block hidden" />
								Services that we provide at Casamed in{" "}
								<strong>{`${City}`}</strong>
							</h2>
						</div>
						<Box
							className=" "
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							}}
						>
							<nav aria-label="main mailbox folders">
								<List className="grid grid-cols-1 xxs:text-center sm:text-left justify-center items-center ">
									{cityPage.serviceOffers.map(
										(serviceOffer, index, id) => (
											<Flip
												direction="horizontal"
												cascade
												damping={0.5}
												key={index}
											>
												{serviceOffer.service ===
												"Physiotherapy" ? (
													<Link
														to={`/${serviceOffer.link}/${slug}`}
														target="_blank"
													>
														<ListItem
															className=""
															disablePadding
														>
															<ListItemButton>
																<ListItemText
																	className="py-1 xxs:text-center text-casashade sm:text-left"
																	primaryTypographyProps={{
																		style: primarysty,
																	}}
																	primary={
																		serviceOffer.service
																	}
																	sx={{
																		fontFamily:
																			"Poppins",
																	}}
																/>
															</ListItemButton>
														</ListItem>
													</Link>
												) : (
													<Link
														to={`/${slug}/${serviceOffer.link}`}
														target="_blank"
													>
														<ListItem
															className=""
															disablePadding
														>
															<ListItemButton>
																<ListItemText
																	className="xxs:text-center text-casashade sm:text-left py-1"
																	primaryTypographyProps={{
																		style: primarysty,
																	}}
																	primary={
																		serviceOffer.service
																	}
																	sx={{
																		fontFamily:
																			"Poppins",
																	}}
																/>
															</ListItemButton>
														</ListItem>
													</Link>
												)}
											</Flip>
										)
									)}
								</List>
							</nav>
						</Box>
					</section>
				</div>
			)}

			<div className="pt-16 pb-16">
				<div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
					<div className="flex flex-col items-center">
						<h2 className="font-bold text-whyus text-5xl mt-5 tracking-tight">
							FAQ
						</h2>
						<p className="text-whyus text-opacity-70 text-xl mt-3">
							Frequenty asked questions
						</p>
					</div>
					<div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
						{cityPage.faqs.map((faq, index) => (
							<div
								className="xxs:py-5 md:py-10 "
								key={index}
							>
								<details className="group">
									<summary className="flex justify-between items-center font-medium cursor-pointer list-none">
										<span className="text-xl text-whyus">
											{" "}
											{faq.question}
										</span>
										<span className="transition group-open:rotate-180">
											<svg
												fill="none"
												height="24"
												shapeRendering="geometricPrecision"
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="1.5"
												viewBox="0 0 24 24"
												width="24"
											>
												<path d="M6 9l6 6 6-6"></path>
											</svg>
										</span>
									</summary>
									<p className="text-whyus text-opacity-70 text-lg mt-3 group-open:animate-fadeIn">
										{faq.answer}
									</p>
								</details>
							</div>
						))}
					</div>
				</div>
			</div>

			<div className="og--bg--grad--inv">
				<div className="">
					<section className=" md:px-16 pb-16">
						<div className="text-center pb-16">
							<h2 className="font-bold text-blue-400 text-4xl">
								Contact us
							</h2>

							<h3 className="mt-2 text-2xl font-semibold  md:text-3xl text-whyus">
								We’d love to hear from you
							</h3>

							<p className="mt-3 text-gray-500 ">
								Write to our support team.
							</p>
						</div>
						<div className="container py-8 mx-auto bg-[url('https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100')] bg-no-repeat bg-cover  ">
							{/* <div className="text-center ">
            <p className="font-bold text-blue-400 text-3xl">Contact us</p>

            <h1 className="mt-2 text-2xl font-semibold  md:text-3xl text-whyus">We’d love to hear from you</h1>

            <p className="mt-3 text-gray-500 ">Chat to our friendly team.</p>
        </div> */}

							{/* <img className="object-cover w-full h-64 mt-10 rounded-lg lg:h-96" src="https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100" alt="" /> */}

							<div className="flex md:flex-row xxs:flex-col justify-evenly md:justify-between px-3 items-center ">
								<div className="grid grid-cols-1 gap-12 mt-10 xxs:w-full xxs:text-center md:text-left md:w-[41%] xl:w-[34%]">
									{cityPage.slug === "patna" && (
										<div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
											<span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 ">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													stroke-width="1.5"
													stroke="currentColor"
													className="w-5 h-5"
												>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
													/>
													<path
														stroke-linecap="round"
														stroke-linejoin="round"
														d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
													/>
												</svg>
											</span>

											<h2 className="mt-4 text-base font-medium text-gray-800 ">
												Visit us
											</h2>
											<p className="mt-2 text-sm text-gray-500 ">
												Visit our office HQ..
											</p>
											<a
												className="mt-2 text-sm text-blue-500 "
												href="https://goo.gl/maps/zewzqahtaqeFks4Y7"
											>
												1st Floor, Krishna Aangan, 38
												Pillar, Aashiyan More, Bailey
												Rd, near to PARAS HMRI, opp.
												Punjab National Bank, Raja
												Bazar, Patna, Bihar 800014
											</a>
										</div>
									)}

									<div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
										<a href="tel:8235858626">
											<span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 ">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 24 24"
													strokeWidth="1.5"
													stroke="currentColor"
													className="w-5 h-5"
												>
													<path
														strokeLinecap="round"
														strokeLinejoin="round"
														d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
													/>
												</svg>
											</span>
										</a>

										<h2 className="mt-4 text-base font-medium text-gray-800 ">
											Call us
										</h2>
										<p className="mt-2 text-sm text-gray-500 ">
											Mon-Fri from 8am to 5pm.
										</p>
										<a
											className="mt-2 text-sm text-blue-500 "
											href="tel:8235858626"
										>
											033-40585240
										</a>
									</div>

									<div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
										<span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												fill="none"
												viewBox="0 0 24 24"
												strokeWidth="1.5"
												stroke="currentColor"
												className="w-5 h-5"
											>
												<path
													strokeLinecap="round"
													strokeLinejoin="round"
													d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
												/>
											</svg>
										</span>

										<h2 className="mt-4 text-base font-medium text-gray-800 ">
											Chat to sales
										</h2>
										<p className="mt-2 text-sm text-gray-500 ">
											Write to our friendly team.
										</p>
										<a
											className="mt-2 text-sm text-blue-500 "
											href="mailto:support@casamed.in"
										>
											support@casamed.in
										</a>
									</div>
								</div>

								<div className="md:w-[40%] xl:w-[35%] xxs:w-full">
									<form
										id="callback"
										className="xxs:mt-4 sm:mt-8 flex flex-col xxs:gap-1  sm:gap-2 justify-center items-center bg-blue-50 xxs:p-2 sm:p-6  rounded-lg drop-shadow-lg shadow-lg xxs:text-center"
										onSubmit={handleSubmit}
									>
										<h2 className="sm:text-4xl xxs:text-2xl font-medium text-neutral-700 pt-4">
											Ask for a CallBack
										</h2>
										{cityPage.cta.map((cta, index) => (
											<p
												key={index}
												className="font-thin xxs:text-sm sm:text-[15px] py-2"
											>
												{cta.para.replace(
													/<br\/?>/g,
													"\n"
												)}
											</p>
										))}
										<input
											type="text"
											placeholder="Name"
											className="mt-4 px-4 w-full py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
											value={name}
											onChange={handleNameChange}
										/>
										<input
											type="text"
											placeholder="Mobile Number"
											className="mt-4 px-4 py-2 w-full rounded-lg shadow-md focus:outline-none focus:shadow-outline"
											value={phoneNumber}
											onChange={handlePhoneNumberChange}
										/>
										{/* <input
              type="text"
              placeholder="Location"
              className="mt-4 px-4 py-2 w-full rounded-lg shadow-md focus:outline-none focus:shadow-outline"
            /> */}
										<button
											type="submit"
											className="mt-4 px-6 py-2 w-full bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
										>
											Begin Your Recovery
										</button>
									</form>
								</div>
							</div>
						</div>
					</section>
				</div>

				{/* <h2 className='xxs:pt-8 md:pt-16 text-4xl text-center sm:text-5xl font-bold text-whyus leading-tight' >Our <span className='decoration-[#02B9ED] underline underline-offset-8'>P</span>resence</h2>
    <br/>
    <div className='flex xxs:flex-col gap-4 md:flex-row justify-center items-center flex-wrap pt-8'>

    {cityPage.presence.map((pres, idx) =>(
        
          <div key={idx} className="flex flex-col justify-center items-center">
            <Link to = {`/physiotherapy/${pres.city.toLowerCase()}`} target="_blank">
            <Avatar
                imgProps={{ style: { borderRadius: "50%" } }}
                src={pres.photoLink}
                style={{
                width: 110,
                height: 110,
                border: "1px solid lightgray",
                padding: 1,
                marginBottom: 0,
                }}
            />
            </Link>
            </div>
        
        ))}
    </div> */}

				<Footer />
			</div>
		</div>
	);
}

export default City;
