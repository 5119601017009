import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const solutions = [
  { name: "Patna", href: "/patna" },
  { name: "Jaipur", href: "/jaipur" },
  { name: "Bangalore", href: "/bangalore" },
  { name: "Chandigarh", href: "/chandigarh" },
  { name: "Delhi", href: "/delhi" },
  { name: "Goa", href: "/goa" },
  { name: "Guwahati", href: "/guwahati" },
  { name: "Hyderabad", href: "/hyderabad" },
  { name: "Kolkata", href: "/kolkata" },
  { name: "Mumbai", href: "/mumbai" },
  { name: "Pune", href: "/pune" },
  { name: "Vizag", href: "/vizag" },
];
// const callsToAction = [
//   { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
//   { name: 'Contact sales', href: '#', icon: PhoneIcon },
// ]

export default function Example() {
  return (
    <Popover className="relative ">
      <Popover.Button className="inline-flex items-center gap-x-1  focus:outline-none">
        <span>Locations</span>
        <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-1/2 z-10 mt-2 flex w-screen max-w-max -translate-x-1/2 px-4">
          <div className=" flex-auto overflow-hidden rounded-3xl bg-casalight text-sm leading-6 shadow-lg ">
            <div className="p-2" style={{ overflowY: "auto" }}>
              {solutions.map((item) => (
                <div
                  key={item.name}
                  className="group relative flex gap-x-6 rounded-lg p-2 justify-center hover:bg-gray-50 "
                >
                  <div className=" border-b-2">
                    <Link
                      to={item.href}
                      className="font-semibold text-gray-900"
                    >
                      {item.name}
                      <span className="absolute inset-0" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
