
function Button({ children, style }) {
    return (
        <button

            style={{zIndex: 0,}}
            
            className={`flex gap-3 ${style} px-5 py-[9px] items-center capitalize active:-translate-y-1 cursor-pointer font-poppins hover:-translate-y-2 duration-300 text-white font-[600] bg-casablue rounded-[12px] `}>
            {children}
        </button>
    )
}

export default Button