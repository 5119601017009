import { stats } from "../../Constants";

const Herostats = () => (
  <section
    className={`  flex justify-center items-center xxs:flex-col md:flex-row lg:flex-row xl:flex-row flex-wrap   px-6 xxs:gap-6 xs:gap-6 ss:gap-6 sm:gap-6 lg:gap-6 xl:gap-6 2xl:gap-6  text--greyish
    `}
  >
    {stats.map((stat, index) => (
      <div
        key={stat.id}
        className="flex-1 flex flex-row justify-center items-center text-whitish"
      >
        <h4 className="font-poppins text--greyish opacity-80 font-bold xxs:text-[16px] xs:text-[16px] sm:text-[16px] text-[26px] lg:text-[20px]  ">
          {stat.value}
        </h4>
        <div className={`mx-2`}>
          <img
            src={stat.arrow}
            className={`w-[70%] h-[70%] object-contain m-auto`}
            alt=""
          />
        </div>
        <p className="font-poppins text--greyish opacity-80 font-bold xxs:text-[16px] sm:text-[16px] xs:text-[16px] lg:text-[16px]  md:text-[12px]">
          {stat.title}
        </p>
      </div>
    ))}
    {/* <div>
        <video playsInLine autoPlay loop muted className='video-background overflow-auto background-color: rgba(255, 255, 255, 0.2); object-contain '><source src={backvideo} type = 'video/mp4'/>
         </video>
    </div> */}
  </section>
);
export default Herostats;
