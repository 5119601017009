import React from "react";
import { Link } from "react-router-dom";
import { proitems, enteritems } from "../Constants/pricing";

export const PricingThree = () => {
  return (
    <>
      <div className=" py-10 flex flex-col text-center ">
        <div className="w-full space-y-4 mb-10">
          <h1 className=" text-gray-200 text-5xl font-semibold">
            <span className="underline underline-offset-8 decoration-[#02B9ED]">
              C
            </span>
            hoose your B2B plan
          </h1>
          <p className="text-gray-300 font-medium text-base">
            We believe Freedom from Pain for your Patients should not be
            expensive.
          </p>
        </div>
        <div className="flex flex-col md:flex md:flex-row md:gap-8 justify-around   rounded-lg ">
          <div className="relative w-full  rounded-lg bg-indigo-600 flex flex-col justify-between">
            <div className="bg-indigo-800 left-[50%] transform -translate-x-1/2 text-white absolute uppercase font-medium text-sm py-1 rounded-br-md rounded-bl-md px-[10px]">
              popular
            </div>
            <div className="p-8 mt-4 space-y-4 rounded-lg md:mt-0">
              <h6 className="text-xl font-semibold text-white">Basic</h6>
              <h4 className="inline-flex items-center text-3xl font-medium text-white ">
                ₹ 999
                <span className="ml-2 text-base ">/ User / Month</span>
              </h4>
              <p className="text-sm font-thin tracking-widest text-white ">
                BILLED ANNUALLY
              </p>
              <p className="text-sm  font-thin tracking-widest text-white ">
                ₹ 99,999
              </p>
              <p className="text-base font-medium text-white ">
                For larger localized Physiotherapy chains and Digital Clinics.
              </p>
              <ul className="pb-4">
                {proitems.map((item, idx) => (
                  <li
                    key={idx}
                    className="flex justify-start items-center py-1 "
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      className="mr-[10px] text-white"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                    </svg>
                    <span className={`text-gray-200 text-left ${item.style}`}>
                      {item.features}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="pb-8 px-8">
              <Link to="/contactus">
                <button className="text-sm font-medium text-indigo-600 bg-white w-full py-2 px-5 rounded-md">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full col-start-2 border rounded-lg flex flex-col justify-between">
            <div className="p-8 space-y-4 rounded-lg ">
              <h6 className="text-xl font-semibold text-white">Pro</h6>
              <h4 className="text-3xl font-medium text-white">
                ₹ 899<span className="ml-2 text-base">/ User / Month</span>
              </h4>
              <p className="text-sm font-thin tracking-widest text-white ">
                BILLED ANNUALLY
              </p>
              <p className="text-sm  font-thin tracking-widest text-white ">
                ₹ 1,80,000
              </p>
              <p className="text-base font-medium text-gray-300">
                For hospitals and other large scale corporate entities.
              </p>
              <ul className="pb-4">
                {enteritems.map((item, idx) => (
                  <li
                    key={idx}
                    className="flex justify-start items-center py-1 "
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      className="mr-[10px] text-indigo-600"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                    </svg>
                    <span className={`text-gray-200 text-left ${item.style}`}>
                      {item.features}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="pb-8 px-8">
              <Link to="/contactus">
                <button className="text-sm font-medium text-white bg-indigo-600 w-full py-2 px-5 rounded-md">
                  Contact Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PricingThree.displayName = "PricingThree";
