import { useState} from "react";
// import { Image } from "react-bootstrap";
import Loading from "../../Loading/loading";
import "./post.css";
import { SanitizeHTML } from "../../Sanitize HTML/SanitizeHTML";

export default function Post(props) {
  const [loading, setLoading] = useState(true);

  return (
    <div className="post position-relative ">
      {loading && (
        <div
          className="postImg position-absolute border rounded "
          style={{backgroundColor: "#EBF9FF"}}
        
        >
          <Loading />
        </div>
      )}
      <img className="postImg" src={props.img} alt="image" onLoad={() => setLoading(false)} />
      <div className="postInfo">
        <span className="postTitle" onClick={props.handleClick}>
          {props.title}
        </span>
        <hr />
      </div>
      <p className="postDesc">
        <SanitizeHTML html={props.body} />
      </p>
    </div>
  );
}
