import {React, useState, useEffect } from 'react'
import Nav from '../Components/Nav'
import { Helmet } from 'react-helmet'
import bannerImg from "../Assets/Autism.jpg"
import Numbers from "../Components/Numbers"
import Footer from "../Components/Footer"

import {makeStyles}  from '@mui/styles';
import { Alert, AlertTitle } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';

import {IconButton, SnackbarContent} from '@mui/material'

import { CheckCircle, Error, Close } from '@material-ui/icons';



import MuiAlert from '@mui/material/Alert';
import PersonaStyled from "../Components/PersonaStyled"

import { green } from '@mui/material/colors'
import { deepPurple } from '@material-ui/core/colors'

import axios from "axios"


import { NavHashLink as Link1 } from 'react-router-hash-link';
import { motion } from 'framer-motion'


import { Flip } from 'react-awesome-reveal'
import { Link, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



// import FAQItem from './TestFAQ'
// import testFAQ2 from './testFAQ2'
import Contact from './ContactStyled'
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";


import { Avatar } from "@mui/material";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/effect-cards";


// import required modules
// import { EffectCards, Autoplay, Keyboard } from "swiper";
// import TestimonialRevamp2 from '../Components/TestimonialRevamp2'

const TestimonialRevamp3 = () => {
    const testimonials = [

        {
            avatar: (
                <Avatar sx={{ bgcolor: green[500] }}>H</Avatar>
              ),
            name: "Harsh",
            // title: "1",
            quote: "CasaMed's autism treatment has been life-changing for my child. Their personalized approach and innovative strategies have made a significant difference in managing autism. I am grateful for CasaMed's expertise and dedication."
        },
        {
            avatar: (
                <Avatar sx={{ bgcolor: deepPurple[500] }}>A</Avatar>
              ),
            name: "Amaya",
            // title: "2",
            quote: "We found immense support and effective treatment for autism at CasaMed. Their team of professionals provided personalized care, empowering our child with valuable skills and tools. CasaMed's autism treatment has been instrumental in our child's development and progress."
        },
    ]
  
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentTestimonial((prevIndex) => (prevIndex + 1) % testimonials.length);
      }, 5000); // Change the interval duration (in milliseconds) as needed
  
      return () => clearInterval(interval);
    }, [testimonials.length]);
  
    return (
      <section className="pt-16 pb-20">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-casashade2 xxs:pb-10 mb-4 md:pb-14 font-bold xxs:text-4xl  md:text-5xl ">
              What people are saying
            </h3>
            <div className={`testimonial-container   ${currentTestimonial===1 ? `md:h-[340px] xxs:h-[400px]` : `md:h-[320px] xxs:h-[320px]`}`}>
              {testimonials.map((item, idx) => (
                <div
                  key={idx}
                  className={`testimonial-slide  ${
                    currentTestimonial === idx ? "active" : ""
                  }`}
                >
                  <figure className={`${currentTestimonial === 0 ? `mt-16` : `mt-20`}`}>
                <blockquote>
                  <p className="text-casashade2 text-xl font-regular sm:text-2xl">
                    “{item.quote}“
                  </p>
                </blockquote>
                <div className="mt-6 flex flex-col justify-center items-center">
                    <div>
                        {item.avatar}
                    </div>
                  <div className="mt-3">
                    <span className="block text-gray-800 font-semibold">
                      {item.name}
                    </span>
                    <span className="block text-gray-600 text-sm mt-0.5">
                      {/* {item.title} */}
                    </span>
                  </div>
                </div>
              </figure>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-6">
            <ul className="flex gap-x-3 justify-center">
              {testimonials.map((item, idx) => (
                <li key={idx}>
                  <button
                    className={`w-2.5 h-2.5 rounded-full duration-150 ring-offset-2 ring-indigo-600 focus:ring ${
                      currentTestimonial === idx
                        ? "bg-indigo-600"
                        : "bg-gray-300"
                    }`}
                    onClick={() => setCurrentTestimonial(idx)}
                  ></button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  };



const primarysty = {
  color: "#024461",
  fontWeight: "700",
  fontSize: "17px",
  fontFamily: "Poppins",    

}
const secondarysty = {
  color: "#024461",
  fontWeight: "400",
  fontSize: "15px",
  fontFamily: "Poppins",
  whiteSpace: "pre-line",

}



const imageVariant = {
  start: {
      rotate: "-180deg"
  },
  end: {
      rotate: 0,
      transition: {
          duration: 1
      }
  }
}

const primarysty1 = {
  color: "#024461",
  fontWeight: "700",
  fontSize: "1.5rem",
  fontStyle: "Poppins",

}
const secondarysty1 = {
  color: "#016D8D",
  fontWeight: "400",
  fontSize: "1.125rem",
  fontStyle: "Poppins",
  paddingRight: "0.5rem",
  

}

console.log(` 

██████╗ █████╗ ███████╗ █████╗ ███╗   ███╗███████╗██████╗
██╔════╝██╔══██╗██╔════╝██╔══██╗████╗ ████║██╔════╝██╔══██╗
██║     ███████║███████╗███████║██╔████╔██║█████╗  ██║  ██║
██║     ██╔══██║╚════██║██╔══██║██║╚██╔╝██║██╔══╝  ██║  ██║
╚██████╗██║  ██║███████║██║  ██║██║ ╚═╝ ██║███████╗██████╔╝
 ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝╚═════╝
 `)


function Autism() {
    
    
    const [locationPageRheumat, setLocationPageRheumat] = useState(null);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const { slug } = useParams();

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [nameError, setNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);


    const validateName = () => {
        if (name.trim() === '') {
          setNameError('This field is required');
          return false;
        }
        return true;
      };
    
    const validatePhoneNumber = () => {
    const phoneRegex = /^[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumberError('Please enter a valid phone number');
        return false;
    }
    return true;
    };

    

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowSnackbar(false);
      };


    useEffect(() => {
        axios.get(`/api/locAutismPages/${slug}`)
          .then(response => {
            setLocationPageRheumat(response.data);
            console.log(response.data)
        })
        .catch(error => {
            console.error(error);
        });
    }, [slug]);
    
    if (!locationPageRheumat) {
        return <div>Loading...</div>;
    }
    // console.log(locationPageRheumat)
    
      const City = locationPageRheumat.slug.charAt(0).toUpperCase() + locationPageRheumat.slug.slice(1)


      const physioInLoc = locationPageRheumat.ptInLoc
      console.log(physioInLoc)

      const ptLOC1 = physioInLoc.p1.replace(/\./g, '.<br/><br/>')
      const ptLOC2 = physioInLoc.p2.replace(/\./g, '.<br/><br/>')
      const ptLOC3 = physioInLoc.p3.replace(/\./g, '.<br/><br/>')
      


    // const ptInLocLinkP1 = physioInLoc.p1.toLowerCase().includes('physiotherapy') ? ptLOC1.replace(/physiotherapy/ig, `<a style="color: blue; text-decoration: underline;" target="_blank" href="https://casamed.in/physiotherapy">physiotherapy</a>`) : ptLOC1 
    // const ptInLocLinkP2 = physioInLoc.p2.toLowerCase().includes('physiotherapy') ? ptLOC2.replace(/physiotherapy/ig, `<a style="color: blue; text-decoration: underline;" target="_blank" href="https://casamed.in/physiotherapy">physiotherapy</a>`) : ptLOC2 
    const ptInLocLinkP3 = physioInLoc.p3.toLowerCase().includes('rheumatologist') ? ptLOC3.replace(/rheumatologist/i, `<a style="color: blue; text-decoration: underline;" target="_blank" href="https://casamed.in/arthritis/rheumatoid">rheumatologist</a>`) : ptLOC3

    // const physioLoc1 = ptInLocLinkP1.replace(/<br\/?>/g, '\n');
    // const physioLoc2 = ptInLocLinkP2.replace(/<br\/?>/g, '\n');
    const physioLoc3 = ptInLocLinkP3.replace(/<br\/?>/g, '\n');

      const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateName() || !validatePhoneNumber()) {
          return;
        }

        axios.post('/api/locationFormAutism', { 
            name: name, 
            phoneNumber: phoneNumber,
            location: City
         },{
            headers: {
              Authorization: localStorage.getItem('token'),
              auth: {
                user: JSON.stringify(localStorage.getItem('user')),
              },
            },
          })
          .then(response => {
            // console.log(response.data);
            // alert('Form submitted successfully');

            setShowSnackbar(true);
            setSnackbarMessage('Form submitted successfully!');
            setSnackbarSeverity('success');

            setName('');
            setPhoneNumber('');
          })
          .catch(error => {
            console.error(error);
            // alert('Form submission failed');

            setShowSnackbar(true);
            setSnackbarMessage('Failed to submit form. Please try again later.');
            setSnackbarSeverity('error');

          });
      }

      const handleNameChange = (event) => {
        setName(event.target.value);
        setNameError(false);
      };

      const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
        setPhoneNumberError(false);
      };


      console.log(locationPageRheumat.presence)

     
      
  return (
    <div>
        <div className='pb-15 w-[100vw] '>    
            <Nav />
        </div>
        <Helmet>
        {/* Meta title and Description */}
        <title>
        {locationPageRheumat.metaTitle}
        </title>
        <meta
          name="description"
          content={locationPageRheumat.metaDesc}
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="muscle pain therapy, spinal treatment"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href= {`https://casamed.in/${slug}/autism`} />
        {/* Open Graph */}
        <meta
          property="og:title"
          content={locationPageRheumat.metaTitle}
        />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content={`https://casamed.in/${slug}/autism`} />
        <meta
          property="og:description"
          content={locationPageRheumat.metaDesc}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content= {bannerImg}
        />

        <meta name="robots" content="INDEX,FOLLOW" />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content={`https://casamed.in/${slug}/autism`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Best Autism Specialists in ${City} | Effective Autism Treatment`} />
        <meta property="og:description" content={`Get the top Autism specialists in ${City} for effective Autism Treatment. Experience effective and personalised care for all your Autism-related issues.`} />
        <meta property="og:image" content={bannerImg} />


        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://casamed.in/${slug}/autism`} />
        <meta name="twitter:title" content={`Best Autism Specialists in ${City} | Effective Autism Treatment`} />
        <meta name="twitter:description" content={`Get the top Autism specialists in ${City} for effective Autism Treatment. Experience effective and personalised care for all your Autism-related issues.`} />
        <meta name="twitter:image" content={bannerImg} />

        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: locationPageRheumat.metaTitle,
            url: `https://casamed.in/${slug}/autism`,
            logo: {
              "@type": "ImageObject",
              url: bannerImg,
            },
            description: locationPageRheumat.metaDesc,
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": locationPageRheumat.faqs.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            }))
          })}
        </script>
      </Helmet>

      <div className="relative">
      <img
        className="w-full xxs:h-[1150px] sm:h-[950px] xxs:object-cover lg:h-[88vh]  sm:object-cover"
        src={bannerImg}
        alt="Hero section background"
      />
      <div className="absolute  top-28 xxs:mt-24 sm:xxs:mt-0 lg:top-10 left-0 w-full h-screen flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-4xl sm:text-5xl font-bold text-white pt-4 xxs:px-3 md:px-[15%]">
          Exceptional Autism Treatment in{` ${City}`} by Skilled and Experienced Autism Specialists 
            <br />
          </h1>
          <p className="mt-4 text-lg blur-[0.5px] uppercase sm:text-xl xxs:px-2 text-white">
          Tranforming lives 
          </p>
          <div className='flex md:flex-row xxs:flex-col justify-center items-center '>
          <div className='px-[5%] xxs:w-full md:w-1/2 xxs:text-center md:text-left flex justify-end'>
          
          <p className="mt-4 xxs:ml-0 md:ml-[10%]  text-lg sm:text-xl font-medium  text-white">
          Discover a transformative approach to autism treatment in {`${City}`}. CasaMed welcomes you to a world of empowerment, where we ignite the potential within individuals with Autism.
          <br/>
          <br/>
          With innovative techniques and a team of seasoned specialists, we offer personalised interventions that redefine possibilities. Step into a realm of limitless growth and embark on a transformative journey with us.  
          </p>
          </div>
          <div className='w-4/7 p-4'>
          <form id="callback" style={{opacity: "80%"}} className=" xxs:mt-4 sm:mt-2 flex flex-col xxs:gap-1  sm:gap-2 justify-center items-center  bg-white xxs:py-2 sm:py-4 px-4  rounded-lg drop-shadow-lg shadow-lg" onSubmit={handleSubmit}>
            <h2 className='sm:text-4xl xxs:text-2xl font-medium text-neutral-700 px-[2%] pt-1'>Ask for a CallBack</h2>
            <p className='font-thin xxs:text-sm sm:text-base px-[2%]'>Fill out the callback form to schedule your appointment today.</p>
            <div className='w-full'>
            <input
              type="text"
              placeholder="Name"
              className={`appearance-none mt-4 w-full rounded-lg shadow-md border  py-2  leading-tight focus:outline-none focus:shadow-outline ${
                nameError ? 'border-red-500' : ''
              }`}

              value={name}
              onChange={handleNameChange}
              

            />
            {nameError && <p className="text-red-500 text-xs italic">This field is required</p>}
            </div>
            <div className='w-full'>
            <input
              type="text"
              placeholder="Mobile Number"
              className={`appearance-none mt-4 w-full rounded-lg shadow-md border  leading-tight focus:outline-none focus:shadow-outline ${
                phoneNumberError ? 'border-red-500' : ''
              }`}
              value={phoneNumber} 
              onChange={handlePhoneNumberChange}
            />
            {phoneNumberError && (
          <p className="text-red-500 text-xs italic mt-2">This field is required/incorrect</p>
        )}
            </div>
            
            

           

            <button
              type="submit"
              className="mt-4 px-6 py-2 w-full bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
            >
              Begin Your Recovery
            </button>
          </form>
          </div>
          </div>
        </div>
      </div>
    </div>
            <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{ backgroundColor: snackbarSeverity === 'success' ? '#43a047' : '#d32f2f' }}
          message={
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {snackbarSeverity === 'success' ? <CheckCircle style={{ marginRight: '8px' }} /> : <Error style={{ marginRight: '8px' }} />}
              {snackbarMessage}
            </span>
          }
          action={[
            <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
              <Close />
            </IconButton>,
          ]}
        />
            </Snackbar>

    <div className='pt-2'>
        <Numbers />
    </div>

    <div className='py-16  text-center bg-casalight'>
        <h2 className='text-4xl sm:text-5xl font-bold text-whyus leading-tight' ><span className='decoration-[#02B9ED] underline underline-offset-8'>U</span>nderstanding Autism</h2>
        
        <div className='flex flex-row-reverse  justify-center items-center'>
        <div className='xxs:px-[8%] sm:px-[25%]'>
        <p className='pt-8 pb-4 text-lg text-left'>
        <div dangerouslySetInnerHTML={{ __html: locationPageRheumat.aboutUs.p1.replace(/\./g, '.<br/><br/>')}}/>
        </p>

        <p className='pb-4 text-lg text-left'>
        <div dangerouslySetInnerHTML={{ __html: locationPageRheumat.aboutUs.p2.replace(/\./g, '.<br/><br/>')}} /> 
        </p>

        <p className='pb-4 text-lg text-left'>
        <div dangerouslySetInnerHTML={{ __html: locationPageRheumat.aboutUs.p3.replace(/\./g, '.<br/><br/>')}} />
        
        </p>
        </div>
        
        </div>
    </div>

    <div className="backPtInLoc bg-center xxs:px-8 md:px-32 py-16">
     
      
        <div className="text-left xxs:pl-0  md:pt-0 md:mr-[30%]">
          <h2 className="text-4xl sm:text-5xl font-bold text-whyus leading-tight ">
          <span className='decoration-[#02B9ED] underline underline-offset-8'>A</span>utism Treatment in {`${City}`}
            <br />
            <br />
            
            
          </h2>
          
          <div className='flex md:flex-row xxs:flex-col justify-center items-center xxs:pr-0 md:pr-[30%]'>
          <div className='text-left'>
          <p className="mt-4 text-lg sm:text-xl text-whyus">
          <div dangerouslySetInnerHTML={{ __html: locationPageRheumat.ptInLoc.p1.replace(/\./g, '.<br/><br/>')}} /> 
          </p>
          <p className="mt-4 text-lg sm:text-xl text-whyus">
          <div dangerouslySetInnerHTML={{ __html: locationPageRheumat.ptInLoc.p2.replace(/\./g, '.<br/><br/>')}} />

          </p>
          <p className="mt-4 text-lg sm:text-xl text-whyus">
          <div dangerouslySetInnerHTML={{ __html: locationPageRheumat.ptInLoc.p3.replace(/\./g, '.<br/><br/>')}} />
          <span className='font-semibold'> Experience our transformative approach to autism treatment and unlock the possibilities in {`${City}`}.</span>
          </p>
          </div>
          </div>
        </div>
      
    </div>
    
          {/* <div className=''>
          <section className='body-back2 py-16 '>
        <header className='flex-col  text-center flex-wrap justify-center items-center  font-poppins text-casashade2 xxs:pb-2 px-6  sm:pb-16'>
            <div className=' flex justify-center items-center'>
            <h2 style={{lineHeight: 1.4}} className='pb-12 font-semibold xxs:text-3xl md:text-5xl md:w-[70%] w-full '>
            <span className='decoration-[#02B9ED] underline underline-offset-8'>C</span>onditions We Treat            
            </h2>
            </div>

            <h4 className='text-[20px]'>

            We treat Spinal Issues, including the following but not limited to:
            </h4>
        </header>

        <section id='persona' className=" flex flex-wrap justify-center items-center font-poppins text-whyus">
            <ul className='grid grid-cols-1 justify-center items-center gap-x-4 gap-y-4 xxs:text-lg sm:text-xl text-center font-medium'>
              <li className=''>
              Spinal <span className='decoration-[#02B9ED] underline underline-offset-8'>A</span>rthritis
              </li>
              <li>
              Spinal <span className='decoration-[#02B9ED] underline underline-offset-8'>S</span>tenosis
              </li>
              <li>
              Spinal <span className='decoration-[#02B9ED] underline underline-offset-8'>F</span>ractures
              </li>
            </ul>
        </section>



    </section>
          </div> */}

          <section className="bg-white xxs:px-[2%] md:pl-[10%] md:pr-[4%] xl:px-[16%] xxs:py-16 md:py-16 grid xxs:grid-cols-1  md:grid-cols-2 md:justify-between  w-full">
        
        <div className="flex">
            <h2 style={{lineHeight: "inherit"}} className={`xxs:text-4xl md:text-[50px]   text-whyus xxs:text-center md:text-left  font-semibold font-poppins xxs:pb-6  `}>
                <span className='decoration-[#02B9ED] underline underline-offset-8'>H</span>ow Autism Treatment at CasaMed Works:<br/><span className='decoration-blue-300 underline underline-offset-8'>Step</span> by <span className=' underline underline-offset-8 decoration-blue-300'>Step</span> <span className='decoration-blue-300 underline underline-offset-8'>Guide</span>
            </h2>
        </div>
        

     
    <Box className=' ' sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
    <nav aria-label="main mailbox folders">
        <List className='xxs:grid grid-cols-1 gap-4 justify-center items-center'>
        {locationPageRheumat.hows.map((how, index, id) =>(
        <Flip direction="horizontal" cascade damping={0.5}>
          <Link
            to= {`/${slug}/autism/#callback`}>
        <ListItem className="xxs:pb-2 md:pb-8" key={index} disablePadding >
            <ListItemButton className='flex xxs:flex-col sm:flex-row '>
            <ListItemIcon className='xxs:w-[15%] md:w-[10%]' >
                <img src={how.icon} className="" index={id} alt=""/>
            </ListItemIcon>
            <ListItemText  className='flex flex-col gap-1 md:px-6 xxs:text-center sm:text-left' 
                primaryTypographyProps={{ style: primarysty }} 
                secondaryTypographyProps={{ style: secondarysty }} 
                primary={how.head}
                secondary={how.content.replace(/<br\/>/g, "\n")}  
                sx={{fontFamily: 'Poppins'}}
                
            />
            </ListItemButton>
        </ListItem>
        </Link>
        </Flip>
        ))}
        
        
        
        </List>
    </nav>



    </Box>
    
    </section>


          <div className='og--bg--grad--inv w-full' >
        
        <motion.div
            variants={imageVariant}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
            className='md:py-32 xxs:py-28 xxs:px-6 md:px-22 '>
                <h2 className='text-center -mt-14 xxs:pb-10 md:pb-10 text-casashade2 md:text-5xl xxs:text-4xl font-bold md:px-20 xxs:px-6'>
                    <span className='decoration-[#02B9ED] underline underline-offset-8'>W</span>hy Choose Us?
                </h2>
                <p className=" text-lg pb-4 blur-[0.5px] uppercase sm:text-xl xxs:px-2 text-casashade2 text-center">
                Here are the reasons why you should opt for CasaMed
                </p>

                <Box className='py-8 sm:px-[25%]' sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <nav aria-label="main mailbox folders">
                    
                    
                
                    <List className='grid grid-cols-1 gap-4 justify-center items-center '>
                    
                    {locationPageRheumat.whys.map((why, index) =>(  
                    <ListItem key={index}  >
                        <Link1 
                    to= {why.link}
                    >
                        <ListItemButton className='flex xxs:flex-col sm:flex-row sm:gap-8  justify-center items-center'>
                        <ListItemIcon className='xxs:w-[20%] sm:w-auto'>
                            <img src={why.img} className="xxs:w-full sm:w-[100px] " alt=""/>
                        </ListItemIcon>
                        <ListItemText className='flex flex-col gap-2 xxs:text-center sm:text-left' 
                            primaryTypographyProps={{ style: primarysty1 }} 
                            secondaryTypographyProps={{ style: secondarysty1 }} 
                            primary={why.head}
                            secondary={why.text} 
                        />
                        </ListItemButton>
                        </Link1>
                    </ListItem>
                    ))}                    
                    </List>
                    
                </nav>
                
                
                
                </Box>

            

            <div className='text-casashade2 flex flex-col gap-24 xxs:pt-2 sm:pt-16'>
            {locationPageRheumat.whys.map((why, index) =>(  
                <div key={index} id={why.id} className={`flex ${
                    index % 2 === 0 ? 'xxs:flex-col md:flex-row-reverse' : 'xxs:flex-col md:flex-row'
                  } justify-center gap-[12%]`}>
                    <div className='flex justify-center items-baseline'>
                        <img src={why.imgJPG} className="w-[400px] h-[600px] rounded-lg object-cover"/>
                    </div>
                    <div className='xxs:w-full xxs:pt-[10%] xxs:text-center sm:text-left md:pt-0 md:w-1/3 flex flex-col md:items-start md:justify-center xxs:items-center xxs:justify-center'>
                            <h3 className='font-semibold xxs:text-xl md:text-2xl capitalize fheading mb-6'>
                            <span className='underline underline-offset-4 decoration-[#02B9ED] mr-0.5'>{why.headActual[0]}</span><span>{why.headActual.slice(1)}</span>
                            </h3>
                            <p className='xxs:text-base md:text-lg'>
                                {why.detailedDesc}
                            </p>
                        
                    </div>
                </div>        
            ))}      
            </div>
            
        </motion.div>
    </div>

          <div className={`${slug === 'guwahati' ? 'backlocation' : slug === 'kolkata' ? 'backlocationkol' : slug === 'delhi' ? 'backlocationdel' : slug === 'bangalore' ? 'backlocationbeng' : slug === 'vizag' ? 'backlocationviz bg-center' : slug === 'chandigarh' ? 'backlocationcha bg-center' : slug === 'pune' ? 'backlocationpune bg-center' : slug === 'mumbai' ? 'backlocationmum bg-center' : slug === 'goa' ? 'backlocationgoa bg-center' : slug === 'patna' ? 'backlocationpatna bg-center' : slug === 'jaipur' ? 'backlocationjaipur bg-top' : slug === 'hyderabad' ? 'backlocationhyd' : '' } py-16`}>
            <h2 className="text-4xl sm:text-5xl font-bold text-white  tracking-wide text-center pt-16">
          <span className='decoration-[#02B9ED] underline underline-offset-8'>A</span>reas we Service
          </h2>
          <div  className='flex xxs:flex-col md:flex-row xxs:px-[8%] md:px-[10%] py-16 justify-center items-center'> 
            <div className='flex justify-center items-center'>
          <div className={`grid xxs:grid-cols-1 ${slug === 'guwahati' || slug === 'bangalore' || slug === 'pune' || slug === 'patna' || slug === 'jaipur' ? 'md:grid-cols-3' : slug === 'kolkata' || slug === 'mumbai' || slug === 'goa' ? 'md:grid-cols-2' : slug === 'vizag' || slug === 'chandigarh' || slug === 'hyderabad' ? 'md:grid-cols-1' : '' }  xxs:gap-4 md:gap-x-16 md:gap-y-8 justify-start items-center `}>
            {locationPageRheumat.serviceAreas.map((serviceArea, index) =>( 
                <div key={index}  className='flex items-center gap-4'>
                <img src={serviceArea.locIcon} />
                
                <div className={`text-xl text-white xxs:font-semibold md:font-medium`}>
                    {serviceArea.area}  
                </div> 
                </div>
            ))}

          </div>
            </div>
            {/* <div id="serviceAreaImage" className='md:w-1/2 xxs:hidden md:visible flex justify-center items-end'>
                <img src={locationPageRheumat.serviceAreaImg} className="rounded-lg xxs:h-0 md:h-[900px]"/>
            </div> */}

          </div>
          </div>

        <div className='xxs:pt-20 md:pt-32 '>

        <>
    <div className='tracking-wide flex justify-center items-center'>
        {/* <h2 style={{lineHeight:1.4}} className='xxs:text-3xl md:text-6xl  max-w-4xl mx-auto  font-semibold text-center xxs:pb-8 md:pb-8 text-casashade2 drop-shadow-lg'>
            <span className="decoration-[#02B9ED] underline underline-offset-8">U</span>ser Testimonials
        </h2> */}
    </div>
    <div className='w-full flex justify-center items-center'>
      
      <TestimonialRevamp3 />
      {/* <div dangerouslySetInnerHTML={{__html: test}} /> */}

        

      </div>
    </>
        </div>
    

          <div className=' pb-16'>
          <div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
	<div className="flex flex-col items-center">
		<h2 className="font-bold text-whyus text-5xl mt-5 tracking-tight">
			FAQ
		</h2>
		<p className="text-whyus text-opacity-70 text-xl mt-3">
			Frequenty asked questions
		</p>
	</div>
	<div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
                {locationPageRheumat.faqs.map((faq,index) =>(
		<div className="xxs:py-5 md:py-10 " key={index}>
			<details className="group">
				<summary className="flex justify-between items-center font-medium cursor-pointer list-none">
					<span className='text-xl text-whyus'> {faq.question}</span>
					<span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
</svg>
              </span>
				</summary>
				<p className="text-whyus text-opacity-70 text-lg mt-3 group-open:animate-fadeIn">
                {faq.answer}
				</p>
                
			</details>
		</div>

))}
	</div>
</div>
          </div>

          <div className='og--bg--grad--inv'>
          <div className=''>
        <section className="xxs:px-6 md:px-16 pb-16">
        <div className="text-center pb-16">
            <h2 className="font-bold text-blue-400 text-4xl">Contact us</h2>

            <h3 className="mt-2 text-2xl font-semibold  md:text-3xl text-whyus">We’d love to hear from you</h3>

            <p className="mt-3 text-gray-500 ">Write to our support team.</p>
        </div>
    <div className="container px-6 py-8 mx-auto bg-[url('https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100')] bg-no-repeat bg-cover  " >
        {/* <div className="text-center ">
            <p className="font-bold text-blue-400 text-3xl">Contact us</p>

            <h1 className="mt-2 text-2xl font-semibold  md:text-3xl text-whyus">We’d love to hear from you</h1>

            <p className="mt-3 text-gray-500 ">Chat to our friendly team.</p>
        </div> */}

        {/* <img className="object-cover w-full h-64 mt-10 rounded-lg lg:h-96" src="https://images.unsplash.com/photo-1568992688065-536aad8a12f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=100" alt="" /> */}
        
        <div className='flex md:flex-row xxs:flex-col justify-evenly items-center '>

        <div className="grid grid-cols-1 gap-12 mt-10 xxs:w-full xxs:text-center md:text-left md:w-[21%] ">
            
            {/* <div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
                <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                    </svg>
                </span>
                
                <h2 className="mt-4 text-base font-medium text-gray-800 ">Visit us</h2>
                <p className="mt-2 text-sm text-gray-500 ">Visit our office HQ..</p>
                <a className="mt-2 text-sm text-blue-500 " href={locationPageRheumat.contact.address.toString()}>Ground Floor, Block 1, New Road Saristabad (West), PO – Anishabad, Patna. India</a>
            </div> */}

            <div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
                <a href={locationPageRheumat.contact.number.toString()}>
                <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                    </svg>
                </span>
                </a>
                
                <h2 className="mt-4 text-base font-medium text-gray-800 ">Call us</h2>
                <p className="mt-2 text-sm text-gray-500 ">Mon-Fri from 8am to 5pm.</p>
                <a className="mt-2 text-sm text-blue-500 " href={locationPageRheumat.contact.number.toString()}>033-40585240</a>
            </div>

            <div className="p-4 rounded-lg bg-blue-50 md:p-6 ">
                <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                </span>

                <h2 className="mt-4 text-base font-medium text-gray-800 ">Chat to sales</h2>
                <p className="mt-2 text-sm text-gray-500 ">Write to our friendly team.</p>
                <a className="mt-2 text-sm text-blue-500 " href="mailto:support@casamed.in">support@casamed.in</a>
            </div>

        </div>

        <div className='md:w-1/3 xxs:w-full'>
          <form id="callback" style={{opacity: "95%"}} className="xxs:mt-4 sm:mt-8 flex flex-col xxs:gap-1  sm:gap-2 justify-center items-center bg-blue-50 xxs:p-2 sm:p-6  rounded-lg drop-shadow-lg shadow-lg xxs:text-center" 
          onSubmit={handleSubmit}>
            <h2 className='sm:text-4xl xxs:text-2xl font-medium text-neutral-700 pt-4'>Ask for a CallBack</h2>
            <p className='font-thin xxs:text-sm sm:text-base px-[10%] text-center py-3 '>Unlock the potential within. Book an appointment today and start your journey toward progress and empowerment.
            
            </p>
            <p className='font-thin xxs:text-sm sm:text-base '>
            Let us help you or your loved one thrive every step of the way.
            </p>
            <input
              type="text"
              placeholder="Name"
              className="mt-4 px-4 w-full py-2 rounded-lg shadow-md focus:outline-none focus:shadow-outline"
              value={name}
              onChange={handleNameChange}
            />
            <input
              type="text"
              placeholder="Mobile Number"
              className="mt-4 px-4 py-2 w-full rounded-lg shadow-md focus:outline-none focus:shadow-outline"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
            />
            {/* <input
              type="text"
              placeholder="Location"
              className="mt-4 px-4 py-2 w-full rounded-lg shadow-md focus:outline-none focus:shadow-outline"
            /> */}
            <button
              type="submit"
              className="mt-4 px-6 py-2 w-full bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
            >
              Begin Your Recovery
            </button>
          </form>
          </div>
        </div>
    </div>
</section>
    </div>

    <h2 className='xxs:pt-8 md:pt-16 text-4xl text-center sm:text-5xl font-bold text-whyus leading-tight' >Our <span className='decoration-[#02B9ED] underline underline-offset-8'>P</span>resence</h2>
    <br/>
    <div className='flex xxs:flex-col gap-4 md:flex-row justify-center items-center flex-wrap pt-8'>

    {locationPageRheumat.presence.map((pres, idx) =>(
        
          <div key={idx} className="flex flex-col justify-center items-center">
            <Link to = {`/${pres.city.toLowerCase()}/autism`} target="_blank">
            <Avatar
                imgProps={{ style: { borderRadius: "50%" } }}
                src={pres.photoLink}
                style={{
                width: 110,
                height: 110,
                border: "1px solid lightgray",
                padding: 1,
                marginBottom: 0,
                }}
            />
            </Link>
            </div>
        
        ))}
    </div>
          
            <Footer />
          </div>
            

        

            
    </div>
  )
}

export default Autism