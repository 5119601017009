import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const JPTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Joint Pain Therapy
      </h1>
      <div className="mx-5">
        <p>
          Do you face the problem of joint pain and feel discomfort all day? Is
          joint pain and stiffness reducing your ability to perform your daily
          task? Have you restricted yourself from doing the work you enjoy
          because of Joint pain?
        </p>
        <p>
          We know how difficult it is to deal with because we have been treating
          thousands of patients with similar problems. Contact Casamed today and
          schedule your care with{" "}
          <Link to="/best-orthopedic-surgeon">best orthopaedic surgeons</Link>,
          leading medical experts and top physical therapists who will take care
          of your ailment through joint pain relief{" "}
          <Link to="/pain-relief-treatment">treatment</Link>.
        </p>
        <p>
          Our <strong>joint pain therapy</strong> panelists are experts that are
          trained in handling and treating musculoskeletal problems like sports
          injuries, osteoarthritis, bursitis, rheumatoid, and other issues
          related to chronic and acute joint pain. Our skilled physiotherapists
          can find out the underlying problems, causes, and factors that
          contribute to joint pain such as poor pattern of movement, tight and
          weak muscles, and will help you to attain pain relief and relaxation.
        </p>
        <p>
          You may even avail, online joint pain therapy if you live outside the
          city or have time issues. Through telemedicine sessions and follow up
          calls, you will be guided towards getting relief from your joint pain
          problems.
        </p>
        <p>
          Joint pain therapy involves exercise, education, hands-on therapy, and
          many other modalities. As per your needs, our therapist designs a
          unique treatment plan and helps you look forward to a life free of any
          joint pain. We assist in improving your range of motion and reducing
          the pain. Our therapist focuses on strengthening the muscles around
          the affected area thereby enabling you to function effectively. We
          provide drug-free, non-invasive, and natural joint pain relief
           treatment.
        </p>
        <p>
          Techniques used in physical therapy treatment include Joint
          manipulation, ultrasound, exercise, cold or heat therapy, electric
          nerve stimulation. For better output, our therapist will also guide
          you and recommend your lifestyle and diet that will improve your
          condition quickly. The length of your treatment depends upon your
          condition. For sports-related inquires it take five to six weeks on
          the other hand for arthritic and other chronic issues our therapist
          will recommend you long treatment for its better long-term effect.
          Hence for overcoming your joint pain contact us and book your
          appointment and find out how our team of therapists will assist you
          with your issues.
        </p>
        <h2>Why Choose Casamed for Joint Pain Therapy</h2>
        <ul>
          <li>
            <strong>Helps in effective movement</strong>
          </li>
        </ul>
        <p>
          Our therapist solves your mobility limitation problem and helps you by
          providing techniques for moving effectively and safely. They will
          guide you in the ways through which you move your body while
          performing several activities for minimizing pain.
        </p>
        <ul>
          <li>
            <strong>Assist you with manual therapy treatment</strong>
          </li>
        </ul>
        <p>
          Apart from active participation, our therapist provides passive
          techniques such as Joint mobilization, massage therapy helps in
          increasing joint space, offer better motion range reduced pain and
          swelling.
        </p>
        <ul>
          <li>
            <strong>Educate you about joint pain</strong>
          </li>
        </ul>
        <p>
          Casamed therapists educate you about the causes of pain and the
          posture and activities that may increase your joint pain problems.
        </p>
        <p>
          For more details about our services, call us on 1800 572 4868 or track
          our services through our website and get detailed information.
        </p>
        <p>Get better and convenient treatment through our home service.</p>
      </div>
    </div>
  );
};

const JointPainTherapy = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>Get Joint Pain Therapy from experienced Doctors @CASAMED</title>
        <meta
          name="description"
          content="Relieve joint pain with expert therapy. Trust our experienced doctors for effective treatment. Take the first step towards relief today!"
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="joint pain therapy" />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/joint-pain-therapy" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Get Joint Pain Therapy from experienced Doctors @CASAMED"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/joint-pain-therapy"
        />
        <meta
          property="og:description"
          content="Relieve joint pain with expert therapy. Trust our experienced doctors for effective treatment. Take the first step towards relief today!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Get Joint Pain Therapy from experienced Doctors @CASAMED",
            url: " https://casamed.in/joint-pain-therapy",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Relieve joint pain with expert therapy. Trust our experienced doctors for effective treatment. Take the first step towards relief today!",
          })}
        </script>
      </Helmet>
      <JPTComponent />
      <Footer />
    </div>
  );
};

export default JointPainTherapy;
