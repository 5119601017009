import React, { useState } from "react";

const AccordionItem = ({ question, answer }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div className="border-b border-gray-200 py-4">
			<button
				className="flex justify-between items-center w-full text-left text-xl font-semibold"
				onClick={() => setIsOpen(!isOpen)}
			>
				<span
					className="bg-gradient-to-r from-[#71e4ff] to-[#ddf6ff] bg-[length:0px_10px] bg-left-bottom
      bg-no-repeat
      transition-[background-size]
      duration-500
      hover:bg-[length:95%_10px]
      group-hover:bg-[length:100%_10px]
      dark:from-purple-300 dark:to-purple-500"
				>
					{question}
				</span>
				<span>{isOpen ? "−" : "+"}</span>
			</button>
			{isOpen && <div className="mt-2 text-lg">{answer}</div>}
		</div>
	);
};

const FAQSection = ({ faqs }) => {
	return (
		<section className="pb-[10%] pt-12 relative">
			<h2 className="text-2xl font-semibold mb-4">
				<span
					className="bg-gradient-to-r from-[#71e4ff] to-[#ddf6ff] bg-[length:0px_10px] bg-left-bottom
      bg-no-repeat
      transition-[background-size]
      duration-500
      hover:bg-[length:95%_10px]
      group-hover:bg-[length:100%_10px]
      dark:from-purple-200 dark:to-purple-500"
				>
					Frequently Asked Questions (FAQs)
				</span>
			</h2>
			<div className="w-full">
				{faqs.map((faq, index) => (
					<AccordionItem
						key={index}
						question={faq.question}
						answer={faq.answer}
					/>
				))}
			</div>
		</section>
	);
};

export default FAQSection;
