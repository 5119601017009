import React from 'react'
import Nav from "../Components/Nav"
import Footer from '../Components/Footer'
import { Helmet } from 'react-helmet'


const faqs = [
    {
        question: "What are the early signs of rheumatoid arthritis?",
        answer: "Early signs of RA include tenderness and pain in small joints like those in your fingers or toes. It can also be a pain in large joints such as the knee and shoulder. It is important to notice these early signs so as to start the treatment as early as possible to prevent any permanent joint damage."
    },
    {
        question: "Is rheumatoid arthritis genetic?",
        answer: "Rheumatoid arthritis is a form of arthritis that is believed to be associated with genetics. While these genes do not cause rheumatoid arthritis, they increase your risk of reacting to environmental factors like infections, stress, and smoking. So if a member of your family has RA, you are at a higher risk of developing it as well."
    },
    {
        question: "Do Home remedies help in rheumatoid arthritis?",
        answer: "A healthy diet and lifestyle can help reduce the pain, inflammation and other symptoms caused by RA along with medical treatment. Some of the home remedies include low-impact aerobic exercise, stretching, heat, and cold packs, adequate rest, and stress management. However, RA cannot be effectively treated or prevented with home remedies alone."
    },
    {
        question: "What does rheumatoid arthritis feel like?",
        answer: "It begins with small joints in the hands and feet and later spreads to larger joints. The affected joints are stiff, particularly in the morning and after a period of rest. RA can also cause swelling in the joints and can cause physical exhaustion if it is not treated correctly. Itchy skin, breathing difficulties, and pain can also affect your sleep due to the inflammation."
    },
    {
        question: "Is rheumatoid arthritis very serious?",
        answer: "As a chronic disease, rheumatoid arthritis can cause joint deformity and disability if not treated early or managed properly. There are also medical problems caused by rheumatoid arthritis that can affect your heart, lungs, eyes, and skin. Therefore, it is very serious, and early treatment is important for it."
    },
    {
        question: "Can rheumatoid arthritis be cured?",
        answer: "Unfortunately, there is no cure for rheumatoid arthritis. But due to advancements in research, the treatment methods are highly effective in managing the symptoms and preventing the progression of the condition. It is important to make an early diagnosis and begin treatment as soon as possible to prevent any permanent damage to the joints."
    },
    {
        question: "Does rheumatoid arthritis cause fatigue?",
        answer: "Antibodies produced by inflammation not only affect your joints, but your central nervous system as well. You can become tired and worn out by suffering from RA pain throughout the day. The joint pain can also disturb your sleep, making you feel exhausted when you wake up."
    },
]


const tocItems = [
    {
        id: "Heading-1",
        text: "Key Takeaways",
    },
    {
        id: "Heading-2",
        text: "What is Rheumatoid Arthritis?",
    },
    {
        id: "Heading-3",
        text: "Types of Rheumatoid Arthritis",
    },
    {
        id: "Heading-4",
        text: "Symptoms of RA",
    },
    {
        id: "Heading-5",
        text: "What causes Rheumatoid Arthritis?",
    },
    {
        id: "Heading-6",
        text: "What are the Risk Factors for Rheumatoid Arthritis?",
    },
    {
        id: "Heading-7",
        text: "Rheumatoid Arthritis Diagnosis",
    },
    {
        id: "Heading-8",
        text: "Treatment for Rheumatoid Arthritis",
    },
    {
        id: "Heading-9",
        text: "When to see a doctor and which one?",
    },
    {
        id: "Heading-10",
        text: "Complications",
    },
    {
        id: "Heading-11",
        text: "How can I manage RA and improve my quality of life?",
    },
    {
        id: "Heading-12",
        text: "FAQs",
    },
    {
        id: "Heading-13",
        text: "Conclusion",
    },
    {
        id: "Heading-14",
        text: "References",
    },

]


const Rheumatology = () => {
  return (
    <article className='bg-casalight'>
        <div className='xxs:pb-[22%] md:pb-[8%]'>
            <Nav />
        </div>

        <Helmet>
        {/* Meta title and Description */}
        <title>
        Rheumatoid (Types, Symptoms, causes, treatment, living, Etc)
        </title>
        <meta
          name="description"
          content="Discover Rheumatoid: Types, Symptoms, Causes, Treatment, Living & More. Stay informed for a healthier life!"
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="rheumatoid arthritis, rheumatology, arthritis, muscle pain therapy,spondylosis therapy,neck pain therapy,shoulder pain therapy,cervical therapy"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href= {`https://casamed.in/arthritis/rheumatoid`} />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Rheumatoid (Types, Symptoms, causes, treatment, living, Etc)"
        />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content="https://casamed.in/arthritis/rheumatoid" />
        <meta
          property="og:description"
          content="Discover Rheumatoid: Types, Symptoms, Causes, Treatment, Living & More. Stay informed for a healthier life!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content= "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2FRheumatMetaImage.jpg?alt=media&token=732d68fd-46b8-4c19-a950-d992a7557e9c"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://casamed.in/arthritis/rheumatoid"/>
        <meta name="twitter:title" content="Rheumatoid (Types, Symptoms, causes, treatment, living, Etc)"/>
        <meta name="twitter:description" content="Discover Rheumatoid: Types, Symptoms, Causes, Treatment, Living & More. Stay informed for a healthier life!"/>
        <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2FRheumatMetaImage.jpg?alt=media&token=732d68fd-46b8-4c19-a950-d992a7557e9c"/>


        <meta name="robots" content="INDEX,FOLLOW" />

        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Rheumatoid (Types, Symptoms, causes, treatment, living, Etc)",
            url: `https://casamed.in/arthritis/rheumatoid`,
            logo: {
              "@type": "ImageObject",
              url: "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2FRheumatMetaImage.jpg?alt=media&token=732d68fd-46b8-4c19-a950-d992a7557e9c",
            },
            description: "Discover Rheumatoid: Types, Symptoms, Causes, Treatment, Living & More. Stay informed for a healthier life!",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": faqs.map(faq => ({
              "@type": "Question",
              "name": faq.question,
              "acceptedAnswer": {
                "@type": "Answer",
                "text": faq.answer
              }
            }))
          })}
        </script>


        {/* Medical WebPage Schema */}

        <script data-rh="true" type="application/ld+json">
            {JSON.stringify({
                "@context":"https://schema.org","@type":"MedicalWebPage",
                "headline":"Rheumatoid (Types, Symptoms, causes, treatment, living, Etc)",
                "description":"Discover Rheumatoid: Types, Symptoms, Causes, Treatment, Living & More. Stay informed for a healthier life!","datePublished":"2023-05-24T11:10:30.672Z",
                "dateModified":"2023-05-24T11:10:30.672Z",
                "url":"https://casamed.in/arthritis/rheumatoid",
                "publisher":{
                    "@type":"Organization",
                    "name":"CasaMed",
                    "logo":{
                        "@type":"ImageObject",
                        "url":"https://casamed.in/static/media/logo.ca2a73c42c559614a4ec05c3f7de5e9d.svg"
                    }
                },
                "author":{
                    "@type":"Person",
                    "name":"Kevin Peter"
                },
                "reviewedBy":{
                    "@type":"Person",
                    "name":"Dr Akanchha Gupta"
                }
            })}
        </script>


      </Helmet>

        <div className='xxs:px-8 md:px-[15%]'>
            
            <div className='flex justify-center items-center flex-col'>

            <h1 className=' text-center md:text-5xl xxs:md:text-3xl xxs:text-2xl font-bold text-slate-800 xxs:pb-4 md:pb-8'>
            <span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>ecoding <span className="underline underline-offset-8 decoration-[#02B9ED]">R</span>heumatoid: Types, Symptoms, Causes, Treatment, and Living Well"
            </h1>
            <div className=' xxs:py-4 md:py-8'>
            <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FRheumatoid%20Arthritis.jpg?alt=media&token=3cc93024-10b3-46c9-a96a-99f7085ceb15' className= ' rounded-xl w-full shadow-xl ' rel="noreferrer" />
            </div>
            </div>

            <div className="py-6 flex justify-center items-center flex-col ">
            <div className='border-4 border-whyus p-4 border-opacity-60 rounded-lg shadow-xl '>
            <h2 className="text-center font-medium text-2xl pb-3"><span className="underline underline-offset-4 decoration-casablue">T</span>able of <span className="underline underline-offset-4 decoration-casablue">C</span>ontents</h2>
            <ul>
              {tocItems.map((item, index) => (
                <li className='pb-2 text-lg font-medium' style={{listStylePosition: 'inside', listStyleType: "decimal"}} key={index}>
                  <a href={`#${item.id}`}>{item.text}</a>
                </li>
              ))}
            </ul>
            </div>
            </div>

            <div className='md:text-left xxs:text-center'>
                <p className='pt-4 pb-2 text-lg'>
                Rheumatoid arthritis is one of the most common types of arthritis.

                </p>

                <p className='py-2 text-lg'>
                Rheumatoid arthritis is one of the most common types of arthritis. It is a  chronic autoimmune disorder that mainly affects your joints. In some cases, rheumatoid arthritis can also affect the other parts of your body, such as the skin, eyes, lungs, heart, and blood vessels.
                </p>

                <p className='py-2 text-lg'>
                It causes pain, swelling, and stiffness in your joints, and if left untreated, can lead to joint deformities and disability. 
                </p>
                
                <p className='py-2 text-lg'>
                Rheumatoid arthritis is a growing concern in India, with a prevalence of approximately <a href='https://pubmed.ncbi.nlm.nih.gov/8310203/' rel='noreferrer' target='_blank' className=' text-blue-500 underline cursor-pointer'>0.75%</a>. 
                </p>
                
                <p className='py-2 text-lg'>
                According to a <a href='https://timesofindia.indiatimes.com/life-style/health-fitness/health-news/rheumatoid-arthritis-here-are-the-early-signs-and-symptoms-of-the-disease/photostory/89998087.cms?from=mdr#:~:text=01%2F81.3+crore+people,India+suffer+from+rheumatoid+arthritis.' rel='noreferrer' target='_blank' className=' text-blue-500 underline cursor-pointer'>news report</a> in 2021, over 1.3 crore people in India suffer from rheumatoid arthritis, and the number is only expected to increase in the coming years. 
                </p>
                
                <p className='py-2 text-lg'>
                But still, the majority of the population in India is unaware of the condition.
                </p>
                
                <p className='py-2 text-lg'>
                With this blog, we aim to provide information about rheumatoid arthritis, its symptoms, causes, risk factors and treatment options and increase awareness of the condition. 
                </p>
                
                <p className='py-2 text-lg'>
                So if you or someone you know has been diagnosed with Rheumatoid arthritis, keep reading the blog to learn about how to manage the condition effectively.
                </p>
                

                <h2 id="Heading-1" className='pt-8 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                    Key Takeaways
                </h2>
                
                <ul className=' list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                    <li className='py-1'>Rheumatoid arthritis is a chronic autoimmune disease that causes joint inflammation.</li> 
                    <li className='py-1'>Common symptoms of rheumatoid arthritis include joint pain, swelling, and fatigue.</li> 
                    <li className='py-1'>Accurate diagnosis involves a combination of medical history, physical examination, blood tests and imaging tests.</li>
                    <li className='py-1'>Risk factors for RA include genetics, age, gender, obesity, and smoking.</li> 
                    <li className='py-1'>Treatment for RA aims to manage symptoms, reduce inflammation, and prevent joint damage.</li> 
                </ul>

                <h2 id="Heading-2" className='pt-8 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                    What is Rheumatoid Arthritis?
                </h2>

                <p className='py-2 text-lg'>
                Rheumatoid arthritis is an autoimmune disease that causes pain, inflammation and stiffness in your joints.
                </p>
                <p className='py-2 text-lg'>
                It occurs when the immune system mistakenly starts attacking the healthy tissues in your body. This causes inflammation in the affected parts of the body. However, in the case of rheumatoid arthritis, this inflammation in the joint is unnecessary and causes problems.
                </p>    
                <p className='py-2 text-lg'>
                Rheumatoid arthritis mainly affects the joints and in most cases, many joints at once. The joints in the hands, knees, and wrists are more likely to get affected by rheumatoid arthritis. It affects the lining of the joints causing it to become inflamed. The inflammation in the joints damages the cartilage which acts as a “shock absorber” in your joints.
                </p>    
                <p className='py-2 text-lg'>
                This can cause joint pain and can eventually lead to bone erosion and joint deformity. Bone erosion can lead to the fusion of your joints as the body tries to protect itself from constant irritation. Rheumatoid arthritis signs and symptoms may vary in severity and may even come and go.
                </p>
                <p className='py-2 text-lg'>
                RA affects people of all ages and genders, but it is more common in women than men and it usually develops around middle age. Some people with rheumatoid arthritis also experience problems in other parts of the body such as the heart, lungs, and eyes, or more general symptoms such as tiredness and weight loss.

                </p>


                <h2 id="Heading-3" className='pt-8 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                    Types of Rheumatoid Arthritis
                </h2>
                <p className='py-2 text-lg'>
                There are mainly two types of rheumatoid arthritis in adults, they are seropositive and seronegative. RA can also affect children and this type is known as juvenile RA or juvenile idiopathic arthritis(JIA). 
                </p>
                <p className='py-2 text-lg'>
                Below is all you need to know about the types of RA to get a better understanding of your condition:
                </p>

                <h3 className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
                Seropositive RA
                </h3>
                <p className='py-2 text-lg'>
                It is the most common type of Rheumatoid arthritis. 
                </p>
                    
                <p className='py-2 text-lg'>
                If your blood tests positive for the presence of a protein called <span className='font-medium'>rheumatoid factor(RF)</span>, it indicates that your body may be producing an immune reaction to your normal tissues.
                </p>
                    
                <p className='py-2 text-lg'>
                Seropositive RA is confirmed by the presence of antibodies in your blood that attacks your body and inflames the joints. These antibodies are called anti-CCPs, or ACPAs.
                </p>    

                <p className='py-2 text-lg'>
                The symptoms of Seropositive RA are similar to the general symptoms of RA.
                </p>    

                <p className='py-2 text-lg'>
                People with seropositive RA may experience more pain than those with seronegative RA. They are also more likely to have other issues such as:
                </p>    

                <ul className=' list-inside list-disc text-lg text-left md:pl-0 xxs:pl-[10%]'>
                    <li className='py-1'>Swollen lumps under the skin(nodules)</li> 
                    <li className='py-1'>Inflamed blood vessels(vasculitis)</li>
                    <li className='py-1'>Rheumatoid lung issues</li>
                    <li className='py-1'>Cardiovascular diseases</li>
                </ul>    
                    
                <p className='py-2 text-lg'>
                People who smoke are more likely to develop seropositive RA
                </p>

                <div className='flex justify-center items-center'>
                <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FTypes%20of%20Rheumatoid%20Arthritis.jpg?alt=media&token=7a548feb-d560-4c3d-ac65-f1bd24ab9b9b' className='rounded-lg shadow-xl my-6' />
                </div>

                

                <h3 className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
                Seronegative RA
                </h3>    

                <p className='py-2 text-lg'>
                In this type of RA, the patients test negative for RF and anti-CCP in their blood. The doctor confirms RA in these cases by taking into account the symptoms, X-rays and other laboratory tests. 
                </p>

                <p className='py-2 text-lg'>
                People who have seronegative RA, have milder symptoms compared to those with seropositive RA. 
                </p>    
                    
                

                <h2 id="Heading-4" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                Symptoms of RA
                </h2>

                <p className='py-2 text-lg'>
                Symptoms of Rheumatoid Arthritis include:
                </p>

                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>

                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>J</span>oint Pain 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>T</span>ender, warm and swollen joints 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>R</span>oint Pain 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>J</span>edness around joints 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>J</span>oint stiffness that becomes worse in the morning or after a period of inactivity 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>P</span>ain and stiffness in the same joints on both sides of the body 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>F</span>atigue 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>F</span>ever 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>L</span>oss of appetite 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>W</span>eakness 
                    </li>
                    
                    
                </ul>

                <p className='py-2 text-lg'>
                For some people, the joint symptoms may develop gradually over the years, while for others these symptoms may progress rapidly. 
                </p>    
                    
                <p className='py-2 text-lg'>
                In the early stage of RA, it usually affects the smaller joints such as the joints of the hands and feet.
                </p>

                <p className='py-2 text-lg'>
                But as the condition progresses, the symptoms of pain, swelling or stiffness.may spread to the wrists, ankles, elbows, hips and shoulders.
                </p>

                <p className='py-2 text-lg'>
                A few people develop fleshy lumps called rheumatoid nodules, which form under the skin around affected joints.
                </p>

                <p className='py-2 text-lg'>
                In most cases, the symptoms affect the same joints on both sides of the body at the same time. Sometimes RA causes problems in other parts of the body as well, such as the eyes, heart and lungs.
                </p>

                <p className='py-2 text-lg'>
                Rheumatoid arthritis symptoms will have periods of increased severity, called flares, and periods of remission when the symptoms come and go.
                </p>

                
                <h2 id="Heading-5" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                What causes Rheumatoid Arthritis?
                </h2>


                <p className='py-2 text-lg'>
                Rheumatoid arthritis is an inflammatory condition. In a healthy body, the immune system fights to protect the body from invaders such as bacteria and viruses. 
                </p>

                <p className='py-2 text-lg'>
                The exact cause of rheumatoid arthritis is still unknown, but doctors believe a genetic component is associated with it. 
                </p>    
                    
                <p className='py-2 text-lg'>
                These genes don't cause rheumatoid arthritis but make you more likely to react to environmental factors such as infections, physical or emotional stress or smoking that may trigger the disease. 
                </p>

                <p className='py-2 text-lg'>
                But when you have RA, the immune system mistakenly attacks the joint lining called synovium. The synovium is a tissue lining around a joint that produces a fluid to help move the joint freely.
                </p>   

                
                <div className='flex justify-center items-center'>
                <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FWhat%20causes%20Rheumatoid%20Arthritis.jpg?alt=media&token=2a2d6523-7ecd-4773-83c0-40aa29481146' className='rounded-lg shadow-xl my-6 ' />
                </div> 

                <p className='py-2 text-lg'>
                These antibodies release inflammatory chemicals, causing the cells in the joint lining to divide and cause inflammation.
                </p>    
                    
                <p className='py-2 text-lg'>
                    In the end, physiotherapy encourages lifestyle changes and the adoption of certain activities that help in preventing injuries further down the road. 
                </p>    
                    
                <p className='py-2 text-lg'>
                The inflammation causes the synovium to become thicker and makes the area around the joint tender, red and swollen. It also makes moving the joint difficult and painful. 
                </p>    
                
                <p className='py-2 text-lg'>
                The chemicals released by the antibodies can also damage the bones, cartilage, tendons and ligaments nearby. 
                </p>    
                
  

                <h2 id="Heading-6" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                What are the Risk Factors for Rheumatoid Arthritis?
                </h2>
                
                <p className='py-2 text-lg'>
                There are several factors that increase the risk of developing rheumatoid arthritis. Some are unavoidable, but there are other factors that can be prevented from leading to RA. 
                </p>
                    
                <p className='py-2 text-lg'>
                These factors include: 
                </p>

                <ul className=' list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Age :</span> People who lead a very busy life may not be able to schedule an appointment and visit a physiotherapy practice centre all the time for their treatment. This may lead to them stopping the treatment. Physiotherapy at home helps to save a lot of time. 
                    </li>


                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Gender :</span> A patient is more comfortable in the environment at their home compared to the environment of a hospital or a rehabilitation centre. Since the patient is more relaxed and has the presence of their family member to boost their morale, it helps in a speedy recovery.
                        
                        
                    </li>


                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Genetics :</span> In a hospital or clinic the health care provider has to deal with a large number of patients and won't be able to devote enough time to each patient. 
                    </li>


                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Excess weight :</span> People who are overweight or obese have a higher risk of developing RA. 
                    </li>


                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Diet :</span> Research shows that certain substances in food can trigger the onset of RA. So it is advised to avoid high consumption of sodium, sugar, animal-based products and processed products. 
                    </li>


                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Smoking :</span> Studies show that smoking can increase the risk of developing RA, particularly if your family members have RA. 
                    </li>
                </ul>

                    
                <p className='pb-8 text-lg italic font-medium'>
                Smoking is also associated with increased severity of the disease.  
                </p>

                <ul className=' list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                    <li className='py-1 text-lg'>
                        <span className='font-semibold text-lg'>Stress :</span> Research shows that many people with RA, often report that their symptoms first appeared after traumatic or stressful experiences. Stress can also cause the symptoms of RA to worsen or flare up. 
                    </li>
                </ul>
                    


                <h2 id="Heading-7" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                Rheumatoid Arthritis Diagnosis  
                </h2>
                    
                <p className='py-2 text-lg'>
                Diagnosis of rheumatoid arthritis can be difficult as there isn't a test that can definitely confirm a diagnosis of rheumatoid arthritis.
                </p>
                    
                <p className='py-2 text-lg'>
                The diagnosis is usually based on the combination of several factors such as your medical history, symptoms, a physical examination and the results of blood tests, scans and X-rays. Your doctor will ask about your symptoms and do a physical examination.
                </p>
                    
                <p className='py-2 text-lg'>
                During a physical examination, the doctor checks for the following:
                </p>
                    
                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Qualification and Experience of the Physiotherapist :</span> Physiotherapists with more experience and qualifications are likely to charge higher fees, as they are skilled and experienced in treating a wide variety of conditions. 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>The Severity of the Condition:</span> Some severe conditions require more time, interventions, and sessions. This leads to increased costs when compared to less severe conditions.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Type of treatment and clinic:</span> There are various physiotherapy treatments ranging from exercises, manual therapy, electrotherapy, and hydrotherapy. 
                    </li>

                    <div className='flex justify-center items-center'>
                        <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FRheumatoid%20Arthritis%20Diagnosis.jpg?alt=media&token=729ff34c-d947-45fc-9872-7c73f74a21cd' className='rounded-lg shadow-xl my-6' />
                    </div>
                </ul>

                <p className='pb-4 text-lg'>
                    Thus the cost can vary depending on the type of treatment.  The Physiotherapy department of a hospital with basic services will charge less compared to an advanced treatment centre that offers a wider range of physical therapy services.   
                </p>

                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>

                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>L</span>ooking for swelling and redness 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>E</span>xamining joint function and range of motion 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>C</span>hecking for warmth and tenderness on affected joints 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>E</span>xamining for skin nodules 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>T</span>esting your reflexes and muscle strength 
                    </li>
                </ul>
                    
                <p className='pb-2 text-lg'>
                During blood tests, they check for substances like antibodies, or check the levels of certain substances like acute phase reactants that are elevated during inflammatory conditions. 
                </p>


                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>

                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Rheumatoid factor test(RF test):</span> This test checks for a protein called rheumatoid factor. Increased levels of rheumatoid factor are associated with autoimmune conditions, especially RA. A positive rheumatoid factor is present in 70-80% of patients with RA. 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>E</span>xamining joint function and range of motion 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Anticitrullinated peptide antibody test(anti-CCP): </span>This test looks for an antibody associated with RA. People who have this antibody usually get rheumatoid arthritis. However, people can test negative for this antibody and still have RA.  This anti-CCP test is more specific for RA than the above RF test.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Erythrocyte Sedimentation Rate(ESR): </span>This test helps to determine the degree of inflammation in your body. For this test, a sample of your red blood cells is put into a test tube of liquid and timed to see how long they take to settle at the bottom of the tube. If the cells sink faster than usual, it indicates levels of inflammation that are higher than normal.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>C-reactive protein test(CRP): </span>This C-reactive protein is produced by your liver when there is a severe infection or inflammation anywhere in the body. High levels of CRP are associated with RA.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Full blood count(CBC): </span>A full blood count measures the number of cells like white blood cells, red blood cells, and platelets that make up your blood. RA does not usually cause a disruption to your blood cells, but many conditions with similar symptoms do. So a CBC test with abnormal results can help rule out rheumatoid arthritis.
                    </li>
                </ul>

                    
                <p className='py-2 text-lg'>
                The doctor will also use imaging tests to look for signs of joint damage as RA can cause the ends of the bones within the joint to wear down.
                </p>

                <p className='py-2 text-lg'>
                These imaging tests also show how severe the damage is.
                </p>

                <p className='py-2 italic font-medium text-lg'>
                These may include:
                </p>


                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>

                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>X-Rays: </span>To show any changes to your joints 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Ultrasound scans: </span>It uses high-frequency sound waves to create a picture of your joint.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>MRI scans: </span>pictures of your joints are formed using strong magnetic fields and radio waves.
                    </li>
    
                </ul>


                
                <h2 id="Heading-8" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                Treatment for Rheumatoid Arthritis  
                </h2>
                    
                    <p className="text-lg py-2">
                        Unlike the wear-and-tear damage of osteoarthritis, rheumatoid arthritis affects the lining of your joints, causing a painful swelling that can eventually result in bone erosion and joint deformity.
                    </p>
                    <p className="text-lg py-2">
                        There is no cure for rheumatoid arthritis, but there are effective treatments that help you manage the symptoms of the disease and prevent damage.
                    </p>
                    <p className="text-lg py-2">
                        The main purpose of the treatment is to control the inflammation, reduce the pain, and prevent the disability that can be caused due to RA.
                    </p>
                    <p className="text-lg py-2">
                        The long-term goal of the treatment is to slow or stop the joint damage and improve your quality of life.
                    </p>
                    <p className="text-lg py-2">
                        The damage to the joints usually occurs within 2 years after diagnosis. So it is important to see a doctor if you notice the symptoms as treating rheumatoid arthritis within this time period can help prevent any long-term damage.
                    </p>
                    <p className="text-lg py-2">
                        Treatment for RA includes lifestyle changes, medications, physiotherapy, and surgery. Your healthcare provider considers your age, health, medical history, and how bad your symptoms are when deciding on a treatment.
                    </p>

                    <div className='flex justify-center items-center'>
                        <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FTreatment%20for%20Rheumatoid%20Arthritis.jpg?alt=media&token=c9f4e3e7-bebf-4a8e-b5ad-62f119ff0e6e' className='rounded-lg shadow-xl my-6 w-3/4' />
                    </div>

                    <p className="text-lg py-2">
                        Even though these treatments will not cure RA, they will help you live an active life and reduce the risk of long-term complications.
                    </p>
                    <h3 className="text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]">Medications</h3>
                    <p className="text-lg py-2">
                        There are many medications to decrease joint pain, swelling, and inflammation and to prevent or slow down the progression of RA. Early treatment with these drugs can improve your long-term outcome.
                    </p>
                    <p className="text-lg py-2">
                        Some of these medications help to reduce the pain and inflammation of RA, while others help to reduce flares and limit the damage RA does to your joints.
                    </p>
                    <p className="text-lg py-2 font-medium italic">
                        Medications that treat RA include:
                    </p>
                    <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                        <li className='py-1 text-lg'><span className='font-semibold'>Non-steroidal anti-inflammatory drugs (NSAIDs): </span>These drugs help to reduce pain and inflammation. The effect of these medications is felt within a few hours and can last a whole day.</li>
                        
                        <li className='pt-4 text-lg list-outside list-none'>
                            Ibuprofen, Naproxen and aspirin are some of the commonly prescribed over-the-counter NSAIDs. NSAIDs can be found in various forms such as tablets, capsules, liquids, gels and creams.
                        </li>
                            
                            <li className='py-2 text-lg list-outside list-none'>The prescription version of NSAIDs may be needed for most people with RA as it offers a higher dose with longer-lasting results. Celecoxib(Celebrex) is a prescription version and it comes under a type of NSAIDs called COX-2 inhibitors.</li>
                            
                            <li className='pb-4 text-lg list-outside list-none'>Prescription NSAIDs do increase the risk of heart attack and stroke. It can also raise blood pressure and cause stomach irritation and ulcers.</li>
                        
                        <li className='py-1 text-lg'><span className='font-semibold'>Corticosteroids: </span>They help to reduce pain, stiffness, and inflammation caused by rheumatoid arthritis. They are usually prescribed by doctors for severe cases of RA or when the symptoms flare up.</li>

                        <div className='flex justify-center items-center'>
                        <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FMedications%20of%20Rheumatoid%20Arthritis.jpg?alt=media&token=d133a8b5-8067-4a7b-9941-052f76a23117' className='rounded-lg shadow-xl my-6 w-3/4' />
                        </div>


                        <li className='pt-4 text-lg list-outside list-none'>
                        Corticosteroids are available as a shot that is injected directly to the inflamed joint or as pills. Long-term use of corticosteroids can have serious side effects such as weight gain, increased blood pressure and bone loss which may lead to osteoporosis.
                        </li>
                        <li className='pb-4 pt-2 text-lg list-outside list-none'>
                        Common corticosteroids include hydrocortisone, deltasone and medrol.
                        </li>

                        <li className='py-1 text-lg'><span className='font-semibold'>Disease-modifying anti-rheumatic drugs (DMARDs): </span>These medications are used to slow the progression of rheumatoid arthritis. It works by blocking the body's immune response.</li>

                        <li className='pt-4 text-lg list-outside list-none'>
                        The most commonly prescribed DMARD is methotrexate(Trexall). Other types of conventional DMARD such as Plaquenil, Azulfidine or Arava may be added if Trexall alone does not calm the inflammation down.
                        </li>
                            
                            <li className='py-2 text-lg list-outside list-none'>It comes in the form of a pill or a shot and it takes a few weeks or months for them to start working and longer to get the full effect.</li>
                            
                            <li className='pb-4 text-lg list-outside list-none'>In the case of severe rheumatoid arthritis, DMARDs particularly methotrexate have proven to make major improvements to the condition and help save your joint.</li>
                        

                        <li className='py-1 text-lg'><span className='font-semibold'>Biologics: </span>When DMARDs don't ease the symptoms and inflammation, your doctor may recommend biologics.</li>
                        
                        <li className='pt-4 text-lg list-outside list-none'>
                        They are genetically engineered proteins that only target the specific molecules that cause inflammation in your joints.
                        </li>
                            
                            <li className='py-2 text-lg list-outside list-none'>Biologics are more effective as they attack the cells at a more specific level, rather than blocking your body’s entire immune system response.</li>

                            <li className='py-2 text-lg list-outside list-none'>These tend to work rapidly and take two-six weeks to ease the joint pain and swelling.</li>

                            <li className='py-2 text-lg list-outside list-none'>Etanercept, Infliximab, Anakinra and Rituximab are some of the common biologics.</li>
                            
                            <li className='pb-4 text-lg list-outside list-none'>Medication information is just for educational purposes. We do not recommend taking any medicine without it being prescribed by a professional, Please consult your doctor and take only the medicines that they prescribed.</li>
                        
                    </ul>

                    <h3 className="text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]">Physical and Occupational Therapy</h3>


                <p className='py-2 text-lg'>
                Physical therapy and occupational therapy are key parts of any rheumatoid treatment plan as they help in making a big difference in how you carry out your daily life.
                </p>
                    
                <p className='py-2 text-lg'>
                Physiotherapy includes exercises, manual therapy, heat and ice therapy and other treatment modalities that will help you improve your physical function.
                </p>
                    
                <p className='py-2 text-lg'>
                Occupational therapists guide you in performing everyday activities with ease and reduced pain if RA limits your functioning.
                </p>
                    
                
                
                <h3 className="text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]">Surgery</h3>


                <p className='py-2 text-lg'>
                When all other treatment methods have not made any difference to your condition, your doctor may recommend surgery.
                </p>
                    
                <p className='py-2 text-lg'>
                Joint replacement surgery is usually performed when the joint pain and inflammation become unbearable or when the joints are severely damaged.
                </p>
                    


                    
                <h2 id="Heading-9" className="pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl">When to see a doctor and which one?</h2>


                <p className='py-2 text-lg'>
                Starting early treatment for rheumatoid arthritis is very important to prevent any long-term damage to the joints. So consult a doctor if you are experiencing the following:
                </p>
                        
                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>P</span>ain in multiple joints 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>N</span>ew joint not related to any injury
                    </li>

                <div className='flex justify-center items-center'>
                <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FWhen%20to%20see%20a%20doctor%20for%20Rheumatoid%20Arthritis.jpg?alt=media&token=c4e45095-3c6b-49d4-a1e8-5a0f62e224c8' className='rounded-lg shadow-xl my-6 ' />
                </div>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>J</span>oint or muscle pain accompanied by fever, fatigue, morning stiffness or chest pain. 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>M</span>uscle pain with or without other symptoms 
                    </li>
                </ul>

                    
                <p className='pb-2 text-lg'>
                If you are experiencing any of the above symptoms you may need to visit a Rheumatologist for a detailed assessment and to begin treatment. 
                </p>

                    
                <p className='pb-2 text-lg'>
                Rheumatologists are doctors who receive special training in diagnosing and treating musculoskeletal and autoimmune conditions like RA. 
                </p>


                    
                <p className='pb-2 text-lg'>
                Rheumatologists will perform physical examinations, run blood tests and request imaging tests to confirm the presence of RA. 
                </p>

                    
                <p className='pb-2 text-lg'>
                They will then develop a treatment plan based on your personal goals and needs and follow up to ensure the treatment is working. They will also make changes to the treatment plan as you progress. 
                </p>



                

                <h2 id="Heading-10" className="pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl">Complications
                </h2>


                <p className='py-2 text-lg'>
                Rheumatoid arthritis can increase the risk of developing other health complications in your body. The medications that are used to treat RA can also cause complications.
                </p>
                        
                <p className='py-2 text-lg'>
                Some of the possible complications that could arise are:
                </p>
                        
                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Premature heart disease: </span>People suffering from RA are twice as likely to develop heart diseases than those without the condition. 
                    </li>

                    <li className='py-4 text-lg list-outside list-none'>
                    People who are obese and have RA are at the highest risk. Coronary heart disease is the number one cause of death for people suffering from RA.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Carpal tunnel syndrome: </span>It is a condition common in people with rheumatoid arthritis that affects the hands. It is caused by compression of the median nerve that controls the sensation and movement in our hands.
                    </li>

                    <li className='py-4 text-lg list-outside list-none'>
                    It causes aching, numbness or tingling in your thumbs, fingers and part of the hand. These symptoms can be controlled with proper medication.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Pulmonary fibrosis: </span>This condition makes it difficult to breathe, as it causes lung scarring and stiffness. RA can also cause the lung lining(pleura) to get inflamed which leads to another painful condition known as Pleurisy. 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Pericarditis: </span>RA can cause inflammation of the tissues around your heart which leads to a condition known as pericarditis. It causes chest pain. 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Eye complications: </span>Inflammation in the eyes due to RA can lead to uveitis and scleritis, which causes eye redness and pain. 
                    </li>

                    <li className='py-4 text-lg list-outside list-none'>
                    Inflammation in the eyes can also lead to Sjogren's syndrome, which causes dry eyes.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Vasculitis: </span>The blood vessels can thicken, weaken, narrow and scar due to the inflammation. This condition is known as vasculitis and in serious cases, it can affect the blood flow to your organs which can be life-threatening.
                    </li>
                    
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Joint damage: </span>If rheumatoid arthritis is not treated early or is not managed properly, the inflammation in your joints could lead to significant and permanent damage to the joints.
                    </li>
                    
                    <li className='py-4 text-lg list-outside list-none'>
                    It can cause the joints to become severely deformed, and damage the nearby cartilage, bones and tendons.
                    </li>
                   
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Cervical myelopathy: </span>RA puts you at an increased risk of developing cervical myelopathy. It is caused due to the compression of the spinal cord in the neck and can lead to the dislocation of joints at the top of the spinal cord.
                    </li>

                    <li className='py-4 text-lg list-outside list-none'>
                    This can seriously affect your mobility and lead to permanent spinal cord damage.
                    </li>

                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Pneumonia: </span>It is the most commonly observed infection in people with RA.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Renal failure: </span>Renal failure is the third most common cause of death among people with RA. Rheumatoid arthritis can also increase the risk of chronic kidney disease and glomerulonephritis.
                    </li>
                    

                </ul>





                <h2 id="Heading-11" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                How can I manage RA and improve my quality of life?
                </h2>
    
                <div className='flex justify-center items-center'>
                <img src='https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FRheumatoid_Arthritis%2FHow%20can%20I%20manage%20RA%20and%20improve%20my%20quality%20of%20life.jpg?alt=media&token=7dc97067-b623-4c8f-ac50-2af4bfbb4fd8' className='rounded-lg shadow-xl my-6 w-3/4' />
                </div>

                    
                <p className='pb-2 text-lg'>
                The intensity of the symptoms of rheumatoid arthritis can vary,  with periods of flare-up and other times the symptoms may be less intense or completely absent. 
                </p>


                    
                <p className='pb-2 text-lg'>
                Once diagnosed with RA, you will be managing your symptoms with the help of your doctor through medicines and other treatments. But it is also important that you manage your symptoms by yourself every day to improve your quality of life. 
                </p>

                    
                <p className='pb-2 italic font-medium text-md'>
                Here are some ways to do that:  
                </p>


                <ul className='list-inside list-disc text-lg pb-8 text-left md:pl-0 xxs:pl-[5%]'>

                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Exercise:</span> Some people with rheumatoid arthritis become inactive due to joint pain and stiffness. But inactivity can lead to a loss of joint motion and loss of muscle strength. So, it is important to stay as active as possible because it helps reduce your symptoms and prevent long-term damage. 
                    </li>
                    
                    <li className='pt-4 text-lg'>
                        Some of the recommended exercises for rheumatoid arthritis include: 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Stretching: </span>They help in increasing joint mobility and flexibility. They also help in reducing muscle tension which helps with joint stiffness. 
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Low-impact aerobic exercise: </span>These exercises help in keeping your body fit and your heart strong without putting too much strain on your joints. Walking, riding a bike and swimming are some examples of these exercises.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Strengthening: </span>These exercises help in keeping the muscles around the affected joint strong. These stronger muscles help in providing better support to the joint, thus reducing the stress and strain on them.
                    </li>

                    <li className='list-none list-outside py-4 text-lg'>
                    Research has also shown that slow and gentle exercises such as yoga, tai chi and pilates also help reduce pain, joint tenderness and swelling.
                    </li>
                    
                    <li className='list-none list-outside py-4 text-lg'>
                    If you experience a lot of pain while exercising, stop and consult with your doctor before starting again.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Diet: </span>It is important to eat a balanced, healthy diet to help fight inflammation. Fishes like salmon, trout, and sardines are full of omega-3 fatty acids that help in controlling inflammation.  Omega-3 is also known to ease joint pain and help with joint stiffness in the morning.
                    </li>
                    
                    <li className='list-none list-outside pt-4 text-lg'>
                    Eat fruits and vegetables that are rich in antioxidants to help fight damaging free-radical molecules in your body.
                    </li>
                    
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Consuming whole wheat like oatmeal, brown rice, and barley can help in lowering the levels of C-reactive protein, which is an indicator of inflammation.
                    </li>
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Consult your doctor about any extra vitamins or minerals that your body may need and add supplements or foods that are rich in them to your diet.
                    </li>
                    
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Avoid saturated fats, foods high in sodium and sugar, and pre-processed foods.
                    </li>
                    
                    <li className='list-none list-outside pb-4 text-lg'>
                    It is advisable to avoid alcohol as it may be a problem when combined with some medicines for RA.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Maintain a healthy weight: </span>Studies show that nearly two-thirds of people who have RA are overweight or obese. Maintaining a healthier weight can lead to fewer complications and a better chance of remission.
                    </li>

                    <li className='list-none list-outside pt-4 text-lg'>
                    Cytokines are proteins that control inflammation and the more they are produced in your body the more the inflammation is.
                    </li>
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Fat cells release cytokines, so it is important to reduce weight and control the fat cells so that the symptoms do not get worse and cause more damage to your body.
                    </li>
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Studies show that medications such as biologics, biosimilars, and some DMARDs are less effective for people who are overweight compared to people at a healthy weight.
                    </li>
                    
                    <li className='list-none list-outside pb-4 text-lg'>
                    Being overweight also means more stress on your load-bearing joints such as your knees, hips, low back, spine and ankles. So for people living with arthritis, being overweight further wears down and damages their joints.
                    </li>
                    
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Managing your daily tasks: </span>People suffering from rheumatoid arthritis may find it difficult to perform everyday tasks, but some simple fixes can make it easier to get things done.
                    </li>
                    
                    <li className='list-none list-outside pt-4 text-lg'>
                    Make a plan of things to do the following day, as it helps to be organised because RA can cause you to have less energy. Keep your goals realistic and take breaks in between.
                    </li>
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Focusing too much on one activity can cause fatigue and aches in your body. So spend around 30 minutes on a task and do something else, this will help you get more done.
                    </li>
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    Sometimes you will have good days where your symptoms are less intense and you feel more energetic. But don’t overdo it and by exerting yourself as the fatigue the next day can set you back.
                    </li>
                    
                    <li className='list-none list-outside py-2 text-lg'>
                    If you spend all day at an office job, having a good posture is very important as it can prevent any additional stress on your joints and reduce fatigue.
                    </li>
                    
                    <li className='list-none list-outside pb-4 text-lg'>
                    It is also ideal to get up from the chair and move around throughout the day to keep your joints from becoming too stiff. Alternate between sitting and standing if possible.
                    </li>
                    
                
                    <li className='py-1 text-lg'>
                        <span className='text-lg font-semibold'>Stop smoking: </span>Smoking can make the condition worse and cause other medical problems. Smoking can also make it difficult to stay physically active, which is important in managing RA.
                    </li>
                    
                
                
                </ul>



                



                <div id="Heading-12" className='pt-32 pb-16'>
                    <div className="max-w-screen-xl mx-auto px-5 bg-casalight min-h-sceen">
                <div className="flex flex-col items-center">
                    <h2 className="font-bold text-slate-700 text-5xl mt-5 tracking-tight">
                        FAQ
                    </h2>
                    <p className="text-whyus text-opacity-70 text-xl mt-3">
                        Frequenty asked questions
                    </p>
                </div>
                <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
                            {faqs.map((faq,index) =>(
                    <div className="xxs:py-5 md:py-10 " key={index}>
                        <details className="group">
                            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
                                <span className='text-xl text-whyus'> {faq.question}</span>
                                <span className="transition group-open:rotate-180">
                            <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
            </svg>
                        </span>
                            </summary>
                            <p className="text-whyus text-opacity-70 text-lg mt-3 group-open:animate-fadeIn">
                            {faq.answer}
                            </p>
                            
                        </details>
                    </div>

            ))}
                </div>
            </div>
                </div>

                <h2 id="Heading-13" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                    Conclusion
                </h2>
    
                <p className='py-2 text-lg'>
                Rheumatoid arthritis is a degenerative condition that can make it very challenging for you to perform your daily activities. But by understanding the symptoms, causes and treatment options discussed in this blog, you can manage the condition effectively and live a quality life. 
                </p>    
                    
                <p className='py-2 text-lg'>
                Living with rheumatoid arthritis can be mentally challenging due to the various struggles you face daily. So joining support groups or interacting with other people who suffer from RA, can help give you the guidance and encouragement needed to move forward. 
                </p>    

                <p className='py-2 text-lg'>
                Even the small changes that you make to your lifestyle make a big difference in managing your symptoms. So perform gentle exercises, eat a balanced diet and manage your stress to prevent the condition from worsening. 
                </p>    
                    
                <p className='py-2 text-lg'>
                Along with following the prescribed treatment plan, self-care is also important. Keep your mind and body healthy so as to effectively fight the condition. 
                </p>    

                <p className='py-2 text-lg'>
                Starting treatment for RA early can significantly help in reducing the severity of the symptoms and preventing permanent damage to the joints. 
                </p>
                    
                <p className='py-2 text-lg'>
                So visit a medical practitioner like CasaMed as soon as you start experiencing the symptoms mentioned in this blog.
                </p>    

                <p className='py-2 text-lg'>
                Advancements are constantly being made in treatment options for rheumatoid arthritis.
                </p>    

                <p className='py-2 text-lg'>
                So stay positive and with the right support and care, you can lead a fulfilling life despite your condition.
                </p>    


                
                <h2 id="Heading-14" className='pt-16 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
                    References
                </h2>

                <div>

                <ul className='xxs:list-outside md:list-inside list-disc text-md pb-8 text-left italic break-words'>
                    <li className='text-md py-1 cursor-pointer'><a className='underline text-blue-500' href='http://www.csp.org.uk/director/public/whatphysiotherapy.cfm' target="_blank" rel='nofollow' >https://www.mayoclinic.org/diseases-conditions/rheumatoid-arthritis/symptoms-causes/syc-20353648</a></li>
                    <li className='text-md py-1 cursor-pointer'><a className='underline text-blue-500' href='https://bcphysio.org/public/what-physiotherapy' target="_blank" rel='nofollow' >https://www.cdc.gov/arthritis/basics/rheumatoid-arthritis.html#:~:text=Rheumatoid+arthritis%2C+or+RA%2C+is,usually+many+joints+at+once.</a></li>
                    <li className='text-md py-1 cursor-pointer'><a className='underline text-blue-500' target="_blank" rel='nofollow'  href='https://www.nhs.uk/conditions/physiotherapy/how-it-works/'>https://www.healthcentral.com/article/types-of-rheumatoid-arthritis</a></li>
                    <li className='text-md py-1 cursor-pointer'><a className='underline text-blue-500' target="_blank" rel='nofollow' href='https://aiims.edu/index.php?option=com_content&view=article&id=1054&Itemid=1382&lang=en' >https://www.arthritis.org/diseases/rheumatoid-arthritis</a></li>
                </ul>
                </div>

    
     

            </div>






        </div>
        <div className='pt-16'>
            <Footer />
        </div>

    </article>
  )
}

export default Rheumatology