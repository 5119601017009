import Pagination from "@mui/material/Pagination";
import { PaginationItem } from "@mui/material";
import React, { useState } from "react";
import { Button } from "react-bootstrap";

const PaginationH = ({ totalData, currentPage, dataPerPage, onPageChange }) => {
  const totalPages = Math.ceil(totalData / dataPerPage);

  return (
    <nav className=" bg-blend-darken">
      <Pagination
        shape="rounded"
        color="primary"
        count={totalPages}
        page={currentPage}
        onChange={(event, page) => onPageChange(page)}
        renderItem={(item) => (
          <PaginationItem
            component={Button}
            {...item}
            className={item.page === currentPage ? "bg-blue-500" : ""}
          />
        )}
      />
    </nav>
  );
};

export default PaginationH;

{
  /* <div className="py-16">
<Grid.Container gap={2}>
<Grid  xs={12}>

  <PaginationNext
    
    shadow
    total={totalPages}
    active={active}
    onChange={(number) => {
      paginate(number);
      setActive(number);
    }}
    initialPage={1}
    
    
  />
  
</Grid>

</Grid.Container>
</div> */
}

// <Pagination
// onClick={() => {
//   paginate(number);
//   setActive(number);
// }}
// shape="rounded"
// />
