import React from 'react'
import customer from "../../Assets/AboutViews/OurValues/customercentric.svg"
import innovation from "../../Assets/AboutViews/OurValues/innovation.svg"
import quality from "../../Assets/AboutViews/OurValues/quality.svg"
import integrity from "../../Assets/AboutViews/OurValues/integrity.svg"
import accountability from "../../Assets/AboutViews/OurValues/accountability.svg"
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';


const primarysty = {
    color: "#016D8D",
    fontWeight: "700",
    fontSize: "18px",
    fontStyle: "Poppins",

}
const secondarysty = {
    color: "#016D8D",
    fontWeight: "400",
    fontSize: "16px",
    fontStyle: "Poppins",

}



function OurValues(){
  return (
    <section className=''>

        <article className=' xxs:px-0'>

                <Box className='md:px-12 ' sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <nav aria-label="main mailbox folders">
                    <List className='grid grid-cols-1 justify-center items-center'>
                    
                    <ListItem disablePadding >
                        <ListItemButton>
                        <ListItemIcon>
                            <img alt=""  src={customer} className='xxs:w-[40%] sm:w-[60%]'/>
                        </ListItemIcon>
                        <ListItemText className='' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Customer Centric"
                            secondary="We give the control of your Pain Relief journey in your own hands" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img alt=""  src={innovation} className='xxs:w-[40%] sm:w-[60%]'/>
                        </ListItemIcon>
                        <ListItemText className='' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Innovation"
                            secondary="Our CasaAIGuru™️ tracks your body movements, relaying key data and info
                            to your assigned physiotherapist for real time monitoring." 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img alt=""  src={quality} className='xxs:w-[40%] sm:w-[60%]'/>
                        </ListItemIcon>
                        <ListItemText className='' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Quality"
                            secondary="Not just any generic exercise app.
                            We have got you covered with the country's best experts and equipments" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img alt=""  src={integrity} className='xxs:w-[40%] sm:w-[60%]'/>
                        </ListItemIcon>
                        <ListItemText className='' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Integrity"
                            secondary="Multiple touch points ensures transparency which we, at CasaMed believe is the cornerstone of a Positive Pain Management Experience" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img alt=""  src={accountability} className='xxs:w-[40%] sm:w-[60%]'/>
                        </ListItemIcon>
                        <ListItemText className='' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Accountability"
                            secondary="Constant and Continuos Feedback Enquiries ensures theres no room for any doubt.
                            Sit back and let us take charge of the externals while you focus on your recovery." 
                        />
                        </ListItemButton>
                    </ListItem>
                    
                    </List>
                </nav>
                
                
                
                </Box>




            
            {/* <ul className='text-casashade2 xxs:px-0 sm:px-32'>
                <li className='pb-4 flex flex-row xxs:gap-6 md:gap-16'>
                    <div  className="xxs:w-[70%]  flex justify-center items-center">
                    <img alt=""  src={customer} className='xxs:w-[60%] '/>
                    </div>
                    <div className='flex flex-col justify-center items-start'>
                    <strong><h1 className='text-xl'>Customer Centric</h1></strong>
                    <p className='text-lg'>We give the control of your Pain Relief journey in your own hands</p>
                    </div> 
                </li>
                
                
                <li className='pb-4 flex flex-row xxs:gap-6 md:gap-16'>
                    <div  className="xxs:w-[80%]  flex justify-center items-center">
                    <img alt=""  src={innovation} className='xxs:w-[60%] '/>
                    </div>
                    <div className='flex flex-col justify-center items-start'>
                    <strong><h1 className='text-xl'>Innovation</h1></strong>
                    <p className='text-lg'>Our CasaAIGuru™️ tracks your body movements, relaying key data and info <br/>to your assigned physiotherapist for real time monitoring.</p>
                    </div> 
                </li>
                
                <li className='pb-4 flex flex-row xxs:gap-6 md:gap-16'>
                    <div  className="xxs:w-[80%] flex justify-center items-center">
                    <img alt=""  src={quality} className='xxs:w-[60%] '/>
                    </div>
                    <div className='flex flex-col justify-center items-start'>
                    <strong><h1 className='text-xl'>Quality</h1></strong>
                    <p className='text-lg'>Not just any generic exercise app. <br/>We have got you covered with the country's best experts and equipments</p>
                    </div> 
                </li>
                
                <li className='pb-4 flex flex-row xxs:gap-6 md:gap-16'>
                    <div  className="xxs:w-[80%] flex justify-center items-center">
                    <img alt=""  src={integrity} className='xxs:w-[60%] '/>
                    </div>
                    <div className='flex flex-col justify-center items-start'>
                    <strong><h1 className='text-xl'>Integrity</h1></strong>
                    <p className='text-lg'>
                        Multiple touch points ensures transparency which we, at CasaMed believe is the <br/>cornerstone of a Positive Pain Management Experience   
                    </p>
                    </div> 
                </li>
                
                <li className='pb-4 flex flex-row xxs:gap-6 md:gap-16'>
                    <div  className="xxs:w-[80%] flex justify-center items-center">
                    <img alt=""  src={accountability} className='xxs:w-[60%] '/>
                    </div>
                    <div className='flex flex-col justify-center  items-start'>
                    <strong><h1 className='text-xl'>Accountability</h1></strong>
                    <p className='text-lg'>Constant and Continuos Feedback Enquiries ensures theres no room for any doubt. <br/>
                    Sit back and let us take charge of the externals while you focus on your recovery.</p>
                    </div> 
                </li>
                

            </ul>
             */}
            
        </article>


    </section>
  )
}

export default OurValues