import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const PMTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Pain Management Treatment
      </h1>
      <div className="mx-5">
        <p>
          We all have experienced some kind of body pain in our life. Some fade
          away with time, some persist. If you experience pain, don’t ignore it
          because the underlying cause might be severe. Connect with our medical
          experts who can assist you with the treatment and help beat your pain.
          <br />
          Pain in general terms can be said as an unpleasant or uncomfortable
          sensation in our body because of which we face difficulty in
          performing our daily activities. Pain can be of different types but
          for ease of understanding, we are grouping them into eight categories
          which are: acute pain, chronic pain, breakthrough pain, nerve pain,
          soft tissue pain, bone pain, phantom pain, and referred pain. Our
          therapist at Casamed first tries to understand the cause and then
          provides the treatment according to the need.
        </p>
        <p>
          In acute pain, the pain doesn&#39;t persist for a longer duration. It
          is usually caused by some specific event or injury like a cut mark,
          dental work, etc. Chronic pain is the one whose symptoms last for a
          longer time and the patient experience the pain very often. These
          include the pain experienced in arthritis or by cancer patients. Then
          there is breakthrough pain that occurs as a sudden and sharp pain to
          the people who are taking medication for chronic pain or perform
          excessive exercise. Each one is a different form of pain so if you are
          experiencing any of the above-mentioned or any other kind of pain,
          contact us at 1800 572 4868 and connect with our medical experts who
          will guide you through proper pain management treatment to beat the
          pain and perform the daily activities hassle free.
        </p>
        <p>
          We also provide online consultations and sessions for those who have
          time constraints or live outside the city. Our therapists used
          different types of techniques like massage, manipulation of joints,
          for soft tissue pain we use manual therapy using hands. Our therapists
          are well trained and aware of which techniques to be used when needed.
        </p>
        <p>
          There are physical exercises that help the patients with strengthing
          of muscles, stability in the joint, and flexibility of muscles and
          joints. We aim to provide drug-free and natural treatment to our
          patients, if possible. Apart from this we also guide our patients in
          improving their lifestyle that will boost their condition. The
          duration of the treatment depends on how the body of the patient
          responds. The duration can be as less than 6 months or it can take a
          few years. Connect with us to schedule your appointment for a
          comprehensive <strong>pain management treatment:</strong>
        </p>
        <h2>Why Choose Casamed for Pain Management Treatment</h2>
        <ul>
          <li>Advanced and effective pain care</li>
        </ul>
        <p>
          Connect with us to plan your session with our therapists so that they
          can guide you regarding the pain and suggest the appropriate
          treatment. They will help you to overcome your pain by performing
          exercises.
        </p>
        <ul>
          <li>
            Educate you about the <strong>pain management treatment</strong>:
          </li>
        </ul>
        <p>
          At Casamed we first educate the patient and the associated family
          member regarding the cause of pain and what kind of therapy we will be
          performing so that it will help the patient to recover from
          it. Gradually, you may use our cutting edge technology to self
          rehabilitate
        </p>
        <ul>
          <li>Assist you with manual treatment:</li>
        </ul>
        <p>
          Our therapist performs some manual treatment through hands so that it
          helps the patient to relax. Other than that, we also help the patient
          to make changes in their way of living.
        </p>
      </div>
    </div>
  );
};

const PainManagementTreatment = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>Pain Management Treatment | Get Consultation @CASAMED</title>
        <meta
          name="description"
          content="Connect with CASAMED to get pain management treatment from experts. We have certified therapist. Visit us now to get more details."
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="pain management treatment" />
        {/* Canonical Tag */}
        <link
          rel="canonical"
          href="https://casamed.in/pain-management-treatment"
        />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Pain Management Treatment | Get Consultation @CASAMED"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/pain-management-treatment"
        />
        <meta
          property="og:description"
          content="Connect with CASAMED to get pain management treatment from experts. We have certified therapist. Visit us now to get more details."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Pain Management Treatment | Get Consultation @CASAMED",
            url: " https://casamed.in/pain-management-treatment",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Connect with CASAMED to get pain management treatment from experts. We have certified therapist. Visit us now to get more details.",
          })}
        </script>
      </Helmet>
      <PMTComponent />
      <Footer />
    </div>
  );
};

export default PainManagementTreatment;
