import React from "react";
import NavStyled from "../Components/NavStyled";
import FooterStyled from "../Components/FooterStyled";

import { PricingThree } from "../Components/PricingTest";
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";

const Pricing = React.memo(function Pricing() {
  return (
    <div className="bg-bluenav">
      <NavStyled />
      <Helmet>
        <title>Corporate Pricing | CasaMed</title>
        <meta
          name="description"
          content="Discover CasaMed's Corporate Pricing Page for Partners - Doctors/Physios and gain complete control of your patients' progress, recovery, and practice analytics. With CasaMed, you can access valuable insights into patient health and improve treatment outcomes. Take advantage of our powerful platform to enhance patient care and grow your practice today."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Healthcare software, Patient management,Practice analytics, Treatment outcomes, Medical records, Electronic health records, Physiotherapy software, Doctor software, Patient progress, Patient recovery"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/pricing" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta property="og:title" content="Corporate Pricing | CasaMed" />
        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content="https://casamed.in/pricing" />
        <meta
          property="og:description"
          content="Discover CasaMed's Corporate Pricing Page for Partners - Doctors/Physios and gain complete control of your patients' progress, recovery, and practice analytics. With CasaMed, you can access valuable insights into patient health and improve treatment outcomes. Take advantage of our powerful platform to enhance patient care and grow your practice today."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Corporate Pricing | CasaMed",
            url: "https://casamed.in/pricing",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "Discover CasaMed's Corporate Pricing Page for Partners - Doctors/Physios and gain complete control of your patients' progress, recovery, and practice analytics. With CasaMed, you can access valuable insights into patient health and improve treatment outcomes. Take advantage of our powerful platform to enhance patient care and grow your practice today.",
          })}
        </script>
      </Helmet>
      {/* <section className='bg-lightgrad md:pt-32 xxs:pt-24 md:pb-16 xxs:pb-0'>
              <div className="flex justify-center items-center pb-6">
                  <span className="text-casashade2 sm:text-[34px] md:text-[54px] xl:text-[54px] xl:tracking-wider drop-shadow-lg rounded-xl font-bold font-poppins xxs:text-[44px] text-center xxs:px-6 ">Product Pricing</span>
              </div>

              <div className="flex justify-center pb-32">
                  
                  <ul className="grid drop-shadow-md pt-2  ">
                  {items.map((item, index) => 
                      index===0 ? 
                      (
                      <li key={index}>

                          <div className="grid  text-casashade2 grid-cols-4">

                              <div className={`${item.style} tracking-wide pb-2 `}>
                                  {item.features}
                              </div>

                              <div className="text-center tracking-wide md:text-3xl xxs:text-md sm:text-2xl font-bold pb-2 pt-8">
                                  {item.basichead}
                              </div>

                              <div className="bg-white rounded-t-lg  shadow-md  z-50  ">
                              <div className="text-center font-bold tracking-wide md:text-3xl xxs:text-md sm:text-2xl pb-2 pt-8">
                                  {item.prohead}
                              </div>
                              </div>

                              <div className="text-center font-bold tracking-wide md:text-3xl xxs:text-md sm:text-2xl pb-2 pt-8">
                                  {item.enterhead}
                              </div>

                              

                          </div>
                      </li>
                      )
                      :
                      index === items.length-1?
                      (
                          <li key={index}>
      
                              <div className="grid  text-casashade2 grid-cols-4">
      
                                  <div className={`${item.style} tracking-wide pb-2  `}>
                                      {item.features}
                                  </div>
      
                                  <div className="flex xxs:text-[12px] sm:text-lg py-4 justify-center"> 
                                      <ButtonPricing >
                                          <a href="#">{item.basichead}</a>
                                      </ButtonPricing>
                                  </div>

                                  <div className="bg-white rounded-b-lg ">
                                  <div className="flex py-4 xxs:text-[12px] sm:text-lg  justify-center"> 
                                      <ButtonPricing>
                                        <a href="#">{item.prohead}</a>
                                      </ButtonPricing>
                                  </div>
                                  </div>
      
                                  <div className="flex xxs:text-[12px] sm:text-lg py-4 justify-center"> 
                                      <ButtonPricing>
                                          <a href="#">{item.enterhead}</a>
                                      </ButtonPricing>
                                  </div>
      
                                  
      
                              </div>
                          </li>
                          )
                          :
                      (
                      <li key={index}>

                          <div className="grid grid-cols-4 ">

                              <div className={`${item.style} tracking-wide mb-4 text-casadarkshade font-poppins font-semibold py-1 xxs:text-[10px] xs:text-xs ss:text-ss sm:text-sm md:text-lg text-center  `}>
                                  {item.features}
                              </div>

                              <div>
                                  <div className="flex justify-center pb-4">
                                  <img src={item.basichead} className={`${item.id===2 || item.id ===5 ? 'xxs:w-[80%] md:w-[40%] sm:w-[60%] ': 'xxs:w-[15%] md:w-[4%]'} `} />
                                  </div>
                              </div>

                              
                              <div className="bg-white  z-50 ">
                              <div >
                                  <div className="flex justify-center pb-4">
                                  <img src={item.prohead}  className={`${item.id===2 || item.id ===5 ? 'xxs:w-[80%] md:w-[40%] sm:w-[60%]': 'xxs:w-[15%] md:w-[4%]'} `}/>
                                  </div>
                              </div>
                              </div>

                              <div>
                                  <div className="flex justify-center pb-4">
                                  <img src={item.enterhead} className={`${item.id===2 || item.id===5 ? 'xxs:w-[80%] md:w-[40%] sm:w-[60%] ': 'xxs:w-[15%] md:w-[4%]'} `}/>
                                  </div>
                              </div>

                              

                          </div>
                      </li>
                      )

                  )}

                  </ul>
              </div>
              
              

          </section> */}

      <div className="flex justify-center items-center xxs:pt-12 md:pt-32 bg-bluenav ">
        <PricingThree />
      </div>
      {/* <div className="bg-bluenav md:py-28 xxs:py-0">
              <ProductFeaturesSwiper />
              </div> */}
      <div className="text-casalight bg-bluenav">
        <FooterStyled />
      </div>
    </div>
  );
});

export default Pricing;
