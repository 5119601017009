import React from "react";
import ReactDOM from "react-dom"; 
import { BrowserRouter } from "react-router-dom";
// import { Provider } from 'react-redux'
// import { createStore, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk'

// import reducers from './reducers'

import App from './App'
import './index.css'
import * as serviceWorker from "./serviceWorker" 
// import { NextUIProvider } from "@nextui-org/react";

// import { NextUIProvider } from "@nextui-org/react";



// const store = createStore(reducers, compose(applyMiddleware(thunk)))


ReactDOM.render(
    <React.StrictMode>

        

    <App />

        
    </React.StrictMode>, document.getElementById('root'))


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
