import React from "react";

import styles from "./FAQ.module.css";

const Brand = () => {
  const testimonialItems = [
    {
      avatar:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Testimonials/ut1.webp",
      name: "Arnav Anshuman",
      title: "Sports Injury",
      quote:
        "“The detailed enquiry on the various facets of pain and bodily reactions is exceptional. One can be as specific about their concern and get immaculate responses for their issue”",
    },
    {
      avatar:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Testimonials/ut2.webp",
      name: "Vishnu Kumari",
      title: "Arthiritis - Chronic Pain",
      quote:
        "“Casamed proved to be really useful with expert consultation, Audio and video assisted rehabilitation and reminder notification to ensure adherence to care regime. With continued efforts, I was able to gain full functional mobility and pain relief”",
    },
    {
      avatar:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Testimonials/ut4.jpg",
      name: "Anjali Kalita",
      title: "Post Surgical Rehab (Hip Replacement Surgery)",
      quote:
        "“Detailed questionnaire to diagnose the problem, fluidic navigation and expert advice. Prompt feedback and Physio support!”",
    },
  ];

  const faqItems = [
    {
      question: "What is CasaMed?",
      answer:
        "CasaMed specialises in online consultations and therapy for muscle and joint pain, providing non-surgical, home-based physiotherapy, nutrition, mobility training and pain management solutions.",
    },
    {
      question: "How does CasaMed's Pain Care work?",
      answer:
        "At CasaMed, our Pain Care follows a thorough process. When patients present with pain our experienced medical team conducts a comprehensive assessment. They meticulously review the patient's complete medical history and recommend a personalised treatment protocol based on their findings. We establish a baseline for the patient's condition using objective parameters and then employ clinically proven techniques to track and monitor their recovery over time. Our goal is to ensure a complete and timely recovery for each individual.",
    },
    {
      question: "What kind of pain does CasaMed treat?",
      answer:
        "CasaMed covers various pain areas, including orthopaedic issues resulting from trauma, muscle stress, nerve damage, posture problems, gynecological /PCOD related pains, autoimmune conditions, neurological conditions, cancer and sports injuries.",
    },
    {
      question: "Is CasaMed's service available globally?",
      answer:
        "Yes, CasaMed has a global presence, offering services in over 20 countries with a diverse user base.",
    },
    {
      question: "What technology does CasaMed use for treatment?",
      answer:
        "CasaMed utilises AI, specifically Computer Vision enabled AI for posture correction, as part of their CasaAI Motion Assist™ system, ensuring a natural physiotherapy experience.",
    },
    {
      question: "What recognition has CasaMed received?",
      answer:
        "CasaMed has been recognised with awards for its innovative approach, including Startup India Recognition, Best Startup Award at IMPACT 2.0 Conclave and Best Startup Awards for scalability.",
    },
    {
      question: "How does CasaMed ensure patient-centric care?",
      answer:
        "CasaMed emphasises a patient-centric approach, with detailed analysis and multi-level assessments tailored to each individual's recovery journey.",
    },
    {
      question:
        "Is Surgery Always the Best Option for Musculoskeletal (MSK) Conditions?",
      answer:
        "Not necessarily. CasaMed focuses on effective non-surgical solutions for MSK pain relief, which can be cost-effective and less invasive compared to surgery.",
    },
    {
      question:
        "Can Online Physiotherapy Be as Effective as In-Person Sessions?",
      answer:
        "Yes, with CasaMed's live interactive sessions and advanced AI technology for motion tracking and guidance, online physiotherapy can be highly effective, offering convenience and personalized care.",
    },
    {
      question: "How Does Technology Enhance Physiotherapy at CasaMed?",
      answer:
        "CasaMed utilises AI for posture correction and motion analysis, providing a more efficient, objective and accurate pain care experience.",
    },
    {
      question: "Can CasaMed Help in Post-Surgery Rehabilitation?",
      answer:
        "Yes, CasaMed provides comprehensive post-surgery rehabilitation plans, tailored to individual recovery requirements.",
    },
    {
      question: "How Does CasaMed Contribute to Sustainable Healthcare?",
      answer:
        "By offering online consultations and therapy, CasaMed reduces the need for travel and in-person visits, contributing to a more sustainable healthcare model.",
    },
    {
      question: "Does CasaMed Offer Services for Elderly Patients?",
      answer:
        "Yes, CasaMed caters to patients of all ages, including the elderly, with specialized care plans suited to their specific health needs.",
    },
  ];

  const pricingItems = [
    {
      type: "1 Month Plan",
      price: "₹9209",
      subscription: "month",
      description1: "Standard Physiotherapy Plan",
      description2: "Minimum 30 days",
      features: [
        "2 PMR / Rheumat / Ortho Consultations",
        "Nutrition and Diet Management",
        "15 Live / Group Sessions",
        "Free Updates",
        "1 month app access",
      ],
      buttonText: "Choose Personal",
      active: false,
    },
    {
      type: "Lifetime Access Plan",
      price: "₹29500",
      subscription: "year",
      description1: "Intense Condition Management Plan",
      description2: "Continuous Evaluation",
      features: [
        "6 PMR / Rheumat / Ortho Consultations",
        "Nutrition and Diet Management - 6 months",
        "100 Live / Group Sessions",
        "Free Physio Consultations for the Entire Year",
        "Free Updates",
        "12 month app access",
      ],
      buttonText: "Choose Business",
      active: false,
    },
  ];

  const personaItems = [
    {
      head: "Arthiritis Chronic Pain",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Persona/arthiritis.svg",
    },
    {
      head: "Posture Issues",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Persona/postureback.svg",
    },
    {
      head: "Sports Injury",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Persona/sports.svg",
    },
    {
      head: "PCOD",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Persona/pcod.svg",
    },
  ];

  const featureItems = [
    {
      heading: "Expert Consultation - Case Assessment by Super-Specialists",
      text1:
        "Prompt Pain Management by the country's leading Orthopaedicians and Physiotherapists",
      text2: "Medical History and Investigation Evaluation.",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/featureImage1.png",
      style: "md:ml-20",
    },
    {
      heading: "detailed statistics",
      text1:
        "Multi-level Analysis powered by Smart Physio Analysis helping you to assess your Recovery Journey",
      text2:
        "Diagnostic Tools designed by experts for accurate identification of problem areas.",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/feature-2-img.svg",
      style: "md:flex-row-reverse  ",
    },
    {
      heading: "Computer Vision enabled AI assist ",
      text1: "While generic posture correction apps tract only a single joint,",
      text2:
        "CasaMed's CasaAI Motion Assist™️ tracks the complete body motion to give a much more natural PT experience ",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/motionassist.png",
      style: "md:ml-20",
    },
    {
      heading: "Live Sessions",
      text1:
        "Get all your pain management needs sorted by our expert live sessions",
      text2:
        "Covering a foray of pain areas such as Orthopaedic, Posture Issues, Gynae/PCOD related, you can rest assured that all your queries shall be heard and answered !",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/live.svg",
      style: "md:flex-row-reverse  ",
    },
    {
      heading: "Smart Diagnostics",
      text1:
        "AI based smart diagnostics tool with Logic driven Symptom Addressal which helps you figure out the best plan for you.",
      text2:
        "Choose from over 9 Pain points - Result displayed instantly with recommended plans",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/painpoints.png",
      style: "md:flex-row-reverse  ",
    },
    {
      heading: "Personalised Plans",
      text1:
        "A plethora of Affordable Subscription Plans to choose from. High Degree of Personalization results in a pain management plan curated just for you!",
      text2:
        "These plans also Smart-modify themselves based on continuous user feedback.",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/plans.svg",
      style: "md:flex-row-reverse  ",
    },
    {
      heading: "Audio - Visual Guidance",
      text1:
        "Library of over 500 exercises takes care of all your pain-management needs through high quality Audio and Video guidance throughout the exercise.",
      text2:
        "CasaMed's intuitive UI makes sure that your pain relief journey is as hassle free as possible.",
      button: "learn more",
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Features/videoguidance.svg",
      style: "md:flex-row-reverse  ",
    },
  ];

  const howItems = [
    {
      id: 1,
      icon: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/How/monitor.svg",
      head: "Patient Monitoring",
      content1: "PT-grade automated feedback delivered to your smartphone.",
      content2: "",
    },
    {
      id: 2,
      icon: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/How/value.svg",
      head: "Enhancing Value",
      content1:
        "Connecting Patients with top medical experts for guided pain relief. Integrates with leading medical providers",
    },
    {
      id: 3,
      icon: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/How/transparency.svg",
      head: "Transparency",
      content1: "Multiple touch points throughout the pain relief journey.",
    },
    {
      id: 4,
      icon: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/How/ai.svg",
      head: "AI Physio Assist",
      content1: "Computer Vision enabled Posture Correcting AI.",
    },
  ];

  const whyItems = [
    {
      head: "Non-Surgical Intervention",
      text: "Effective Non-Surgical Pain Relief @ 5% of the cost of surgery",
      img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Why/nsi.svg",
      link: "/product#prodfeatures",
    },

    {
      head: "Cost Effective",
      text: "Expert advice at a fraction of hospital expenses",
      img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Why/cost.svg",
      link: "/product#prodfeatures",
    },
    {
      head: "Live Sessions",
      text: "Live Interactive Sessions with our Experts",
      img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Why/live.svg",
      link: "/product#livesession",
    },
    {
      head: "Home Visits",
      text: "Expert Physiotherapy Care in the Comfort of Your Home",
      img: "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Why/visit.svg",
      link: "/homevisits",
    },
  ];

  const numItems = [
    {
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Numbers/careplans.svg",
      heading: "300+",
      text: "Curated Care Plans",
    },

    {
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Numbers/countries.svg",
      heading: "20+",
      text: "Countries Around The World",
    },

    {
      image:
        "https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Numbers/users.svg",
      heading: "3000+",
      text: "trusted Users",
    },
  ];

  return (
    <div className="w-full bg-white ">
      <section
        className="pb-12 sm:pb-16 lg:pb-20 px-0 mx-auto max-w-7xl sm:px-6 lg:px-8"
        style={{}}
      >
        <div
          className="py-8 md:px-10 flex justify-center md:justify-start items-center"
          style={{
            background: "rgb(2,0,36)",
            // eslint-disable-next-line no-dupe-keys
            background:
              "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,88,127,1) 100%)",
          }}
        >
          <img
            src="https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/HomeViews/Logo/White_Logo.png"
            alt="white_logo"
            className="h-[50px] w-auto"
          />
        </div>

        <div className="py-20">
          <div className="flex justify-center items-center flex-col px-4 md:flex-row gap-4 text-center md:text-left md:gap-[10%] md:px-[5%]">
            <div className="w-full md:w-[60%]">
              <h2 className="font-semibold text-3xl tracking-wide">
                Struggling with Muscle and Joint Pain?
              </h2>
              <p className="pt-4 text-lg tracking-wide">
                Discover CasaMed, your gateway to non-surgical pain relief.
              </p>
              <p className="pt-2 text-lg tracking-wide">
                Access expert physiotherapy, cutting-edge AI for posture
                correction, and tailored care plans, all from your home. Join
                many in overcoming pain with <strong>CasaMed</strong>, the
                forefront in <strong>online pain management</strong>.
              </p>
            </div>

            <div className="px-2 md:px-0 w-full md:w-[60%]">
              <img
                src="https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/Arthiritis/arth1.jpg"
                alt="arthritis_treatment"
                className="rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="rounded-xl flex flex-col justify-start pb-20 items-center bg-[#030226]">
          <div className="py-20 px-10 ">
            <h2 className="text-white  font-semibold text-3xl">
              One Stop Shop for All Your Pain Management Needs
            </h2>
          </div>

          <div>
            <iframe
              width="640"
              height="360"
              src="https://www.youtube.com/embed/sYg__GHnjng?si=0bL7hD0y1XSmYwNn"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              className="xxs:w-full xxs:h-auto md:w-[640px] md:h-[360px]"
            ></iframe>
          </div>

          <div className="pt-8">
            <h3 className="text-white font-semibold text-[1.2rem] ">
              Watch our Product Showcase
            </h3>
          </div>
        </div>

        <div className="mt-20">
          <h1 className="text-center xxs:pb-10 md:pb-10 text-[#030226] md:text-[56px] xxs:text-[36px] font-bold md:px-20 xxs:px-6">
            <span className="decoration-[#02B9ED] underline underline-offset-8">
              W
            </span>
            hy Choose CasaMed?
          </h1>

          <div className="flex justify-center items-center ">
            <div className="grid grid-cols-1 md:grid-cols-2 justify-center items-start w-[85%] gap-y-10">
              {whyItems.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row justify-center items-center md:items-start gap-6 px-4"
                >
                  <div>
                    <img
                      src={item.img}
                      alt={item.head}
                      className="w-[80px] h-[80x]"
                    />
                  </div>
                  <div className="w-[340px] ]md:w-[400px] text-center md:text-left">
                    <h3 className="font-semibold text-2xl text-[#030226]">
                      {item.head}
                    </h3>
                    <p className="font-medium text-lg text-[#030226]">
                      {item.text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="text-casashade2 flex justify-center items-center pt-16">
            <div className="flex  items-center gap-16 justify-center flex-wrap">
              <ul className="flex flex-col sm:flex-row  ">
                {numItems.map((item, index) => (
                  <li key={index}>
                    <div className="flex gap-4  p-4  ">
                      <div className="p-2 text-casashade2 ">
                        <img
                          className="w-full"
                          src={item.image}
                          alt={item.image}
                        />
                      </div>
                      <div className="text-whyus">
                        <h3 className="font-poppins text-[18px] font-bold">
                          {item.heading}
                        </h3>
                        <p className="capitalize font-poppins text-casashade2 text-[14px] font-semibold ">
                          {item.text}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="mt-20 flex justify-center items-center ">
          <div className="grid grid-cols-1 md:grid-cols-2 md:w-[80%] lg:w-[90%] xl:w-full">
            <div>
              <h2
                className={`text-[36px] md:text-[50px]   text-whyus xxs:text-center md:text-left font-semibold font-poppins xxs:pb-6  `}
              >
                <span className="decoration-[#02B9ED] underline underline-offset-8">
                  H
                </span>
                ow is CasaMed
                <br className="sm:block hidden text-2xl" />{" "}
                <strong>different?</strong>
              </h2>
            </div>
            <div className=" flex flex-col justify-center items-center px-8  gap-y-8 ">
              {howItems.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row gap-x-2 gap-y-4 md:gap-y-0 justify-center items-center md:gap-x-6"
                >
                  <div>
                    <img
                      src={item.icon}
                      alt={item.head}
                      className="min-w-[65px] "
                    />
                  </div>
                  <div className="flex flex-col justify-center items-center text-[#024461]  ">
                    <h3 className="text-lg md:text-xl font-semibold text-center md:text-left">
                      {item.head}
                    </h3>
                    <p className="text-md md:text-lg tracking-wide text-center md:text-left">
                      {item.content1}
                    </p>
                    <p className="text-md md:text-lg tracking-wide text-center md:text-left">
                      {item.content2}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-20 flex flex-col justify-center items-center px-8">
          <div>
            <h2
              style={{ lineHeight: 1.4 }}
              className="text-[36px] md:text-5xl font-semibold text-center mb-10 text-casadarkshade"
            >
              <span className="decoration-[#02B9ED] underline underline-offset-8">
                O
              </span>
              ne Stop Shop for All Your Pain Management Needs
            </h2>
          </div>
          <div className="grid grid-cols-1 w-[90%] tracking-wide">
            <div className="">
              {featureItems.slice(0, 4).map((item, index) => (
                <div
                  key={index}
                  className={`flex md:gap-x-8 justify-center flex-col-reverse mb-16 md:mb-0 gap-y-8 md:gap-y-0 items-center ${
                    index % 2 === 0 ? " md:flex-row " : "md:flex-row-reverse "
                  }`}
                >
                  <div className="flex flex-col gap-y-4 pb-8 text-center md:text-left w-full md:w-[650px]">
                    <h3 className="font-bold text-2xl capitalize">
                      <span className="underline underline-offset-4 decoration-[#02B9ED] mr-0.5">
                        {item.heading[0]}
                      </span>
                      <span>{item.heading.slice(1)}</span>
                    </h3>

                    <p className="text-casadarkshade text-[20px]">
                      {item.text1}
                    </p>
                    <p className=" text-casadarkshade text-[20px]">
                      {item.text2}
                    </p>
                  </div>
                  <div className="">
                    <img
                      className="w-[250px] h-[520px] "
                      src={item.image}
                      alt="features"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-20 flex flex-col justify-center items-center px-4">
          <div className="flex-col  text-center flex-wrap justify-center items-center  font-poppins text-casashade2">
            <div className=" flex justify-center items-center">
              <h1
                style={{ lineHeight: 1.4 }}
                className="pb-12 font-semibold text-[36px] md:text-5xl"
              >
                Pain Relief Journey that spans a spectrum of Users
              </h1>
            </div>

            <h4 className="text-[20px]">
              CasaMed covers all your Pain Management needs with an array of
              Curated Care Plans
            </h4>
          </div>

          <div className="mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 justify-center items-center">
              {personaItems.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-y-8 justify-center items-center"
                >
                  <div className="">
                    <img
                      src={item.image}
                      alt={item.head}
                      className="w-[300px] h-[300px]"
                    />
                  </div>
                  <div className="flex justify-center items-center">
                    <h3 className="text-casashade2 text-2xl font-semibold hover-underline-animation">
                      {item.head}
                    </h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-40">
          <div className="flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center">
                <h2 className="mb-3 text-3xl font-bold leading-[1.208] text-casashade2 sm:text-4xl md:text-[40px]">
                  Our Pricing Plan
                </h2>
                <p className="text-casadarkshade text-body-color dark:text-dark-6">
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className=" flex xxs:flex-col lg:flex-row justify-center items-center lg:items-start">
          {pricingItems.map((item, index) => (
            <div className="w-full px-4 md:w-1/2 lg:w-[36%] " key={index}>
              <div className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-8 py-10 shadow-pricing dark:border-dark-3 dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-[50px]">
                <span className="mb-3 block text-lg font-semibold text-primary">
                  {item.type}
                </span>
                <h2 className="mb-5 text-[42px] font-bold text-casadarkshade ">
                  {item.price}
                  <span className="text-base font-medium text-body-color dark:text-dark-6">
                    / {item.subscription}
                  </span>
                </h2>
                <p className="mb-8 border-b border-stroke  pb-8 text-base text-body-color dark:border-dark-3 dark:text-dark-6">
                  {item.description1}
                  <br />
                  {item.description2}
                </p>

                {item.features.map((item, index) => (
                  <div className="mb-9 flex flex-col gap-[14px]" key={index}>
                    <p className="text-base text-body-color dark:text-dark-6">
                      {item}
                    </p>
                  </div>
                ))}

                <div>
                  <span className="absolute right-0 top-7 z-[-1]">
                    <svg
                      width={77}
                      height={172}
                      viewBox="0 0 77 172"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx={86}
                        cy={86}
                        r={86}
                        fill="url(#paint0_linear)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1={86}
                          y1={0}
                          x2={86}
                          y2={172}
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#3056D3" stopOpacity="0.09" />
                          <stop
                            offset={1}
                            stopColor="#C4C4C4"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  <span className="absolute right-4 top-4 z-[-1]">
                    <svg
                      width={41}
                      height={89}
                      viewBox="0 0 41 89"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="38.9138"
                        cy="87.4849"
                        r="1.42021"
                        transform="rotate(180 38.9138 87.4849)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="74.9871"
                        r="1.42021"
                        transform="rotate(180 38.9138 74.9871)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="62.4892"
                        r="1.42021"
                        transform="rotate(180 38.9138 62.4892)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="38.3457"
                        r="1.42021"
                        transform="rotate(180 38.9138 38.3457)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="13.634"
                        r="1.42021"
                        transform="rotate(180 38.9138 13.634)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="50.2754"
                        r="1.42021"
                        transform="rotate(180 38.9138 50.2754)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="26.1319"
                        r="1.42021"
                        transform="rotate(180 38.9138 26.1319)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="38.9138"
                        cy="1.42021"
                        r="1.42021"
                        transform="rotate(180 38.9138 1.42021)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="87.4849"
                        r="1.42021"
                        transform="rotate(180 26.4157 87.4849)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="74.9871"
                        r="1.42021"
                        transform="rotate(180 26.4157 74.9871)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="62.4892"
                        r="1.42021"
                        transform="rotate(180 26.4157 62.4892)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="38.3457"
                        r="1.42021"
                        transform="rotate(180 26.4157 38.3457)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="13.634"
                        r="1.42021"
                        transform="rotate(180 26.4157 13.634)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="50.2754"
                        r="1.42021"
                        transform="rotate(180 26.4157 50.2754)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="26.1319"
                        r="1.42021"
                        transform="rotate(180 26.4157 26.1319)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="26.4157"
                        cy="1.4202"
                        r="1.42021"
                        transform="rotate(180 26.4157 1.4202)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="87.4849"
                        r="1.42021"
                        transform="rotate(180 13.9177 87.4849)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="74.9871"
                        r="1.42021"
                        transform="rotate(180 13.9177 74.9871)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="62.4892"
                        r="1.42021"
                        transform="rotate(180 13.9177 62.4892)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="38.3457"
                        r="1.42021"
                        transform="rotate(180 13.9177 38.3457)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="13.634"
                        r="1.42021"
                        transform="rotate(180 13.9177 13.634)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="50.2754"
                        r="1.42021"
                        transform="rotate(180 13.9177 50.2754)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="26.1319"
                        r="1.42021"
                        transform="rotate(180 13.9177 26.1319)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="13.9177"
                        cy="1.42019"
                        r="1.42021"
                        transform="rotate(180 13.9177 1.42019)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="87.4849"
                        r="1.42021"
                        transform="rotate(180 1.41963 87.4849)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="74.9871"
                        r="1.42021"
                        transform="rotate(180 1.41963 74.9871)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="62.4892"
                        r="1.42021"
                        transform="rotate(180 1.41963 62.4892)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="38.3457"
                        r="1.42021"
                        transform="rotate(180 1.41963 38.3457)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="13.634"
                        r="1.42021"
                        transform="rotate(180 1.41963 13.634)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="50.2754"
                        r="1.42021"
                        transform="rotate(180 1.41963 50.2754)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="26.1319"
                        r="1.42021"
                        transform="rotate(180 1.41963 26.1319)"
                        fill="#3056D3"
                      />
                      <circle
                        cx="1.41963"
                        cy="1.4202"
                        r="1.42021"
                        transform="rotate(180 1.41963 1.4202)"
                        fill="#3056D3"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div
          className={`${styles["mt-20"]} ${styles["mb-20"]} ${styles.flex} ${styles["justify-center"]} ${styles["items-center"]}`}
        >
          <div className="">
            <div
              className={`${styles.flex} ${styles["flex-col"]} ${styles["items-center"]}`}
            >
              <h2
                className={`${styles["font-bold"]} ${styles["text-slate-700"]} ${styles["text-5xl"]} ${styles["mt-5"]} ${styles["tracking-tight"]}`}
              >
                FAQ
              </h2>
              <p
                className={`${styles["text-whyus"]} text-opacity-70 ${styles["text-xl"]} ${styles["mt-3"]}`}
              >
                Frequently asked questions
              </p>
            </div>
            <div
              className={`${styles.grid} ${styles["divide-y"]} ${styles["divide-neutral-200"]} ${styles["max-w-xl"]} ${styles["mx-auto"]} ${styles["mt-8"]}`}
            >
              {faqItems.map((item, index) => (
                <div
                  key={index}
                  className={`${styles["py-5"]} ${styles["px-4"]}`}
                >
                  <details className={styles.group}>
                    <summary
                      className={`${styles.flex} ${styles.summary} ${styles["justify-between"]} ${styles["items-center"]} ${styles["font-medium"]} ${styles["cursor-pointer"]} ${styles["list-none"]}`}
                    >
                      <span
                        className={`${styles["text-xl"]} ${styles["text-whyus"]}`}
                      >
                        {item.question}
                      </span>
                      <span className={styles.transition}>
                        <svg
                          className={
                            styles["svg-icon"] + " " + styles["summary-icon"]
                          }
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 9l6 6 6-6"></path>
                        </svg>
                      </span>
                    </summary>
                    <p
                      className={`${styles["text-whyus"]} text-opacity-70 ${styles["text-lg"]} ${styles["mt-3"]} ${styles["group-open:animate-fadeIn"]}`}
                    >
                      {item.answer}
                    </p>
                  </details>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="my-20 px-4">
          <div className="flex flex-col items-center">
            <div className="text-center">
              <p className="text-lg font-medium text-gray-600 font-pj">
                4000+ people have benefitted from our tailored care plans
              </p>
              <h2 className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj">
                Our happy clients say about us
              </h2>
            </div>

            <div className="relative mt-10 md:mt-24 md:order-2">
              <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
                <div
                  className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter"
                  style={{
                    background:
                      "linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)",
                  }}
                ></div>
              </div>

              <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
                <div className="flex flex-col overflow-hidden shadow-xl">
                  <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div className="flex-1">
                      <div className="flex items-center">
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <blockquote className="flex-1 mt-8">
                        <p className="text-lg leading-relaxed text-gray-900 font-pj">
                          {testimonialItems[0].quote}
                        </p>
                      </blockquote>
                    </div>

                    <div className="flex items-center mt-8">
                      <img
                        className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                        src={testimonialItems[0].avatar}
                        alt=""
                      />
                      <div className="ml-4">
                        <p className="text-base font-bold text-gray-900 font-pj">
                          {testimonialItems[0].name}
                        </p>
                        <p className="mt-0.5 text-sm font-pj text-gray-600">
                          {testimonialItems[0].title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col overflow-hidden shadow-xl">
                  <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div className="flex-1">
                      <div className="flex items-center">
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <blockquote className="flex-1 mt-8">
                        <p className="text-lg leading-relaxed text-gray-900 font-pj">
                          {testimonialItems[1].quote}
                        </p>
                      </blockquote>
                    </div>

                    <div className="flex items-center mt-8">
                      <img
                        className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                        src={testimonialItems[1].avatar}
                        alt=""
                      />
                      <div className="ml-4">
                        <p className="text-base font-bold text-gray-900 font-pj">
                          {testimonialItems[1].name}
                        </p>
                        <p className="mt-0.5 text-sm font-pj text-gray-600">
                          {testimonialItems[1].title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col overflow-hidden shadow-xl">
                  <div className="flex flex-col justify-between flex-1 p-6 bg-white lg:py-8 lg:px-7">
                    <div className="flex-1">
                      <div className="flex items-center">
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                        <svg
                          className="w-5 h-5 text-[#FDB241]"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <blockquote className="flex-1 mt-8">
                        <p className="text-lg leading-relaxed text-gray-900 font-pj">
                          {testimonialItems[2].quote}
                        </p>
                      </blockquote>
                    </div>

                    <div className="flex items-center mt-8">
                      <img
                        className="flex-shrink-0 object-cover rounded-full w-11 h-11"
                        src={testimonialItems[2].avatar}
                        alt=""
                      />
                      <div className="ml-4">
                        <p className="text-base font-bold text-gray-900 font-pj">
                          {testimonialItems[2].name}
                        </p>
                        <p className="mt-0.5 text-sm font-pj text-gray-600">
                          {testimonialItems[2].title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-20 md:mt-40 lg:mt-80">
          <div className="flex justify-center items-end mt-10 px-3 lg:-mt-60 lg:mr-6">
            <div className="bg-[rgba(0,88,127,1)] p-8 max-w-[400px] md:max-w-[600px] xl:rounded-5xl xl:px-8 xl:py-10 relative w-full overflow-hidden rounded-3xl">
              <h2 className="text-center text-lg text-white">
                <strong>Begin Your Recovery Now!</strong>
              </h2>
              <div className="flex flex-col justify-center items-center">
                <p className="text-center text-dimWhite pt-2.5 pb-5">
                  Sit back and let us get back to you with a follow-up and a
                  personalized recovery plan.
                </p>
                <button
                  id="brand-cta"
                  className="px-5 py-2.5 bg-dimWhite rounded-xl font-bold text-casadarkshade"
                >
                  I am Interested
                </button>
              </div>
              <img
                src="https://casamed.in/api/static-serve/rU8!kt-47W/c@dNsj9/quote.svg"
                alt="camp-2"
                className="absolute -right-6 bottom-4 w-[140px] lg:bottom-10 xl:-right-8 xl:w-[186px] 3xl:right-0 h-219px 3xl:h-[250px]"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Brand;
