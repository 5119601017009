import { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

import Button from "./Button";
import logo from "../Assets/Footer/logofooter.svg";

import { NavLink, Link } from "react-router-dom";
import { debounce } from "../Utils/helpers";

export default function Nav() {
  const [nav, setNav] = useState(false);

  const navToogle = () => {
    setNav(!nav);
  };

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = debounce(() => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > 70) ||
        currentScrollPos < 10
    );

    setPrevScrollPos(currentScrollPos);
  }, 100);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  const navbarStyles = {
    position: "fixed",
    height: "60px",
    textAlign: "center",
    transition: "top 0.6s",
  };

  //     const [stickyClass, setStickyClass] = useState('relative');

  //     useEffect(() => {
  //     window.addEventListener('scroll', stickNavbar);

  //     return () => {
  //       window.removeEventListener('scroll', stickNavbar);
  //     };
  //   }, []);

  //   const stickNavbar = () => {
  //     if (window !== undefined) {
  //       let windowHeight = window.scrollY;
  //       windowHeight > 500 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('relative');
  //     }
  //   };

  return (
    <nav
      style={{ ...navbarStyles, top: visible ? "0" : "-60px", zIndex: 1000 }}
      className={`flex w-screen justify-between h-16  opacity-100 bg-livesesgrad relative items-center stickynav px-8 lg:px-20`}
    >
      <div>
        <Link to="/">
          <img src={logo} alt="logo" color="#fff" />
        </Link>
      </div>

      <ul className="hidden md:flex text-light items-center ">
        <li className="hover:border-b border-secondary  cursor-pointer  px-3">
          <NavLink to="/">Home</NavLink>
        </li>
        <li className="hover:border-b border-secondary cursor-pointer px-3">
          <NavLink
            to="/product"
            className={({ isActive }) => (isActive ? "link-active" : "")}
          >
            Product
          </NavLink>
        </li>
        <li className="hover:border-b border-secondary cursor-pointer px-3">
          <NavLink
            to="/partners"
            className={({ isActive }) => (isActive ? "link-active" : "")}
          >
            Partners
          </NavLink>
        </li>
        <li className="hover:border-b border-secondary cursor-pointer px-3">
          <NavLink
            to="/pricing"
            className={({ isActive }) => (isActive ? "link-active" : "")}
          >
            Corporate Pricing
          </NavLink>
        </li>
        <li className="hover:border-b border-secondary cursor-pointer px-3">
          <NavLink
            to="/blog"
            className={({ isActive }) => (isActive ? "link-active" : "")}
          >
            Blog
          </NavLink>
        </li>
        {/* <li className='hover:border-b border-secondary cursor-pointer px-3'>
                    <NavLink 
                        to= "/contactus" 
                        className={({ isActive }) => (isActive ? "link-active" : "")}>
                            Connect
                    </NavLink>
                </li> */}
        <li className="hover:border-b border-secondary cursor-pointer px-3">
          <NavLink
            to="/about"
            className={({ isActive }) => (isActive ? "link-active" : "")}
          >
            About
          </NavLink>
        </li>
        <li className="flex gap-3 items-center pl-3">
          <NavLink
            to="/contactus"
            className={({ isActive }) => (isActive ? "link-active" : "")}
          >
            <Button>Connect</Button>
          </NavLink>
        </li>
      </ul>

      <div onClick={navToogle} className="md:hidden">
        <FaBars size={24} />
      </div>

      <nav
        id="mobileNav"
        style={{ zIndex: 1000, overflowY: "auto" }}
        className={`fixed h-[90vh] duration-300 text-lg md:hidden top-0 bg-primary opacity-100     shadow w-full   ${
          nav ? "right-0" : "-right-full"
        }`}
      >
        <div className="pt-40 ">
          <div
            onClick={navToogle}
            className="absolute right-8 top-8 md:hidden text-light"
          >
            <FaTimes size={24} />
          </div>
          <ul className="flex-col text-light gap-8 flex items-center">
            <li className="hover:border-b border-secondary cursor-pointer">
              <Link to="/">Home</Link>
            </li>

            <li className="hover:border-b border-secondary cursor-pointer">
              <Link to="/product">Product</Link>
            </li>
            <li className="hover:border-b border-secondary cursor-pointer">
              <Link to="/partners">Partners</Link>
            </li>
            <li className="hover:border-b border-secondary cursor-pointer">
              <Link to="/pricing">Corporate Pricing</Link>
            </li>
            <li className="hover:border-b border-secondary cursor-pointer">
              <Link to="/blog">Blog</Link>
            </li>
            {/* <li className='hover:border-b border-secondary cursor-pointer'><Link to = "/contactus">Connect</Link></li> */}
            <li className="hover:border-b border-secondary cursor-pointer">
              <Link to="/about">About</Link>
            </li>
            <li className="flex gap-3 items-center">
              <Link to="/contactus">
                <Button>Connect</Button>
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </nav>
  );
}
