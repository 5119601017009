import React from "react";
import amulya from "../../Assets/AboutViews/Mentors/amulya.png";
import sarsij from "../../Assets/AboutViews/Mentors/sarsij.png";
import amita from "../../Assets/AboutViews/Mentors/amita.jpeg";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";
import Loading from "../../Components/Loading/loading";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function MentorsTest() {
  const [mentors, setMentors] = useState([]);
  const [loading, setLoading] = useState(true);

  useState(() => {
    const getMentors = async () => {
      await axios
        .get(`/api/mentors`, {
          headers: {
            Authorization: localStorage.getItem("token"),
            auth: {
              user: JSON.stringify(localStorage.getItem("user")),
            },
          },
        })
        .then((res) => {
          setMentors(res.data.data);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    };
    getMentors();
  }, []);

  return (
    <div className="md:pt-20 md:pb-14 xxs:pt-12 xxs:pb-8">
      <div className="lg:px-16 xl:px-32">
        <h1 className="xxs:text-[30px] sm:text-[50px] text-casashade2 tracking-wider font-bold xxs:text-center md:text-left  ">
          Our <br className="xxs:hidden md:visible" />
          <span className="underline underline-offset-8 decoration-[#00CC6D]">
            M
          </span>
          entors
        </h1>
      </div>

      <section className="text-casablue xxs:py-8 md:py-16 xxs:px-0 lg:px-16 xl:px-32 ">
        <div className="sm:grid xxs:flex xxs:flex-col xxs:justify-center xxs:items-center sm:grid-cols-3 justify-center xxs:gap-16 sm:gap-16 items-start">
          {loading ? (
            <Loading />
          ) : (
            mentors.map((mentor, slug) => (
              <div key={slug} className="flex-col justify-center items-center">
                <Link
                  to={`/about/mentors/${mentor.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="fullwrap">
                    <img
                      src={mentor.photoUrl}
                      alt=""
                      className="object-cover    rounded-t-md  "
                    />
                    <div className="fullcap   ">
                      <h1 className="text-ellipsis line-clamp-4 ">
                        {mentor.bio.replace(/<br\/>/g, "\n")}
                      </h1>
                      <br />
                      <div className="flex justify-center items-center">
                        <button className="flex flex-row items-center justify-center ">
                          <InfoIcon className="mr-1 " /> Know More
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex border-slate-300  border-[3px] border-t-0 p-0 justify-center bg-white  items-center font-semibold text-casashade2 rounded-b-md">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingX: "16px",
                          paddingY: "16px",
                        }}
                        className="text-center"
                      >
                        <strong>
                          <h1 className="py-2 ">{mentor.name}</h1>
                        </strong>
                        <h1 className="pb-2">{mentor.occupation}</h1>
                        <h1 className="italic">{mentor.exp1}</h1>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingX: "16px",
                          paddingY: "16px",
                        }}
                      >
                        <h1 className="pt-3 text-[14px] leading-tight pb-2 text-center font-thin ">
                          {mentor.exp2}
                          <br />
                          {mentor.exp3}
                          <br />
                          <br className="sm:visible xl:hidden" />
                        </h1>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
}

export default MentorsTest;
