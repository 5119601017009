import { motion } from 'framer-motion'
import fplans from "../../Assets/ProductViews/ProdFeatureList/fplans.svg"
import ftools from "../../Assets/ProductViews/ProdFeatureList/ftools.svg"
import flibrary from "../../Assets/ProductViews/ProdFeatureList/flibrary.svg"
import fcustomize from "../../Assets/ProductViews/ProdFeatureList/fcustomize.svg"
import ftelemed from "../../Assets/ProductViews/ProdFeatureList/ftelemed.svg"
import fdashboard from "../../Assets/ProductViews/ProdFeatureList/fdashboard.svg"
import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



const imageVariant = {
    start: {
        rotate: "-180deg"
    },
    end: {
        rotate: 0,
        transition: {
            duration: 1
        }
    }
}

const primarysty = {
    color: "#016D8D",
    fontWeight: "700",
    fontSize: "17px",
    fontStyle: "Poppins",

}
const secondarysty = {
    color: "#016D8D",
    fontWeight: "400",
    fontSize: "15px",
    fontStyle: "Poppins",

}

function ProdFeatureList(){
  return (
    <div>
        
        <motion.div
            variants={imageVariant}
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ type: "spring", damping: 6, delay: 0.8, duration: 1 }}
            className='md:py-28 xxs:py-28 xxs:px-6 md:px-32 '>
                <h1 className='text-center -mt-14 xxs:pb-10 md:pb-20 text-casashade2 md:text-[48px] xxs:text-[36px] font-semibold md:px-20 xxs:px-6'>
                    6 Scientifically Validated Offerings to help take care of your Pain Management Needs
                </h1>

                <Box className='md:px-12 ' sx={{ width: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <nav aria-label="main mailbox folders">
                    <List className='xxs:grid xxs:grid-cols-1 md:grid md:grid-cols-3 justify-center items-center'>
                    
                    <ListItem disablePadding >
                        <ListItemButton>
                        <ListItemIcon>
                            <img src={fplans} alt=""/>
                        </ListItemIcon>
                        <ListItemText className='xxs:pl-6 md:px-6' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Common Ailment Plans"
                            secondary="Built in Physiotherapy plans for some common ailments" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img src={ftools} alt=""/>
                        </ListItemIcon>
                        <ListItemText className='xxs:pl-6 md:px-6' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Diagnostic Tools"
                            secondary="Diagnostic tools designed by experts for accurate identification of problem areas" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img src={fdashboard} alt=""/>
                        </ListItemIcon>
                        <ListItemText className='xxs:pl-6 md:px-6' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Performance Dashboard"
                            secondary="Continuous and real time monitoring of progress through recovery dashboard" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img src={flibrary} alt=""/>
                        </ListItemIcon>
                        <ListItemText className='xxs:pl-6 md:px-6' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Exercise Library"
                            secondary="Large exercise database with high quality video and audio guidance" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img src={fcustomize} alt=""/>
                        </ListItemIcon>
                        <ListItemText className='xxs:pl-6 md:px-6' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Customizable Plans"
                            secondary="Custom tailored plan as per your needs" 
                        />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                        <ListItemButton>
                        <ListItemIcon>
                            <img src={ftelemed} alt=""/>
                        </ListItemIcon>
                        <ListItemText className='xxs:pl-6 md:px-6' 
                            primaryTypographyProps={{ style: primarysty }} 
                            secondaryTypographyProps={{ style: secondarysty }} 
                            primary="Telemedicine"
                            secondary="Online consultation from best doctors and virtual assistance from top certified physiotherapists" 
                        />
                        </ListItemButton>
                    </ListItem>
                    
                    </List>
                </nav>
                
                
                
                </Box>


        </motion.div>
    </div>
  )
}

export default ProdFeatureList

