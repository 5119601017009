import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import { Theme } from "../Utils/colorSchema";

const MPTComponent = () => {
  return (
    <div className="m-md-5 my-4 pt-3">
      <h1 className="text-center md:pt-16 xxs:pt-6 font-semibold text-xl pb-2">
        Muscle Pain Therapy
      </h1>
      <div className="mx-5">
        <p>
          Everyone at some point in their life has experienced muscle pain. This
          pain may vary from mild to severe. While some can simply be treated
          with the help of regular exercise and physical therapy, others could
          get slightly tricky needing intensive medical care.
        </p>
        <p>
          Muscle tissues are present throughout the body, hence muscle pain can
          occur because of multiple reasons, for example, excessive exercise,
          stretching, lifting of heavy objects, excessive walking, etc. Most
          muscle pains are easily curable with the help of therapy.
        </p>
        <p>
          Muscle pain therapy allows you to get back on your feet again and
          reduces the pain. With the help of{" "}
          <strong>muscle pain therapy</strong>, you can make your muscles
          stronger and more flexible. Therapy is advised by almost every doctor
          because it is a natural way of treatment that doesn’t involve any
          surgery or medications.
        </p>
        <p>
          At Casamed, there are different therapists available for different
          types of injury and pain. Each one is well qualified and trained in
          treating people with muscles pain injuries. They have years of
          experience in treating people and have received a lot of recognition
          and success. Casamed helps people get back to their routine life by
          giving them the best treatment. With their knowledge and experience
          related to <strong>muscle pain therapy</strong>, our therapists make
          sure that you don’t have to undergo any unnecessary surgery. At
          Casamed we hold self-esteem in high regards therefore our therapists
          motivate the patients to perform their best which helps them in
          reducing the muscles pain. Our therapists try to build a healthy and
          positive relationship with the patients which results in better
          improvement of their health. They have a deep knowledge of
          physiotherapy, exercise physiology, neuroscience, and other fields of
          science which is applied to treating people.
        </p>
        <p>
          At Casamed, we also provide online consultation to our patients if
          they are unable to come to the clinic. Our therapists use all kinds of
          techniques to treat the patients like manual therapy techniques,
          automatic machine therapy techniques, etc. We are always present for
          our patients and we always strive for their well-being. We also have
          an AI enabled diagnostic engine that provides 100% true and verified
          results for the patients.
        </p>
        <h2>Why Choose Casamed for Muscle Pain Therapy</h2>
        <p>
          <strong>Best Therapist - </strong>We have the best team of the
          therapists to treat the patients. They are highly experienced and
          qualified in all the required fields for treating the injury of the
          people. They are trained to handle all kinds of situations.
        </p>
        <p>
          <strong>Emergency Services - </strong>Casamed provides emergency
          services to the patients. We are always ready to provide service
          anytime anywhere. Our therapist also gives online consultation through
          calls and video conferences to the patients. Whatever the situation
          is, we diagnose the problem and offer relief solutions to improve the
          situation of the patients.
        </p>
        <p>
          <strong>Equipped Tools and Machine - </strong>We have all kinds of
          machines and tools used for treating the patients. The machines are of
          the latest technology which helps in providing relief to the patients
          at a fast rate.
        </p>
        <p>
          <strong>Better Relationship - </strong>We treat patients like part of
          the family, build strong connections to create a safe, healthy and
          happy environment. This encourages and motivates them to recover fast.
        </p>
      </div>
    </div>
  );
};

const MusclePainTherapy = () => {
  return (
    <div style={{ backgroundColor: Theme.bgcolor }}>
      <Nav />
      <Helmet>
        {/* Meta Title and Description */}
        <title>Muscle Pain Therapy | Consult Expert Doctors @CASAMED</title>
        <meta
          name="description"
          content="Get rid of your muscle pain and also make the muscles more stronger and flexible with the help of the best therapists at CASAMED. Consult today!"
        />
        {/* Meta Keywords */}
        <meta name="keywords" content="muscle pain therapy" />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/muscle-pain-therapy" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Muscle Pain Therapy | Consult Expert Doctors @CASAMED"
        />
        <meta property="og:site_name" content="Casamed" />
        <meta
          property="og:url"
          content=" https://casamed.in/muscle-pain-therapy "
        />
        <meta
          property="og:description"
          content="Get rid of your muscle pain and also make the muscles more stronger and flexible with the help of the best therapists at CASAMED. Consult today!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://casamed.in/static/media/casamed-
  logo.03879803.png"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Muscle Pain Therapy | Consult Expert Doctors @CASAMED",
            url: " https://casamed.in/muscle-pain-therapy ",
            logo: {
              "@type": "ImageObject",
              url: "https://casamed.in/static/media/casamed-logo.03879803.png",
            },
            description:
              "Get rid of your muscle pain and also make the muscles more stronger and flexible with the help of the best therapists at CASAMED. Consult today!",
          })}
        </script>
      </Helmet>
      <MPTComponent />
      <Footer />
    </div>
  );
};

export default MusclePainTherapy;
