import React from 'react'

import { items } from '../../Constants'
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/navigation";

import { Navigation, Autoplay, Keyboard  } from "swiper";


function ProdFeaturesSwiper(){
  return (
    <div id='prodfeatures' className='px-10 py-10'>
        <h1 className='xxs:pb-8 xxs:pt-4     md:py-16 text-center text-[22px] sm:text-[34px] md:text-[54px] xl:text-[54px] xxs:tracking-wide md:tracking-wider xxs:leading-[40px]  text-light drop-shadow-lg rounded-xl font-bold font-poppins xxs:text-[44px] '>
            <span className='decoration-[#02B9ED] underline underline-offset-8 mr-1'>P</span>roduct Features
        </h1>   
        <Swiper 
            navigation={true}
            loop={true} 
            autoplay={{
                delay: 3000,
                disableOnInteraction: false
                }}
            keyboard={{
                enabled: true,
                }}
            modules={[Navigation, Autoplay, Keyboard]} className="mySwiper bg-transparent md:w-[90%]"
        >
        {items.map((item, index) => (
        <SwiperSlide key={index}>
            <div  className='flex md:flex-row xxs:flex-col justify-between items-center  xxs:px-2 md:px-20'>
                <div id='prodfeatures' className=' xxs:w-[70%] md:w-1/2 flex justify-center items-center xxs:pb-4' >
                    <img src={item.image} className="xxs:w-full md:w-[55%] xl:w-[50%]" alt="" />
                </div>
                <div className='flex flex-col justify-center text-center items-center xxs:w-[100%] md:w-[50%]'>
                    <h1 className='font-poppins font-bold xxs:text-center md:text-center xxs:pb-4 sm:pb-16 drop-shadow-md xxs:text-[22px] text-white md:text-[38px]'>
                    <span className='underline underline-offset-4 decoration-[#02B9ED] capitalize mr-0.5'>{item.heading[0]}</span><span>{item.heading.slice(1)}</span>
                    </h1>
                    <ul className="xxs:pb-0  xxs:text-center md:text-left font-poppins xxs:text-[14px] md:text-[24px] font-semibold text-white">
                        <li>
                            <span className="pb-2 xxs:pb-1 "> 
                                {item.text1} 
                            </span>
                        </li>
                            <br/>
                        <li>  
                            <span className="pt-2" >
                                {item.text2}
                            </span>
                        </li>
                        
                    </ul>
                </div>
            </div>
        </SwiperSlide>
        ))}
        </Swiper>
    </div>
  )
}

export default ProdFeaturesSwiper