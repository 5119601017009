import React from "react";
import Nav from "../Components/Nav";
import Footer from "../Components/Footer";
import ProdHeader from "../Views/ProductViews/ProductHeader";
import ProdFeatureList from "../Views/ProductViews/ProdFeatureList";
import ProdFeaturesSwiper from "../Views/ProductViews/ProdFeaturesSwiper";
import Shop from "../Views/ProductViews/Shop";
import Live from "../Views/ProductViews/Live";
import Persona from "../Views/HomeViews/Persona";

import { Fade } from "react-awesome-reveal";
import { Helmet } from "react-helmet";
import casasvg from "../Assets/casamed.svg";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import { VideoPlayer } from "../Components/VideoPlayer";

function Product() {
  return (
    <div className="w-full">
      <Helmet>
        {/* Meta title and Description */}
        <title>Product | CasaMed</title>
        <meta
          name="description"
          content="Discover CasaMed - Your Ultimate Health Companion. Shop now for top-quality wellness products & elevate your lifestyle. Feel better, live better!"
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="muscle pain therapy,spondylosis therapy,neck pain therapy,shoulder pain therapy,cervical therapy"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href="https://casamed.in/product" />
        {/* Open Graph */}
        <meta property="og:title" content="Product | CasaMed" />
        <meta name="ROBOTS" content="INDEX, FOLLOW" />
        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content=" https://casamed.in/product" />
        <meta
          property="og:description"
          content="Discover CasaMed - Your Ultimate Health Companion. Shop now for top-quality wellness products & elevate your lifestyle. Feel better, live better!"
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={casasvg} />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Product | CasaMed",
            url: " https://casamed.in/product",
            logo: {
              "@type": "ImageObject",
              url: casasvg,
            },
            description:
              "Discover CasaMed - Your Ultimate Health Companion. Shop now for top-quality wellness products & elevate your lifestyle. Feel better, live better!",
          })}
        </script>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "VideoObject",
            name: "CasaMed | Say Goodbye to Pain with our App - Transforming the Doctor-Patient Relationship!",
            description:
              "Do not let the burden of physical pain impact your mental well being. CasaMed is here to assist and hand hold you through out the journey of pain relief.  Our innovative app provides comprehensive support for both patients and doctors, guiding patients through their journey of pain relief and equipping doctors with invaluable insights to help tailor treatment plans to individual needs.  With our app, patients can track their symptoms, monitor their progress, and receive personalized recommendations for managing their pain.  Meanwhile, doctors can access detailed reports and analytics to gain a deeper understanding of their patients' conditions and make more informed treatment decisions.  Say goodbye to the frustration and uncertainty of pain management - try our app today and experience the future of pain relief!",
            thumbnailUrl: "https://i.ytimg.com/vi/sYg__GHnjng/default.jpg",
            uploadDate: "2023-04-22T08:46:26Z",
            duration: "PT1M2S",
            embedUrl: "https://www.youtube.com/embed/sYg__GHnjng",
            interactionCount: "90",
          })}
        </script>
      </Helmet>
      <div className="stickynav">
        <Nav />
      </div>
      <div className="xxs:invisible sm:visible">
        <div className="floating-container-call">
          <a href="tel:03340585240">
            <FaPhone color="#fff" size={30} />
          </a>
        </div>

        <div className="floating-container-whatsapp">
          <a href="https://api.whatsapp.com/send?phone=918235858626">
            <FaWhatsapp color="#fff" size={30} />
          </a>
        </div>
      </div>
      <div className="og--bg--grad pt-10 ">
        <ProdHeader />
        <ProdFeatureList />
      </div>
      <div className="bg-featureshade">
        <ProdFeaturesSwiper />
      </div>
      <div className="px-[5%] py-[5%]">
        <VideoPlayer />
      </div>
      <div className="bg-end text-light xxs:py-14 md:py-24">
        <Shop />
        <Fade direction="right"></Fade>
        <Live />
        <Persona />

        <Footer />
      </div>
    </div>
  );
}

export default Product;
