import React from "react";
import startupind from "../../Assets/HomeViews/Awards/startupind.png";
import impact from "../../Assets/HomeViews/Awards/impact.png";
import scalability from "../../Assets/HomeViews/Awards/scalability.png";

function Awards() {
  return (
    <section className="xxs:px-6 md:px-28 md:mx-27 xl:px-36 xl:mx-28">
      <div className=" ">
        <ul className="flex xxs:flex-col md:flex-row gap-12 justify-center ">
          <li className="flex flex-col justify-between">
            <img src={startupind} alt="" className="rounded-md" />
            <div className="text-casashade2 text-[20px] font-semibold flex flex-col pt-6 justify-center items-center">
              <h1 className="text-md">Startup India Recognition</h1>
              <h1 className="text-md">Startup Seed-Fund</h1>
            </div>
          </li>
          <li className="flex flex-col justify-between">
            <img src={scalability} alt="" className="rounded-md" />
            <div className="text-casashade2 text-[20px] font-semibold flex flex-col pt-6 justify-center items-center">
              <h1 className="text-md">Best StartUp Award</h1>
              <h1 className="text-md">(Scalability)</h1>
            </div>
          </li>
          <li className="flex flex-col ">
            <img src={impact} alt="" className="rounded-md" />
            <div className="text-casashade2 text-[20px] font-semibold flex flex-col pt-6 justify-center items-center">
              <h1 className="text-md">Best StartUp Award</h1>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default Awards;
