import React from 'react'
import Nav from '../Components/Nav'
import Footer from '../Components/Footer'
import verified from "../Assets/BlogViews/verified.png"
import {Image } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import arth1 from "../Assets/Arthiritis/arth1.jpg"


const KneeOsteoarthritis = () => {

    const tocItems = [
        {
            id: "Heading-1",
            text: "Introduction",
        },
        {
            id: "Heading-2",
            text: "What is Arthritis",
        },
        {
            id: "Heading-3",
            text: "Arthritis Types: What Are They?",
        },
        {
            id: "Heading-4",
            text: "How Common is Arthritis?",
        },
        {
            id: "Heading-5",
            text: "What are the Causes of Arthritis?",
        },
        {
            id: "Heading-6",
            text: "Symptoms of Arthritis",
        },
        {
            id: "Heading-7",
            text: "Is Arthritis a Serious Disease?",
        },
        {
            id: "Heading-8",
            text: "Treatment of Arthritis",
        },
        {
            id: "Heading-9",
            text: "Does Arthritis Require Surgery?",
        },
        {
            id: "Heading-10",
            text: "How can I make Living with Arthritis Easier?",
        },
        {
            id: "Heading-11",
            text: "How can CasaMed Help with this?",
        },
        {
            id: "Heading-12",
            text: "FAQs",
        },
        {
            id: "Heading-13",
            text: "Final Thoughts",
        },
        {
            id: "Heading-14",
            text: "References",
        },

    ]


    const faqs = [
        {
            question: "What is the main cause of arthritis?",
            answer: "There are over 100 different types of arthritis which are caused due to various reasons. Arthritis is an inflammation of the joints that may affect one joint or multiple joints. Some types of arthritis are caused due to excessive wear-and-tear on certain joints while some other types are caused due to an immune system disorder that causes the body to attack its own joints."
        },
        
        {
            question: "Is arthritis a serious disease?",
            answer: "Arthritis is a very serious disease. It is the leading cause of disability worldwide. If left untreated it can lead to serious and sometimes irreversible damage to your joints. Some types of arthritis can also affect your skin, organs and other areas of your body."
        },
        {
            question: "What's the first sign of arthritis?",
            answer: "Generally, arthritis causes pain and inflammation. It is also characterized by stiffness, especially after periods of inactivity or overuse. Symptoms like these may also be accompanied by tenderness or warmth. Arthritis can also cause redness and heat around joints."
        },
        {
            question: "Do certain types of weather make arthritis worse?",
            answer: "Yes, People often find arthritis is worse in humid or cold weather. It could be because people are less active during rainy and winter seasons. In some studies, the pressure of the air around us and cold and damp weather have been found to aggravate arthritis."
        },

    ]

  return (
    <div className='bg-casalight'>
        <div className='md:pb-[9%] xxs:pb-[25%] ss:pb-[20%]'>
            <Nav />
        </div>

        <Helmet>
        {/* Meta title and Description */}
        <title>
        Guide to Arthritis: Signs, Causes, Types, and Treatment Methods
        </title>
        <meta
          name="description"
          content="Understanding of Osteoarthritis knee causes, symptoms, diagnosis & osteoarthritis knee treatment."
        />
        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="muscle pain therapy,arthiritis therapy, knee pain"
        />
        {/* Canonical Tag */}
        <link rel="canonical" href= {`https://casamed.in/blog/arthiritis-complete-guide`} />
        {/* Open Graph */}
        <meta
          property="og:title"
          content="Knee Osteoarthritis: Causes, Symptoms, Diagnosis & Treatments"
        />

        <meta property="og:site_name" content="Casamed" />
        <meta property="og:url" content="https://casamed.in/blog/arthirtis-complete-guide" />
        <meta
          property="og:description"
          content="Learn about Arthritis: its Signs, Causes, Types, and Treatment Methods. Get expert advice on managing this common condition. Read now!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content= "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F5_5_11zon.jpg?alt=media&token=4260e522-bc76-47aa-85ba-af20e856c4e4"
        />
        {/* Schema MarkUp */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            name: "Guide to Arthritis: Signs, Causes, Types, and Treatment Methods",
            url: `https://casamed.in/blog/osteoarthritis-knee-complete-guide`,
            logo: {
              "@type": "ImageObject",
              url: "https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F5_5_11zon.jpg?alt=media&token=4260e522-bc76-47aa-85ba-af20e856c4e4",
            },
            description: "Learn about Arthritis: its Signs, Causes, Types, and Treatment Methods. Get expert advice on managing this common condition. Read now!",
          })}
        </script>

        
      </Helmet>

            <h1 className='text-center md:text-5xl xxs:md:text-3xl xxs:text-2xl font-bold text-slate-800 xxs:pb-4 md:pb-8'>Arthritis: Signs, Causes, Types, and Treatment Methods</h1>


            <div className="xxs:px-0 md:px-[20%] xxs:pt-2 sm:pt-4 pb-8 px-4">
            <div className='xxs:py-2 xxs:px-2 md:px-0 md:py-4 flex justify-center items-center'>
                <img src={arth1} className='rounded-lg shadow-xl ' />
            </div>
              
                  <div
                    className="flex justify-center items-center pt-4 "
                  >
                    <div>
                    <div className=" flex flex-row gap-2 items-center" >
                      <Image className="xxs:w-[8%] md:w-[4%]" src={verified} />
                      
                      <strong className='xxs:text-sm md:text-lg'>
                        WRITTEN BY :
                      </strong>
                    </div>
                    
                      <div className="italic py-4 xxs:text-xs md:text-lg">Arnav Anshuman -  Arnav is a geography (Hons) graduate with keen interest into sport research. An Inquisitive nature drives his consistent pursuit of truth and reason through domains.</div>
                    <div className=" flex flex-row gap-2 items-center" >
                      <Image className="xxs:w-[12%] md:w-[4%]" src={verified} />
                      
                      <strong className='text-lg'>
                        REVIEWED BY :
                      </strong>
                    </div>
                    
                      <a href='https://casamed.in/about/team/Akanchha' target="_blank" rel="noreferrer"><div className="italic py-4 text-lg">Dr Akanchha Gupta, Master of Physiotherapy (Musculoskeletal), Indian Spinal Injury Centre (ISIC) Institute of Rehabilitation Sciences Consultant Physiotherapist, CasaMed</div></a>
                  </div>

                  
                  </div>

                  <span className='italic text-lg'>Reading Time : 5 mins</span>
                
            </div>
        
            <div className='md:px-[15%] xxs:px-[8%]'>

            <div className="py-6 flex justify-center items-center flex-col ">
            <div className='border-4 border-whyus p-4 border-opacity-60 rounded-lg shadow-xl '>
            <h2 className="text-center font-medium text-2xl pb-3"><span className="underline underline-offset-4 decoration-casablue">T</span>able of <span className="underline underline-offset-4 decoration-casablue">C</span>ontents</h2>
            <ul>
              {tocItems.map((item, index) => (
                <li className='pb-2 text-lg font-medium' style={{listStylePosition: 'inside', listStyleType: "decimal"}} key={index}>
                  <a href={`#${item.id}`}>{item.text}</a>
                </li>
              ))}
            </ul>
            </div>
            </div>

<h2 id="Heading-1" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'></h2>
<p className='py-2 text-lg'>
    Are you tired of living with the pain and discomfort of arthritis? Well, no more as this blog is about to take you on a journey to a better quality of life. 
</p>

<p className='py-2 text-lg'>
    Arthritis refers to the pain, swelling, and stiffness in the joints. A joint is where two or more bones meet, such as in the fingers, shoulders, elbow or knee. 
</p>

<p className='py-2 text-lg'>
    Arthritis isn't a single disease and there are several different types. Each form causes different symptoms and may need different treatments. 
</p>

<p className='py-2 text-lg'>
    Arthritis usually develops as a result of ageing, but people of all ages and genders are susceptible to arthritis due to several other factors. 
</p>

<p className='py-2 text-lg'>
    It is more common in women and is the leading cause of disability in the world. Arthritis can make even the simplest tasks, like picking up a pencil, feel like a challenge. 
</p>

<p className='py-2 text-lg'>
    In this blog, we'll dive into what arthritis disease is, its different types, causes, symptoms, and some ways to manage it. 
</p>

<p className='py-2 text-lg'>
    So, whether you're newly diagnosed or have been living with arthritis for a while, join me on this journey to learn more about this condition and how to live a fulfilling life despite its challenges. 
</p>

<p className='py-2 text-lg'>
    From simple lifestyle changes to effective pain management techniques, we'll explore everything you need to know to manage your arthritis symptoms and live life to the fullest. 

</p>
    <div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F5_5_11zon.jpg?alt=media&token=4260e522-bc76-47aa-85ba-af20e856c4e4" className='rounded-lg shadow-xl w-3/4' />
    </div>

<h3  className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
    Key Takeaways
</h3>

<ul  className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        Get a better understanding of Arthritis and how common it is.
    </li>
    <li className="py-1 text-lg">
        Different types of Arthritis and their symptoms to identify which type of arthritis you have.
    </li>
    <li className="py-1 text-lg">
        Early signs of arthritis to look out for.
    </li>
    <li className="py-1 text-lg">
        Various methods of arthritis treatment you can undergo to manage your condition.
    </li>
    <li className="py-1 text-lg">
        Learn how to live a fulfilling life despite the challenges faced due to your arthritis symptoms.
    </li>
</ul>

<h2 id="Heading-2" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">W</span>hat is Arthritis?
</h2>


<p className='py-2 text-lg'>
    Arthritis is a degenerative disease that affects the joints in our body. It causes symptoms including swelling, pain, stiffness, and reduced range of motion in joints. These symptoms usually develop over time, but in some cases, they may also appear suddenly.
</p>

<p className='py-2 text-lg'>
    The severity of the symptoms varies from person to person. These symptoms may stay the same for years, but they can also progress and worsen over time without proper treatment or care.
</p>
    <div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F3_3_11zon.jpg?alt=media&token=70eab65e-0d3f-4544-8f90-2f514d0d4b08" className='rounded-lg shadow-xl w-3/4' />
    </div>

<p className='py-2 text-lg'>
    Severe arthritis can cause performing daily activities, walking, or climbing stairs extremely difficult due to chronic pain.
</p>

<p className='py-2 text-lg'>
    There are several types of arthritis, with each form having different causes and symptoms and requiring different methods of treatment.
</p>

<p className='py-2 text-lg'>
    Some of the common types of arthritis include Osteoarthritis, Rheumatoid Arthritis, Psoriatic Arthritis, and Gout.
</p>

<p className='py-2 text-lg'>
    Despite the fact that arthritis has no cure, decades of research have led to the development and improvement of various treatments to help you manage the symptoms and improve your quality of life.
</p>

<p className='py-2 text-lg'>
    Some of the treatment methods include medications, physiotherapy, and lifestyle changes such as exercise and a healthy diet.
</p>


<h2 id="Heading-3" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>rthritis Types: What Are They? 
</h2>

<p className='py-2 text-lg'>
    Arthritis is an umbrella term that describes more than 100 different joint conditions that involve inflammation and damage in the joints, the tissues around the joint, and other connective tissues. So it is important to identify and learn about the different types of arthritis you have, so as to receive the best treatment and management strategies. Some of the most common types of arthritis include: 
</p>

<h3  className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
    Osteoarthritis 
</h3>
<p className='py-2 text-lg'>
    Osteoarthritis is the most common type of arthritis. It is caused due to a mechanical breakdown of the cartilage in your joints. 
</p>

<p className='py-2 text-lg'>
    Since the cartilage acts as a cushion covering the ends of your joint, deterioration causes your bones to rub against each other and your joint to be inflamed. Rubbing of the bones in the joint alters the shape of the joint and forces the bones out of their normal position. 
</p>

<p className='py-2 text-lg'>
    Osteoarthritis can damage any joint in the body but typically affects the load-bearing joints such as the back, hand, knee, and hip. Osteoarthritis symptoms often develop slowly and worsen over time. 
</p>

<p className='py-2 text-lg'>
    Osteoarthritis typically affects just one joint, though in some cases, such as with finger arthritis, several joints can be affected. 
</p>

<p className='py-2 text-lg'>
    It also weakens the bones, deteriorates the tendons and ligaments as they have to work harder and damages the joint lining due to inflammation. 
</p>


<h3  className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
    Rheumatoid Arthritis 
</h3>
<p className='py-2 text-lg'>
    It is a type of auto-immune inflammatory arthritis. An auto-immune condition causes the immune system to be overactive, mistakenly attacking healthy tissues in the body, such as the joints. 
</p>

    <div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F9_9_11zon.jpg?alt=media&token=e9bb630e-365e-43a1-b65f-a0e9bc9757ea" className='rounded-lg shadow-xl' />
    </div>

<p className='py-2 text-lg'>
    This causes inflammation in the joints, as the body sends extra blood and fluid to fight off an infection that does not exist. This unnecessary inflammation damages the affected joints, resulting in pain, stiffness, and swelling. 
</p>

<p className='py-2 text-lg'>
    The inflammation and the extra fluid in the joint can make moving the joint painful and difficult. The chemicals in the fluid can also damage the bone and joint and irritate the nerve endings which can be painful.
    Rheumatoid arthritis often starts in the small joints in the hands and feet, and it can affect the same joints on both sides of the body at the same time.
 
</p>

<p className='py-2 text-lg'>
    Rheumatoid arthritis can affect other organs in the body, leading to a variety of symptoms.
    Gender, a family history of rheumatoid arthritis, age, obesity, previous joint damage from an injury, and exposure to tobacco smoke are some of the factors that increase the risk of developing rheumatoid arthritis.
 
</p>

<p className='py-2 text-lg'>
    It can also cause permanent damage to the joint, so starting treatment early can help minimise or prevent permanent damage as well as reduce pain and improve function. 
</p>


<h3  className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
    Infectious Arthritis 
</h3>
<p className='py-2 text-lg'>
    It is triggered by a bacterial, viral, or fungal infection. The infection usually travels from another part of the body to a joint. 
</p>

<p className='py-2 text-lg'>
    The symptoms, such as swelling, pain, and fever, can be sudden and intense. But the infection clears away pretty quickly once treatment with antibiotics or antifungals begins. 
</p>

<p className='py-2 text-lg'>
    However, if the infection is not treated and persists for some time, it can lead to chronic arthritis and irreversible joint damage.
</p>



<h3  className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
    Gout (Metabolic Arthritis) 
</h3>
<p className='py-2 text-lg'>
    Metabolic or gouty arthritis is caused due to the buildup of painful uric acid crystals in the joints. Uric acid is a byproduct formed when the body breaks down substances called purines. Purines are normally found in human cells and foods such as red meat, organ meat, some seafood, and alcohol. 
</p>

<p className='py-2 text-lg'>
    The body usually gets rid of excess uric acid, but when it doesn’t, it builds up and accumulates in the joints. The uric acid forms needle-like crystals in the joint causing sudden spikes of intense joint pain or a gout attack. 
</p>

<p className='py-2 text-lg'>
    For some people, gout comes and goes in episodes, while for others it can become chronic if the uric acid levels are not decreased.
</p>

<p className='py-2 text-lg'>
    Gouty arthritis usually affects a single joint or a small number of joints, such as the big toe and hands.
</p>

<p className='py-2 text-lg italic font-medium'>
    The other common types of arthritis and related conditions are:
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%] ' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className='py-1 text-lg'>Psoriatic Arthritis</li>
    <li className='py-1 text-lg'>Juvenile Idiopathic Arthritis</li>
    <li className='py-1 text-lg'>Ankylosing Spondylitis</li>
    <li className='py-1 text-lg'>Cervical Spondylosis</li>
    <li className='py-1 text-lg'>Fibromyalgia</li>
    <li className='py-1 text-lg'><a href='https://www.nhs.uk/conditions/lupus/' target="_blank" rel="noreferrer" className="text-blue-500 underline">Lupus</a></li>
    <li className='py-1 text-lg'>Enteropathic Arthritis</li>
    <li className='py-1 text-lg'>Spondyloarthritis</li>
    <li className='py-1 text-lg'>Septic Arthritis</li>
    <li className='py-1 text-lg'>Reactive Arthritis</li>
</ul>



<h2 id="Heading-4" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">H</span>ow Common is Arthritis?
</h2>

<p className='py-2 text-lg'>
    Indians suffer from arthritis in greater numbers than many well-known diseases like diabetes, cancer, and AIDS combined. Osteoarthritis is the most common form of arthritis. Around 15 million adults suffer from it every year. Women are more likely to suffer from osteoarthritis, and their chances of contracting it increase with age.
</p>

<p className='py-2 text-lg'>
    The prevalence of rheumatoid arthritis in India is 0.5%-1%, with women being three to four times more likely to be affected than men. It is most common in adults aged 35-55.
</p>

<p className='py-2 text-lg'>
    Men and obese people over 50 years of age are three to four times more likely to develop gout. Thus, it is one of the most common health problems that requires attention and treatment on time otherwise, it can lead to more severe conditions.
</p>



<h2 id="Heading-5" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">W</span>hat are the Causes of Arthritis?
</h2>

<p className='py-2 text-lg'>
    There are many types of arthritis, each having its own specific causes. But for some types, what causes arthritis is still unknown.
</p>

<p className='py-2 text-lg italic font-medium'>
    Some of the common causes of arthritis are:
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>ge :</strong> As we get older, the cartilage in the joints can wear down causing osteoarthritis. Ageing also increases the risk of other types of arthritis such as rheumatoid arthritis and gout. 
        <div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F4_4_11zon.jpg?alt=media&token=94693ed2-1f85-497e-a26b-fe5033f08d2f" className='rounded-lg shadow-xl w-2/3' />
        </div>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">G</span>enetics :</strong> Certain types of arthritis such as rheumatoid arthritis, have a genetic component associated with them. You are also more likely to develop arthritis if your parents or other family members have the condition.
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">G</span>ender :</strong> Rheumatoid arthritis is more common in women, while gout is more common in men.
        
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">L</span>ifestyle Factors: :</strong> People who are obese or lead a sedentary lifestyle with a poor diet, are at the risk of developing arthritis. Smoking can also increase the risk of arthritis.
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">I</span>njury :</strong> Injuries affecting the joint, such as a fracture, tendon or ligament tear can cause arthritis later on. It mainly leads to degenerative arthritis.
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>bnormal Metabolism :</strong> This can lead to gout or calcium pyrophosphate deposition disease.
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">I</span>nfections :</strong> Some infections such as Lyme disease, and bacterial or fungal infections can trigger arthritis.
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>utoimmune Disorders :</strong> It is a disorder where the immune system attacks the tissue around joints, leading to inflammation and arthritis mistakenly. Rheumatoid arthritis, lupus, and psoriasis arthritis are the types of arthritis caused due to this disorder.
    </li>
</ul>




<h2 id="Heading-6" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>ymptoms of Arthritis	
</h2>
<p className='py-2 text-lg'>
    There are different types of arthritis and their symptoms vary from individual to individual. They can develop gradually or suddenly.
</p>

<p className='py-2 text-lg'>
    The symptoms of arthritis that appear and how they appear vary widely, depending on the type.
</p>

<p className='py-2 text-lg'>
    However, Some people experience mild symptoms while others experience severe symptoms. The symptoms may come and go, or persist over time.
</p>

<p className='py-2 text-lg'>
    Individuals with arthritis may feel worse in the morning when you get out of bed or while standing up after a period of rest.
</p>

<p className='py-2 text-lg'>
    Talk to your primary care doctor about your symptoms. You may be referred to a rheumatologist or orthopedist, doctors who specialize in arthritis and other musculoskeletal conditions.
</p>

<p className='py-2 text-lg italic font-medium'>
    The most common symptoms are:
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">P</span>ain :</strong> It may affect one or more joints. Pain may be constant or it may keep coming back.        
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>welling :</strong> The skin over the affected joint becomes red and swollen and feels warm.
        <div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F2_2_11zon.jpg?alt=media&token=077bc915-6bd6-4029-9d7d-d29a10ee8048" className='rounded-lg shadow-xl w-2/3' />
        </div>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>tiffness :</strong> In some types of arthritis, stiffness can be observed upon waking up in the morning or getting up after a period of rest.
        
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>ifficulty Moving a Joint: :</strong> It may become difficult to move the joints in a normal way.
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">U</span>nusual Fatigue</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">F</span>ever</strong>
    </li>
</ul>



<p className='py-2 text-lg'>
    These are also some of the early signs of arthritis that indicate the need to visit a medical practitioner.  Some types of arthritis may cause their own unique symptoms on top of these general symptoms.
</p>

<p className='py-2 text-lg italic font-medium'>
    Symptoms of Osteoarthritis
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">C</span>licking or popping with bending</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">M</span>uscle weakness around the joint</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">I</span>nstability or buckling of the joint</>        
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">B</span>ony growths in the fingers</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">G</span>rating or scraping feeling in the knees</>
    </li>
</ul>

<p className='pt-6 pb-2 text-lg italic font-medium'>
    Other symptoms of Rheumatoid Arthritis
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">M</span>ore than one affected joint</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">O</span>nset in smaller joints like feet and hands</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>ame joints on both sides of the body are affected</>        
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">L</span>ow-grade fever</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">I</span>nflammation of the eyes and mouth</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">I</span>nflammation of the heart muscle and blood vessels</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">F</span>atigue</>
    </li>
</ul>



<p className='pt-6 pb-2 text-lg italic font-medium'>
    Other symptoms of Infectious Arthritis
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <><a className='text-blue-500 underline cursor-pointer' href='https://www.webmd.com/first-aid/fevers-causes-symptoms-treatments' target="_blank" rel="noreferrer"><span className="underline underline-offset-8 decoration-[#02B9ED]">F</span>ever</a></>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">C</span>hills</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">J</span>oint <a className='text-blue-500 underline cursor-pointer' href='https://www.webmd.com/arthritis/about-inflammation' target="_blank" rel="noreferrer">inflammation</a></>        
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">T</span>enderness</>
    </li>
    <li className="py-1 text-lg">
        <><span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>harp <a className='text-blue-500 underline cursor-pointer' href='https://www.webmd.com/pain-management/default.htm' target="_blank" rel="noreferrer">pain</a> that is related to an injury or infection elsewhere in your body</>
    </li>
</ul>




<h2 id="Heading-7" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">I</span>s Arthritis a Serious Disease?
</h2>

<p className='py-2 text-lg'>
    Untreated arthritis can lead to serious and sometimes irreversible joint damage, which is one of the leading causes of disability worldwide.
</p>

<p className='py-2 text-lg'>
    Some types of arthritis can also affect your skin, organs and other areas of your body.
</p>

<p className='py-2 text-lg'>
    Arthritis causes pain, fatigue, loss of function, mobility issues, and disability all of which can make performing daily activities very challenging. Being in pain from arthritis can often lead to anxiety and depression.
</p>

<p className='py-2 text-lg'>
    Since there is no cure for arthritis, once diagnosed you have to live with arthritis and manage its symptoms throughout your life.
</p>



<h2 id="Heading-8" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">T</span>reatment of Arthritis
</h2>

<p className='py-2 text-lg'>
    Arthritis is a chronic disease that makes it very difficult for people suffering from it to live a quality life and to carry out their daily activities.
</p>

<p className='py-2 text-lg'>
    Unfortunately, since there's no cure for arthritis the best treatment plan is to manage the pain and the progress of the condition.
</p>

<p className='py-2 text-lg'>
    The right treatment plan will vary based on the type of arthritis and its severity, its symptoms, and your overall health.
</p>

<p className='py-2 text-lg'>
    The treatment also aims to reduce inflammation, control the symptoms, prevent damage to the joints, restore joint function, and mainly improve the quality of life of the patient.
</p>

<p className='pb-2 pt-4 text-lg italic font-medium'>
    Some of the treatments include:
</p>

<h3 className='text-slate-600 pt-4 font-medium md:text-[22px] xxs:text-[20px]'>
    Medication  
</h3>

<p className='py-2 text-lg'>
    There are a number of different types of medication that treat arthritis. 
    <br/>
    They are:
</p>


<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>nalgesics :</strong> They are used to reduce pain. But they do not reduce inflammation. Hydrocodone(Vicodin) and acetaminophen(Tylenol) are some of the analgesics that are used.    
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">N</span>onsteroidal anti-inflammatory drugs(NSAIDs) :</strong> These help to manage both pain and inflammation. NSAIDs such as ibuprofen, naproxen sodium, and salicylates can be brought over the counter.    
    </li>
</ul>

<p className='py-2 text-lg'>
    There are prescription versions of these pain medications, for patients whose condition is severe and the OTC versions have no effect on reducing their pain or inflammation.
</p>

<p className='py-2 text-lg'>
    NSAIDs are also available as creams, gels, or patches which can be directly applied to the affected joints.
</p>

<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>isease-modifying anti-rheumatic drugs(DMARDs) :</strong> They are used to treat rheumatoid arthritis by slowing down the progression of the condition.    
    </li>
</ul>

<p className='py-2 text-lg'>
    DMARDs help in slowing or stopping the immune system from attacking the joints.
</p>

<p className='py-2 text-lg'>
    Methotrexate, sulfasalazine, and hydroxychloroquine are some of the drugs that are commonly prescribed by doctors.
</p>

<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">C</span>ounterirritant :</strong> These are creams and ointments that contain menthol or capsaicin. They help to block the transmission of pain signals from your joints, to reduce the pain.    
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">B</span>iologics :</strong> These are drugs that are genetically engineered to target various protein molecules involved in the immune response.    
    </li>
</ul>

<p className='py-2 text-lg'>
    They help to reduce the body’s overactive immune response.
</p>

<p className='py-2 text-lg'>
    Etanercept(Enbrel), infliximab(Remicade), and adalimumab(Humira) are some examples.
</p>

<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">C</span>orticosteroids :</strong> They are powerful anti-inflammatory agents that are injected directly into the joint. They help in providing pain relief and reducing inflammation in patients.    
    </li>
</ul>

<p className='py-2 text-lg'>
    Repeated usage of these injections over a period of time is harmful due to possible side effects such as long-term joint damage and infections.
</p>

<p className='py-2 text-lg'>
    Prednisone and cortisone are some examples that not only reduce inflammation but also help in suppressing the immune system.
</p>

<div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F8_8_11zon.jpg?alt=media&token=b38ac426-824f-41ff-a036-fbb797b67c82" className='rounded-lg shadow-xl w-2/3' />
</div>


<h3 className='text-slate-600 pt-4 font-medium md:text-[22px] xxs:text-[20px]'>
    Physical Therapy  
</h3>

<p className='py-2 text-lg'>
    People with arthritis are often recommended for physical therapy by doctors.
</p>

<p className='py-2 text-lg'>
    Physical therapy helps people with arthritis to overcome the challenges they face in performing daily activities, improve the range of motion of their joints and solve any mobility issues.
</p>

<p className='py-2 text-lg'>
    Specific exercises such as mobilisation exercises help the patients increase their range of motion and flexibility, and strengthening exercises help strengthen the muscles around the affected joints.
</p>

<p className='py-2 text-lg'>
    These exercises are often combined with ice and heat packs for pain relief.
</p>



<p className='py-2 text-lg'>
    Using heating pads on the joints helps reduce pain and stiffness.
    Whereas using ice packs on the joints helps in relieving joint pain, swelling, and inflammation.
</p>

<div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F1_1_11zon.jpg?alt=media&token=8063850e-d3d7-4dee-bcb1-eab5317a1692" className='rounded-lg shadow-xl w-2/3' />
</div>

<p className='py-2 text-lg'>
    Some physiotherapists may use Transcutaneous electric nerve stimulations(TENS) to relieve your pain.
</p>

<p className='py-2 text-lg'>
    A TENS device sends mild electric pulses to nerve endings in the affected areas.
</p>

<p className='py-2 text-lg'>
    This helps in blocking pain signals to the brain and hence changes the patient's pain perception.
</p>

<p className='py-2 text-lg'>
    Physiotherapists also give advice on how to manage your everyday task without causing further damage to the joints. They also teach patients to perform their daily activities without causing much pain and fatigue.
</p>



<h2 id="Heading-9" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>oes Arthritis Require Surgery?
</h2>

<div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F7_7_11zon.jpg?alt=media&token=2ab2a570-86ce-4f09-9388-363fe126432d" className='rounded-lg shadow-xl w-2/3' />
</div>

<p className='py-2 text-lg'>
    The need for surgery depends on the degree of your symptom and whether the other treatment options have been successful.
</p>

<p className='py-2 text-lg'>
    Surgery is also recommended for severe cases of arthritis where there is serious damage to the joints which can cause disability.
</p>

<p className='py-2 text-lg'>
    In these cases, surgery can help fix or replace a joint, improve joint function and mobility, and reduce pain.
</p>

<p className='pt-6 pb-2 text-lg italic font-medium'>
    Some of the common arthritis surgeries are:
</p>

<ul className='text-lg md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>rthroscopy (Joint Replacement)</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">O</span>steotomy (Joint Realignment)</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">T</span>otal Joint Replacement (TJR)</strong>        
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>ynovectomy</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">A</span>rthrodesis (Joint fusion)</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">L</span>igament Reconstruction and Tendon Interposition (LRTI)</strong>
    </li>
</ul>

<p className='pt-6 pb-2 text-lg italic font-medium'>
    Recovery after surgery can take up to 6 months. A rehabilitation program after surgery is an important part of the treatment. As with all surgeries, there are some risks involved and possible complications, which will all be discussed by the doctor beforehand.
</p>



<h2 id="Heading-10" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>oes Arthritis Require Surgery?
</h2>

<p className='py-2 text-lg'>
    Since there is no cure for arthritis, most arthritis patients will need to manage the symptoms for the rest of their lives. Living a healthy lifestyle is the best way to manage arthritis.
</p>

<p className='py-2 text-lg'>
    Some of the lifestyle changes that can be made include:
</p>

<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">R</span>educing Weight :</strong> Extra weight puts increased stress on load-bearing joints such as the hips and knees. So losing any excess weight reduces the symptoms of arthritis, especially osteoarthritis. 
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">E</span>xercise :</strong> Inactivity or becoming sedentary after experiencing joint pain, will only make the symptoms worse. 
    </li>
</ul>

<p className='py-2 text-lg'>
    So, regular exercise is very important to help you manage your arthritis and reduce joint pain and stiffness.
</p>

<p className='py-2 text-lg'>
    Overworking the joints while exercising can also cause further damage.
</p>

<p className='py-2 text-lg'>
    Thus it is important to perform exercises such as swimming, walking, and low-impact aerobic exercises that don't put too much strain on the joints.
</p>

<p className='py-2 text-lg'>
    Performing stretching, strengthening exercises, and range of motion exercises at home can also be helpful to reduce stiffness, strengthen the muscles, and keep the joints flexible.
</p>

<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>iet :</strong> Consuming a nutrient-rich diet is important to boost your immune system and your overall health. 
    </li>
</ul>

<div className='xxs:py-2 md:py-4 flex justify-center items-center'>
        <img src="https://firebasestorage.googleapis.com/v0/b/casamed-6ec79.appspot.com/o/Blog%2FArthritis%2F6_6_11zon.jpg?alt=media&token=7f120663-b806-48d1-bd69-43d8df79932e" className='rounded-lg shadow-xl w-2/3' />
</div>


<p className='py-2 text-lg'>
    A healthy diet can also help maintain a healthy weight. A plant-based diet is rich in antioxidants, this helps in reducing inflammation.
</p>

<p className='py-2 text-lg'>
    Other foods such as fish and nuts are also inflammation-reducing foods.
</p>

<p className='py-2 text-lg'>
    Foods that should be avoided or limited if you are diagnosed with arthritis include:
</p>

<ul className='md:pl-[10%] xxs:pl-[5%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}} >
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">F</span>ried Foods</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">P</span>rocessed Foods</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">D</span>airy Products</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">H</span>igh Intake of Red Meat</strong>
    </li>
    <li className="py-1 text-lg">
        <strong><span className="underline underline-offset-8 decoration-[#02B9ED]">S</span>ugary and Salty Foods</strong>
    </li>
</ul>

<p className='py-2 text-lg'>
    These foods will not only aggravate the inflammation associated with arthritis but can also contribute to other health conditions, such as obesity, high blood pressure, high cholesterol and heart disease.
</p>



<h2 id="Heading-11" className='pt-16 text-slate-700 font-semibold md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">H</span>ow can CasaMed Help with this?
</h2>

<p className='py-2 text-lg'>
    CasaMed can help you to manage your arthritic symptoms and slow down the progress of the condition. We aim to help patients with arthritis lead a high-quality life and help them achieve the highest level of autonomous function to carry out their daily activities as independently as possible.
</p>

<p className='py-2 text-lg'>
    Since CasaMed is a digital physiotherapy platform, it allows you to do exercises and attend physiotherapy sessions from the comfort of your own home. This is especially useful for patients who have difficulty moving because of arthritis.
</p>

<p className='py-2 text-lg'>
    We provide personalised exercise plans that are tailored to your needs and the type of arthritis you have.
</p>

<p className='py-2 text-lg'>
    Our physiotherapists also adjust the plan as the treatment progresses based on how your body reacts to the treatment and the physical limitations of your body.
</p>

<p className='py-2 text-lg'>
    This ensures that you get the possible treatment and a faster recovery so that you can get back to doing what you love the most.
</p>

<p className='py-2 text-lg'>
    We also track your progress and give detailed feedback after each session. 
</p>


<div id="Heading-12" className='pt-32 pb-16'>
    <div className="max-w-screen-xl mx-auto px-5 bg-light min-h-sceen">
	<div className="flex flex-col items-center">
		<h2  className="font-bold text-whyus text-5xl mt-5 tracking-tight">
			FAQ
		</h2>
		<p className="text-whyus text-opacity-70 text-xl mt-3">
			Frequenty asked questions
		</p>
	</div>
	<div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
                {faqs.map((faq,index) =>(
		<div className="xxs:py-5 md:py-10 " key={index}>
			<details className="group">
				<summary className="flex justify-between items-center font-medium cursor-pointer list-none">
					<span className='text-xl text-whyus'> {faq.question}</span>
					<span className="transition group-open:rotate-180">
                <svg fill="none" height="24" shapeRendering="geometricPrecision" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" width="24"><path d="M6 9l6 6 6-6"></path>
</svg>
              </span>
				</summary>
				<p className="text-whyus text-opacity-70 text-lg mt-3 group-open:animate-fadeIn">
                {faq.answer}
				</p>
                
			</details>
		</div>

))}
	</div>
</div>
</div>



<h2 id="Heading-13" className='pt-8 text-slate-700 font-semibold py-4 md:text-3xl xxs:text-2xl'>
    <span className="underline underline-offset-8 decoration-[#02B9ED]">F</span>inal Thoughts
</h2>

<p className='py-2 text-lg'>
    Arthritis is a degenerative condition that affects millions of people worldwide. It causes pain and swelling in the joints making it difficult for people to perform their daily activities and live a quality life.
</p>

<p className='py-2 text-lg'>
    Even though there is no cure for arthritis, the various treatment methods help people manage its symptoms and prevent the progression of the condition.
</p>

<p className='py-2 text-lg'>
    Starting the treatment early is very crucial, to prevent the condition from progressing and causing irreparable damage to the joints. If left untreated arthritis can even lead to disability.
</p>

<p className='py-2 text-lg'>
    A healthcare professional will work closely with you to develop a personalised treatment plan to fit your needs and goals.
</p>

<p className='py-2 text-lg'>
    The treatment plans generally include taking medications, exercising regularly, undergoing physiotherapy and adopting a healthy lifestyle which includes eating a balanced diet and staying active.
</p>

<p className='py-2 text-lg'>
    So this was all about what is arthritis, what are the different types, what are the causes, arthritis symptoms and treatment methods available.
</p>

<p className='py-2 text-lg'>
    I hope with the help of this information about arthritis, you now have a deeper understanding of the condition and how to manage it.
</p>

<h3 id="Heading-14" className='text-slate-600 pt-8 font-medium md:text-[22px] xxs:text-[20px]'>
    References
</h3>

<ul  className='text-lg md:pl-[10%] xxs:pl-[0%]' style={{listStylePosition: 'inside', listStyleType: 'disc'}}>
    <li className="py-1 text-sm break-words">
        <a className='text-blue-500 underline cursor-pointer' href='https://www.healthdirect.gov.au/arthritis' target="_blank" rel="noreferrer">https://www.healthdirect.gov.au/arthritis</a>
    </li>
    <li className="py-1 text-sm break-words">
        <a className='text-blue-500 underline cursor-pointer' href='https://orthoinfo.aaos.org/en/diseases--conditions/arthritis-of-the-knee/' target="_blank" rel="noreferrer">https://orthoinfo.aaos.org/en/diseases--conditions/arthritis-of-the-knee/</a>
    </li>
    <li className="py-1 text-sm break-words">
        <a className='text-blue-500 underline cursor-pointer' href='https://www.healthline.com/health/arthritis#diet-and-exercise' target="_blank" rel="noreferrer">https://www.healthline.com/health/arthritis#diet-and-exercise</a>
    </li>
    <li className="py-1 text-sm  break-words">
        <a className='text-blue-500 underline cursor-pointer' href='https://www.mayoclinic.org/diseases-conditions/arthritis/symptoms-causes/syc-20350772' target="_blank" rel="noreferrer">https://www.mayoclinic.org/diseases-conditions/arthritis/symptoms-causes/syc-20350772</a>
    </li>
    <li className="py-1 text-sm break-words">
        <a className='text-blue-500 underline cursor-pointer' href='https://my.clevelandclinic.org/health/diseases/12061-arthritis' target="_blank" rel="noreferrer">https://my.clevelandclinic.org/health/diseases/12061-arthritis</a>
    </li>
</ul>

<p className='py-2 text-lg italic'>
    The information provided here is for educational purposes to help you manage your symptoms of knee osteoarthritis and to prevent further deterioration of the condition. In case of discomfort, seek medical help
</p>




            </div>

        <Footer />

    </div>
  )
}

export default KneeOsteoarthritis