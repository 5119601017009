import React from "react";
import { Link } from "react-router-dom";

import arthiritis from "../Assets/HomeViews/Persona/arthiritis.svg";
import pcod from "../Assets/HomeViews/Persona/pcod.svg";
import postureback from "../Assets/HomeViews/Persona/postureback.svg";
import sports from "../Assets/HomeViews/Persona/sports.svg";

function Persona() {
  return (
    <section className="body-back2 py-16 ">
      <header className="flex-col  text-center flex-wrap justify-center items-center  font-poppins text-casashade2 xxs:pb-2 px-6  sm:pb-32">
        <div className=" flex justify-center items-center">
          <h2
            style={{ lineHeight: 1.4 }}
            className="pb-12 font-semibold xxs:text-3xl md:text-5xl md:w-[70%] w-full "
          >
            <span className="decoration-[#02B9ED] underline underline-offset-8">
              S
            </span>
            ervices We Offer
          </h2>
        </div>

        <h4 className="text-[20px]">
          CasaMed covers all your Pain Management needs with an array of Curated
          Care Plans
        </h4>
      </header>

      <section
        id="persona"
        className=" flex flex-wrap justify-center items-center font-poppins text-black"
      >
        <ul className="grid xxs:grid-cols-1 sm:grid-cols-4 items-end px-16">
          <Link to="/arthiritis-therapy" className="">
            <li className="xxs:mr-0 flex justify-center flex-col text-center hover:drop-shadow-md hover:-translate-y-1 hover:shadow-black duration-200">
              <img src={arthiritis} alt="" className="w-[80%]" />

              <span className="font-semibold  rounded-lg  hover-underline-animation text-casashade2 text-[22px] tracking-wider pt-4">
                Arthiritis Chronic Pain
              </span>
            </li>
          </Link>
          <Link to="/posture-issues">
            <li className="xxs:mr-0 flex justify-center  flex-col text-center items-center hover:drop-shadow-md hover:-translate-y-1 hover:shadow-black duration-200">
              <img src={postureback} alt="" className="w-[60%]" />

              <span className="font-semibold  rounded-lg hover-underline-animation text-casashade2 text-[22px] tracking-wider pt-4">
                Posture Issues
              </span>
            </li>
          </Link>

          <Link to="/sports-injury">
            <li className="xxs:mr-0 flex justify-center  flex-col text-center items-center hover:drop-shadow-md hover:-translate-y-1 hover:shadow-black duration-200">
              <img src={sports} alt="" className="w-[60%]" />

              <span className="font-semibold rounded-lg hover-underline-animation text-casashade2 text-[22px] tracking-wider pt-4">
                Sports Injury
              </span>
            </li>
          </Link>

          <Link to="/pcod">
            <li className="xxs:mr-0 flex  justify-center flex-col  text-center items-center hover:drop-shadow-md hover:-translate-y-1 hover:shadow-black duration-200 ">
              <img src={pcod} alt="" className="w-[70%]" />

              <span className="font-semibold  rounded-lg hover-underline-animation text-casashade2 text-[22px] tracking-wider pt-4 ">
                PCOD
              </span>
            </li>
          </Link>
        </ul>
      </section>
    </section>
  );
}

export default Persona;
